import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing';
import { DashboardComponent } from './Component/Dashboard/dashboard.component'
import { ProviderComponent } from './Component/Provider/provider.component';
import { PatientsComponent } from './Component/Patient/patient.component';
import { ProviderDetailsComponent } from './Component/Provider/Provider-details/provider-details.component';
import { PatientDetailsComponent } from './Component/Patient/Patient-details/patient-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { AddOEditDoctorComponent } from './Component/Provider/Add-edit-provider-details/Add-edit-patient-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddOEditPatientComponent } from './Component/Patient/Add-edit-patient-details/add-edit-patient-details.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from "ngx-bootstrap/tabs";
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReferralComponent } from '@app/shared/components/referral/referral.component';
import { AddReferralComponent } from '@app/shared/components/referral/Add-edit-referral/add-referral.component';
import { ChangePasswordComponent } from '@app/Login/change-password/change-password.component';
import { MessageComponent } from './Component/Message/message.component';
import { NewMessageComponent } from './Component/Message/new-message/new-message.component';
import { FacilityComponent } from './Component/Facility/facility.component';
import { AddOrEditFacilityComponent } from './Component/Facility/add-or-edit-facility/add-or-edit-facility.component';
import { MatPaginatorModule } from "@angular/material/paginator";
import { FacilityDetailsComponent } from './Component/Facility/facility-details/facility-details.component';
import { AttorneyInsuranceComponent } from './Component/attorney-insurance/attorney-insurance.component';
import { EditAddAttorneyInsuranceComponent } from './Component/attorney-insurance/edit-add-attorney-insurance/edit-add-attorney-insurance.component';
import { AttorneyInsuranceDetailsComponent } from "./Component/attorney-insurance/attorney-insurance-details/attorney-insurance-details.component";
import { MyAccountComponent } from '@app/home/Component/My-Account/my-account.component';
import { ReferralDeatilsComponent } from '@app/shared/components/referral/referral-details/referral-details.component';
import { NgxMaskModule } from 'ngx-mask';
import { NotesComponent } from './Component/notes/notes.component';
import { DiagnosticImagingComponent } from './Component/diagnostic-imaging/diagnostic-imaging.component';
import { DomSanatizePipe } from './Component/safe.pipe';
import { ConsultationComponent } from './Component/consultation/consultation.component';
import { RecordsComponent } from './Component/records/records.component';
import { PhotosComponent } from './Component/photos/photos.component';
import { NotificationComponent } from './Component/notification/notification.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { AdminsComponent } from './Component/Admins/admins.component';
import { AdminsDetailsComponent } from './Component/Admins/Admins-details/admins-details.component';
import { AddOEditAdminComponent } from './Component/Admins/Add-edit-admin-details/Add-edit-admin-details.component';
import { StaffsComponent } from './Component/staffs/staffs.component';
import { MyBusinessComponent } from './Component/my-business/my-business.component';

// import { AddStaffDialogComponent } from './Component/staffs/add-staff-dialog.component';







@NgModule({
  declarations: [
    SpinnerComponent,
    HomeComponent,
    DashboardComponent,
    ProviderComponent,
    PatientsComponent,
    AdminsComponent,
    PatientDetailsComponent,
    ProviderDetailsComponent,
    AdminsDetailsComponent,
    AddOEditDoctorComponent,
    AddOEditAdminComponent,
    ReferralComponent,
    ReferralDeatilsComponent,
    AddOEditPatientComponent,
    AddReferralComponent,
    ChangePasswordComponent,
    MessageComponent,
    NewMessageComponent,
    FacilityComponent,
    AddOrEditFacilityComponent,
    FacilityDetailsComponent,
    AttorneyInsuranceComponent,
    EditAddAttorneyInsuranceComponent,
    AttorneyInsuranceDetailsComponent,
    MyAccountComponent,
    NotesComponent,
    DiagnosticImagingComponent,
    DomSanatizePipe,
    ConsultationComponent,
    RecordsComponent,
    PhotosComponent,
    NotificationComponent,
    StaffsComponent,
    MyBusinessComponent,


  ],
  imports: [
    HomeRoutingModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUiLoaderModule,
    CommonModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    NgbModule,
    MatPaginatorModule,
    NgxMaskModule.forRoot(),
    TabsModule.forRoot()
  ],
  providers: [BnNgIdleService],
})
export class HomeModule { }
