<div class="px-3">
    <form [formGroup]="createMessage" (submit)="onMessageSend()">
        <div class="form-group">
            <ng-select [items]="providerList" placeholder="Select User" bindLabel="name" [multiple]="true"
                (change)="onEmailSelect($event)" bindValue="email" formControlName="email"></ng-select>
            <small class="text-danger"
                *ngIf="!!createMessage.controls.email.invalid && !!createMessage.controls.email.touched">Required</small>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" formControlName="subject" placeholder="Subject" />
            <small class="text-danger"
                *ngIf="!!createMessage.controls.subject.invalid && !!createMessage.controls.subject.touched">Required</small>
        </div>
        <div class="form-group">
            <textarea placeholder="Message" formControlName="messages" class="form-control"></textarea>
            <!-- <small class="text-danger" *ngIf="!!createMessage.controls.message.invalid && !!createMessage.controls.message.touched">Required</small> -->
        </div>
        <div class="form-group text-right">
            <button class="btn bg_green text-white" type="submit" [disabled]="createMessage.invalid || isFormSubmitting">
                <ng-container *ngIf="isFormSubmitting;else isFornNotSubmitting"> Sending... </ng-container>
                <ng-template #isFornNotSubmitting> Send </ng-template>
            </button>
        </div>
    </form>
</div>
