import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MainService } from '../../../services/mainservice/main.service';
// import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { providerdetailswithPaginationandresponse } from '@app/models/provider/prodider.model';
// import { adminDetailswithPaginationandresponse } from '@app/models/admin/admin.model';
import { staffDetailswithPaginationandresponse } from '@app/models/staff/staff.model';

@Injectable()

export class StaffsResolver implements Resolve<staffDetailswithPaginationandresponse | null> {
    constructor(
        private _MainService: MainService,
        // private ngxLoader: NgxUiLoaderService
    ) { }
    resolve(): Promise<staffDetailswithPaginationandresponse> | null {
        // this.ngxLoader.start();
        return this._MainService.getStaffs().then(data => {
            // this.ngxLoader.stop()
            return data;
        });
    }
}
