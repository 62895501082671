import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { NotificationService } from '@app/core/services/provider/notification.service';
import { MainService } from '@app/services/mainservice/main.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { FormGroup, FormBuilder } from '@angular/forms';

import * as moment from 'moment'
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  //styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() patientID: any
  notificationList: any;
  myDate: any
  fromDay: any
  toDay: any
  minDate: Date;
  maxDate: Date;
  role:any
  reviewedList: any;
  notificationFormGroup: FormGroup;
  isRadioReviewedChecked: any;
  isRadioUnReviewedChecked: any;
  pagination: any;
  dataSwiching: boolean;
  constructor(private _ActivatedRoute: ActivatedRoute, private _NgxService: NgxUiLoaderService, private _MainService: MainService, private _FormBuilder: FormBuilder, private _NgxLoader: NgxUiLoaderService
    ) {

    this.isRadioUnReviewedChecked = true;
    this.dataSwiching = true;
    this.minDate = new Date(),
      this.maxDate = new Date(),
    this.notificationFormGroup = this._FormBuilder.group({


      fromDate: [],
      toDate: []
    })
  }

  ngOnInit(): void {
    this.role = window.localStorage.getItem('role')
   
    this.notificationFormGroup.controls.toDate.setValue(new Date())

    this.myDate = new Date();
    this.myDate.setDate(this.myDate.getDate() - 7);

    this.notificationFormGroup.controls.fromDate.setValue(this.myDate)
    this.dateFilter()

    this._ActivatedRoute.data.subscribe(data => {

      this.notificationList = data.notification.data
      console.log(this.notificationList,"132");
      

      this.pagination = data.notification.pagination;
    })
  }
  isSeenTrueStatus() {
    this.dateFilter()


    // this._MainService.getNotificationCounter().then(x => {
    //   this._NotificationService.setnotificationData(x.data);
    // })
  }
  dateFilter() {

    this.fromDay = (moment(this.notificationFormGroup.controls.fromDate.value).format("YYYY-MM-DD"));
 


    this.toDay = (moment(this.notificationFormGroup.controls.toDate.value).format("YYYY-MM-DD"));
    


  }

  dateOrderMin(mDate: any) { this.minDate = mDate; }

  notifcsByDate() {
    this.dateFilter();
    this._NgxService.start()


    this._MainService.notifcationCountData(10, 1, '', false, this.fromDay, this.toDay).then((data: any) => {
      

      this.notificationList = data.data
      this.pagination = data.pagination
      this._NgxService.stop()
    })


  }
  

  // isSeenFalseStatus(_id: any) {
  //   this._MainService.getPatientsbyPatientID(_id, false)
  //   this._MainService.getNotificationCounter().then(x => {
  //     this._NotificationService.setnotificationData(x.data);
  //   })
  // }







  checkedUnReviewed(event: any) {

    debugger
    if (event.target.checked) {
      this.isRadioUnReviewedChecked = true;
      this.isRadioReviewedChecked = false;
      this.dataSwiching = false;
      this._MainService.notifcationCountData(10, 1, '', false, this.fromDay, this.toDay).then((data: any) => {
        if (data.success) {
          this._NgxLoader.stop()
          this.notificationList = data.data;
          this.pagination = data.pagination;
        } else this._NgxLoader.stop()
      })
    }
  }
  checkedReviewed(event: any) {
    debugger
    if (event.target.checked) {
      this.isRadioReviewedChecked = true;
      this.isRadioUnReviewedChecked = false;
      this.dataSwiching = true;
      this._MainService.notifcationCountData(10, 1, '', false, this.fromDay, this.toDay).then((data: any) => {
        if (data.success) {
          this._NgxLoader.stop()
          this.reviewedList = data.data;
          this.pagination = data.pagination;
        } else this._NgxLoader.stop()
      })
    }
  }
  pageChange(pagedata: any) {
    this.dateFilter()
    this._NgxLoader.start()
    this._MainService.notifcationCountData(10, pagedata.pageIndex + 1, '', false, this.fromDay, this.toDay).then((data: any) => {
      if (data.success) {
        this._NgxLoader.stop()
        if (this.dataSwiching) this.notificationList = data.data;
        else this.reviewedList = data.data;
        this.pagination = data.pagination;
      } else this._NgxLoader.stop()
    })
  }
}
