
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Utilities {
    

    /**
     * Generate random password
     * In form of Capital letters, small letters, digits and special characters
     * @returns string
     */
    getRandomPassword(): string {
        const source = [
            'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            'abcdefghijklmnopqrstuvwxyz',
            '0123456789',
        ];

        let password = source.map((item, index) => {
            let i = index + 2;
            let str = '';
            (function random2chars() {
                if (--i < 0) {
                    return;
                }
                str += item[Math.floor(Math.random() * item.length)];
                random2chars();
            })();
            return str;
        });

        return (function () {
            password = password.join('').split('');
            let currentIndex = password.length,
                temporaryValue,
                randomIndex;

            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = password[currentIndex];
                password[currentIndex] = password[randomIndex];
                password[randomIndex] = temporaryValue;
            }
            return password.join('');
        })();
    }

    /**
     * get age in years from dateOfBirth
     * @param birthDay Date of birth in Date or string type
     * @returns Returns age in years
    */
    getAgeFromDOB(birthDay: Date | string): number {
        if (birthDay) {
            const today = new Date();
            let birthDate: Date;
            if (typeof birthDay === 'string') {
                birthDate = new Date(birthDay);
            } else {
                birthDate = birthDay;
            }
            let age = today.getFullYear() - birthDate.getFullYear();
            const month = today.getMonth() - birthDate.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else {
            return 0;
        }
    }

    /**
     * resolve the values for multiple select generated from ng-select
     * @param value Array<MasterDataDDL> | MasterDataDDL
     * @returns Array<number> | number
     */
    // ngSelectValueResolver(value: Array<MasterDataDDL> | MasterDataDDL): Array<number> | number | null {
    //     if (value) {
    //         if (value.constructor.name === 'Array') {
    //             return (<Array<MasterDataDDL>>value).map((ddlValue) => {
    //                 return getIdFromDDL(ddlValue);
    //             });
    //         } else {
    //             return getIdFromDDL(<MasterDataDDL>value);
    //         }
    //     } else {
    //         return null;
    //     }

    //     function getIdFromDDL(ddlValue: MasterDataDDL): number {
    //         if (ddlValue.id) {
    //             return ddlValue.id;
    //         } else if (typeof ddlValue === 'number' || typeof ddlValue === 'string') {
    //             return ddlValue;
    //         } else {
    //             return 0;
    //         }
    //     }
    // }

    /**
     * resolve the values for angular datepicker
     * @param date any
     * @returns date in ISOString format
     */
    getValueFromDatepicker(dateValue: any): string | null {
        if (dateValue) {
            return dateValue.jsdate ? (<Date>dateValue.jsdate).toISOString() : null;
        } else {
            return null;
        }
    }

    /**
     * @param date Date | string
     * @returns date in angular datepicker format
     */
    setValueForDaatepicker(value: Date | string): any {
        if (value) {
            const date = new Date(value);
            return {
                date: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                },
                jsdate: date
            };
        } else {
            return null;
        }
    }
}

