<div *ngIf="isLoading" class="h-100 w-100 loader-overlay">
  <div class="progress">
    <div class="indeterminate"></div>
  </div>
</div>
<div class="container-custom my-5 mx-auto">
  <div class="bg-white shadow rounded">
    <div class="form-group ml-3" style="width: 40%;" *ngIf="addressList.length > 2">
      <label class="font-weight-600" for="">Select Location<span class="text-danger">*</span></label>
      <ng-select [items]="addressList" bindLabel="addressLine1" bindValue="_id" placeholder="Select case status"
      (change)="onAddressSelect($event)" [(ngModel)]="selectedAddress"></ng-select>
    </div>
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ml-1">
        <ng-container>
          <div class="d-flex ">
            <div class="form-check mr-3">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                (click)="checkedPatient($event)" [checked]="isRadioPatientChecked">
              <label class="form-check-label cursor-pointer ml-1 " for="flexRadioDefault1"> Patient </label>
            </div>
            <div class="form-check mr-3">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                (click)="checkedStatus($event)" [checked]="isRadioStatusChecked">
              <label class="form-check-label cursor-pointer ml-1 " for="flexRadioDefault2"> Status Needed </label>
            </div>
            <div class="form-check mr-3">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"
                (click)="checkedUrgentStatus($event)" [checked]="isRadioUrgentStatusChecked">
              <label class="form-check-label cursor-pointer ml-1 " for="flexRadioDefault3"> Urgent Status </label>
            </div>
          </div>
        </ng-container>
        <!-- <ng-template #roleProvider>
          <h4>Patients</h4>
        </ng-template> -->
        <div class="form-row w-50">
          <div class="col">
            <input type="text" class="form-control form-control-sm" (input)="searchPatient($event)"
              placeholder="Search by: Patient name" aria-label="Search patient" />
              <span class="alertUpdate" *ngIf="expressionUpdate">Please enter at least 3 characters.</span>

          </div>
          <div class="col-auto" *ngIf="isSearchKeyFilled">
            <button class="btn btn-sm btn_m_outline_green" type="button" id="btn-search-doctor" (click)="clearSearch()">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
              Clear Filters
            </button>
          </div>
          <div class="col-auto">
            <a class="btn btn-sm btn_m_outline_green" routerLink="../edit-add-patient/0">
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add New Patient
            </a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isRadioPatientChecked" class="p-3 position-relative">
      <div class="table-responsive border border-bottom-0">
        <table class="table table-sp-sm mb-0" aria-describedby="describe">
          <thead class="header_main_bg border-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Date of birth</th>
              <th scope="col" class="border-bottom-0 border-top-0">Gender</th>
              <th scope="col" class="border-bottom-0 border-top-0">Associated Providers</th>
              <th scope="col" class="border-bottom-0 border-top-0">Insurance</th>
              <th scope="col" class="border-bottom-0 border-top-0">Attorney</th>
              <th scope="col" class="border-bottom-0 border-top-0">Phone Number</th>
              <th scope="col" *ngIf="roleAdmin" class="border-bottom-0 border-top-0" (click)="deleteBtnActive ? openModal() : '' "><em class="fa fa-trash"
                aria-hidden="true" [style.color]="deleteBtnActive ? 'red' : 'white'" style="font-size: 20px;"></em></th>
              <th scope="col" *ngIf="roleSuperAdmin" class="border-bottom-0 border-top-0" (click)="deleteBtnActive ? openModal() : '' "><em class="fa fa-trash"
                aria-hidden="true" [style.color]="deleteBtnActive ? 'red' : 'white'" style="font-size: 20px;"></em></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="patientsList && patientsList.length > 0; else noPatientFound">
              <ng-template ngFor let-patient [ngForOf]="patientsList">
                <!-- <tr> -->
                <tr [style.background-color]="patient?.patientType === 'General Patient' ? '' : patient?.patientType === 'Commercial Patient' ? '#4aa93c' :patient?.patientType === 'Dropped Patient' ? '#FF0000' :patient?.patientType === 'Completed' ? '#FFFF00' :  patient?.patientType === 'Surgical Patient' ? '#b2d7f0' : 'white'">
                  <td (click)="goToView(patient._id)">
                    <div class="form-row">
                      <div class="col">
                        <p class="mb-0 name_limit">
                          {{ patient.firstName }} {{ patient.lastName }}
                          <small *ngIf="patient.genderId"
                            class="d-inline text-muted">({{ patient.genderName.toLowerCase() === "male" ? "Male" : "Female" }})</small>
                          <ng-container *ngIf=" patient.addressLine1 || patient.addressLine2; else noAddressProvided ">
                            <small class="text-muted">
                              <span class="d-inline">{{ patient.addressLine1 }}</span>
                              <span class="d-inline" *ngIf="patient.addressLine2"><span *ngIf="patient.addressLine1">,
                                </span>{{ patient.addressLine2 }}</span>
                            </small>
                          </ng-container>
                          <ng-template #noAddressProvided>
                            <small class="text-muted">No address provided</small>
                          </ng-template>
                        </p>
                      </div>
                    </div>
                  </td>

                  <td (click)="goToView(patient._id)">
                    <p class="mb-0">
                      {{ patient.dateOfBirth ? (patient.dateOfBirth | date: "MM/dd/yyyy") : "No dob provided" }}<br />

                    </p>
                  </td>
                  <td (click)="goToView(patient._id)">{{ patient?.gender?.name }}</td>

                  <td style="white-space: normal;" (click)="goToView(patient._id)">
                    <span
                  class="font-weight-light"
                  *ngIf="patient?.treatingDoctor!=='null'">
                  <ng-template ngFor let-provider let-last="last" let-i="index" [ngForOf]="patient?.treatingDoctor">
                    {{provider?.firstName}} {{provider?.lastName}}
                     <span *ngIf="!last"></span>
                  </ng-template>
                  </span>
                    <ng-template #noprovider>
                      <span class="d-inline">No Associated Provider</span>
                    </ng-template>
                  </td>
                  <td (click)="goToView(patient._id)">
                    <span class="d-inline">{{ patient?.insurence?.primaryInsurence || "No Insurance" }}</span>
                  </td>
                  <td (click)="goToView(patient._id)">
                    <span class="d-inline">{{ patient?.attorney?.firstName  || "No Attorney" }} {{ patient?.attorney?.firstName ? patient?.attorney?.lastName : ''}}</span>
                  </td>
                  <td (click)="goToView(patient._id)">
                    <span class="d-inline">{{ patient?.cellNumber || "No Phone" }}</span>
                  </td>
                  <td *ngIf="roleAdmin">
                    <input (click)="selectedPatients(patient._id)" type="checkbox"/>
                  </td>
                  <td *ngIf="roleSuperAdmin">
                    <input (click)="selectedPatients(patient._id)" type="checkbox"/>
                  </td>
                </tr>
              </ng-template>
            </ng-container>

            <ng-template #noPatientFound>
              <tr class="border-bottom">
                <td colspan="12">
                  <div class="text-center py-5">
                    <h1 class="display-4">

                    </h1>
                    <h5 class="font-weight-bold text-muted mt-3">
                      No patient found
                    </h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <mat-paginator [length]="pagination" [pageSize]="10" (page)="pageChange($event)">
      </mat-paginator>
    </div>
    <div *ngIf="isRadioStatusChecked" class="p-3 position-relative">
      <div class="table-responsive border border-bottom-0">
        <table class="table table-sp-sm mb-0" aria-describedby="describe">
          <thead class="header_main_bg border-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Patient Id</th>
              <th scope="col" class="border-bottom-0 border-top-0">Patient Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Pending</th>
              <th scope="col" class="border-bottom-0 border-top-0">Ordered Date</th>
              <th scope="col" class="border-bottom-0 border-top-0">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="statusNeededList !== null; else noStatus">
              <ng-container *ngFor="let notification of statusNeededList">
                <tr>
                  <td> <span class="d-inline">{{ notification?.patientId?.patientUID || "No Subject" }}</span> </td>
                  <td> <span class="d-inline"> {{ notification?.patientId?.fullName }} </span> </td>
                  <td class="inline_giver">
                    <ng-container *ngIf="notification?.ActionName?.length >1;else def">
                      <span class="completedActionCounter"
                        *ngFor="let action of notification?.ActionName;let i = index"> {{ action }}
                        <span class="counter_ac" *ngIf="i===0">{{notification.diagnosticCount}}</span> <br />
                        <span class="counter_ac" *ngIf="i===1">{{notification.consultationCount}}</span>
                      </span>
                    </ng-container>
                    <ng-template #def>
                      <span class="completedActionCounter"
                        *ngFor="let action of notification?.ActionName;let i = index"> {{ action }}
                        <span class="counter_ac"
                          *ngIf="i===0 && notification.diagnosticCount
                          !==0">{{notification.diagnosticCount}}</span>
                        <br />
                        <span class="counter_ac"
                          *ngIf="i===0 && notification.consultationCount
                          !==0">{{notification.consultationCount}}</span>
                      </span>
                    </ng-template>

                  </td>
                  <td>
                    <span class="d-inline" *ngIf="notification?.diagnostic[0]?.date">
                      {{ notification?.diagnostic[0]?.date | date:"MM/dd/YYYY , hh:mm aa" }} </span>
                    <br *ngIf="notification?.diagnostic[0]?.date">
                    <span class=""
                      [style]="notification?.diagnostic[0]?.date?'padding-top:10px':'padding-top:0px'">
                      {{ notification?.consultations[0]?.date | date:" MM/dd/YYYY , hh:mm aa" }} </span>
                  </td>
                  <td>
                    <ng-container *ngIf="notification?.patientDiagnosticCount!==0 ;else cons">
                      <button class="btn btn_m_outline_green position-relative diag"
                        routerLink="../patient-details/{{ notification?.patientId?._id }}" [queryParams]="{ tab: 2
                        }">Details
                        <span
                          class="counter_ac">{{notification?.consultationCount + notification.diagnosticCount}}</span>
                      </button>
                    </ng-container>
                    <ng-template #cons>
                      <button class="btn btn_m_outline_green position-relative cons"
                        routerLink="../patient-details/{{ notification?.patientId?._id }}" [queryParams]="{ tab: 3
                        }">Details
                        <span
                          class="counter_ac">{{notification?.patientConsultationCount + notification.patientDiagnosticCount}}</span>
                      </button>
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-template #noStatus>
              <tr class="border-bottom">
                <td colspan="12">
                  <div class="text-center">
                    <h1 class="display-4">

                    </h1>
                    <h5>
                      No Status Found
                    </h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <mat-paginator [length]="statusPagination" [pageSize]="10" (page)="statusPageChange($event)">
      </mat-paginator>
    </div>
    <div *ngIf="isRadioUrgentStatusChecked" class="p-3 position-relative">
      <div class="table-responsive border border-bottom-0">
        <table class="table table-sp-sm mb-0" aria-describedby="describe">
          <thead class="header_main_bg border-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Patient Id</th>
              <th scope="col" class="border-bottom-0 border-top-0">Patient Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Pending</th>
              <th scope="col" class="border-bottom-0 border-top-0">Ordered Date</th>
              <th scope="col" class="border-bottom-0 border-top-0">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="urgentStatuslist !== null; else noStatus">
              <ng-container *ngFor="let notification of urgentStatuslist">
                <tr>
                  <td> <span class="d-inline">{{ notification?.patientId?.patientUID || "No Subject" }}</span> </td>
                  <td> <span class="d-inline"> {{ notification?.patientId?.fullName }} </span> </td>
                  <td class="inline_giver">
                    <ng-container *ngIf="notification?.ActionName?.length >1;else def">
                      <span class="completedActionCounter"
                        *ngFor="let action of notification?.ActionName;let i = index"> {{ action }}
                        <span class="counter_ac" *ngIf="i===0">{{notification.diagnosticCount}}</span> <br />
                        <span class="counter_ac" *ngIf="i===1">{{notification.consultationCount}}</span>
                      </span>
                    </ng-container>
                    <ng-template #def>
                      <span class="completedActionCounter"
                        *ngFor="let action of notification?.ActionName;let i = index"> {{ action }}
                        <span class="counter_ac"
                          *ngIf="i===0 && notification.diagnosticCount
                          !==0">{{notification.diagnosticCount}}</span>
                        <br />
                        <span class="counter_ac"
                          *ngIf="i===0 && notification.consultationCount
                          !==0">{{notification.consultationCount}}</span>
                      </span>
                    </ng-template>

                  </td>
                  <td>
                    <span class="d-inline" *ngIf="notification?.diagnostic[0]?.date">
                      {{ notification?.diagnostic[0]?.date | date:"MM/dd/YYYY , hh:mm aa" }} </span>
                    <br *ngIf="notification?.diagnostic[0]?.date">
                    <span class=""
                      [style]="notification?.diagnostic[0]?.date?'padding-top:10px':'padding-top:0px'">
                      {{ notification?.consultations[0]?.date | date:" MM/dd/YYYY , hh:mm aa" }} </span>
                  </td>
                  <td>
                    <ng-container *ngIf="notification?.diagnosticCount!==0 ;else cons">
                      <button class="btn btn_m_outline_green position-relative diag"
                        routerLink="../patient-details/{{ notification?.patientId?._id }}" [queryParams]="{ tab: 2
                        }">Details
                        <span
                          class="counter_ac">{{notification?.consultationCount + notification.diagnosticCount}}</span>
                      </button>
                    </ng-container>
                    <ng-template #cons>
                      <button class="btn btn_m_outline_green position-relative cons"
                        routerLink="../patient-details/{{ notification?.patientId?._id }}" [queryParams]="{ tab: 3
                        }">Details
                        <span
                          class="counter_ac">{{notification?.consultationCount + notification.diagnosticCount}}</span>
                      </button>
                    </ng-template>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-template #noStatus>
              <tr class="border-bottom">
                <td colspan="12">
                  <div class="text-center">
                    <h1 class="display-4">

                    </h1>
                    <h5>
                      No Status Found
                    </h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <mat-paginator [length]="urgentStatusPagination" [pageSize]="10" (page)="urgentStatusPageChange($event)">
      </mat-paginator>
    </div>
  </div>
</div>


<!-- Modal HTML -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'d-block': isModalOpen, 'd-none': !isModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Modal content goes here -->
        <p>Do you want to delete selected patients?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">No</button>
        <button type="button" class="btn btn-primary" (click)="deletePatientInBulk()">Yes</button>
      </div>
    </div>
  </div>
</div>
