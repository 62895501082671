import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '@app/services/mainservice/main.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-attorney-insurance',
  templateUrl: './attorney-insurance.component.html'
})
export class AttorneyInsuranceComponent implements OnInit {
  pagination: any
  attorneyInsuranceData: any = []
  isLoading: boolean = false
  role: any

  constructor(private _Router: Router, private _MainService: MainService, private _NgxService: NgxUiLoaderService, private _NgxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this._NgxService.start()
    this.isLoading = true
    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.role = data.data.role
      }
    })
    this._MainService.getattorneyinsuranceList(1, 10, "").then((data: any) => {
      if (data.success) {
        this.isLoading = false
        this._NgxService.stop()
        this.attorneyInsuranceData = data.data
       
        
        this.pagination = data.pagination;
      } else {
        this._NgxService.stop()
        this.isLoading = false
      }
    })
  }

  goToView(id: any) {
    if (id) {
     
      this._Router.navigate([`/admin/attorney-insurance-details/${id}`])
     
    }
  }
  searchAttorney(search: any) {
   

    this._MainService.getattorneyinsuranceList(1, 10, search.target.value).then((data: any) => {
    
      
      this.attorneyInsuranceData = data.data
    
    })
  }

  

  pageChange(pagechangedata: any): void {
    debugger
    this._NgxLoader.start();
    this._MainService.getattorneyinsuranceList(pagechangedata.pageIndex + 1, 10,"").then((data: any) => {
      if (data.success) {
        
        this.attorneyInsuranceData = data.data
        this.pagination = data.pagination;
        this._NgxLoader.stop();
      } else this._NgxLoader.stop();
    });
  }
}
