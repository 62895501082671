import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { AuthService } from '../services/authenticationservice/auth.service';
import { NotifierService } from '../../app/services/notifierservice/notifier.service';
import { SessionService } from '../services/sessionservice/session.service';
import { MainService } from '../../../src/app/services/mainservice/main.service';
import { UserInfoService } from '@app/services/user-info/user-info.service';
import { AccessTokenService } from '@app/services/token.service';
import { EmailTemplate } from '../services/emailTemplate/emailTemplate.service';
import * as moment from 'moment'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../app/services/userservice/userservice';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [
    trigger('forgotPwdState', [
      state('active', style({
        visibility: 'visible',
        opacity: 1
      })),
      state('inactive', style({
        visibility: 'hidden',
        opacity: 0
      })),
      transition('active => inactive', animate('350ms ease-in')),
      transition('inactive => active', animate('350ms ease-in'))
    ])
  ]
})
export class LoginComponent implements OnInit {
  isFormSubmitting = false;
  isFormSubmitted = false;
  checkProvider = false;
  resetUrl:any
  loginForm: FormGroup;
  isFrgotPwdFormSubmitted = false;
  isFrgotPwdFormSubmitting: boolean;
  forgotPwdForm: FormGroup;
  forgotPwdState = 'inactive';

  isChecked: boolean;
  accessToken: any;
  myDate: any
  providerTypeForm: FormGroup;
  providerTypes: any;
  providerTypesCheck: any;
  PageModalRef: BsModalRef | null;
  @ViewChild('providerSelectionModal') providerSelectionModal!: TemplateRef<any>;

  constructor(
    private _FormBuilder: FormBuilder,
    private _Router: Router,
    private _EmailTemplate: EmailTemplate,
    private _AuthService: AuthService,
    private _NotifierService: NotifierService,
    private _SessionService: SessionService,
    private _MainService: MainService,
    private _UserInfoService: UserInfoService,
    private _AccessTokenService: AccessTokenService,
    private _BsModalService: BsModalService,
    private userService: UserService

  ) {
    this.loginForm = this._FormBuilder.group({ email: ['', [Validators.required]], password: ['', [Validators.required]] });
    this.forgotPwdForm = this._FormBuilder.group({ email: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]] })
    this.isFrgotPwdFormSubmitting = false;
    this.isChecked = false;
    this.myDate = new Date();
    this.PageModalRef = new BsModalRef()
    this.providerTypeForm = this._FormBuilder.group({
      providerType: ['', Validators.required]
    });

  }
  ngOnInit() {
    console.log(" ");
    this.myDate = (moment(this.myDate).format("YYYY"));

  }


  loginFormSubmit() {

    this.isFormSubmitted = true;
    if (this.loginForm.valid) {
      this.isFormSubmitting = true;
      this._AuthService.authenticate(this.loginForm.value).then(data => {

        if (data.success === true) {
          this._SessionService.setSesssion({ 'token': data.token });
          this._AccessTokenService.setaccesstoken({ 'token': data.token });
          this.accessToken = data.token;
          this.accessToken = data.token;

          this.userInfo();

        } else {
          this.isFormSubmitting = false;
          this._NotifierService.showError('Invalid Credentials');
        }
      });
    }
  }
  userInfo() {
    // this._MainService.getCurrentUserInfo().then((info: any) => {
    //   let name = `${info.data.firstName} ${info.data?.lastName ? info.data?.lastName:""}`
    //   window.localStorage.setItem('user', name)
    this._MainService.getCurrentUserInfo().then((info: any) => {
      let name = `${info.data.firstName} ${info.data?.lastName ? info.data?.lastName : ""}`;
      this.userService.updateUserName(name);
      window.localStorage.setItem('role', info.data.role);
      window.localStorage.setItem('roleID', info?.data?._id);
      if (info.data.role === 'Provider' || info.data.role === 'Staff') {
        info.data.role === 'Staff' ? window.localStorage.setItem('staffProviderID',info.data.providerId) : '' ;
        this.isFormSubmitting = false;
        this._MainService.updateCount({ providerId: [info.data._id] }).then((data: any) => {
          console.log(data);

        })

        this._Router.navigate(['./home']);
      } else if (info.data.role === 'Admin') {
        this.isFormSubmitting = false;
        this._Router.navigate(['./admin']);
      } else if (info.data.role === 'SuperAdmin') {
        this.isFormSubmitting = false;
        this._Router.navigate(['./super-admin']);
      }

      this._UserInfoService.setUserInfo(info)


    })
  }
  forgotPassword() {



    this._MainService.sendUnauthEmail(this.takeformData()).then((data: any) => {
     console.log(data);



    })

  }
  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.forgotPwdForm.controls.email.value )
    formData.append('html', this._EmailTemplate.forgotPasswordTemplate(this.resetUrl))
    formData.append('message', "Reset Password")

    return formData
  }





  forgotPwdFormSubmit(): void {
    this.isFrgotPwdFormSubmitted = true;
    if (this.forgotPwdForm.valid) {
      this.isFrgotPwdFormSubmitting = true;
      this._MainService.forgotPassword((() => {
        return Object.assign({}, this.forgotPwdForm.value, {
          url: (() => {
            let url = '';
            url = window.location.protocol + '//' + window.location.hostname;
            if (window.location.port !== '') {
              url += ':' + window.location.port;
            }

            url += '/resetpassword';
            return url;
          })()
        });
      })()).then((data: any) => {

        this.resetUrl = data.data.resetUrl
        this.isFrgotPwdFormSubmitting = false;
        if (data.success === true) {
          this._NotifierService.showSuccess('Check your mail please..!');
          this.forgotPassword()

          this.forgotPwdState = 'inactive'
        } else if (data.success === false && data.message === 'Email id not found') {
          this._NotifierService.showError('Email not registered..!');
        } else {
          this._NotifierService.showError('Something went wrong..!');
        }
      });
    }
  }

  toggleForgotPwd() {
    this.forgotPwdState = this.forgotPwdState === 'inactive' ? 'active' : 'inactive';
  }

  authProviderCheck() {
    if (this.loginForm.valid) {
      this.isFormSubmitting = true;
      this._AuthService.authCheck(this.loginForm.value).then(data => {

        if (data.success === true) {
          this.isFormSubmitting = false;
          if (data.role === 'Provider' && data.providerType.length > 1) {

            this.providerTypesCheck = data.providerType
            this.providerTypes = data.providerType
            .filter((item: any) => item && item.providerType)
            .map((item: any) => item.providerType);

            this.openProviderSelectionModal();
          } else {
            this.loginFormSubmit();
          }

        } else {
          this.isFormSubmitting = false;
          this._NotifierService.showError('Invalid Credentials');
        }
      });
    }
  }

  openProviderSelectionModal() {
    this.PageModalRef = this._BsModalService.show(this.providerSelectionModal, {
      class: 'modal-lg',
      backdrop: 'static',
    });
  }

  checkDrop(){
    this.checkProvider = false
  }

  onProviderTypeSelect() {

    if(this.providerTypeForm.invalid){
      this.checkProvider = true;
      return
    }

    if (this.providerTypeForm.valid) {
      this.checkProvider = false;
      const selectedTypeId = this.providerTypeForm.value.providerType;
      console.log('Selected Provider Type:', selectedTypeId);

      const matchingProvider = this.providerTypesCheck.find((item: any) => item.providerType?._id === selectedTypeId);

      if (matchingProvider) {
        const defaultPassword = matchingProvider?.defaultPassword;
        this.loginForm.get('password')?.setValue(defaultPassword);
      } else {
        console.log('No matching provider type found.');
      }

      this.PageModalRef?.hide();
      this.loginFormSubmit();
    }
  }

  closeModal() {
    this.PageModalRef?.hide();
  }

}
