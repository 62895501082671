<div class="container my-5">
    <div class="bg-white shadow rounded">
        <div class="p-3 border-bottom">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <h1 class="h5 font-weight-bold mb-0">
                   
                    <span>New Referral Specialist</span>
                </h1>
                <button class="btn btn-sm btn-outline-primary" (click)="goBack()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    Back
                </button>
            </div>
        </div>
        <div class="px-3 pb-3">
            <form [formGroup]="addOrEditReferralSpecialist">
                <div class="main-patient-form">
                    <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
                        <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">Info</span>
                        <div class="form-row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
                                    <div class="form-row">
                                        <div class="col-6">
                                            <input type="text" class="form-control" placeholder="First Name "
                                                formControlName="firstName" />

                                           
                                        </div>
                                        <div class="col-6">
                                            <input type="text" class="form-control" placeholder="Last Name "
                                                formControlName="lastName" />

                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="font-weight-600" for="">Gender<span class="text-danger">*</span></label>
                                <ng-select [items]="genders" bindLabel="name" [searchable]="true" bindValue="_id"
                                    formControlName="genderId" placeholder="Select" [clearable]="false"></ng-select>
                                
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="font-weight-600" for="">phone</label>
                                    <input type="text" class="form-control" formControlName="phone"
                                        placeholder="Phone" />
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label class="font-weight-600" for="">Email</label>
                                    <input type="text" class="form-control" formControlName="email"
                                        placeholder="Email" />
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <label class="font-weight-600" for="">Profile Image</label>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="doctorProfileImage"
                                            formControlName="photo" (change)="fileChange($event)" />
                                        <label class="custom-file-label" for="doctorProfileImage">{{
                                            fileViewer
                                            }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="font-weight-600" for="">Address Line 1</label>
                                    <input type="text" class="form-control" formControlName="addressline1"
                                        placeholder="Address Line 1" />
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="font-weight-600" for="">Address Line 2</label>
                                    <input type="text" class="form-control" formControlName="addressline2"
                                        placeholder="Address Line 2" />
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
        </form>
    </div>
</div>
</div>