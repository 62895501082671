
<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">

          <button class="btn mr-1 focus_active_remove" (click)="myAcc()" [ngClass]="myHipaa?'header_main_bg text-light':''">My Account</button>
          <button class="btn focus_active_remove" (click)="hipaaComp()" [ngClass]="!myHipaa?'header_main_bg text-light':''">HIPAA compliant</button>
        </h1>
        <div class="btn-group btn-group-sm">
          <button class="btn btn-sm btn-primary" (click)="goBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="p-3" *ngIf="myHipaa && userRole !=='Staff'">
      <ng-container>
        <form [formGroup]="profileForm">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
                  <div class="form-row">
                    <div class="col-6">
                      <input type="text" class="form-control" placeholder="First Name" formControlName="firstName">
                      <small class="text-danger" *ngIf="!!profileForm.controls.firstName.invalid &&  !!profileForm.controls.firstName.touched">Required</small>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
                      <small class="text-danger" *ngIf="!!profileForm.controls.lastName.invalid &&  !!profileForm.controls.lastName.touched">Required</small>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Gender<span class="text-danger">*</span></label>

                <ng-select [items]="genders" bindLabel="name" bindValue="_id" formControlName="genderId" placeholder="Select Gender">
                </ng-select>
                <small class="text-danger" *ngIf="!!profileForm.controls.genderId.invalid &&  !!profileForm.controls.genderId.touched">Required</small>
              </div>
            </div>
            <div class="col-6">
              <div class="form-row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
                    <input appNumberOnly type="text" class="form-control" formControlName="phone" maxlength="15">
                    <span class="text-danger" *ngIf="!!profileForm.controls.phone.invalid &&  !!profileForm.controls.phone.touched">
                      <small *ngIf="!!profileForm.controls.phone.errors.required">Required</small>
                      <small *ngIf="!!profileForm.controls.phone.errors.minlength">Minimum length should be 10</small>
                      <small *ngIf="!!profileForm.controls.phone.errors.maxlength">Maximum length should be 15</small>
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Email<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="email">
                    <span class="text-danger" *ngIf="!!profileForm.controls.email.invalid &&  !!profileForm.controls.email.touched">
                      <small *ngIf="!!profileForm.controls.email.errors.required">Required</small>
                      <small *ngIf="!!profileForm.controls.email.errors.pattern">Invalid Email</small>
                      <small *ngIf="!!profileForm.controls.email.errors.alreadyExists">Email already exists</small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Profile Image</label>
                <div class="input-group">

                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="doctorProfileImage" formControlName="photo" (change)="fileChange($event)">
                    <label class="custom-file-label" for="doctorProfileImage">{{fileViewer}}</label>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Address Line 1<span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="addressLine1">
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Address Line 2</label>
                <input type="text" class="form-control" formControlName="addressLine2">
              </div>
            </div>

            <div class="col text-right align-self-end">
              <div class="form-group">
                <button type="submit" [disabled]="profileForm.pristine || profileForm.invalid" class="bg_green btn text-light" (click)="profileFormSubmit()"><span *ngIf="isSubmitting" class="dots-circle-spinner loading mr-1"></span> Submit<span *ngIf="isSubmitting">ting...</span> </button>
              </div>
              
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="p-3" *ngIf="myHipaa && userRole ==='Staff'">
      <ng-container>
        <form [formGroup]="staffProfileForm">
          <div class="form-row">
            <div class="col-4">
              <div class="form-group">
                <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
                  <div class="form-row">
                    <div class="col-12">
                      <input type="text" class="form-control" placeholder="Full Name" formControlName="firstName">
                      <small class="text-danger" *ngIf="!!staffProfileForm.controls.firstName.invalid &&  !!staffProfileForm.controls.firstName.touched">Required</small>
                    </div>
              </div>
            </div>
          </div>
            <div class="col-8">
              <div class="form-row">
                <div class="col-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
                    <input appNumberOnly type="text" class="form-control" formControlName="phone" maxlength="15">
                    <span class="text-danger" *ngIf="!!staffProfileForm.controls.phone.invalid &&  !!staffProfileForm.controls.phone.touched">
                      <small *ngIf="!!staffProfileForm.controls.phone.errors.required">Required</small>
                      <small *ngIf="!!staffProfileForm.controls.phone.errors.minlength">Minimum length should be 10</small>
                      <small *ngIf="!!staffProfileForm.controls.phone.errors.maxlength">Maximum length should be 15</small>
                    </span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Email<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="email">
                    <span class="text-danger" *ngIf="!!staffProfileForm.controls.email.invalid &&  !!staffProfileForm.controls.email.touched">
                      <small *ngIf="!!staffProfileForm.controls.email.errors.required">Required</small>
                      <small *ngIf="!!staffProfileForm.controls.email.errors.pattern">Invalid Email</small>
                      <small *ngIf="!!staffProfileForm.controls.email.errors.alreadyExists">Email already exists</small>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col text-right align-self-end">
              <div class="form-group">
                <button type="submit" [disabled]="staffProfileForm.pristine || staffProfileForm.invalid" class="bg_green btn text-light" (click)="staffProfileFormSubmit()"><span *ngIf="isSubmitting" class="dots-circle-spinner loading mr-1"></span> Submit<span *ngIf="isSubmitting">ting...</span> </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="p-3 my_acc_hipaa" *ngIf="!myHipaa">
      <div class="d-flex">
        <p class="mb-0" data-hipaa-header>You are HIPAA compliant</p><label class="ml-4"><input type="checkbox" checked disabled id="" class="form-check-input input_check_green"></label>
      </div>

      <hr />
      <label for="authorization" class="">{{userInfo.email}} <span class="text-success font-weight-600">[authorized]</span></label>
      <div class="form-check">
        <input class="form-check-input mt-1" type="checkbox" value="" id="flexCheckDefault" checked disabled>
        <label class="font-weight-600"> I have read and accept <span class="text-primary">
            <a href="https://privacyruleandresearch.nih.gov/authorization.asp" target="_blank">HIPAA Authorization.</a>
          </span> </label>
      </div>

    </div>
  </div>
</div>
