<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class=" d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
        <h1 class="h5 font-weight-bold mb-0">{{isIncoming?"Inbox":"Outbox"}}</h1>
        <div class="form-row w-20">
          <div class="col-auto">
            <button class="btn btn-sm btn-primary pull-right" (click)="messageState()">
              {{isIncoming?"Outbox":"Inbox"}} </button>
          </div>
          <div class="col-auto">
            <button class="btn btn-sm btn_m_outline_green pull-right" (click)="showModal(createMessage)"> <i
                class="fa fa-plus" aria-hidden="true"></i> New Message </button>
          </div>
        </div>
      </div>
      <hr />
      <div class="position-relative">
        <div class="table-responsive border border-bottom-0">
          <table class="border table table-sp-sm mb-0" aria-describedby="describe">
            <thead class="header_main_bg border-bottom-0 text-white">
              <tr>
                <th scope="col" class="border-bottom-0 border-top-0">Name</th>

                <th scope="col" class="border-bottom-0 border-top-0">Subject</th>
                <th scope="col" class="border-bottom-0 border-top-0">Message</th>

              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="messageData !== null && !isLoading; else noMessage">
                <ng-container *ngFor="let message of messageData">
                  <tr (click)="openModal(chatSection,message)"
                  [ngClass]="{ 'bold-text': (currentUser?._id !== message?.messages[message?.messages?.length - 1]?.senderID) && !(message?.messages[message?.messages?.length - 1]?.isSeen) }">
                    <td *ngIf="isIncoming"> <span class="d-inline name_limit"> {{message?.userId?.firstName }}
                        {{message?.userId?.lastName  }}<br /> </span> </td>
                    <td *ngIf="!isIncoming">
                      <ng-container *ngFor="let name of message.provider">
                        <span class="d-inline name_limit"> {{name?.firstName }}
                          {{name?.lastName  }}<br />
                        </span>
                      </ng-container>
                    </td>
                    <td> <span class="d-inline name_limit">{{ message?.subject || "No Subject" }}</span> </td>
                    <td> <span class="d-inline name_limit">{{ message?.messages[message?.messages?.length - 1]?.message || "No Message" }}</span> </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-template #noMessage>
                <td colspan="12">
                  <h2 class="text-center pt-3">No Messages</h2>
                </td>
              </ng-template>
            </tbody>
          </table>
        </div>
        <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)"></mat-paginator>
      </div>
    </div>
  </div>
</div>

<!-- for message modal -->

<ng-template #createMessage>
  <div class="modal-body p-0">
    <div class="modal-header header_main_bg text-white mb-3">
      <h5 class="modal-title font-weight-bold">New Message</h5>
      <button type="button" class="close pull-right" (click)="_BsModalService.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <app-new-message (isMessageAdded)="isMessageAdded($event)"></app-new-message>
  </div>
</ng-template>
<ng-template #AttachmentFile>
  <div style="height: 500px;">
    <div class="modal-header">
      <h4>Attachment</h4>
      <button type="button" class="close pull-right btn" aria-label="Close" (click)="_BsModalService.hide()"> <span
          aria-hidden="true">&times;</span> </button>
    </div>
    <div class="w-100" style="display: grid;place-items: center; height: 85%;">
      <img [src]="image" alt="">
    </div>
  </div>
</ng-template>
<ng-template #chatSection>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>{{ chatSectionList?.subject }}</p>
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body BODY">
    <div *ngFor="let message of chatSectionList?.messages">
      <div class="message-container BODY-Container" [ngClass]="{'right-message': message.senderID === currentUser?._id || message.senderID === currentUser?.providerId , 'left-message': message.senderID !== currentUser?._id || message.senderID !== currentUser?.providerId}">
        <p>{{ message.message }}</p>
      </div>
    </div>
    <div class="input-container BODY-Input-Container mt-3">
      <input #messageInput id="messageInput" type="text" [(ngModel)]="userInput" placeholder="Type your message...">
      <i class="fas fa-paper-plane sendIcon" (click)="sendReply()"></i>
    </div>
  </div>
</ng-template>

