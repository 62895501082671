import { ResponseBase } from '../response-base';
import { Doctor } from './doctor';
import { Attorney } from './attorney';

export class PatientWithResponse extends ResponseBase {
    public data: PatientDetails;
    constructor() {
        super();
        this.data = new PatientDetails();
    }
}

export class PatientsWithResponse extends ResponseBase {
    public data: PatientsWithPagination;
    constructor() {
        super();
        this.data = new PatientsWithPagination();
    }
}

export class PatientsWithPagination {
    public patientsList: Array<PatientDetails>;
    constructor() {
        this.patientsList = Array<PatientDetails>();
    }
}

export class ICD10WithResponse extends ResponseBase {
    public data: Array<ICD10>;
    constructor() {
        super();
        this.data = Array<ICD10>();
    }
}

export class PatientDetails {
    attorney: attorneyDetails;
    cellNo: any = '';
    public id: number;
    public doctorID: number;
    public patientUID:string=''
    insurence: insuranceDetails;
    homeNumber: string = '';
    workMobileNumber: string = '';
    addressLine1: string = '';
    photo: any = '';

    addICD10: string = '';

    occuption: string = '';

    employeer: string = ''
    gender: gender;
    provider: string = '';
    public firstName: string;
    public lastName: string;
    public genderId: number;
    public genderName: string;
    public dateOfBirth: string;
    public email: string;
    public cellNumber: string;
    public homeNo: string;
    public workMobileNo: string;
    public picture: string | null;

    public addressLine2: string;
    public city: string;
    public state: string;
    public zip: string;
    public icD10: Array<string | number>;
    public socialSecurityNumber: string;
    public occupation: string;
    public employer: string;
    public referralLogID: number;
    public primaryInsurance: string;
    public otherInsurance: string;
    public subscribersName: string;
    public mainAttorney: Attorney;
    public likedAttornesList: Array<Attorney>;
    public ageInYears: number;
    public doctors: Array<Doctor>;
    public icdDescription: Array<string>;
    public workCompInsuranceName: string;
    public workCompAddress: string;
    public workCompPhone: string;
    public workCompFax: string;
    public workCompAdjuster: string;
    public workCompAdjustersPhone: string;
    public workCompAdjustersFax: string;
    public workCompNetwork: string;
    public workCompNetworkName: string;
    public workCompNetworkClaimNumber: string;
    public dOI: string;
    public doi: string;
    public referredFrom: string;
    public attorneyId: number;
    workComp: workingCompanyDetails;
    constructor() {
        this.id = 0;
        this.patientUID=''
        this.doctorID = 0;
        this.firstName = '';
        this.lastName = '';
        this.genderId = 0;
        this.genderName = '';
        this.dateOfBirth = '';
        this.email = '';
        this.cellNumber = '';
        this.homeNo = '';
        this.workMobileNo = '';
        this.picture = '';
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.icD10 = Array<string | number>();
        this.socialSecurityNumber = '';
        this.occupation = '';
        this.employer = '';
        this.referralLogID = 0;
        this.primaryInsurance = '';
        this.otherInsurance = '';
        this.subscribersName = '';
        this.mainAttorney = new Attorney();
        this.likedAttornesList = Array<Attorney>();
        this.ageInYears = 0;
        this.doctors = Array<Doctor>();
        this.icdDescription = Array<string>();
        this.workCompInsuranceName = '';
        this.workCompAddress = '';
        this.workCompPhone = '';
        this.workCompFax = '';
        this.workCompAdjuster = '';
        this.workCompAdjustersPhone = '';
        this.workCompAdjustersFax = '';
        this.workCompNetwork = '';
        this.workCompNetworkName = '';
        this.workCompNetworkClaimNumber = '';
        this.dOI = '';
        this.doi = '';
        this.referredFrom = '';
        this.attorneyId = 0;
        this.gender = new gender();
        this.attorney = new attorneyDetails();
        this.workComp = new workingCompanyDetails();
        this.insurence = new insuranceDetails();
    }
}

export class gender {
    name: string = '';
}
export class PatientDDLsWithResponse extends ResponseBase {
    public data: Array<PatientDDL>;
    constructor() {
        super();
        this.data = Array<PatientDDL>();
    }
}
export class attorneyDetails {
    lastName: string = '';
    firstName: string = '';
    phone: string = '';
    fax: string = '';
    addressLine1: string = '';
    addressLine2: string = '';
    email: string = '';
    caseManager: string = '';
    lawFirm: string = '';
    attorneypatientHavePIP: string = '';
    attorneycoveredByInsurance: string = '';
}
export class insuranceDetails {
    primaryInsurence: string = '';
    otherInsurence: string = '';
    subscribersName: string = '';
    group: string = '';
    policy: string = '';
}

export class workingCompanyDetails {
    insurenceName: string = '';
    phone: string = '';
    adjuster: string = '';
    fax: string = '';
    address: string = '';
    adjustersPhone: string = '';
    adjustersFax: string = '';
    network: boolean = false;
    networkName: string = '';
    networkClaimNumber: string = '';
}
export class PatientDDL {
    public id: number;
    public docID: number;
    public name: string;
    constructor() {
        this.id = 0;
        this.docID = 0;
        this.name = '';
    }
}

export class ICD10 {
    public id: number;
    public name: string;
    constructor() {
        this.id = 0;
        this.name = '';
    }
}
