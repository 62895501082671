import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Doctor } from '../../../../models/users/doctor';
import { MainService } from '../../../../services/mainservice/main.service';

@Injectable()
export class ProviderDetailsResolver implements Resolve<Doctor | null> {
    constructor(
        private _MainService: MainService
    ) { }
    resolve(route: ActivatedRouteSnapshot): Promise<Doctor> | null {
        const id = route.params.id

        if (id && id !== "0") {
            return this._MainService.getProvidersbyProviderID(id).then((data: any) => {
                return data.data;
            });
        } else {
            return null;
        }

    }
}
