import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'Health Sight Care Tracker';
  isLoading: boolean;

  constructor(
    private _NotifierService: NotifierService,
    private bnIdle: BnNgIdleService,
    private _Router: Router,
  ) {
    this.isLoading = false;
    
  }
  ngOnInit() {
    
    this.bnIdle.startWatching(1800).subscribe((res: any) => {

      if (res) {

        this._Router.navigate(['/login'])
        this._NotifierService.showError('Session time-out, Please login again')
      }

    })
    
    

  }
  
}
