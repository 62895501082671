import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/authenticationservice/auth.service';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { EmailTemplate } from '../../services/emailTemplate/emailTemplate.service';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-invite-signup',
  templateUrl: './invite-signup.component.html',
  //styleUrls: ['./invite-signup.component.scss'],
})
export class InviteSignupComponent implements OnInit {
  providerType: any;
  genders: any;
  url: any
  speciality: any;
  defaultPassword: any
  sentEmail: any
  selectedProviderType: any
  providerList: any
  addDoctorForm: FormGroup;
  addDoctorFormSubmitted: boolean = false;
  genderValidator: any;
  doesExist: boolean = false;
  specialityValidator: any;
  key: any
  addDoctorFormSubmitting: boolean = false;
  isChangePwdFrmSubmitted: boolean = false;
  isAttorney = false;
  isFacility = false;
  insuranceValidator = false;
  associatedDoctorValidator = false;
  constructor(
    private _MainService: MainService,
    private _FormBuilder: FormBuilder,
    private _EmailTemplate: EmailTemplate,
    private _NotifierService: NotifierService,
    private _ActivatedRoute: ActivatedRoute,
    private _AuthService: AuthService,
    private Route: Router
  ) {
    this.addDoctorForm = this._FormBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: [''],
      genderId: [],
      phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],],
      email: ['', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
      specialityList: [null],
      providerType: [null, [Validators.required]],
      fax: [''],
      addressLine1: [null, [Validators.required]],
      addressLine2: [''],
      businessName: [''],
      contactPerson: [''],
      caseManager: [''],
      zip: [null, [Validators.required]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$<=>?@])/)]
      ],
      confirmPassword: ['', [Validators.required], this.checkconfirmPassword()],
      associatedprovider: [null],
      ClinicAffiliationsList: this._FormBuilder.array([this._FormBuilder.control('')])
    });
  }

  ngOnInit(): void {
    this.url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') this.url += ':' + window.location.port;
    console.log(this.url);
    this.specialityValidator = true
    this._MainService.getProviderType().then(($: any) => {
      this.providerType = $.data;

    });

    this._MainService.getProviderInviteNameList(true, false, false).then((data: any) => {
      if (data.success) {
        this.providerList = data?.data?.filter((e: any) => {
          if (e.providerType?.name == 'Doctor') {
            return e;
          }
        })
      }
    })
    this._MainService.getgenderList().then((data: any) => {
      this.genders = data.data;
    });
    this._MainService.getspecialitiesList().then((data: any) => {
      this.speciality = data.data;
    });
    this.key = this._ActivatedRoute.snapshot.params.id


  }

  checkconfirmPassword(): ValidatorFn {
    return (constrol: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (constrol.value === this.addDoctorForm.controls.newPassword.value) {
        return of(null);
      } else {
        return of({ 'notSame': true });
      }
    };
  }

  providerChange(data: any) {
    this.selectedProviderType = data.name
    if (data.name === 'Attorney') {
      this.isAttorney = true;
    }
    else {
      this.isAttorney = false;
    }
    if (data.name === 'Facility') {
      this.isFacility = true;
    }
    else {
      this.isFacility = false;
    }
    if (data.name !== 'Referral Coordinator' && data.name !== 'Attorney' && data.name !== 'Facility' && data.name !== 'Imaging Center') {
      this.specialityValidator = true;
    } else {
      this.specialityValidator = false;
    }
    if (data.name === 'Imaging Center' || data.name === 'Facility') {
      this.genderValidator = false;
    } else {
      this.genderValidator = true;
    }

    if (data.name === 'Attorney') {
      this.insuranceValidator = true;
    } else {
      this.insuranceValidator = false;
    }
    if (data.name === 'Facility') {
      this.associatedDoctorValidator = true;
    } else {
      this.associatedDoctorValidator = false;
    }
    if (data.name === 'Doctor') {
      this.addDoctorForm.get('specialityList')?.setValidators([Validators.required])
      this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
      this.addDoctorForm.get('genderId')?.setValidators([Validators.required])
      this.addDoctorForm.get('genderId')?.updateValueAndValidity();
      this.addDoctorForm.get('lastName')?.setValidators([Validators.required])
      this.addDoctorForm.get('lastName')?.updateValueAndValidity();
      this.addDoctorForm.get('associatedprovider')?.setValidators([])
      this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
      this.addDoctorForm.get('insurance')?.setValidators([])
      this.addDoctorForm.get('insurance')?.updateValueAndValidity();
    } else {
      if (data.name === 'Referral Coordinator' || data.name === 'Attorney') {
        this.addDoctorForm.get('specialityList')?.setValidators([])
        this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
        this.addDoctorForm.get('genderId')?.setValidators([Validators.required])
        this.addDoctorForm.get('genderId')?.updateValueAndValidity();
        this.addDoctorForm.get('associatedprovider')?.setValidators([])
        this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
      }
      if (data.name === 'Imaging Center') {
        this.addDoctorForm.get('genderId')?.setValidators([])
        this.addDoctorForm.get('genderId')?.updateValueAndValidity();
        // this.addDoctorForm.get('specialityList')?.setValidators([Validators.required])
        // this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
        this.addDoctorForm.get('associatedprovider')?.setValidators([])
        this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
        this.addDoctorForm.get('insurance')?.setValidators([])
        this.addDoctorForm.get('insurance')?.updateValueAndValidity();
      }
      if (data.name === 'Facility') {
        this.addDoctorForm.get('genderId')?.setValidators([])
        this.addDoctorForm.get('genderId')?.updateValueAndValidity();
        this.addDoctorForm.get('lastName')?.setValidators([])
        this.addDoctorForm.get('lastName')?.updateValueAndValidity();
        this.addDoctorForm.get('specialityList')?.setValidators([]);
        this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
        // this.addDoctorForm.get('associatedprovider')?.setValidators([Validators.required])
        // this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
        this.addDoctorForm.get('insurance')?.setValidators([])
        this.addDoctorForm.get('insurance')?.updateValueAndValidity();
      }
      if (data.name === 'Attorney') {
        this.addDoctorForm.get('insurance')?.setValidators([Validators.required])
        this.addDoctorForm.get('insurance')?.updateValueAndValidity();
        this.addDoctorForm.get('lastName')?.setValidators([Validators.required])
        this.addDoctorForm.get('lastName')?.updateValueAndValidity();
      }
      if (data.name === 'Referral Coordinator') {
        this.addDoctorForm.get('lastName')?.setValidators([Validators.required])
        this.addDoctorForm.get('lastName')?.updateValueAndValidity();
      }
    }
  }

  sendEmail() {
    this._MainService.sendUnauthEmail(this.takeformData()).then((data: any) => {

      console.log(data);

    })

  }

  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.sentEmail)
    formData.append('html', this._EmailTemplate.welcomeEmailTemplate(this.sentEmail, this.defaultPassword, this.url))
    formData.append('message', "Login Credential")

    return formData

  }



  addDoctorFormSubmit() {
    this.addDoctorFormSubmitted = true;
    if (this.addDoctorForm.valid && !this.doesExist) {
      this.addDoctorFormSubmitting = true;
      this._AuthService.sendInvitedProvider(this.key, this.getFormData()).then((data: any) => {
        if (data.success) {
          this.defaultPassword = data.data.defaultPassword
          this.sentEmail = data.data.email
          this._NotifierService.showSuccess('Provider added Successfully.')
          // this._MainService.updateCount({ providerId: [data.data._id] }).then((data: any) => {
          //   console.log(data);
          // })

          if (data.sendMail === true) {
            this.sendEmail()
          }

          this.Route.navigate(['/login']);
          this.addDoctorFormSubmitting = false;
        } else {
          debugger
          this.addDoctorFormSubmitting = false;
          if (data.message === "Already Exist in System") {
            this._NotifierService.showError('Provider Already Exist');
          }
          else if (data.message === 'Invalid Token or Token has been used') {
            this._NotifierService.showError('Invitation link has been already used');
          }
          else {
            this._NotifierService.showError('Something went wrong..!');
          }
        }
      })
    }
  }

  getFormData(): FormData {
    const formData: FormData = new FormData();
    let speciality = this.addDoctorForm.controls.specialityList.value
    formData.append('firstName', this.addDoctorForm.controls.firstName.value);
    formData.append('lastName', this.addDoctorForm.controls.lastName.value);
    formData.append('newPassword', this.addDoctorForm.controls.newPassword.value);
    formData.append('email', this.addDoctorForm.controls.email.value);
    formData.append('phone', this.addDoctorForm.controls.phone.value);
    formData.append('fax', this.addDoctorForm.controls.fax.value)
    formData.append('contactPerson', this.addDoctorForm.controls.contactPerson.value)
    formData.append('addressLine1', this.addDoctorForm.controls.addressLine1.value)
    formData.append('addressLine2', this.addDoctorForm.controls.addressLine2.value)
    formData.append('zip', this.addDoctorForm.controls.zip.value);
    formData.append('businessName', this.addDoctorForm.controls.businessName.value)
    formData.append('insurance', this.addDoctorForm.controls.caseManager.value)
    formData.append('associatedDoctor', JSON.stringify(this.addDoctorForm.controls.associatedprovider.value));
    formData.append('isAttorney', JSON.stringify(this.isAttorney));
    formData.append('isFacility', JSON.stringify(this.isFacility));
    if (this.addDoctorForm.controls.ClinicAffiliationsList.value[0] !== null && this.addDoctorForm.controls.ClinicAffiliationsList.value[0] !== '') {
      formData.append('clinicAffiliations', JSON.stringify(this.addDoctorForm.controls.ClinicAffiliationsList.value));
    }
    if (this.addDoctorForm.controls.genderId.value !== null && this.genderValidator) {

      formData.append('gender', this.addDoctorForm.controls.genderId.value);
    }
    formData.append('providerType', this.addDoctorForm.controls.providerType.value);
    formData.append('specialilties', JSON.stringify(speciality));
    return formData;
  }


  get ClinicAffiliationsLists(): FormArray {
    return this.addDoctorForm.controls.ClinicAffiliationsList as FormArray;
  }

  addClinicAffiliationsList(): void {
    this.ClinicAffiliationsLists.push(this._FormBuilder.control('', [Validators.required]));
  }

  removeClinicAffiliationsList(): void {
    this.ClinicAffiliationsLists.removeAt(this.ClinicAffiliationsLists.length - 1);
  }

  onBlur(data: any) { console.log(data.target.value); }

  onFocus() { /*console.log("on focus") //this.doesExist = false*/ }
}
