import { Injectable } from '@angular/core';
import { ResponseBase, ResponseBaseTemp } from '../../models/response-base';
import { ApiService } from '../api/api.services';
import { SessionService } from '../sessionservice/session.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _ApiService: ApiService,
    private _SessionService: SessionService
  ) {

  }


  authCheck(loginData: any): Promise<ResponseBaseTemp> {
    return this._ApiService.postRequest<ResponseBaseTemp>('api/v1/auth/authCheck', loginData);
  }
  authenticate(loginData: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/auth/login', loginData);
  }



  sendInvitedProvider(token: any, data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>(`api/v1/auth/addInvitedProvider/${token}`, data);
  }
  logout(): void {
    this._SessionService.removeSessions();

  }

}
