import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FacilityComponent } from '@app/home/Component/Facility/facility.component';
import { AttorneyInsuranceComponent } from '@app/home/Component/attorney-insurance/attorney-insurance.component';
import { MyAccountComponent } from '@app/home/Component/My-Account/my-account.component';
import { AddOEditPatientComponent } from '@app/home/Component/Patient/Add-edit-patient-details/add-edit-patient-details.component';
import { PatientDetailsComponent } from '@app/home/Component/Patient/Patient-details/patient-details.component';
import { PatientDetailsResolver } from '@app/home/Component/Patient/Patient-details/patient-details.resolver';
import { PatientsComponent } from '@app/home/Component/Patient/patient.component';
import { ReferralResolver } from '@app/shared/components/referral/referral.resolver';
import { PatientsResolver } from '@app/home/Component/Patient/patient.resolver';
import { AddOEditDoctorComponent } from '@app/home/Component/Provider/Add-edit-provider-details/Add-edit-patient-details.component';
import { ProviderDetailsComponent } from '@app/home/Component/Provider/Provider-details/provider-details.component';
import { ProviderDetailsResolver } from '@app/home/Component/Provider/Provider-details/provider-details.resolver';
import { ProviderComponent } from '@app/home/Component/Provider/provider.component';
import { ProviderResolver } from '@app/home/Component/Provider/provider.resolver';
import { ReferralComponent } from '@app/shared/components/referral/referral.component';
import { SuperAdminComponent } from './super-admin.component';
import { MessageComponent } from '../home/Component/Message/message.component';
import { NotesResolver } from '../home/Component/notes/notes.resolver';
import { NotificationResolver } from '../home/Component/notification/notification.resolver';
import { NotificationComponent } from '../home/Component/notification/notification.component';
import { SuperAdminDashBoardComponent } from './Components/DashBoard/dash-board.component';
import { ReferralSpecialistComponent } from './Components/referral-specialist/referral-specialist.component';
import { FacilityDetailsResolver } from '@app/home/Component/Facility/facility-details/facility-details.resolver';
import { AddOrEditFacilityComponent } from '@app/home/Component/Facility/add-or-edit-facility/add-or-edit-facility.component';
import { EditAddAttorneyInsuranceComponent } from '@app/home/Component/attorney-insurance/edit-add-attorney-insurance/edit-add-attorney-insurance.component';
import { AttorneyinsuranceDetailsResolver } from '@app/home/Component/attorney-insurance/attorney-insurance-details/attorney-insurance-details.resolver';
import { FacilityDetailsComponent } from '@app/home/Component/Facility/facility-details/facility-details.component';
import { AttorneyInsuranceDetailsComponent } from '@app/home/Component/attorney-insurance/attorney-insurance-details/attorney-insurance-details.component';
import { SuperAdminDashboarddResolver } from './Components/DashBoard/dashboard.resolver';
import { SuperAdminResolver } from './super-admin.resolver';
import { AdminsComponent } from '@app/home/Component/Admins/admins.component';
import { AdminsResolver } from '@app/home/Component/Admins/admins.resolver';
import { AdminsDetailsResolver } from '@app/home/Component/Admins/Admins-details/admins-details.resolver';
import { AdminsDetailsComponent } from '@app/home/Component/Admins/Admins-details/admins-details.component';
import { AddOEditAdminComponent } from '@app/home/Component/Admins/Add-edit-admin-details/Add-edit-admin-details.component';
import { StaffsComponent } from '@app/home/Component/staffs/staffs.component';
import { StaffsResolver } from '@app/home/Component/staffs/staffs.resolver';
import { MyBusinessComponent } from '@app/home/Component/my-business/my-business.component';

const superAdminRoutes: Routes = [
  {
    path: 'super-admin',
    component: SuperAdminComponent, resolve: { admin: SuperAdminResolver},
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: SuperAdminDashBoardComponent,resolve:{dashboard:SuperAdminDashboarddResolver} },
      { path: 'patients', component: PatientsComponent, resolve: { patient: PatientsResolver } },
      // {path:'patients',component:PatientComponent},
      { path: 'providers', component: ProviderComponent, resolve: { doctors: ProviderResolver } },
      { path: 'admins', component: AdminsComponent, resolve: { admins: AdminsResolver } },
      // {path:'providers',component:ProvidersComponent},
      { path: 'referralspecialist', component: ReferralSpecialistComponent },
      { path: 'referrals', component: ReferralComponent, resolve: { referrals: ReferralResolver } },
      { path:'myaccount',component:MyAccountComponent},
      { path: 'facility', component: FacilityComponent },
      { path: 'message', component: MessageComponent },
      { path:'attorney-insurance',component:AttorneyInsuranceComponent },
      { path: 'provider-details/:id', component: ProviderDetailsComponent, resolve: { providerdetails: ProviderDetailsResolver } },
      { path: 'patient-details/:id', component: PatientDetailsComponent, resolve: { patientdetails: PatientDetailsResolver, notes: NotesResolver  } },
      { path: 'admins-details/:id', component: AdminsDetailsComponent, resolve: { admindetails: AdminsDetailsResolver } },
      { path: 'edit-add-doctor/:id', component: AddOEditDoctorComponent, resolve: { doctor: ProviderDetailsResolver } },
      { path: 'edit-add-patient/:id', component: AddOEditPatientComponent, resolve: { patient: PatientDetailsResolver } },
      { path: 'edit-add-admin/:id', component: AddOEditAdminComponent, resolve: { admin: AdminsDetailsResolver } },
      { path: 'edit-add-facility/:id',component:AddOrEditFacilityComponent,resolve:{facility:FacilityDetailsResolver} },
      { path: 'edit-add-attorney-insurance/:id',component:EditAddAttorneyInsuranceComponent,resolve:{attorney:AttorneyinsuranceDetailsResolver}},
      { path: 'facility-details/:id',component:FacilityDetailsComponent,resolve:{facilitydetails:FacilityDetailsResolver}},
      { path: 'attorney-insurance-details/:id',component:AttorneyInsuranceDetailsComponent,resolve:{attorneyInsurance:AttorneyinsuranceDetailsResolver}},
      { path: 'notification', component: NotificationComponent, resolve: { notification: NotificationResolver } },

      { path: 'staffs', component: StaffsComponent, resolve: { staffs: StaffsResolver } },
      { path: 'myBusiness', component: MyBusinessComponent, },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(superAdminRoutes)],
  exports: [RouterModule],
  providers:[SuperAdminDashboarddResolver]
})
export class SuperAdminRoutesModule { }
