import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { MainService } from "@app/services/mainservice/main.service";
@Injectable({
    providedIn: 'root'
})
export class NotesResolver implements Resolve<any | null>{
    constructor(private _MainService: MainService) { }
    resolve(route: ActivatedRouteSnapshot): Promise<any> | undefined {        
        return this._MainService.getNotes(100, 1, '', route.params.id).then(($: any) =>{
            return $})
    }
}