import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { PatientDetails } from '../../../models/users/patient';

import { MainService } from '../../../services/mainservice/main.service';
import { Utilities } from '../../../services/utilityservice/utilityservice';
import { patientDetails } from '@app/models/patient/patient.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotifierService } from '@app/services/notifierservice/notifier.service';

@Component({
    selector: 'app-patient',
    templateUrl: './patient.component.html',
    styles: [],
})
export class PatientsComponent implements OnInit {
    @ViewChild('openPopUpModal') openPopUpModal: any;
    roleAdmin: any = false;
    roleSuperAdmin: any = false;
    patientsList: patientDetails[];
    isLoading: boolean = true;
    deleteBtnActive: boolean = false;
    patientIdsList: string[] = [];
    searchKey: string;
    isSearchKeyFilled: boolean;
    isModalOpen: boolean = false;
    customphotourl: string = '';
    role: string = ''
    pagination: any;
    statusPagination: any;
    urgentStatusPagination: any;
    isRadioPatientChecked: any;
    isRadioStatusChecked: any;
    isRadioUrgentStatusChecked: any
    statusNeededList: any;
    urgentStatuslist: any
    patientsTab: any;
    expressionUpdate: boolean = false;
    searchValue: string = '';
    addressList: any;
    locationId: any;
    selectedAddress: string = 'all';
    providerId: any;
    constructor(
        private _Router: Router,
        private _ActivatedRoute: ActivatedRoute,
        private _Utilities: Utilities,
        private _MainService: MainService,
        private _NotifierService: NotifierService,
        private _NgxLoader: NgxUiLoaderService,
    ) {
        this.patientsList = [];
        this.searchKey = '';
        this.isSearchKeyFilled = false;
        this.pagination = 0
        this.isRadioPatientChecked = true;
        this.providerId = '';
        this.locationId = '';
        this.addressList = [{ _id: 'all', addressLine1: 'All' }];
    }

    ngOnInit() {

        const role = window.localStorage.getItem("role");

        this.roleAdmin = role === "Admin";
        this.roleSuperAdmin = role === "SuperAdmin";

        if (role === "Provider") {
            this.role = "Provider";
        } else if (role === "Staff") {
            this.role = "Staff";
        }

        this._ActivatedRoute.data.subscribe(data => {
            if (data && data.patient) {
                this.patientsList = data.patient[0].data;
                this.patientsTab = data.patient[1];
                // console.log(this.patientsTab);

                this.statusNeededList = data.patient[2].data;


                this.urgentStatuslist = data.patient[3].data;
                // console.log("vipul", this.urgentStatuslist);

                if (this.patientsTab === "1") {
                    this.isRadioPatientChecked = false;
                    this.isRadioUrgentStatusChecked = false;
                    this.isRadioStatusChecked = true;
                } if (this.patientsTab === "2") {
                    this.isRadioPatientChecked = false;
                    this.isRadioStatusChecked = false;
                    this.isRadioUrgentStatusChecked = true;
                }

                this.pagination = data.patient[0].pagination?.totalCount
                this.statusPagination = data.patient[2].pagination?.totalCount
                this.urgentStatusPagination = data.patient[3].pagination?.totalCount
                this.isLoading = false;
                this.patientsList?.forEach((photo: any) => {
                    debugger
                    if (photo.photo) {
                        photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
                    }
                });
                this.sortPatients();
            }
        });
        this._MainService.getCurrentUserInfo().then((data: any) => {
            if (data.success) {
                this.addressList = [{ _id: 'all', addressLine1: 'All' }, ...(Array.isArray(data?.Address) ? data.Address : [])];
                if (data?.data?.role === "Staff") {
                    this.providerId = data?.data?.providerId;
                    this.locationId = '';
                } else {
                    this.locationId = '';
                    this.providerId = data?.data?.id
                }
            }
        }).catch(error => console.error('Error fetching current user info:', error));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.patientsList) {
            this.sortPatients();
        }
    }

    sortPatients() {
        this.patientsList?.sort((a, b) => {
            const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
            const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
            if (nameA < nameB) {
                return -1;
            } else if (nameA > nameB) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    getModifiedList(list: Array<PatientDetails>): Array<PatientDetails> {
        list.forEach((item, index, array) => {
            array[index].ageInYears = this._Utilities.getAgeFromDOB(item.dateOfBirth);
        });
        return list;
    }

    goToView(id: any): void {
        if (id) {
            if (window.localStorage.getItem('role') === 'Admin') {
                this._Router.navigate([`admin/patient-details/${id}`]);
            } else if (window.localStorage.getItem('role') === 'SuperAdmin') {
                this._Router.navigate([`super-admin/patient-details/${id}`]);
            } else {
                this._Router.navigate([`home/patient-details/${id}`]);
            }

        }
    }
    gotoviewadd() {
        this._Router.navigate([`home/edit-add-patient/0`]);
    }

    searchPatient(search: any) {
        this.searchValue = search.target.value;
        if (search.target.value.length < 3) {
            this.expressionUpdate = true;
        } else {
            this.expressionUpdate = false;
        }

        if (search.target.value.length % 3 === 0) {
            this._NgxLoader.start()
            if (this.isRadioPatientChecked === true) {
                if (this.role === 'Provider' || this.role === 'Staff') {
                    this._MainService.getPatientListByProvider(1, 10, this.searchValue, this.providerId, this.locationId)
                        .then((data: any) => {
                            if (data.success) {
                                this._NgxLoader.stop();
                                this.patientsList = data.data;
                                this.pagination = data.pagination.totalCount;
                            }
                        });
                } else {
                    this._MainService.getPatients(1, 10, search.target.value).then((data: any) => {
                        this.patientsList = data.data;
                        this.pagination = data.pagination.totalCount
                    })
                }
                this._NgxLoader.stop();
            }
            if (this.isRadioStatusChecked === true) {
                this._MainService.statusNeededCountData(10, 1, search.target.value).then((data: any) => {
                    this.statusNeededList = data.data;
                    this.pagination = data.pagination.totalCount
                    this._NgxLoader.stop()
                })
            }
            if (this.isRadioUrgentStatusChecked === true) {
                this._MainService.urgentStatusCountData(10, 1, search.target.value, true).then((data: any) => {
                    this.urgentStatuslist = data.data;
                    this.pagination = data.pagination.totalCount
                    this._NgxLoader.stop()
                })
            }
            this.sortPatients();
            this._NgxLoader.stop()
        }
    }

    pageChange(pagechangedata: any) {
        this._NgxLoader.start()
        if (this.role === 'Provider' || this.role === 'Staff') {
            this._MainService.getPatientListByProvider(pagechangedata.pageIndex + 1, 10, this.searchValue, this.providerId, this.locationId)
                .then((data: any) => {
                    if (data.success) {
                      this.patientsList = data.data;
                      this.pagination = data.pagination.totalCount;
                      this.sortPatients();
                      this._NgxLoader.stop();
                    } else this._NgxLoader.stop();
                });
        } else {
            this._MainService.getPatients(pagechangedata.pageIndex + 1, 10, "").then((data: any) => {
                if (data.success) {
                  this.patientsList = data.data;
                  this.pagination = data.pagination.totalCount;
                  this.sortPatients();
                  this._NgxLoader.stop()
                } else {
                    this._NgxLoader.stop()
                }
            })
        }
        // this.sortPatients();
    }
    statusPageChange(statusPageChangedata: any) {
        this._NgxLoader.start()
        // this._MainService.statusNeededCountData(10, statusPageChangedata.pageIndex + 1, "").then((data: any) => {
        this._MainService.statusNeededCountData(10, statusPageChangedata.pageIndex + 1, "").then((data: any) => {
            if (data.success) {
                this._NgxLoader.stop()
                this.statusNeededList = data.data;
                this.pagination = data.pagination.totalCount
            } else {
                this._NgxLoader.stop()
            }
        })
    }

    urgentStatusPageChange(urgentStatusPageChangedata: any) {
        this._NgxLoader.start()
        this._MainService.urgentStatusCountData(10, urgentStatusPageChangedata.pageIndex + 1, "", true).then((data: any) => {
            if (data.success) {
                this._NgxLoader.stop()
                this.urgentStatuslist = data.data;
                this.pagination = data.pagination.totalCount
            } else {
                this._NgxLoader.stop()
            }
        })
    }



    checkedPatient(event: any) {

        if (event.target.checked) {
            this.isRadioPatientChecked = true;
            this.isRadioStatusChecked = false;
            this.isRadioUrgentStatusChecked = false;
        }
    }
    checkedStatus(event: any) {

        if (event.target.checked) {
            this.isRadioStatusChecked = true;
            this.isRadioPatientChecked = false;
            this.isRadioUrgentStatusChecked = false;
        }
    }
    checkedUrgentStatus(event: any) {
        if (event.target.checked) {
            this.isRadioStatusChecked = false;
            this.isRadioPatientChecked = false;
            this.isRadioUrgentStatusChecked = true;
        }
    }
    selectedPatients(event: any) {
        const index = this.patientIdsList.indexOf(event);
        if (index > -1) {
            this.patientIdsList.splice(index, 1);
        } else {
            this.patientIdsList.push(event);
        }
        this.deleteBtnActive = this.patientIdsList.length > 0;
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }
    compareFn = (o1: any, o2: any) => {
        return o1 && o2 ? o1._id === o2._id : o1 === o2;
    };
    deletePatientInBulk() {
        const requestBody = {
            patientIds: this.patientIdsList
        };
        this._MainService.deletePatientInBulks(requestBody).then((data: any) => {
            if (data?.message === 'deleted successfully') {
                this._NotifierService.showSuccess('Patients Deleted Successfully');
                this.patientIdsList = [];
                this.deleteBtnActive = false;
                this.isModalOpen = false;
                if (this.role === 'Provider' || this.role === 'Staff') {
                    this._MainService.getPatientListByProvider(1, 10, this.searchValue, this.providerId, this.locationId)
                        .then((data: any) => {
                            if (data.success) {
                                this._NgxLoader.stop();
                                this.patientsList = data.data;
                                this.pagination = data.pagination.totalCount
                            } else this._NgxLoader.stop();
                        });
                } else {
                    this._MainService.getPatients(1, 10, "").then((data: any) => {
                        if (data.success) {
                            this.pagination = data?.pagination?.totalCount
                            this._NgxLoader.stop();
                            this.patientsList = data.data;
                        } else {
                            this._NgxLoader.stop();
                        }
                    });
                }
                this.sortPatients();
            } else {
                this._NotifierService.showError('Something went Wrong');
                this._NgxLoader.stop();
            }
        });
    }

    onAddressSelect(event: any): void {
        const selectedAddress = event;
        if (selectedAddress?._id === 'all') {
            this.locationId = "";
        } else {
            this.locationId = selectedAddress?._id;
        }

        this._MainService
            .getPatientListByProvider(1, 10, '', this.providerId, this.locationId)
            .then((data: any) => {
                if (data.success) {
                  this.patientsList = data.data;
                  this.pagination = data.pagination.totalCount
                  this.sortPatients();
                  this._NgxLoader.stop();
                } else this._NgxLoader.stop();
            });
    }

    uniqueProviders(providers: any[]): any[] {
        const uniqueSet = new Set();
        return providers.filter(provider => {
            const name = `${provider.firstName} ${provider.lastName}`;
            if (uniqueSet.has(name)) {
                return false;
            } else {
                uniqueSet.add(name);
                return true;
            }
        });
    }
}
