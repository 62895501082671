import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userNameSource = new BehaviorSubject<string>(window.localStorage.getItem('user') || '');
  currentUserName = this.userNameSource.asObservable();

  updateUserName(name: string) {
    window.localStorage.setItem('user', name);
    this.userNameSource.next(name);
  }
}
