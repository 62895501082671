<ng-container [ngSwitch]="addreferralStep" class="ml-10 mr-10">
  <main role="main" class="col-md-12 col-sm-12">
    <form [formGroup]="addReferralForm">
      <ng-container *ngSwitchCase="'search-patient'">
        <div class="text-center">
          <div class="w-75 mx-auto my-5 p-4 bg-light shadow text-left">
            <div class="form-group position-relative mb-0">
              <label class="font-weight-600" for="">Search Patient</label>
              <ng-select [items]="patientList" bindLabel="customname" bindValue="_id" placeholder="Type name of the patient" (change)="getPatientDetails($event)" formControlName="patient">
              </ng-select>
              <small class="text-danger" *ngIf="!!addReferralForm.get('patient')?.invalid && (isSubmittedRequiredValue || !!addReferralForm.get('patient')?.touched)">Required</small>
            </div>
            <button class="btn btn-primary mt-5 pull-right" (click)="nextButton()">
              Next
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'patient-details'">
        <div class="w-75 mx-auto my-5 p-4 bg-light shadow text-left">
          <div class="form-group position-relative mb-0">
            <label class="font-weight-600" for="">Patient: {{patientdetails.firstName}} {{patientdetails.lastName}}
            </label>
          </div>
          <fieldset>
            <div class="row">
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Fullname:</label>
                  <span>{{patientdetails.firstName}} {{patientdetails.lastName}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Date of Birth:</label>
                  <span>{{patientdetails.dateOfBirth |date : 'mediumDate'}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Gender:</label>
                  <span>{{patientdetails.gender.name}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Social Security:</label>
                  <span>{{patientdetails.socialSecurityNumber}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Address:</label>
                  <span>{{patientdetails.addressLine1 }} {{patientdetails.addressLine2 }}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">City:</label>
                  <span>{{patientdetails.city}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">State:</label>
                  <span> {{patientdetails.state}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">ZIP:</label>
                  <span>{{patientdetails.zip}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Home#:</label>
                  <span>{{patientdetails.homeNumber}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Cell:#</label>
                  <span>{{patientdetails.cellNumber}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">ICD10:</label>
                  <span>{{patientdetails.addICD10}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Occupation:</label>
                  <span>{{patientdetails.occuption}}</span>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Employer:</label>
                  <span>{{patientdetails.employeer}}</span>
                </div>
              </div>
              <div class="col-3 p-0">
                <div class="px-2 py-1 border h-100 custom-line-height">
                  <label class="font-weight-600 w-100 mb-0" for="">Work No:</label>
                  <span>{{patientdetails.workMobileNumber}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="font-weight-600 w-100" for="">Is Patient to bring images to Doctors?</label>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="bringImagesYes" formControlName="bringImgDoc" value="yes" class="custom-control-input">
                  <label class="custom-control-label" for="bringImagesYes">Yes</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline mr-0">
                  <input type="radio" id="bringImagesNo" formControlName="bringImgDoc" value="no" class="custom-control-input">
                  <label class="custom-control-label" for="bringImagesNo">No</label>
                </div>
                <div class="w-100"></div>
                <small class="text-danger" *ngIf="!!addReferralForm.get('bringImgDoc')?.invalid && (isSubmittedRequiredValue || !!addReferralForm.get('bringImgDoc')?.touched)">Required</small>
              </div>

              <div class="col-4">
                <div class="form-group">
                  <label class="font-weight-600" for="">Reason for Consult/Scan:</label>
                  <ng-select [items]="visitReasonList" bindLabel="name" bindValue="_id" [multiple]="true" formControlName="visitReason" placeholder="Select reason for visit"></ng-select>
                  <small class="text-danger" *ngIf="!!addReferralForm.get('visitReason')?.invalid && (isSubmittedRequiredValue || !!addReferralForm.get('visitReason')?.touched)">Required</small>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="row mt-5 pull-right">
            <button class="btn btn-primary mr-2 pull-left" (click)="prevButton()">
              Previous
            </button>
            <button class="btn btn-primary pull-right" (click)="nextButton()">
              Next
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'referral-details'">
        <div class="w-75 mx-auto my-5 p-4 text-left">
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label class="font-weight-600" for="">Referred To:</label>
                <ng-select [items]="providerList" bindLabel="name" bindValue="_id" formControlName="referredTo" (change)="onProviderSelected($event)" placeholder="Type the name of the provider"></ng-select>
                <small class="text-danger" *ngIf="!!addReferralForm.get('referredTo')?.invalid && (isSubmittedRequiredValue || !!addReferralForm.get('referredTo')?.touched)">Required</small>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="font-weight-600" for="">Specialties:</label>
                <ng-select [items]="providerSpecialityList" bindLabel="name" [multiple]="true" bindValue="_id" formControlName="speciality" placeholder="Select Specialties"></ng-select>

              </div>
            </div>

          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="form-row">
                <div class="col-4">
                  <label class="font-weight-600" for="">MRI</label>
                </div>
                <div class="col-4">
                  <div class="form-group mb-0">
                    <ng-select [items]="bodypartsList" bindLabel="name" bindvalue="_id" [multiple]="true" formControlName="MRI" placeholder="Select BodyParts"></ng-select>
                  </div>
                </div>
                <div class="col-4 pl-4">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="withMRIContrast" class="custom-control-input" name="withMRIContrast" value="withcontrast" />
                    <label class="custom-control-label" for="withMRIContrast">With Contrast</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="withoutMRIContrast" class="custom-control-input" name="withMRIContrast" value="withoutcontrast" />
                    <label class="custom-control-label" for="withoutMRIContrast">Without Contrast</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="form-row">
                <div class="col-4">
                  <label class="font-weight-600" for="">CT</label>
                </div>
                <div class="col-4">
                  <div class="form-group mb-0">
                    <ng-select [items]="bodypartsList" bindLabel="name" bindvalue="_id" [multiple]="true" formControlName="CT" placeholder="Select BodyParts"></ng-select>
                  </div>
                </div>
                <div class="col-4 pl-4">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="withCTContrast" value="withcontrast" name="withCTContrast" class="custom-control-input" />
                    <label class="custom-control-label" for="withCTContrast">With Contrast</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="withoutCTContrast" value="withoutcontrast" name="withCTContrast" class="custom-control-input" />
                    <label class="custom-control-label" for="withoutCTContrast">Without Contrast</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="form-row">
                <div class="col-4">
                  <label class="font-weight-600" for="">Bill Upper Extremities</label>
                </div>
                <div class="col-4">
                  <div class="form-group mb-0">
                    <ng-select [items]="bodypartsList" bindLabel="name" bindvalue="_id" [multiple]="true" formControlName="billUpperExtremities" placeholder="Select BodyParts"></ng-select>
                  </div>
                </div>
                <div class="col-4 pl-4">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="billUpperExtremitiesEMGNCV" class="custom-control-input" value="EMG/NCV" name="billUpeer">
                    <label class="custom-control-label" for="billUpperExtremitiesEMGNCV">EMG/NCV</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="billUpperExtremitiesR" class="custom-control-input" value="r" name="billUpeer">
                    <label class="custom-control-label" for="billUpperExtremitiesR">R</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="billUpperExtremitiesL" class="custom-control-input" value="l" name="billUpeer">
                    <label class="custom-control-label" for="billUpperExtremitiesL">L</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="form-row">
                <div class="col-4">
                  <label class="font-weight-600" for="">Bill Lower Extremities</label>
                </div>
                <div class="col-4">
                  <div class="form-group mb-0">
                    <ng-select [items]="bodypartsList" bindLabel="name" bindvalue="_id" [multiple]="true" formControlName="billLowerExtremities" placeholder="Select BodyParts"></ng-select>
                  </div>
                </div>
                <div class="col-4 pl-4">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="billLoweritiesEMGNCV" class="custom-control-input" value="EMG/NCV" name="billLower">
                    <label class="custom-control-label" for="billLoweritiesEMGNCV">EMG/NCV</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="billLoweritiesR" class="custom-control-input" value="r" name="billLower">
                    <label class="custom-control-label" for="billLoweritiesR">R</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="billLoweritiesL" class="custom-control-input" value="l" name="billLower">
                    <label class="custom-control-label" for="billLoweritiesL">L</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="form-row">
                <div class="col-4">
                  <label class="font-weight-600" for="">X-Ray</label>
                </div>
                <div class="col-4">
                  <div class="form-group mb-0">
                    <ng-select [items]="bodypartsList" bindLabel="name" bindvalue="_id" [multiple]="true" formControlName="xRay" placeholder="Select BodyParts"></ng-select>
                  </div>
                </div>
                <div class="col-4 pl-4">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="xRayYes" class="custom-control-input" value="yes" name="xRay">
                    <label class="custom-control-label" for="xRayYes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="xRayNo" class="custom-control-input" value="no" name="xRay">
                    <label class="custom-control-label" for="xRayNo">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5 pull-right">
            <button class="btn btn-primary mr-2 pull-left" (click)="prevButton()">
              Previous
            </button>
            <button class="btn btn-primary pull-right" (click)="nextButton()">
              Next
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'attorney-details'">
        <div class="w-75 mx-auto my-5 p-4 bg-light shadow text-left">
          <div class="row">
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Attorney Name:</label>
                <span>{{patientdetails?.attorney?.firstName }}&nbsp;{{patientdetails?.attorney?.lastName }}</span>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Attorney Phone:</label>
                <span>{{patientdetails?.attorney?.phone }}</span>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Attorney Fax:</label>
                <span>{{patientdetails?.attorney?.fax }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Attorney Address:</label>
                <span>{{patientdetails?.attorney?.addressLine1 }} {{patientdetails?.attorney?.addressLine2 }}</span>
              </div>
            </div>
            <div class="col-3 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Patient have PIP?:</label>
                <span>{{patientdetails?.attorney?.attorneypatientHavePIP ? 'Yes' : 'No' }}</span>
              </div>
            </div>
            <div class="col-3 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Covered By Insurance?:</label>
                <span>{{patientdetails?.attorney?.attorneycoveredByInsurance ? 'Yes' : 'No' }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Primary Insurance:</label>
                <span>{{patientdetails?.insurence?.primaryInsurence }}</span>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Other Insurance:</label>
                <span>{{patientdetails?.insurence?.otherInsurence }}</span>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Subscriber's Name:</label>
                <span>{{patientdetails?.insurence?.subscribersName }}</span>
              </div>
            </div>
          </div>

          <div class="row mt-5 pull-right">
            <button class="btn btn-primary mr-2 pull-left" (click)="prevButton()">
              Previous
            </button>
            <button class="btn btn-primary pull-right" (click)="nextButton()">
              Next
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'select-doctor'">
        <div class="w-75 mx-auto my-5 p-4 bg-light shadow text-left">
          <label class="font-weight-600" for="">Referred By</label>
          <div class="row">
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Doctor Name (Referred By):</label>
                <span>{{providerDetails.firstName}} {{providerDetails.lastName}}</span>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Email:</label>
                <span>{{providerDetails.email}}</span>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="px-2 py-1 border h-100 custom-line-height">
                <label class="font-weight-600 w-100 mb-0" for="">Phone:</label>
                <span>{{providerDetails.phone}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-5 pull-right">
            <button class="btn btn-primary mr-2 pull-left" (click)="onSubmitAddReferral()">
              Submit
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </main>
</ng-container>

