import { Injectable } from '@angular/core';
import { ResponseBase } from '../../models/response-base';
import { specialiltiesListwithResponse } from '../../models/masterdata-ddl';
import { DoctorWithResponse } from '../../models/users/doctor';
import { PatientDDLsWithResponse } from '../../models/users/patient';
import { ApiService } from '../api/api.services';
import { AddAttorneyDetailsWithResponse } from '@app/models/add-edit-attorney-insurance.model';
import { providerdetailswithPaginationandresponse } from '@app/models/provider/prodider.model';
import { patientdetailswithpaginationResponse } from '@app/models/patient/patient.model';
import { adminDetailswithPaginationandresponse } from '@app/models/admin/admin.model';
import { AdminWithResponse } from '@app/models/users/admin';
import { staffDetailswithPaginationandresponse } from '@app/models/staff/staff.model';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  constructor(private _ApiService: ApiService) { }
  getCurrentUserInfo(): Promise<any> {
    return this._ApiService.getRequest<any>('api/v1/auth/me');
  }
  getHippaAuthorization(): Promise<any> {
    return this._ApiService.getRequest<any>(`api/v1/auth/acceptHIPPA/`);
  }
  getPatients(pageno: number, pagesze: number, search: string): Promise<patientdetailswithpaginationResponse> {
    return this._ApiService.getRequest<patientdetailswithpaginationResponse>(`api/v1/provider/getPatientList?pageSize=${pagesze}&pageNumber=${pageno}&searchKey=${search}`);
  }
  getPatientListByProvider(pageno: number, pagesze: number, search: string, providerId: string , location: string): Promise<patientdetailswithpaginationResponse> {
    return this._ApiService.getRequest<patientdetailswithpaginationResponse>(`api/v1/provider/getPatientListByProvider?pageSize=${pagesze}&pageNumber=${pageno}&searchKey=${search}&providerId=${providerId}&location=${location}`);
  }
  getPatientsbyPatientID(id: any, isSeen: any): Promise<PatientDDLsWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getPatientByPatientId?patientId=${id}&notification=${isSeen}`);
  }
  getProvidersbyProviderID(id: any): Promise<DoctorWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getProviderByProviderId?providerId=${id}`);
  }
  getAdminByAdminId(id: any): Promise<AdminWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getAdminByAdminId?AdminId=${id}`);
  }
  deletePatientbyid(patId: number): Promise<ResponseBase> {
    return this._ApiService.deleteRequest<ResponseBase>(`api/v1/provider/deletePatientByPatientId?patientId=${patId}`);
  }
  deleteProviderbyid(patId: number): Promise<ResponseBase> {
    return this._ApiService.deleteRequest<ResponseBase>(`api/v1/provider/deleteProviderByProviderId?providerId=${patId}`);
  }
  deleteAdminbyid(adminId: number): Promise<ResponseBase> {
    return this._ApiService.getRequest<ResponseBase>(`api/v1/admin/deleteAdminByAdminId?AdminId=${adminId}`);
  }
  registerDoctor(formData: FormData): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/registerProvider', formData);
  }
  updateProfile(formData: FormData): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/updateProfile', formData);
  }
  addEditMyBusiness(formData: FormData): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/addEditCompany', formData);
  }
  registerAdmin(formData: FormData): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/RegisterAdmin', formData);
  }
  registerStaff(formData: FormData): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/registerStaff', formData);
  }
  getDoctors(pageNumber?: number, pageSize?: number, searchKey?: string, filter?: any): Promise<providerdetailswithPaginationandresponse> {
    pageNumber = pageNumber || 1; pageSize = pageSize || 10; searchKey = searchKey || ''; filter = filter || '';

    return this._ApiService.getRequest<providerdetailswithPaginationandresponse>(`api/v1/provider/getProvidersList?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}&filter=${filter}`);
  }
  getCompanyBusinessDetails(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getCompanyDetails`);
  }
  getAdmins(pageNumber?: number, pageSize?: number, searchKey?: string, filter?: any): Promise<adminDetailswithPaginationandresponse> {
    pageNumber = pageNumber || 1; pageSize = pageSize || 10; searchKey = searchKey || ''; filter = filter || '';

    return this._ApiService.getRequest<adminDetailswithPaginationandresponse>(`api/v1/provider/getAdminList?pageNumber=${pageNumber}&pageSize=${pageSize}&searchKey=${searchKey}&filter=${filter}`);
  }
  getStaffs(pageNumber?: number, pageSize?: number, searchKey?: string, filter?: any): Promise<staffDetailswithPaginationandresponse> {
    pageNumber = pageNumber || 1; pageSize = pageSize || 10; searchKey = searchKey || ''; filter = filter || '';

    return this._ApiService.getRequest<staffDetailswithPaginationandresponse>(`api/v1/provider/getStaffList?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
  addPatient(formData: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/registerPatient', formData);
  }
  getfacilityList(pageno: number, pagesze: number, searchKey?: string): Promise<PatientDDLsWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getFacilityList?pageSize=${pagesze}&pageNumber=${pageno}&searchKey=${searchKey}`);
  }
  getattorneyinsuranceList(pageno: number, pagesze: number, searchKey?: string): Promise<PatientDDLsWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getAttorneyInsurenceList?pageSize=${pagesze}&pageNumber=${pageno}&searchKey=${searchKey}`);
  }
  getspecialitiesList(): Promise<specialiltiesListwithResponse> {
    return this._ApiService.getRequest(`api/v1/master/getSpecialilties`);
  }
  getgenderList(): Promise<specialiltiesListwithResponse> {
    return this._ApiService.getRequest(`api/v1/master/getGenders`);
  }
  authenticate(loginData: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/auth/login', loginData);
  }
  getDashboardCountById(a: any): Promise<any> { return a; }

  getReferrals(pageno: number, pagesze: number, fromDate: any, toDate: any): Promise<PatientDDLsWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getReferralLogList?pageSize=${pagesze}&pageNumber=${pageno}&fromDate=${fromDate}&toDate=${toDate}`);
  }
  getbodyParts(): Promise<any> {
    return this._ApiService.getRequest(`api/v1/master/getBodyParts`);
  }
  getVistReason(): Promise<any> {
    return this._ApiService.getRequest(`api/v1/master/getReasonConsult`);
  }
  getProviderNameList(currentProvider: any, type: any, required: boolean): Promise<any> {
    return this._ApiService.getRequest(`api/v1/master/getProvidersNameList?currentProvider=${currentProvider}&typeRequired=${type}&adminRequired=${required}`);
  }
  GetAlluserNameList(): Promise<any> {
    return this._ApiService.getRequest(`api/v1/master/GetAlluserNameList`);
  }
  getProviderInviteNameList(currentProvider: any, type: any, required: boolean): Promise<any> {
    return this._ApiService.getRequest(`api/v1/master/getProvidersNameListSignup?currentProvider=${currentProvider}&typeRequired=${type}&adminRequired=${required}`);
  }

  addReferral(formData: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/addReferral ', formData);
  }
  forgotPassword(authdata: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/auth/forgotPassword', authdata);
  }
  resetPassword(token: any, data: any): Promise<ResponseBase> {
    return this._ApiService.putRequest<ResponseBase>(`api/v1/auth/resetPassword/${token}`, data);
  }
  changePassword(authdata: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/auth/changePassword', authdata);
  }
  changeProviderPassword(authdata: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/auth/changeParticularProviderPassword', authdata);
  }
  createNewMessage(data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/createSystemMessage', data);
  }
  getSystemMessage(pagesze: number, pageno: number, search: string, type: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getSystemMessages?pageSize=${pagesze}&pageNumber=${pageno}&searchKey=${search}&incoming=${type}`);
  }
  getproviderAcceptIncomingReferral(_id: any) {
    return this._ApiService.getRequest(`api/v1/provider/acceptIncomingReferral?id=${_id}`);
  }
  addFacility(data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/addFacility', data);
  }
  getFacilityByFacilityID(id: any): Promise<DoctorWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getFacilityByFacilityId?facilityId=${id}`);
  }
  deleteFacilitybyid(facId: number): Promise<ResponseBase> {
    return this._ApiService.deleteRequest<ResponseBase>(`api/v1/provider/deleteFacilityByFacilityId?facilitytId=${facId}`);
  }
  addAttorneyInsurance(data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/addAttorneyInsurence', data);
  }
  getAttorneyInsuranceByAttorneyId(id: any): Promise<AddAttorneyDetailsWithResponse> {
    return this._ApiService.getRequest(`api/v1/provider/getAttorneyInsuranceByAttorneyId?attorneyId=${id}`);
  }
  deleteAttorneyInsurancebyid(attorId: number): Promise<ResponseBase> {
    return this._ApiService.deleteRequest<ResponseBase>(`api/v1/provider/deleteAttorney?attorneyId=${attorId}`);
  }
  getNotificationCounter(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getDashboardNotificationCount`);
  }
  setNotificationToZero(type: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/updateDashboardNotificationCount/?type=${type}`);
  }
  setNewNotes(data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/createPatientNotes', data);
  }
  updateNotes(data: any, id: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>(`api/v1/provider/editPatientNotes?noteId=${id}`, data);
  }

  getNotes(pageSize: any, pageNumber: any, searchKey: any, patientId: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getPatientNotes?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchKey}&patientId=${patientId}`);
  }
  getAllNotes(patientId: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/admin/getAllPatientNotes?&patientId=${patientId}`);
  }
  changeRole(Id: any, role: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/admin/changeRole?&Id=${Id}&role=${role}`);
  }

  addPatientDiagnostic(data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/addPatientDiagnostic', data);
  }
  getPatientDiagnosticList(pageSize: any, pageNumber: any, searchKey: any, patientId: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getPatientDiagnosticList?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchKey}&patientId=${patientId}`);
  }
  getDiagnosticList(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/master/getDiagnosticsList`);
  }
  getProcedureList(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/master/getProcedureList`);
  }
  getFacilityNameList(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/master/getFacilityNameList`);
  }
  addPatientConsultationList(consultFormData: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>(`api/v1/provider/addPatientConsultation`, consultFormData);
  }
  getProviderPatientConsultationList(pSize: any, pNumber: any, sKey: any, pId: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getPatientConsultationList?pageSize=${pSize}&pageNumber=${pNumber}&searchKey=${sKey}&patientId=${pId}`);
  }
  getRecordsNameList(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/master/getRecordTypeList`);
  }
  getAdditionalRecordsNameList(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/master/getAdditionalRecordTypeList`);
  }
  getCaseStatusList() {
    return this._ApiService.getRequest(`api/v1/master/getCaseStatus`);
  }

  addPatientRecords(records: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>(`api/v1/provider/addPatientRecord`, records);
  }

  getPatientRecords(pageSize: any, pageNumber: any, patientId: any) {
    return this._ApiService.getRequest(`api/v1/provider/getPatientRecordList?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=""&patientId=${patientId}`);
  }
  addPatientPhotos(photos: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>(`api/v1/provider/addPatientPhotos`, photos);
  }

  getPatientPhotos(pageSize: any, pageNumber: any, patientId: any) {
    return this._ApiService.getRequest(`api/v1/provider/getPatientPhotos?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=""&patientId=${patientId}`);
  }
  editConsultationImaging(mode: any, typeId: any, patientId: any) {
    return this._ApiService.getRequest(`api/v1/provider/imagingOrConsultationByID?type=${mode}&typeId=${typeId}&patientId=${patientId}`)
  }
  notifcationCountData(pageSize: any, pageNumber: any, searchkey: any, statusNeeded: any, fromDate: any, toDate: any) {
    return this._ApiService.getRequest(`api/v1/provider/getNotificationCountData?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchkey}&statusNeeded=${statusNeeded}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  statusNeededCountData(pageSize: any, pageNumber: any, searchkey: any) {
    return this._ApiService.getRequest(`api/v1/provider/getStatusNeededCountData?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchkey}`);
  }
  urgentStatusCountData(pageSize: any, pageNumber: any, searchkey: any, isUrgent: any) {
    return this._ApiService.getRequest(`api/v1/provider/getStatusNeededCountData?pageSize=${pageSize}&pageNumber=${pageNumber}&searchKey=${searchkey}&isUrgent=${isUrgent}`);
  }

  emailCheckProvider(providerEmail: any) {
    return this._ApiService.getRequest(`api/v1/provider/emailExist?providerEmail=${providerEmail} `)
  }
  getProviderType(): Promise<any> {
    return this._ApiService.getRequest('api/v1/master/getProviderTypeList');
  }
  sendInvitation(formData: any): Promise<ResponseBase> {
    return this._ApiService.postRequest(`api/v1/provider/sendInvitation`, formData)
  }
  messageSeen(): Promise<any> {
    return this._ApiService.getRequest(`api/v1/provider/messageSeen`);
  }
  sendEmail(formData: any, isBulk: any): Promise<any> {
    return this._ApiService.postRequest(`api/v1/provider/sendEmail?isBulk=${isBulk}`, formData);
  }

  updateCount(body: any): Promise<any> {
    return this._ApiService.postRequest(`api/v1/provider/updateCountsForProvider`, body);
  }
  updateAdminCount(body: any): Promise<any> {
    return this._ApiService.postRequest(`api/v1/Admin/updateCountsForAdmin`, body);
  }
  sendUnauthEmail(formData: any,): Promise<any> {
    return this._ApiService.postRequest(`api/v1/auth/sendEmail`, formData);
  }
  deleteRecord(id: any): Promise<ResponseBase> {
    return this._ApiService.deleteRequest(`api/v1/provider/deleteMedicalRecordById?recordID=${id}`);
  }
  deleteStaffByProvider(id: any): Promise<ResponseBase> {
    return this._ApiService.deleteRequest(`api/v1/provider/deleteStaff?staffId=${id}`);
  }
  deletePhoto(id: any): Promise<ResponseBase> {
    return this._ApiService.deleteRequest(`api/v1/provider/deletePhotosById?recordID=${id}`);
  }
  deleteNote(id: any): Promise<ResponseBase> {
    return this._ApiService.deleteRequest(`api/v1/provider/deletePatientNotes?Id=${id}`);
  }
  deleteConsultation(id: any): Promise<ResponseBase> {
    return this._ApiService.deleteRequest(`api/v1/provider/deletePatientConsultation?Id=${id}`);
  }
  deleteDiagnostic(id: any): Promise<ResponseBase> {
    return this._ApiService.deleteRequest(`api/v1/provider/deletePatientDiagnostics?Id=${id}`);
  }
  editRecord(formData: any,): Promise<any> {
    return this._ApiService.postRequest(`api/v1/provider/editPatientRecords`, formData);
  }
  deletePatientInBulks(body: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>(`api/v1/provider/deletebulkPatientsByPatientIds`, body);
  }
  getLawFirmNameList(): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/master/getLawFirmList`);
  }
  getChatMessages(pagesze: number, pageno: number, type: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getChatMessagesList?pageSize=${pagesze}&pageNumber=${pageno}&inComming=${type}`);
  }
  getChatMessagesByChatId(pagesze: number, pageno: number, chatID: any): Promise<ResponseBase> {
    return this._ApiService.getRequest(`api/v1/provider/getChatMessagesByChatId?pageSize=${pagesze}&pageNumber=${pageno}&chatId=${chatID}`);
  }
  createChatNewMessage(data: any): Promise<ResponseBase> {
    return this._ApiService.postRequest<ResponseBase>('api/v1/provider/sendChatMessages', data);
  }

}

