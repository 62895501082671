<button class="btn btn-green" (click)="print()">Print</button>

<h2>Referral</h2>

<div *ngFor="let product of referral">

  <h3>
    {{ product.name }}
  </h3>

</div>
