import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '@app/services/mainservice/main.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html'
})
export class FacilityComponent implements OnInit {

  facilityData: any
  isLoading: boolean = false
  role: any
  pagination: any
  constructor(private _MainService: MainService, private _NgxLoader: NgxUiLoaderService, private _Router: Router) {
    this.facilityData = []
  }

  ngOnInit(): void {
    this._NgxLoader.start()
    this.isLoading = true
    // this._ActivatedRoute.data.subscribe(data=>{
    //   if(data&&data.facility){
    //     debugger
    //     this._NgxLoader.stop()
    //     this.isLoading=false
    //     this.facilityData=data.facility
    //   }else{
    //     this._NgxLoader.stop()
    //     this.isLoading=false
    //   }
    // })
    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.role = data.data.role
      }
    })
    this._MainService.getfacilityList(1, 10,"").then((data: any) => {
      debugger
      if (data.success) {
        this._NgxLoader.stop()
        this.isLoading = false
        this.facilityData = data.data
       
        
        this.pagination = data.pagination;
      } else {
        this._NgxLoader.stop()
        this.isLoading = false
      }
    })
  }

  goToView(id: any) {
    if (id) {
      if (this.role === 'Admin') {
        this._Router.navigate([`/admin/facility-details/${id}`])
      } else {
        this._Router.navigate([`/home/facility-details/${id}`])
      }
    }
  }

  gotoviewadd() {
    debugger
    if (this.role === 'Admin') {
      this._Router.navigate(['admin/edit-add-facility/0'])
    } else {
      this._Router.navigate(['home/edit-add-facility/0'])
    }
  }

  searchFacility(search: any) {
    
    
     this._MainService.getfacilityList(1, 10, search.target.value).then((data: any) => {
      this.facilityData = data.data
     })
  }



  pageChange(pagechangedata: any) {
    this._NgxLoader.start();
    this._MainService.getfacilityList(pagechangedata.pageIndex + 1,10,'').then((data: any) => {
      if (data.success) {
        this.facilityData = data.data
        this.pagination = data.pagination;
        this._NgxLoader.stop();
      } else this._NgxLoader.stop();
    });
  }
}
