<div class="container my-5">
    <div class="bg-white shadow rounded">
        <div class="p-3 border-bottom">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <h1 class="h5 font-weight-bold mb-0">Referral Specialist</h1>
                <div class="form-row w-20">
                    <div class="col-auto">
                        <a class="btn btn-sm btn-outline-primary pull-right" >
                            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                            New Referral Specialist
                        </a>
                    </div>
                </div>
            </div>
            <hr>
            <div class="p-3 position-relative">
                <div class="table-responsive">
                  <table class="table table-sp-sm" aria-describedby="describe">
                    <thead class="bg-gradient-primary border-bottom-0 text-white">
                      <tr>
                        <th scope="col"class="border-bottom-0 border-top-0">Name</th>
                        <th scope="col"class="border-bottom-0 border-top-0">Contacts</th>
                        <th scope="col"class="border-bottom-0 border-top-0">Address</th>
                      </tr>
                    </thead>
                  </table>
                  </div>
                  </div> 
        </div>
    </div>
</div>