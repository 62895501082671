import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from '@app/services/mainservice/main.service';

@Component({
  selector: 'app-edit-add-referralspecialist',
  templateUrl: './edit-add-referralspecialist.component.html'
})
export class EditAddReferralspecialistComponent implements OnInit {

  genders: any
  profileImage: any
  fileViewer: string = "Choose an Image.."
  addOrEditReferralSpecialist: FormGroup

  constructor(private _MainService: MainService, private _FormBuilder: FormBuilder) {
    this.addOrEditReferralSpecialist = this._FormBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      genderId: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null],
      photo: [null],
      addressline1: [null],
      addressline2: [null]
    })
  }

  ngOnInit(): void {
    this._MainService.getgenderList().then((data: any) => {
      if (data.success) {
        this.genders = data.data
      }
    })
  }

  goBack() {
    window.history.back()
  }

  fileChange(event: Event): void {
    debugger
    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files !== null) {
        const fileList: FileList = files;
        if (fileList && fileList.length > 0) {
          const file: File = fileList[0];
          this.profileImage = file;
          this.fileViewer = file.name;
        }
      }
    }
  }

}
