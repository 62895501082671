<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3">
      <ng-container>
        <form [formGroup]="businessForm">
          <div class="form-row">


            <!-- Phone and Email -->
            <div class="col-6" style="display: none;">
              <div class="form-row">
                <div class="col-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
                    <input appNumberOnly type="text" class="form-control" formControlName="phone" maxlength="15">
                    <!-- <span class="text-danger" *ngIf="!!businessForm.controls.phone.invalid &&  !!businessForm.controls.phone.touched">
                      <small *ngIf="!!businessForm.controls.phone.errors.required">Required</small>
                      <small *ngIf="!!businessForm.controls.phone.errors.minlength">Minimum length should be 10</small>
                      <small *ngIf="!!businessForm.controls.phone.errors.maxlength">Maximum length should be 15</small>
                    </span> -->
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Email<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="email">
                    <!-- <span class="text-danger" *ngIf="!!businessForm.controls.email.invalid &&  !!businessForm.controls.email.touched">
                      <small *ngIf="!!businessForm.controls.email.errors.required">Required</small>
                      <small *ngIf="!!businessForm.controls.email.errors.pattern">Invalid Email</small>
                    </span> -->
                  </div>
                </div>
              </div>
            </div>

            <!-- Profile Image -->
            <div class="col-12">
              <div class="form-group">
                <label class="font-weight-600" for="">Company Logo</label>

                <div class="mt-2">
                  <div class="d-flex flex-wrap mt-2" style="position: relative;width: 226px;"
                    *ngIf="imageUrl.length > 0 || imageShow">
                    <ng-container
                      *ngIf="(this.businessForm.controls.ImageUrl.pristine && imageUrl.length > 0 && !imageShow) else editImage">
                      <a>
                      <!-- <a [href]="'https://spineplusbe.azurewebsites.net/'+imageUrl[0]?.substring(7, imageUrl[0]?.length)"
                        target="_blank"> -->
                        <img
                          [src]="'https://spineplusbe.azurewebsites.net/'+imageUrl[0]?.substring(7, imageUrl[0]?.length)"
                          style="width: 200px;height: 100px;object-fit: cover;" class="mr-2 border" alt="">
                        <em class="fa fa-times cross-btn-img" aria-hidden="true" (click)="openModal(deleteLogo)"></em>
                      </a>
                    </ng-container>
                    <ng-template #editImage>
                      <a>
                        <img [src]="imageShow" style="width: 200px;height: 100px;object-fit: cover;" class="mr-2 border"
                          alt="">
                          <em class="fa fa-times cross-btn-img" aria-hidden="true" (click)="openModal(deleteLogo)"></em>
                      </a>
                    </ng-template>
                  </div>
                  <div class="pt-2 d-flex pr-0" style="width: 25%;">
                    <div class="col-md-5 px-0 mr-3">
                      <button type="button" class="btn btn_m_primary btn-block c_btn_file">
                        {{ (imageUrl.length > 0 || imageShow ) ? 'Update Logo':'Upload Logo'}}
                        <input type="file" class="btn btn_m_primary" (change)="fileChange($event)" id="fileUpload"
                          accept=".jpg,.png">
                      </button>
                    </div>
                  </div>
                  <span style="color: grey;font-size: 12px;">Maximum file size: 250*46</span>
                  <div *ngIf="isInvalid" class="text-danger mt-2">Only PNG/JPEG is accepted</div>
                </div>
              </div>
            </div>

            <!-- Full Name -->
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Full Name<span class="text-danger">*</span></label>
                <input type="text" (blur)="onBlur('fullName')"
                  [ngClass]="{'border-danger': businessForm.controls.fullName.invalid && businessForm.controls.fullName.touched}"
                  class="form-control" placeholder="Enter Full Name" formControlName="fullName">
              </div>
            </div>

            <!-- Address Line 1 -->
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Address Line <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Address"
                  [ngClass]="{'border-danger': businessForm.controls.addressLine.invalid && businessForm.controls.addressLine.touched}"
                  formControlName="addressLine">
              </div>
            </div>

            <!-- City -->
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">City<span class="text-danger">*</span></label>
                <input type="text"
                  [ngClass]="{'border-danger': businessForm.controls.city.invalid && businessForm.controls.city.touched}"
                  placeholder="Enter City" class="form-control" formControlName="city">
                <!-- <small class="text-danger" *ngIf="!!businessForm.controls.city.invalid &&  !!businessForm.controls.city.touched">Required</small> -->
              </div>
            </div>

            <!-- State -->
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">State<span class="text-danger">*</span></label>
                <input type="text"
                  [ngClass]="{'border-danger': businessForm.controls.state.invalid && businessForm.controls.state.touched}"
                  placeholder="Enter State" class="form-control" formControlName="state">
                <!-- <small class="text-danger" *ngIf="!!businessForm.controls.state.invalid &&  !!businessForm.controls.state.touched">Required</small> -->
              </div>
            </div>

            <!-- Zip -->
            <div class="col-6">
              <div class="form-group">
                <label class="font-weight-600" for="">Zip<span class="text-danger">*</span></label>
                <input type="text" maxlength="15"
                  [ngClass]="{'border-danger': businessForm.controls.zip.invalid && businessForm.controls.zip.touched}"
                  placeholder="Enter Zip" class="form-control" formControlName="zip">
                <!-- <small class="text-danger" *ngIf="!!businessForm.controls.zip.invalid &&  !!businessForm.controls.zip.touched">Required</small> -->
              </div>
            </div>

            <!-- Submit Button -->
            <div class="col text-right align-self-end">
              <div class="form-group">
                <button (click)="businessFormSubmit()" [disabled]="businessForm.pristine || businessForm.invalid"
                  type="submit" class="bg_green btn text-light"><span *ngIf="isSubmitting"
                    class="dots-circle-spinner loading mr-1"></span> Submit<span *ngIf="isSubmitting">ting...</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
  <!-- <app-staffs></app-staffs> -->
</div>
<ng-template #deleteLogo>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>Are you sure you want to remove the logo?</p>
    </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <div class="row float-right"> <button class="btn min_width_btn bg_red text-white ml-2"
        (click)="_BsModalService.hide()">No</button> <button (click)="deleteLogos()"
        class="btn min_width_btn bg_green text-white ml-2 mr-4">Yes</button> </div>
  </div>
</ng-template>