import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { UserService } from '@app/services/userservice/userservice';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html'
})
export class MyAccountComponent implements OnInit {
  myHipaa: boolean = true;
  profileForm: FormGroup
  staffProfileForm: FormGroup
  genders: any = []
  fileViewer: string = 'Choose Image';
  photo: any;
  initialFormValues: any;
  userRole:any;
  role: any;
  userInfo: any;
  logProviderId: any;
  providerId: any
  isUpdating: boolean = true;
  isSubmitting: any = false;
  addressList: any;
  constructor(
    private _MainService: MainService,
    private _FormBuilder: FormBuilder,
    private _NgxLoader: NgxUiLoaderService,
    private _NotifierService: NotifierService,
    private _updatedProfileData: NotificationService,
    private userService: UserService
  ) {
    this.profileForm = this._FormBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      genderId: [null, [Validators.required]],
      phone: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      email: [null, [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
      photo: [null],
      addressLine1: [null, [Validators.required]],
      addressLine2: [null]
    }),
      this.staffProfileForm = this._FormBuilder.group({
        firstName: ['', [Validators.required]],
        phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        email: ['', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
        // role: [''],
        // providerId: [''],
        password: [''],
        isEdit: [''],
        isActive: [''],
        _id: [''],
      });
  }

  ngOnInit() {
    this._NgxLoader.start();
    this.role = window.localStorage.getItem('role');

    this._MainService.getgenderList().then((data: any) => {
      this.genders = data.data;
    });

    this.getUserInfo().then(() => {
      if (this.role === 'Staff') {
        this.initialFormValues = this.staffProfileForm.value;
        this.staffProfileForm.valueChanges.subscribe(() => {
          this.checkIfStaffFormChanged();
        });
      } else{
        this.initialFormValues = this.profileForm.value;
        this.profileForm.valueChanges.subscribe(() => {
          this.checkIfFormChanged();
        });
      }
      this._NgxLoader.stop();
    });
  }


  checkIfFormChanged() {
    if (JSON.stringify(this.profileForm.value) === JSON.stringify(this.initialFormValues)) {
      this.profileForm.markAsPristine();
    } else {
      this.profileForm.markAsDirty();
    }
  }

  checkIfStaffFormChanged() {
    if (JSON.stringify(this.staffProfileForm.value) === JSON.stringify(this.initialFormValues)) {
      this.staffProfileForm.markAsPristine();
    } else {
      this.staffProfileForm.markAsDirty();
    }
  }

  getUserInfo(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._MainService.getCurrentUserInfo().then((data: any) => {
        this.userInfo = data.data;
        this.userRole = data?.data?.role;
        this.addressList = data?.Address;

        if (data.success) {
          if (data.data.role === 'Staff') {
            this.logProviderId = data.data._id;
            this.staffProfileForm.controls.firstName.patchValue(data.data.firstName);
            this.staffProfileForm.controls.phone.patchValue(data.data.phone);
            this.staffProfileForm.controls.email.patchValue(data.data.email);
          } else {
            this.profileForm.controls.firstName.patchValue(data.data.firstName);
            this.profileForm.controls.lastName.patchValue(data.data.lastName);
            this.profileForm.controls.phone.patchValue(data.data.phone);
            this.profileForm.controls.email.patchValue(data.data.email);

            if (data.data.addressLine1 !== "null" && data.data.addressLine1 !== null && data.data.addressLine1 !== undefined) {
              this.profileForm.controls.addressLine1.patchValue(data.data.addressLine1);
            }
            if (data.data.addressLine2 !== "null" && data.data.addressLine2 !== null && data.data.addressLine2 !== undefined) {
              this.profileForm.controls.addressLine2.patchValue(data.data.addressLine2);
            }

            this.profileForm.controls.genderId.setValue(data.data.gender);
            this.providerId = data.data._id;
          }
        }

        // Resolve the promise after the form is updated
        resolve();
        this._NgxLoader.stop();
      }).catch(error => {
        // In case of an error, reject the promise
        reject(error);
        this._NgxLoader.stop();
      });
    });
  }


  goBack() { window.history.back(); }

  fileChange(event: Event): void {
    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files !== null) {
        const fileList: FileList = files;
        if (fileList && fileList.length > 0) {
          const file: File = fileList[0];
          this.photo = file;
          this.fileViewer = file.name;
        }
      }
    }
  }

  prepareFormData() {
    let formData = new FormData()

    if (this.profileForm.controls.firstName?.value != null) {
      formData.append('firstName', this.profileForm.controls.firstName?.value)
    }
    if (this.profileForm.controls.lastName?.value != null) {
      formData.append('lastName', this.profileForm.controls.lastName?.value)
    }
    if (this.profileForm.controls.genderId?.value != null) {
      formData.append('gender', this.profileForm.controls.genderId?.value)
    }
    if (this.profileForm.controls.phone?.value != null) {
      formData.append('phone', this.profileForm.controls.phone?.value)
    }
    if (this.profileForm.controls.email?.value != null) {
      formData.append('email', this.profileForm.controls.email?.value)
    }
    if (this.photo && this.photo.name) {
      formData.append('photo', this.photo, this.photo.name)
    }

    formData.append('addressLine1', this.profileForm.controls.addressLine1?.value)

    formData.append('addressLine2', this.profileForm.controls.addressLine2?.value)

    formData.append('isUpdate', JSON.stringify(this.isUpdating))
    formData.append('providerId', this.providerId)
    return formData
  }

  profileFormSubmit() {
    if (this.profileForm.invalid) {
      return;
    }
    //   if(this.role === "Provider"){
    //   const completeAddrs = (this.profileForm.controls.addressLine1?.value.trim() + ' ' + this.profileForm.controls.addressLine2?.value.trim()).trim();
    //   let isPresent = false;

    //   for (let i = 0; i < this.addressList.length; i++) {
    //     if ((this.addressList[i].addressLine1 === completeAddrs) && (this.addressList[i]._id !== this.userInfo?._id)) {
    //       isPresent = true;
    //       break;
    //     }
    //   }

    //   if (isPresent) {
    //     this._NotifierService.showError("Same address is not allowed");
    //     return;
    //   } else {
    //     console.log("not same");
    //   }
    // }
    this.isSubmitting = true;
    if (this.profileForm.valid) {
      this._MainService.updateProfile(this.prepareFormData()).then((data: any) => {
        if (data.success) {
          let name = `${data.data.fullName}`;
          this.userService.updateUserName(name);
          this._NotifierService.showSuccess("Your profile has been Updated Successfully.");
          this.isSubmitting = false;
          this.profileForm.markAsPristine();
          this._updatedProfileData.setProfileData(data.data);
        }
      })
    } else { this._NotifierService.showError("Something went wrong..!"); this.isSubmitting = false }
  }

  getFormData(): FormData {

    const formData: FormData = new FormData();

    formData.append('firstName', this.staffProfileForm.controls.firstName.value);
    formData.append('email', this.staffProfileForm.controls.email.value);
    formData.append('phone', this.staffProfileForm.controls.phone.value);
    // formData.append('role', 'Staff');
    // formData.append('providerId', this.logProviderId);
    formData.append('isActive', JSON.stringify(true));
    formData.append('isEdit', JSON.stringify(true));
    formData.append('_id', this.userInfo._id);
    formData.append('password', this.userInfo.defaultPassword);


    return formData;
  }

  staffProfileFormSubmit() {
    if (this.staffProfileForm.invalid) {
      this.staffProfileForm.markAllAsTouched();
      return;
    }


    const formmValue = this.staffProfileForm.value;
    const formValue = {
      ...formmValue,
      providerId: this.logProviderId,
      role: 'Staff',
    };

    if (this.staffProfileForm.valid) {
      this._NgxLoader.start()
      debugger
      this.isSubmitting = true;
      this._MainService.updateProfile(formValue).then((data: any) => {
        this.isSubmitting = false;
        if (data.success === true) {
          let name = `${data.data.fullName}`;
          this.userService.updateUserName(name);
          this.staffProfileForm.markAsPristine();
          this._NgxLoader.stop();
          this._NotifierService.showSuccess('Staff updated Successfully.');
        } else {

          if (data.message === "Already Exist in System") {
            this._NotifierService.showError('Staff Already Exist');
          } else {
            this._NotifierService.showError('Something went wrong..!');
          }
        }
      });

    } else {
      this._NotifierService.showError('Something went wrong..!');

    }

  }

  myAcc(): void { this.myHipaa = true }
  hipaaComp(): void { this.myHipaa = false }
}
