<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Facility</h1>
        <div class="form-row w-50">
          <div class="col">
            <input type="text" class="form-control form-control-sm" (input)="searchFacility($event)"
              placeholder="Search facility" aria-label="Search facility" />
          </div>
          <div class="col-auto">
            <a class="btn btn-sm btn_m_outline_green" routerLink='../edit-add-facility/0'>
              <i class="fa fa-plus-square-o " aria-hidden="true"></i>
              Add New Facility
            </a>
          </div>
        </div>
      </div>
      <hr>
      <div class="position-relative">
        <div class="table-responsive border border-bottom-0">
          <table class="table table-sp-sm border mb-0" aria-describedby="describe">
            <thead class="header_main_bg border-bottom-0 text-white">
              <tr>
                <th scope="col" class="border-bottom-0 border-top-0">Name</th>
                <th scope="col" class="border-bottom-0 border-top-0">Email</th>
                <th scope="col" class="border-bottom-0 border-top-0">Address</th>
                <th scope="col" class="border-bottom-0 border-top-0">Phone</th>
                <th scope="col" class="border-bottom-0 border-top-0">Fax</th>
                <th scope="col" class="border-bottom-0 border-top-0">Associated Doctor</th>
                <th scope="col" class="border-bottom-0 border-top-0">Contact Person</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="facilityData?.length>0 || isLoading; else noMessage">
                <ng-container *ngFor="let facility of facilityData">
                  <tr (click)="goToView(facility._id)">
                    <td>
                      <span> {{facility.firstName}}<br> </span>
                    </td>
                    <td>
                      <div>
                        <span class="d-inline"> {{facility.email || "No Email"}} </span>
                      </div>
                    </td>
                    <td>
                      <span class="d-inline"
                        *ngIf="facility.addressLine1!=='null'">{{facility.addressLine1 || "No Address"}}</span>
                    </td>
                    <td>
                      <span class="d-inline">{{ facility.phone}}</span>
                    </td>
                    <td>
                      <span
                        class="d-inline">{{ facility.fax!="null"?facility.fax!==""?facility.fax:'No Fax':'No Fax'}}</span>
                    </td>
                    <td>
                      <ng-container *ngIf="facility?.associatedDoctor && facility?.associatedDoctor.length>0 ; else Noasso">
                        <ng-container *ngFor="let asso of facility?.associatedDoctor">
                          <ng-container *ngIf="asso?.firstName!=='null' && asso?.firstName!==''; else Noavail">
                            <span class="d-inline">{{ asso?.firstName +" "+ asso?.lastName }}</span>
                          </ng-container>
                          <ng-template #Noavail>
                            <span class="d-inline">No Associated Doctor</span>
                          </ng-template>

                        </ng-container>

                      </ng-container>
                      <ng-template #Noasso>
                        <span class="d-inline">No Associated Doctor</span>
                      </ng-template>
                    </td>
                    <td>
                      <span *ngIf="facility.contactPerson!=='null'"
                        class="d-inline">{{ facility.contactPerson || "No Contact Person"}}</span>
                      <span *ngIf="facility.contactPerson==='null'" class="d-inline">No Contact Person</span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-template #noMessage>
                <td colspan="12">
                  <h3 class="text-center pt-3"> No Facility </h3>
                </td>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)"></mat-paginator>
  </div>
</div>
