import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { PatientDetails } from "../../../../models/users/patient";

import { MainService } from '../../../../services/mainservice/main.service';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';


@Component({
    selector: 'app-patient-details',
    templateUrl: './patient-details.component.html',
    //styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
    patientNotes: any;
    providerListForConsultation: any;
    linkedProvider: any[] = [];
    providerUpdateid: any = {}
    currPatient: any;
    lawFirmList = [];
    providerList = [];
    matchingLawFirm: any;
    assocProvider: any;
    isDeletingPatient: boolean;
    isOpeningRefModal: boolean;
    isModalOpen: boolean = false;
    subscriptions: boolean;
    adminFlag: boolean;
    ssnHashed: boolean;
    patientPicurl: string = '';
    role: string = ''
    showMore1: boolean;
    currentTab: any;
    @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent | any;
    memberTabs: any;
    showConsultation: boolean;
    showDiagnoxtic: boolean;
    providerNameList: any;


    constructor(private _ActivatedRoute: ActivatedRoute, private _NotificationService: NotificationService, private _MainService: MainService, private _NotifierService: NotifierService, private _Router: Router, private _NgxLoader: NgxUiLoaderService) {
        this.currPatient = new PatientDetails();
        this.isDeletingPatient = false;
        this.isOpeningRefModal = false;
        this.subscriptions = true;
        this.showMore1 = false
        this.adminFlag = false;
        this.ssnHashed = false
        this.showConsultation = false;
        this.showDiagnoxtic = false
    }

    ngOnInit() {
        this._NgxLoader.start()
        this._ActivatedRoute.data.subscribe(data => {
          if (data && data.patientdetails) {
            console.log(data.patientdetails,"data.patientdetails[0]");

            this.currPatient = data.patientdetails[0];
            this.lawFirmList = data.patientdetails[1];

                const matchingLawFirmId = this.currPatient?.attorney?.lawFirm

                if (matchingLawFirmId) {
                    this.matchingLawFirm = this.lawFirmList.find((lawFirm: any) => {
                        if (lawFirm._id === matchingLawFirmId) {
                          return lawFirm
                        }
                    });
                }

                this.currentTab = data.patientdetails[1];
                this.providerNameList = data.patientdetails[2];
                this.providerListForConsultation = data.patientdetails[4];
                if (this.currentTab === "2") {
                    this.showDiagnoxtic = true;
                }
                if (this.currentTab === "3") {
                    this.showConsultation = true;
                }
                this.currPatient?.provider?.map((x: any) => {
                    this.assocProvider = x


                    if (x._id) this.linkedProvider.push(x._id);
                    this.currPatient.linkedProvider = this.linkedProvider;


                    return x;
                })

                this._MainService.getNotificationCounter().then(x => {
                    this._NotificationService.setnotificationData(x.data);
                })
                this._NgxLoader.stop()

                // if (this.currPatient?.photo) {
                //     let photo = this.currPatient.photo;
                //     this.patientPicurl = `https://spineplusbe.azurewebsites.net/${photo.substring(7, photo.length)}`
                // }
            } else this._NgxLoader.stop()
        });

        if (window.localStorage.getItem('role') === 'Admin') {
            this.role = 'admin';
        } else if (window.localStorage.getItem('role') === 'SuperAdmin') {
            this.role = 'super-admin';
        } else {
            this.role = 'home';
        }
    }

    ngOnDestroy() {
        this.subscriptions = false;
    }

    goBack(): void {
        window.history.back();
    }

    toggleShow() {
        this.ssnHashed === true ? this.ssnHashed = false : this.ssnHashed = true
    }
    updatePatientCount() {

        if (this.role == 'admin') {
            this.providerUpdateid = {
                providerId: this.currPatient.linkedProvider
            }
        }
        if (this.role == 'super-admin') {
            this.providerUpdateid = {
                providerId: this.currPatient.linkedProvider
            }
        }
    }
    openModal() {
        this.isModalOpen = true;
      }

      closeModal() {
        this.isModalOpen = false;
      }
    deletePatient(patId: number) {
        if (patId) {
            this.isDeletingPatient = true;
            this._MainService.deletePatientbyid(patId).then(data => {
                this.isDeletingPatient = false;
                if (data.success === true) {

                    this.updatePatientCount()
                    this._MainService.updateCount(this.providerUpdateid).then((data: any) => {
                        console.log(data);

                    })


                    this._NotifierService.showSuccess('Patient deleted Successfully..!');
                    this._Router.navigate(['../../patients'], { relativeTo: this._ActivatedRoute });
                } else {
                    this._NotifierService.showError('Something went wrong..!');
                }
            });
        }
    }
}
