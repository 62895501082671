<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">
          <ng-container *ngIf="editMode; else addMode">
            {{ currDortor.firstName }} {{ currDortor.lastName }}
          </ng-container>
          <ng-template #addMode> New Provider </ng-template>
        </h1>
        <button class="btn btn-sm btn-outline-primary" (click)="goBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
    <div class="p-3">
      <form [formGroup]="addDoctorForm">

        <div class="form-row">
          <div class="col-sm-3">
            <div class="form-group">
              <label class="font-weight-600" for="">Provider Type<span class="text-danger">*</span></label>
              <ng-select [items]="providerType" bindLabel="name" [searchable]="true" bindValue="_id"
                formControlName="providerType" placeholder="Select Provider Type" [clearable]="false"
                (change)="providerChange($event)"></ng-select>
              <small class="text-danger"
                *ngIf=" !!addDoctorForm.controls.providerType.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.providerType.touched) ">Required</small>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
              <div class="form-row">
                <input type="text" class="form-control" maxlength="50"
                  [placeholder]="selectedProviderType==='Facility' || selectedProviderType==='Imaging Center' ?'Name':'First Name'"
                  formControlName="firstName" />
                <small class="text-danger"
                  *ngIf=" !!addDoctorForm.controls.firstName.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.firstName.touched) "></small>
              </div>
            </div>
          </div>
          <div class="col-sm-3"
            *ngIf="selectedProviderType==='Doctor' || selectedProviderType==='Attorney' || selectedProviderType==='Referral Coordinator' || selectedProviderType===''">
            <label style="visibility: hidden;" class="font-weight-600" for="">Name<span
                class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="Last Name " formControlName="lastName" />
            <small class="text-danger"
              *ngIf=" !!addDoctorForm.controls.lastName.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.lastName.touched) "></small>
          </div>
          <div class="col-sm-3" *ngIf="selectedProviderType!=='Facility' &&
            selectedProviderType!=='Imaging Center'">
            <div class="form-group">
              <label class="font-weight-600" for="">Gender<span class="text-danger"
                  *ngIf="genderValidator">*</span></label>
              <ng-select [items]="genders" bindLabel="name" [searchable]="true" bindValue="_id"
                formControlName="genderId" placeholder="Select Gender" [clearable]="false"></ng-select>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
              <input appNumberOnly mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" placeholder="Phone"
                formControlName="phone" />
              <span class="text-danger"
                *ngIf=" !!addDoctorForm.controls.phone.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.phone.touched) ">
                <small *ngIf="!!addDoctorForm.controls.phone.errors.required"></small>
                <small *ngIf="!!addDoctorForm.controls.phone.errors.minlength">Minimum length should be
                  10</small>
                <small *ngIf="!!addDoctorForm.controls.phone.errors.maxlength">Maximum length should be
                  15</small>
              </span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Fax</label>
              <input appNumberOnly type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57;"
                maxlength="15" class="form-control" formControlName="fax" placeholder="Fax" maxlength="15" />
              <span class="text-danger"
                *ngIf=" !!addDoctorForm.controls.fax.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.phone.touched) ">
                <small *ngIf="!!addDoctorForm.controls.fax.errors.minlength">Minimum length should be
                  10</small>
                <small *ngIf="!!addDoctorForm.controls.fax.errors.maxlength">Maximum length should be
                  15</small>
              </span>
            </div>
          </div>
          <!-- <div class="col-sm-6">
            <div class="form-row">


            </div>
          </div> -->
          <div class="col-sm-6">

            <div class="form-group">
              <label class="font-weight-600" for="">Email<span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="email" placeholder="Email" (focus)="onFocus()" />
              <!-- (blur)="onBlur($event)" /> -->
              <span class="text-danger"
                *ngIf=" !!addDoctorForm.controls.email.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.email.touched) ">
                <small *ngIf="!!addDoctorForm.controls.email.errors.required">Required</small>
                <small *ngIf="!!addDoctorForm.controls.email.errors.pattern">Invalid Email</small>
              </span>
              <small class="text-danger" *ngIf="doesExist">Email already exist</small>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Address Line 1<span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="addressLine1" placeholder="Address Line 1" />
            </div>
          </div>
          <div class="col-sm-6" *ngIf="selectedProviderType!=='Facility' && selectedProviderType!=='Attorney'">
            <div class="form-group">
              <label class="font-weight-600" for="">Address Line 2</label>
              <input type="text" class="form-control" formControlName="addressLine2" placeholder="Address Line 2" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Zip<span class="text-danger">*</span></label>
              <input type="text" maxlength="15" class="form-control" formControlName="zip" placeholder="Zip Code" />
            </div>
          </div>
          <!-- <div class="col-sm-6" *ngIf="selectedProviderType==='Doctor'">
            <div class="form-group">
              <label class="font-weight-600" for="">Highest Qualification</label>
              <input type="text" class="form-control" formControlName="highestQualification"
                placeholder="Highest Qualification" />
            </div>
          </div> -->
          <ng-container formArrayName="ClinicAffiliationsList">
            <ng-template ngFor let-ClinicAffiliationsList [ngForOf]="ClinicAffiliationsLists.controls" let-isLast="last"
              let-i="index">
              <div class="col-sm-6" *ngIf="selectedProviderType==='Doctor'">
                <div class="form-group">
                  <label class="font-weight-600" for="">
                    <span>Clinic Affiliation</span>
                    <span>{{ ClinicAffiliationsLists.length > 1 ? " " + (i + 1) : "" }}</span>
                  </label>
                  <div class="input-group">
                    <input type="text" class="form-control" [formControlName]="i" placeholder="Clinic Affiliation" />
                    <div class="input-group-append cursor-pointer">
                      <ng-container *ngIf="i == ClinicAffiliationsLists.length - 2">
                        <span class="input-group-text" (click)="removeClinicAffiliationsList()">
                          <i class="fa fa-minus"></i>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="isLast">
                        <span class="input-group-text" (click)="addClinicAffiliationsList()">
                          <i class="fa fa-plus"></i>
                        </span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <div class="col-sm-6" *ngIf="selectedProviderType==='Doctor' || selectedProviderType==='Facility'">
            <div class="form-group">
              <label class="font-weight-600 w-100" for="">Specialty<span class="text-danger"
                  *ngIf="specialityValidator">*</span></label>
              <ng-select [items]="speciality" bindLabel="name" bindValue="_id" [searchable]="true"
                formControlName="specialityList" [multiple]="true" placeholder="Select specialities"> </ng-select>
              <small class="text-danger"
                *ngIf="!!addDoctorForm.controls.specialityList.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.specialityList.touched)"></small>
            </div>
          </div>


          <div class="col-sm-6" *ngIf="selectedProviderType==='Facility'">
            <div class="form-group">
              <label class="font-weight-600" for="">Associated Doctor</label>
              <ng-select [items]="providerList" bindLabel="name" [multiple]="true" bindValue="_id" [searchable]="true"
                formControlName="associatedprovider" placeholder="Select Associated Doctor"></ng-select>
              <!-- <small class="text-danger" *ngIf=" !!addDoctorForm.controls.associatedprovider.invalid &&
                !!addDoctorForm.controls.associatedprovider.touched ">Required</small> -->
            </div>
          </div>

          <div class="col-sm-6" *ngIf="selectedProviderType==='Facility'">
            <div class="form-group">
              <label class="font-weight-600" for="">Contact Person</label>
              <input type="text" class="form-control" formControlName="contactPerson" placeholder="Contact Person" />
              <var></var>
            </div>
          </div>
          <div class="col-sm-6" *ngIf="selectedProviderType==='Attorney'">
            <div class="form-group">
              <label class="font-weight-600" for="">Case Manager<span class="text-danger"
                  *ngIf="insuranceValidator">*</span></label>
              <input type="text" class="form-control" formControlName="insurance" placeholder="Case Manager" />
              <small class="text-danger" *ngIf=" !!addDoctorForm.controls.insurance.invalid &&
                !!addDoctorForm.controls.insurance.touched ">Required</small>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Business Name</label>
              <input type="text" class="form-control" formControlName="businessName" placeholder="Business Name"
                maxlength="60" />
            </div>
          </div>
          <div class="col-md-12 row">
            <div class="col-md-6 text-left align-self-end">
              <div class="form-group">
                <button *ngIf="editMode && this.role === 'Admin'" type="submit" class="btn btn-primary text-white" (click)="openModal()">
                  Password / Reset </button>
                <button *ngIf="editMode && this.role === 'SuperAdmin'" type="submit" class="btn btn-primary text-white" (click)="openModal2()">
                  Mark As Admin </button>
              </div>
            </div>

            <div class="col-md-6 align-self-end" style="text-align: end !important;">
              <div class="form-group">
                <button type="submit" class="btn bg_green text-white" [disabled]="addDoctorFormSubmitting"
                  (click)="addDoctorFormSubmit()">
                  <ng-container *ngIf="addDoctorFormSubmitting; else addDoctorFormSubmittingDone">
                    <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Submitting...
                  </ng-container>
                  <ng-template #addDoctorFormSubmittingDone>
                    Submit <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
  <!-- Modal HTML -->
  <div [ngClass]="{'d-block': isModalOpen, 'd-none': !isModalOpen}">
    <div class="header_main_bg modal-header text-light">
      <h5 class="modal-title font-weight-bold">Change Password</h5>
      <button type="button" class="close pull-right" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form [formGroup]="changePasswordForm" (submit)="updatePassword()" class="p-3">
      <div class="form-group">
        <label for="" class="font-weight-600">New Password</label>
        <input type="password" class="form-control" formControlName="newPassword" autocomplete="off">
        <div class="input-group-append">
          <!-- <span class="input-group-text" (click)="togglePasswordVisibility('newPassword')">
            <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </span> -->
        </div>
        <span class="text-danger"
          *ngIf="changePasswordForm.controls.newPassword.invalid && (isChangePwdFrmSubmitted || changePasswordForm.controls.newPassword.touched)">
          <small *ngIf="changePasswordForm.controls.newPassword.errors.required">Required</small>
          <small
            *ngIf="changePasswordForm.controls.newPassword.errors.minlength && !changePasswordForm.controls.newPassword.errors.pattern">
            Minimum 6 characters required
          </small>
          <small *ngIf="changePasswordForm.controls.newPassword.errors.pattern">
            Password should contain lower case and upper case characters, digits and special symbols.
          </small>
        </span>
      </div>
      <div class="form-group">
        <label for="" class="font-weight-600">Confirm Password</label>
        <input type="password" class="form-control" formControlName="confirmPassword" autocomplete="off">
        <div class="input-group-append">
          <!-- <span class="input-group-text" (click)="togglePasswordVisibility('confirmPassword')">
            <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </span> -->
        </div>
        <span class="text-danger"
          *ngIf="changePasswordForm.controls.confirmPassword.invalid && (isChangePwdFrmSubmitted || changePasswordForm.controls.confirmPassword.touched)">
          <small *ngIf="changePasswordForm.controls.confirmPassword.errors.required">Required</small>
          <small *ngIf="changePasswordForm.controls.confirmPassword.errors.notSame">
            Password not same
          </small>
        </span>
      </div>
      <div class="text-right">
        <button type="submit" class="btn btn_m_green" [disabled]="isChangePwdFrmSubmitting">
          <ng-container *ngIf="isChangePwdFrmSubmitting; else isChangePwdFrmSubmittingDone">
            <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon> Changing...
          </ng-container>
          <ng-template #isChangePwdFrmSubmittingDone> Change Password <fa-icon [icon]="faArrowRight"></fa-icon>
          </ng-template>
        </button>
      </div>
    </form>
  </div>

  <!-- Modal HTML -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'d-block': isModalOpen2, 'd-none': !isModalOpen2}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal2()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Modal content goes here -->
        <p>Do you want to make this Provider as an Admin?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal2()">No</button>
        <button type="button" class="btn btn-primary" (click)="changeRole()">Yes</button>
      </div>
    </div>
  </div>
</div>

</div>
