<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">
          {{currfacility.name}}
        </h1>
        <div class="btn-group btn-group-sm">
          <button class="btn btn-outline-primary" (click)="goBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          <a class="btn btn-outline-primary" routerLink="../../edit-add-facility/{{currfacility._id}}">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            Edit
          </a>

          <button class="btn btn-outline-danger" (click)="deleteFacility(currfacility._id)"
            [disabled]="isDeletingPatient">
            <ng-container *ngIf="isDeletingPatient; else isDeletingPatientDone">
              <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span>
              Deletting...
            </ng-container>
            <ng-template #isDeletingPatientDone>
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </ng-template>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3">
      <div class="row">
        <div class="col-12">
          <h5 class="font-weight-bold d-flex align-items-center justify-content-between">
            <span>Details:</span>

          </h5>
          <hr />
          <div class="row">
            <div class="col p-3">
              <div class="row">
                <div class="col-4 pb-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Name:</label>
                  <span>{{ currfacility.firstName }}</span>
                </div>
                <div class="col-4 pb-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Email:</label>
                  <span>{{ currfacility.email }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Phone:</label>
                  <span>{{ currfacility.phone }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Fax:</label>
                  <span *ngIf="currfacility.fax!=='null'">{{
                    currfacility.fax
                    }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Associated Doctor:</label>
                  <ng-container *ngFor="let asso of currfacility?.associatedDoctor">
                    <span>{{ asso?.firstName }} {{ asso?.lastName }} </span>

                  </ng-container>
                </div>
                <div class="col-4 py-2 ">
                  <label class="font-weight-600 w-100 mb-0" for="">Contact Person:</label>
                  <span *ngIf="currfacility.contactPerson!=='null'">{{
                    currfacility.contactPerson
                    }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Address:</label>
                  <span *ngIf="currfacility.addressLine1!=='null'">{{
                    currfacility.addressLine1
                    }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Specialty:</label>
                  <ng-container *ngFor="let special of currfacility?.specialilties; let isLast=last">
                    <span *ngIf="special!=='null' || special!==''">{{special.name }}{{isLast ? '' : ', '}}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
