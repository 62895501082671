import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/config';
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';



@Injectable({
    providedIn: 'root'
})

export class ApiService {

    apiRoot: string;

    constructor(
        private _HttpClient: HttpClient,
        private _Config: Config,
        private router: Router,
    ) {

        this.apiRoot = this._Config.api.root;
    }

    postRequest<T>(url: string, body: any, options?: IRequestOptions): Promise<T> {
        const { apiRoot, headers } = this.getApiRootAndHeaders(options);
        return this._HttpClient
            .post(`${apiRoot}/${url}`, body, { headers })
            .toPromise()
            .then(res => res as T)
            .catch(err => this.handleCatchError<T>(err));
    }

    getRequest<T>(url: string, options?: IRequestOptions): Promise<T> {
        const { apiRoot, headers } = this.getApiRootAndHeaders(options);
        return this._HttpClient
            .get(`${apiRoot}/${url}`, { headers })
            .toPromise()
            .then(res => res as T)
            .catch(err => this.handleCatchError<T>(err));
    }

    putRequest<T>(url: string, body: any, options?: IRequestOptions): Promise<T> {
        const { apiRoot, headers } = this.getApiRootAndHeaders(options);
        return this._HttpClient
            .put(`${apiRoot}/${url}`, body, { headers })
            .toPromise()
            .then(res => res as T)
            .catch(err => this.handleCatchError<T>(err));
    }

    deleteRequest<T>(url: string, options?: IRequestOptions): Promise<T> {
        const { apiRoot, headers } = this.getApiRootAndHeaders(options);
        return this._HttpClient
            .delete(`${apiRoot}/${url}`, { headers })
            .toPromise()
            .then(res => res as T)
            .catch(err => this.handleCatchError<T>(err));
    }


    private getApiRootAndHeaders(options?: IRequestOptions): IRequestOptions {
        return {

            apiRoot: (options && options.apiRoot) ? options.apiRoot : this.apiRoot,
            headers: (options && options.headers) ? options.headers : new HttpHeaders()
        };
    }

    // handle errors
    private handleCatchError<T>(err: HttpErrorResponse) {
      if (!environment.production) {
          if ((err.status === 404) || (err.status === 0 && err.url === null)) {
              console.log('HttpError', 'Server not available');
          }
          if ((err.status === 401)) {
            if(err.error.error === 'Not authorized to access this route'){
              console.log('HttpError', 'Unauthorized');
              this.router.navigate(['404']);
            }else{
              console.log('HttpError', 'Unauthorized');
            }
          }
          if (err.status !== 0 && err.status !== 200 && err.status !== 401 && err.status !== 404) {
              console.log('HttpError', 'Server Error');
          }
      }else{
          if ((err.status === 401)) {
            if(err.error.error === 'Not authorized to access this route'){
              console.log('HttpError', 'Unauthorized');
              this.router.navigate(['404']);
            }else{
              console.log('HttpError', 'Unauthorized');
            }
          }
      }
      return {} as T;
  }
}
interface IRequestOptions {
    headers?: HttpHeaders;
    apiRoot?: string;
}
   // tslint:disable-next-line: no-console
     // tslint:disable-next-line: no-console
          // tslint:disable-next-line: no-console
