<div *ngIf="isAlertActive" class="alert alert-danger alert-dismissible fade show mb-4" role="alert">
  <strong>Redirected!</strong> Please, select any admin first, for whom you
  want to view the referrals.
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="container-fuild my-5" style="padding: 0 25px;">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Admins</h1>
        <div class="w-50 form-row">
          <div class="col">
            <input type="text" class="form-control form-control-sm" (input)="searchAdmin($event)"
              placeholder="Search by: Admin name" aria-label="Search admin" />
              <span class="alertUpdate" *ngIf="expressionUpdate">Please enter at least 3 characters.</span>
          </div>
          <div class="col-auto" *ngIf="isSearchKeyFilled">
            <button class="btn btn-sm btn_m_outline_green" type="button" id="btn-search-doctor" (click)="clearSearch()">
              Clear Filters
            </button>
          </div>
          <div class="col-auto">
            <button class="btn btn-sm btn_m_outline_green" (click)="gotoviewadd()">
              <i class="fa fa-plus"></i>
              Add New Admin
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 position-relative">

      <div class="table-responsive border border-bottom-0">

        <table class="table table-sp-sm mb-0" aria-describedby="describe">
          <thead class="header_main_bg border-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Admin Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Gender</th>
              <th scope="col" class="border-bottom-0 border-top-0">Address</th>
              <th scope="col" class="border-bottom-0 border-top-0">Contacts</th>
              <th scope="col" class="border-bottom-0 border-top-0">Fax</th>
              <th scope="col" class="border-bottom-0 border-top-0">Email</th>
              <!-- <th scope="col" class="border-bottom-0 border-top-0">Status</th> -->
              <th scope="col" class="border-bottom-0 border-top-0">Total Providers</th>
              <th scope="col" class="border-bottom-0 border-top-0">New Referrals</th>
              <th scope="col" class="border-bottom-0 border-top-0">Status Needed</th>
              <th scope="col" class="border-bottom-0 border-top-0">Urgent Status</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="adminsList?.length > 0; else noAdminFound">
              <ng-container *ngFor="let admin of adminsList">
                <tr class="border-bottom cursor-pointer" (click)="goToView(admin._id)">
                  <td>
                    <div class="form-row">

                      <div class="col">
                        <p class="mb-0">
                          <span class="name_limit">{{ admin?.firstName }} {{ admin?.lastName }}</span>
                          <span class="d-inline"
                            *ngIf="admin.highestQualification!=='null' && admin.highestQualification!=='' && admin.highestQualification">
                            ({{ admin.highestQualification }})
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td> {{admin?.gender?.name || "N/A" }} </td>
                  <td>
                  <ng-container *ngIf=" admin?.addressLine1 && admin?.addressLine1!=='null' && admin?.addressLine1!==''; else
                    noAddressPresent ">
                    <span class="d-inline">{{ admin?.addressLine1 }}</span>
                    <span class="d-inline" *ngIf="admin?.addressLine2"><span *ngIf="admin.addressLine1">,
                      </span>{{ admin?.addressLine2 }}</span>
                  </ng-container>
                  <ng-template #noAddressPresent>
                    <span>No address provided</span>
                  </ng-template>
                </td>
                  <td>
                    {{ admin.phone }}

                  </td>
                  <td>
                    {{ admin.fax!=="null" && admin.fax!=="" && admin.fax ?admin.fax:'No Fax' }}

                  </td>
                  <td>
                    {{ admin.email!=="null" && admin.email!=="" && admin.email ?admin.email:'No Email' }}

                  </td>
                  <!-- <td>
                    {{ admin.caseStatus?.name!=="null" && admin.caseStatus?.name!=="" && admin.caseStatus?.name ?admin.caseStatus?.name:'N/A' }}

                  </td> -->
                  <td>
                    <span class="centr">

                      {{ admin.invitedProviders && admin.invitedProviders.length > 0 ?admin.invitedProviders.length:'0' }}
                    </span>

                  </td>
                  <td>
                    <span class="centr">

                      {{ admin.totalNewReferral!=="null" && admin.totalNewReferral!=="" && admin.totalNewReferral ?admin.totalNewReferral:'0' }}
                    </span>

                  </td>
                  <td>
                    <span class="centr">

                      {{ admin.statusNeededCount!=="null" && admin.statusNeededCount!=="" && admin.statusNeededCount ?admin.statusNeededCount:'0' }}
                    </span>

                  </td>
                  <td>
                    <span class="centr">

                      {{ admin.urgentCount!=="null" && admin.urgentCount!=="" && admin.urgentCount ?admin.urgentCount:'0' }}
                    </span>

                  </td>

                  <!-- <td>
                    <p class="mb-0">
                      <ng-container *ngIf="admin?.clinicAffiliations?.length; else noClinicAssociated ">
                        <ng-template ngFor let-clinic [ngForOf]="admin.clinicAffiliations" let-i="index">
                          <div *ngIf="i < 2">
                            <span [ngClass]="{ 'd-inline': i === 1 }">{{ clinic }}</span>
                            <small *ngIf=" i === 1 && admin.clinicAffiliations.length > 2 "
                              class="d-inline text-muted"> ({{ admin.clinicAffiliations.length - (i + 1) }} more)
                            </small>
                          </div>
                        </ng-template>
                      </ng-container>
                      <ng-template #noClinicAssociated>
                        No clinic associated
                      </ng-template>
                    </p>
                  </td> -->
                  <!-- <td>
                    <p class="mb-0">
                      <ng-container
                        *ngIf="admin.specialilties !== null && admin.specialilties !== '' && admin.specialilties.length else noSpeciality">
                        <ng-template ngFor let-speciality [ngForOf]="admin.specialilties" let-i="index">
                          <div *ngIf="i < 2">
                            <span [ngClass]="{ 'd-inline': i === 1 }">{{ speciality?.name }}</span>
                            <small *ngIf="i === 1 && admin.specialilties.length > 2" class="d-inline text-muted">
                              ({{ admin.specialilties.length - (i + 1) }} more) </small>
                          </div>
                        </ng-template>
                      </ng-container>
                      <ng-template #noSpeciality> No speciality </ng-template>
                    </p>
                    <p class="mb-0">
                      <ng-container *ngIf="admin.specialilties !== null && admin.specialilties !== '' && admin.specialilties.length; else checkProviderType">
                        <ng-template ngFor let-speciality [ngForOf]="admin.specialilties" let-i="index">
                          <ng-container *ngIf="admin?.providerType?.name === 'Imaging Center' || admin?.providerType?.name === ''; else displaySpeciality">
                            No speciality
                          </ng-container>
                          <ng-template #displaySpeciality>
                            <div *ngIf="i < 2">
                              <span [ngClass]="{ 'd-inline': i === 1 }">{{ speciality?.name }}</span>
                              <small *ngIf="i === 1 && admin.specialilties.length > 2" class="d-inline text-muted">
                                ({{ admin.specialilties.length - (i + 1) }} more)
                              </small>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                      <ng-template #checkProviderType>
                        <ng-container *ngIf="admin?.providerType?.name === 'Imaging Center' || admin?.providerType?.name === ''; else noSpeciality">
                          No speciality
                        </ng-container>
                      </ng-template>
                      <ng-template #noSpeciality> No speciality </ng-template>
                    </p>
                  </td> -->
                </tr>
              </ng-container>
            </ng-container>

            <ng-template #noAdminFound>
              <tr class="border-bottom">
                <td colspan="12">
                  <div class="text-center py-5">
                    <h1 class="display-12">

                    </h1>
                    <h5 class="font-weight-bold text-muted mt-3">
                      No admin found
                    </h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div style="width: 97%;">
      <mat-paginator [length]="pagination" [pageSize]="10" (page)="pageChange($event)">
      </mat-paginator>
    </div>
    </div>
  </div>
</div>
