import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { userinfo } from 'src/app/models/user-info.model';


@Injectable({
    providedIn: 'root'
})
export class UserInfoService {
    currentUserInfo: Subject<userinfo>;

    constructor(

    ) {
        this.currentUserInfo = new BehaviorSubject<any>(new userinfo());
    }

    getUserInfo(): Observable<any> {
        debugger
        return this.currentUserInfo.asObservable();
    }

    setUserInfo(userInfo: any): void {
        debugger
        this.currentUserInfo.next(userInfo.data);
    }


}
