import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MainService } from '../../../services/mainservice/main.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { providerdetailswithPaginationandresponse } from '@app/models/provider/prodider.model';

@Injectable()

export class ProviderResolver implements Resolve<providerdetailswithPaginationandresponse | null> {
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService
    ) { }
    resolve(): Promise<providerdetailswithPaginationandresponse> | null {
        this.ngxLoader.start();
        return this._MainService.getDoctors().then(data => {
            this.ngxLoader.stop()
            return data;
        });
    }
}
