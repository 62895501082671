<div class="mh-100vh d-flex justify-content-center align-items-center p-4">
  <div class="bg-white rounded w-cxs-75 w-sm-50 w-lg-25  p-3 p-xl-4 d-flex flex-column login_view">
    <img src="assets/img/HSCT_Logo Black-1.png" alt="no image" class="img-fluid mb-3 p-2 rounded logo" />
    <div class="position-relative">
      <form class="form-signin" [formGroup]="loginForm" (submit)="authProviderCheck()">
        <div class="form-group">
          <label for="inputEmail" class="font-weight-600 mb-1">Email</label>
          <input type="email" id="email" class="form-control" placeholder="Email" formControlName="email" />
          <span class="text-danger"
            *ngIf=" loginForm.controls.email.invalid && (isFormSubmitted || loginForm.controls.email.touched) ">
            <small *ngIf="loginForm.controls.email.errors.required">Required</small>

          </span>
        </div>
        <div class="form-group">
          <label for="inputPassword" class="font-weight-600 mb-1">Password</label>
          <input type="password" id="inputPassword" class="form-control" placeholder="Password"
            formControlName="password" autocomplete="off" />
          <small class="text-danger"
            *ngIf=" loginForm.controls.password.invalid && (isFormSubmitted || loginForm.controls.password.touched) ">Required</small>
        </div>
        <div class="d-flex align-items-center justify-content-end mb-3">
          <a class="text-primary cursor-pointer" (click)="toggleForgotPwd()">Forgot Password?</a>
        </div>
        <button class="btn bg_green text-white btn-block" type="submit" [disabled]="isFormSubmitting">
          <ng-container *ngIf="isFormSubmitting; else isFormSubmittingDone"> <span
              class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Signing In... </ng-container>
          <ng-template #isFormSubmittingDone> Sign in <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </ng-template>
        </button>
      </form>
      <div [@forgotPwdState]="forgotPwdState" class="position-absolute w-100 h-100 bg-white" style="top: 0; left: 0">

        <hr />
        <form [formGroup]="forgotPwdForm" (submit)="forgotPwdFormSubmit()">
          <div class="form-group">
            <label for="forgotPwdEmail" class="font-weight-600 mb-1">Email</label>
            <input type="email" id="forgotPwdEmail" class="form-control" placeholder="Email" formControlName="email" />
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="text-danger"
              *ngIf=" forgotPwdForm.controls.email.invalid && (isFrgotPwdFormSubmitted || forgotPwdForm.controls.email.touched); else blankSpan ">
              <small *ngIf="forgotPwdForm.controls.email.errors.required">Required</small>
              <small *ngIf="forgotPwdForm.controls.email.errors.pattern">Invalid Email</small>
            </span>
            <ng-template #blankSpan>
              <span></span>
            </ng-template>
            <a class="text-primary cursor-pointer" (click)="toggleForgotPwd()">Back to login?</a>
          </div>
          <button class="btn bg_green text-white btn-block" type="submit" [disabled]="isFrgotPwdFormSubmitting">
            <ng-container *ngIf=" isFrgotPwdFormSubmitting; else isFrgotPwdFormSubmittingDone ">
              <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Sending...
            </ng-container>
            <ng-template #isFrgotPwdFormSubmittingDone> Send Mail <fa-icon [icon]="faArrowRight"></fa-icon>
            </ng-template>
          </button>
        </form>
      </div>
    </div>
    <p class="mt-3 mb-0 text-muted text-center">&copy; HealthSight Care Tracker 2021-{{myDate}} </p>
    <div class="d-flex align-items-center justify-content-center">
      <a class="text-primary cursor-pointer" target="_blank" routerLink="/privacy-policy">Privacy Policy</a>
    </div>
  </div>
</div>

<ng-template #providerSelectionModal>
  <div class="modal-body p-0">
    <div class="modal-header header_main_bg text-white mb-3">
      <h5 class="modal-title font-weight-bold">Select Provider Type</h5>
      <button type="button" class="close pull-right" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="px-3">
      <h5 class="mt-4">Please select a provider type<span class="text-danger">*</span></h5>
      <form [formGroup]="providerTypeForm" (submit)="onProviderTypeSelect()">
        <div class="form-group">
          <!-- <label class="font-weight-600" for="">Select Provider<span class="text-danger">*</span></label> -->
          <ng-select [items]="providerTypes" bindLabel="name" [searchable]="true" bindValue="_id"
                formControlName="providerType" onchange="checkDrop()" placeholder="Select" [clearable]="false"></ng-select>
          <small class="text-danger"
            *ngIf="checkProvider && !providerTypeForm.controls.providerType.value">
            Required
          </small>
          <div class="form-group text-right mt-3">
            <button class="btn bg_green text-white" type="submit"> Continue </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
