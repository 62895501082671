import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  private logoUrlSubject = new BehaviorSubject<string>('');
  logoUrl$ = this.logoUrlSubject.asObservable();

  updateLogoUrl(url: string) {
    this.logoUrlSubject.next(url);
  }
}
