<div class="row mx-0 pb-1 mb-2" style="border:1px solid #c6c6c6;">

  <div class="col-md-8">
    <mat-paginator *ngIf="role==='Provider' || role === 'Staff'" style="transform: translateX(25px);" [length]="pagination?.totalCount" [pageSize]="10"
      (page)="pageChange($event)"> </mat-paginator>
    <div class="row p-2 scroll_hidding position-relative">
      <div class="table-responsive table_patient_info_table">
        <table class="table mb-0" aria-describedby="describe">
          <thead>
            <tr>
              <th scope="col" style="width:60%">Note</th>
              <th scope="col">Created By</th>
              <th *ngIf="role!=='Admin'" scope="col" style="width:30%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of notesList">
              <ng-container *ngIf="item.viewStatus === 'Everyone' || item.createdBy?._id === roleID && item.viewStatus === 'Only me'">
              <td class="name_limit">{{item?.message}}</td>
              <td>{{item.createdBy?.firstName}} {{item.createdBy?.lastName}}
                <small>({{item.createdAt|date:'MM/dd/YYYY, hh:mm aa'}})</small> <small
                  *ngIf="item?.viewStatus === 'Only me'"> (Me)</small>
              </td>
              <td  *ngIf="role!=='Admin'"> <button class="btn btn_m_primary min_width_btn" (click)="editNote(item)"><em
                    class="fa fa-pencil-square-o" aria-hidden="true"></em></button>
                <button *ngIf="role !== 'Staff'" class="btn min_width_btn bg_red text-white ml-2" (click)="openModal(deleteRecord,item._id)"><em class="fa fa-trash"
                    ></em></button>
              </td>
            </ng-container>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
  <div class="col-md-4 d-flex align-items-center justify-content-center mt-3">
    <div>
      <form [formGroup]="patientNotes" (submit)="isEdit? updateNotes():submitNotes() ">
        <div class="form-group res_d_flex">
          <ng-select [items]="viewPermissions" bindLabel="name" bindValue="name" [clearable]="false"
            formControlName="viewStatus" placeholder="View" class="ng-container_cwidth mr-3"></ng-select>
          <span>(Who can view my new posted note)</span>
        </div>


        <div class="form-group">
          <textarea rows="3" placeholder="Note" formControlName="message" class="form-control"></textarea>
          <span class="text-danger"
            *ngIf="!!patientNotes.controls.message.invalid&&(isSubmitted||!!patientNotes.controls.message.touched)">message
            is required</span>
        </div>

        <div class="w-100 text-center">
          <button type="submit" class="btn btn_m_primary">{{isEdit ? 'Update': 'Submit'}}</button>
        </div>

      </form>
    </div>
  </div>
</div>
<ng-template #deleteRecord>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>Are you sure you want to delete the note?</p>
    </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <div class="row float-right"> <button class="btn min_width_btn bg_red text-white ml-2"
        (click)="_BsModalService.hide()">No</button> <button (click)="deleteRecords()"
        class="btn min_width_btn bg_green text-white ml-2 mr-4">Yes</button> </div>
  </div>
</ng-template>
