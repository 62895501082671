

export class userinfo {
    addressLine1: string;
    addressLine2: string;
    createdAt: string;
    email: string;
    firstName: string;
    gender: string
    id: string;
    lastLoginDate: string;
    lastName: string;
    phone: string;
    photo: string;
    role: string;
    updatedAt: string;
    __v: string;
    _id: string;
    constructor() {
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.createdAt = '';
        this.email = '';
        this.firstName = '';
        this.gender = '';
        this.id = '';
        this.lastLoginDate = '';
        this.lastName = '';
        this.phone = '';
        this.photo = '';
        this.role = '';
        this.updatedAt = '';
        this.__v = '';
        this._id = ''
    }
}
