import { Component, OnInit } from '@angular/core';
// import { Location } from '@angular/common';


@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
 styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor(
    // private location: Location
  ) { }

  ngOnInit(): void {
    console.log(" ");

  }
  goBack() {
    // this.location.back(); https://healthsighttracker.com/login (for live)
    window.location.href = 'https://medipro.azurewebsites.net/login'; // for dev
  }

}
