import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';

@Component({
  selector: 'app-attorney-insurance-details',
  templateUrl: './attorney-insurance-details.component.html'
})
export class AttorneyInsuranceDetailsComponent implements OnInit {

  attorneyInsuranceDetails: any = []
  isDeletingAttorIns: boolean = false

  constructor(private _ActivatedRoute: ActivatedRoute, private _MainService: MainService, private _Router: Router, private _NotifierService: NotifierService) { }

  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe(data => {
      if (data && data.attorneyInsurance) {
        this.attorneyInsuranceDetails = data.attorneyInsurance
        

      }
    })
  }
  goBack() {
    window.history.back()
  }

  deleteAttorInsurance(id: any) {
    this.isDeletingAttorIns = true
    this._MainService.deleteAttorneyInsurancebyid(id).then((data: any) => {
      if (data.success) {
        this.isDeletingAttorIns = false
        this._NotifierService.showSuccess('Attorney Deleted Successfully!')
        this._Router.navigate(['/admin/attorney-insurance'])
      } else {
        this.isDeletingAttorIns = false
        this._NotifierService.showSuccess('Something Went wrong')
      }
    })
  }

}
