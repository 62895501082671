<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">

          <span>New Facility</span>
        </h1>
        <button class="btn btn-sm btn-outline-primary" (click)="goBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
    <div class="px-3 pb-3">
      <form [formGroup]="addOrEditFacility" (submit)="addOEditFacilityFormSubmit()">
        <div class="main-patient-form">
          <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
            <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">General Info</span>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Name <span class="text-danger">*</span></label>
                  <div class="form-row">
                    <div class="col-12">
                      <input type="text" class="form-control" placeholder="Facility Name" formControlName="name" />
                      <small class="text-danger" *ngIf=" !!addOrEditFacility.controls.name.invalid && !!addOrEditFacility.controls.name.touched "></small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Phone <span class="text-danger">*</span></label>
                  <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" placeholder="Phone" formControlName="phone" />
                  <small class="text-danger" *ngIf=" addOrEditFacility.controls.phone.invalid && addOrEditFacility.controls.phone.touched "></small>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Fax </label>
                  <input type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57;" class="form-control"  maxlength="15" placeholder="Fax" formControlName="fax" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Email</label>
                  <input type="text" class="form-control" formControlName="email" placeholder="Email"
                    (focus)="onFocus()" (blur)="onBlur($event)" />
                  <small class="text-danger" *ngIf="doesExist">Email already exist</small>

                </div>
              </div>
               <div class="col-sm-3">
                 <div class="form-group">
                   <label class="font-weight-600" for="">Address</label>
                   <input type="text" class="form-control" formControlName="address" placeholder="Address" />


                 </div>
               </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Associated Doctor</label>
                  <ng-select [items]="providerList" bindLabel="name" [multiple]="true" bindValue="_id" [searchable]="true" formControlName="associatedprovider" placeholder="Select Associated Doctor"></ng-select>
                  <!-- <small class="text-danger" *ngIf=" !!addOrEditFacility.controls.associatedprovider.invalid && !!addOrEditFacility.controls.associatedprovider.touched ">Required</small> -->
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Contact Person</label>
                  <input type="text" class="form-control" formControlName="contactperson" placeholder="Contact Person" />
                 <var></var>
                </div>
              </div>
               <div class="col-sm-3">
                 <div class="form-group">
                   <label class="font-weight-600 w-100" for="">Specialty</label>
                   <ng-select [items]="speciality" bindLabel="name" bindValue="_id" [searchable]="true"
                     formControlName="specialityList" [multiple]="true" placeholder="Select specialities"> </ng-select>

                 </div>
               </div>



            </div>
          </div>
          <div class="text-right mt-3">
            <button type="submit" class="btn bg_green text-white" [disabled]="addOrEditFacilityFormSubmitting">
              <ng-container *ngIf=" addOrEditFacilityFormSubmitting; else addOEditFacilityFormSubmittingDone "> <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon> Submitting... </ng-container>
              <ng-template #addOEditFacilityFormSubmittingDone> Submit <i class="fa fa-arrow-right" aria-hidden="true"></i> </ng-template>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
