import { Injectable } from '@angular/core';
import { environment } from '../src/environments/environment';
declare const require: any;

class ConfigHelper {
    config: any;
    constructor() {
        try {
            this.config = require('./config.json');
        } catch (e) {
            // simply console error
            if (!environment.production) {
                // tslint:disable-next-line: no-console
              
            }
        }
    }
}
@Injectable({
    providedIn: 'root'
})
export class Config {
    api: any;



    constructor() {
        const _ConfigHelper = new ConfigHelper();
        this.api = _ConfigHelper.config;
    }
}