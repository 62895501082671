<div class="container-fluid p-0">
  <div class="px-5 bg-white">
    <div class="py-3">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Dashboard</h1>

      </div>
    </div>
    <div class="">

      <div class="row">
        <div class="col-md-6 row_cborder py-3 d-flex align-items-center">
          <form>
            <h5 class="mb-0">Search</h5>
            <div class="input-group">
              <div class="d-flex mb-2">
                <i class="fas fa-search"></i>
                <input type="text" (input)="isBlank()" class="form-control border-left-0" name="searchValuee"
                  autocomplete="off" [(ngModel)]="searchValuee" placeholder="Search by: Patient name">
              </div>
              <button class="btn btn_m_green" style="height: 38px;" (click)="searchPatientList()">Search</button>
            </div>
            <span class="alertUpdate" *ngIf="expressionUpdate">Please enter at least 3 characters.</span>
          </form>
        </div>
        <div class="col-md-6 row_cborder py-3">
          <h2>Overview</h2>
          <div class="form-group ml-3" style="width: 80%;" *ngIf="(addressList.length > 2) &&(role === 'Provider' || role === 'Staff')">
            <label class="font-weight-600" for="">Select Location<span class="text-danger">*</span></label>
            <ng-select [items]="addressList" bindLabel="addressLine1" bindValue="_id" placeholder="Select case status"
            (change)="onAddressSelect($event)" [(ngModel)]="selectedAddress"></ng-select>
          </div>
          <!-- <div class="form-group ml-3" style="width: 80%;" *ngIf="(addressList.length > 2) &&(role === 'Provider' || role === 'Staff')">
            <label class="font-weight-600" for="">Select Location<span class="text-danger">*</span></label>
            <ng-select [items]="addressList" bindLabel="addressLine1" bindValue="_id" placeholder="Select case status"
            (change)="onAddressSelect($event)" [(ngModel)]="selectedAddress"></ng-select>
          </div> -->
          <div class="d-flex flex-column ml-3">
            <div class="overview_inner_w">
              <span class="font-weight-600">New Patients</span>
              <button class="btn btn_m_primary min_width_btn">{{notificationCounter?.patientCount || 0}}</button>
            </div>
          </div>
          <div class="d-flex flex-row ml-3">
            <div class="overview_inner_w">
              <span class="font-weight-600">Status Needed</span>
              <button class="btn btn_m_primary_org min_width_btn" [queryParams]="{ tab: 1 }"
                routerLink="/home/patients">{{notificationCounter?.statusNeededCount || 0}}</button>
            </div>
            <div class="overview_inner_w ml-3">
              <span class="font-weight-600">Urgent Status</span>
              <button class="btn min_width_btn bg_red text-white" [queryParams]="{ tab: 2 }"
                routerLink="/home/patients">{{notificationCounter?.statusNeededCountUrgent || 0}}</button>
            </div>

          </div>
        </div>
      </div>

      <div class="row boca mt-3">

        <div class="table-responsive overflow_height_vh">
          <table class="table m_table" style="width: 99.9%;" aria-describedby="info">
            <thead>
              <th scope="col">Patient ID</th>
              <th scope="col">Patient Name</th>
              <th scope="col">DOB</th>
              <th scope="col">Associated Providers</th>
              <th scope="col">Case Status</th>
              <th scope="col">Last Update</th>
              <th scope="col" style="width:30%">Notes</th>
            </thead>
            <tbody>
              <ng-container *ngIf="patientsData && patientsData.length > 0; else noPatientFound">
                <tr *ngFor="let item of patientsData">
                  <td style="cursor: pointer">
                    <a routerLink="/home/patient-details/{{ item._id }}">
                      <span>{{item.patientUID}}</span>
                    </a>
                  </td>
                  <td class="name_limit">
                    <a routerLink="/home/patient-details/{{item._id}}">
                      <span>{{item.firstName}} {{item.lastName}}</span>
                    </a>
                  </td>
                  <td>
                    <span>{{item.dateOfBirth |date:"MM/dd/yyyy"}}</span>
                  </td>
                  <!-- <td>
                    <span>{{item.provider[0]?.firstName}} {{item.provider[0]?.lastName}}</span>
                  </td> -->
                  <td style="white-space: normal;">
                  <span
                  class="font-weight-light"
                  *ngIf="item?.treatingDoctor!=='null'">
                  <ng-template ngFor let-provider let-last="last" let-i="index" [ngForOf]="item?.treatingDoctor">
                    {{provider?.firstName}} {{provider?.lastName}}
                     <span *ngIf="!last">,</span>
                  </ng-template>
                </span>
              </td>
                  <td>
                    <span>{{item.caseStatus?.name}}</span>
                  </td>
                  <td>
                    <span>{{item.patientNotes?.length>0?(item.patientNotes[0]?.updatedAt | date: "YYYY-MM-dd"):""}}</span>
                  </td>
                  <td class="name_limit">
                    <span>{{item.patientNotes?.length>0?item.patientNotes[0]?.message:""}}</span>
                  </td>
                </tr>
              </ng-container>
              <ng-template #noPatientFound>
                <tr class="border-bottom">
                  <td colspan="12">
                    <div class="text-center py-5">
                      <h1 class="display-4">

                      </h1>
                      <h5 class="font-weight-bold text-muted mt-3">
                        No patient found
                      </h5>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div class="w-100 mb-3">
          <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #hippaModal>

  <h3 class="modal-body hippa_header"> HIPAA AUTHORIZATION </h3>
  <div class="modal-body">
    <h5 class="text-danger">Choosing not to accept HIPAA authorization may result in not having access to HealthSight Care Tracker Services.</h5>
    <hr>
    <label for="authorization" class="text-danger">[not authorized]</label>
    <div class="form-check">
      <input class="form-check-input mt-1" type="checkbox" value="" id="flexCheckDefault" (change)="isChecking($event)">
      <label class="form-check-label" for="flexCheckDefault"> I have read and accept <span class="text-primary">
          <a href="https://privacyruleandresearch.nih.gov/authorization.asp" target="_blank">HIPAA Authorization.</a>
        </span> </label>
    </div>
  </div>
  <div class="w-100 px-3 pb-3">
    <button type="submit" style="width: 120px;" class="float-right btn btn_m_green" (click)="HippaSubmitForm()"
      [disabled]="!isChecked">Save</button>
  </div>
</ng-template>
