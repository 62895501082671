import { ResponseBase } from '../response-base';
import { MasterDataDDL } from '../masterdata-ddl';

export class AttorneyWithResponse extends ResponseBase {
    public data: Attorney;
    constructor() {
        super();
        this.data = new Attorney();
    }
}
export class AttorneysWithResponse extends ResponseBase {
    public data: AttorneysWithPagination;
    constructor() {
        super();
        this.data = new AttorneysWithPagination();
    }
}

export class AttorneysWithPagination {
    public attorneyList: Array<Attorney>;
    constructor() {
        this.attorneyList = Array<Attorney>();
    }
}

export class AttorneyDDLWtihResponse {
    public data: Array<Attorney>;
    constructor() {
        this.data = Array<Attorney>();
    }
}
export class Attorney {
    public id: number;
    public loginID: number;
    public patientId: number;
    public firstName: string;
    public lastName: string;
    public phone: number;
    public email: string;
    public fax: number;
    public role: string;
    public genderId: number;
    public picture: string | null;
    public highestQualifaication: string;
    public addressLine1: string;
    public addressLine2: string;
    public patienthavepip: string;
    public coveredByInsurance: string;
    public createdBy: number;
    public createdOn: string;
    public updatedBy: number;
    public updatedOn: string;
    public statusId: number;
    public password: string;
    public specialilties: string;
    public specialityList: Array<string>;
    public specialiltiesList: Array<MasterDataDDL>;
    public name: string;
    public attorneyId: number;
    public patientHavePIP: string;
    public attorneypatientHavePIP: string;
    public genderName: string;
    constructor() {
        this.id = 0;
        this.loginID = 0;
        this.patientId = 0;
        this.firstName = '';
        this.lastName = '';
        this.phone = 0;
        this.email = '';
        this.fax = 0;
        this.role = '';
        this.picture = null;
        this.genderId = 0;
        this.highestQualifaication = '';
        this.addressLine1 = '';
        this.addressLine2 = '';
        this.patienthavepip = '';
        this.coveredByInsurance = '';
        this.createdBy = 0;
        this.createdOn = '';
        this.updatedBy = 0;
        this.updatedOn = '';
        this.statusId = 0;
        this.password = '';
        this.specialilties = '';
        this.specialityList = Array<string>();
        this.specialiltiesList = Array<MasterDataDDL>();
        this.name = '';
        this.attorneyId = 0;
        this.patientHavePIP = '';
        this.attorneypatientHavePIP = '';
        this.genderName = '';
    }


}
