import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from '../../../../services/mainservice/main.service';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';
import { MasterDataDDL } from '../../../../models/masterdata-ddl';
import { EmailTemplate } from '../../../../services/emailTemplate/emailTemplate.service';


@Component({
    selector: 'app-add-o-edit-admin',
    templateUrl: './Add-edit-admin-details.component.html',
    styles: []
})
export class AddOEditAdminComponent implements OnInit {
    url: any
    insuranceValidator = false
    associatedDoctorValidator = false
    editMode: boolean;
    addAdminForm: FormGroup;
    addAdminFormSubmitting = false;
    addAdminFormSubmitted = false;
    firstName: any;
    selectedProviderType: string = "";
    lastName: any;
    genders: any;
    defaultPassword: any;
    sentEmail: any;
    roleId: number;
    profileImage: any;
    fileViewer: string;
    specialities: Array<MasterDataDDL>;
    currAdmin: any=[];
    tempPicture: string | null;

    invalidFields: any[];
    doesExist: boolean = false;
    // providerId: any;
    adminId: any = null;
    role: any;
    specialityValidator: any;
    genderValidator: any;
    submitValidating: any;
    // status: any
    constructor(
        private _ActivatedRoute: ActivatedRoute,
        private _FormBuilder: FormBuilder,
        private _MainService: MainService,
        private _NotifierService: NotifierService,
        private _Router: Router,
        private _EmailTemplate: EmailTemplate

    ) {
        this.editMode = false;
        this.specialityValidator = true;
        this.genderValidator = true;
        this.addAdminForm = this._FormBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: [''],
            genderId: [],
            // Status: [null, [Validators.required]],
            phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            email: ['', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
            fax: [null, [Validators.minLength(10), Validators.maxLength(15)]],
            // highestQualification: [null],
            photo: [''],
            addressLine1: [''],
            addressLine2: [''],
            businessName: [''],
            associatedprovider: [null],
        });
        this.genders = [];
        this.roleId = 0;
        this.fileViewer = 'Choose File';
        this.specialities = Array<MasterDataDDL>();
        this.tempPicture = null;

        this.invalidFields = [];
    }


    ngOnInit() {


        this.url = window.location.protocol + '//' + window.location.hostname;
        if (window.location.port !== '') this.url += ':' + window.location.port;

        this._MainService.getgenderList().then((data: any) => {
            this.genders = data.data;
        })
        this._MainService.getCurrentUserInfo().then((data: any) => {
            if (data.success) {
                this.role = data.data.role
            }
        })

        this._ActivatedRoute.data.subscribe(data => {

            if (data.admin !== null && data.admin !== undefined) {
                this.editMode = true;
                this.adminId = data.admin.id
                this.currAdmin = data.admin;

                this.addAdminForm.patchValue(data.admin);
                let associated: any = [];
                data.admin.associatedDoctor?.forEach((x: any) => {
                    associated.push(x._id);
                    this.addAdminForm.controls.associatedprovider.setValue(associated)

                })

                this.addAdminForm.controls.addressLine1.patchValue(data.admin.addressLine1);
                this.addAdminForm.controls.businessName.patchValue(data.admin.businessName);
                if (this.addAdminForm.controls.addressLine1.value === "null") { this.addAdminForm.controls.addressLine1?.setValue("") }
                if (this.addAdminForm.controls.businessName.value === "null") { this.addAdminForm.controls.businessName?.setValue("") }
                if (this.addAdminForm.controls.addressLine2.value === "null") { this.addAdminForm.controls.addressLine2?.setValue("") }
                if (this.addAdminForm.controls.email.value === "null") { this.addAdminForm.controls.email?.setValue("") }
                if (this.addAdminForm.controls.fax.value === "null") { this.addAdminForm.controls.fax?.setValue("") }
                this.addAdminForm.controls.genderId.setValue(data.admin.gender?._id);
                this.addAdminForm.setControl('ClinicAffiliationsList', this._FormBuilder.array(data.admin.clinicAffiliations || ['']));
                this.tempPicture = this.currAdmin.photo;

            }
        });

    }

    getFormData(): FormData {
        debugger

        const formData: FormData = new FormData();
        formData.append('firstName', this.addAdminForm.controls.firstName.value);
        formData.append('fullName', this.addAdminForm.controls.firstName.value);
        formData.append('lastName', this.addAdminForm.controls.lastName.value);
        formData.append('email', this.addAdminForm.controls.email.value);
        formData.append('phone', this.addAdminForm.controls.phone.value);

        if (this.addAdminForm.controls.genderId.value !== null && this.genderValidator) {

            formData.append('gender', this.addAdminForm.controls.genderId.value);
        }

        formData.append('addressLine1', this.addAdminForm.controls.addressLine1.value);
        formData.append('addressLine2', this.addAdminForm.controls.addressLine2.value);
        formData.append('businessName', this.addAdminForm.controls.businessName.value);


        formData.append('fax', this.addAdminForm.controls.fax.value);

        if (this.editMode) {
            formData.append('isUpdate', JSON.stringify(true));
            formData.append('AdminId', this.adminId);
          }
          else {
            formData.append('isUpdate', JSON.stringify(false));
            formData.append('AdminId', this.adminId);
        }


        if (this.profileImage && this.profileImage.name) {
            formData.append('photo', this.profileImage, this.profileImage.name);
        }
        return formData;
    }


    fileChange(event: Event): void {
        if (event.target !== null) {
            const files = (<HTMLInputElement>event.target).files;
            if (files !== null) {
                const fileList: FileList = files;
                if (fileList && fileList.length > 0) {
                    const file: File = fileList[0];
                    this.profileImage = file;
                    this.fileViewer = file.name;
                }
            }
        }
    }




    addAdminFormSubmit(): void {
        this._MainService.emailCheckProvider(this.addAdminForm?.value?.email).then((data: any) => {            
            if (data.success && this.editMode !== true) {
                this.doesExist = true;
            }else{
                this.doesExist = false;       


        this.addAdminFormSubmitted = true;
        if (this.addAdminForm.valid && !this.doesExist) {
            if (this.editMode === true) {
                this.addAdminFormSubmitting = true;
                this._MainService.registerAdmin(this.getFormData()).then((data: any) => {

                    this.defaultPassword = data.data.defaultPassword
                    this.sentEmail = data.data.email

                    this.addAdminFormSubmitting = false;
                    if (data.success === true) {
                        this._NotifierService.showSuccess('Admin updated Successfully.');
                        if (this.role === 'Admin') {
                            this._Router.navigate(['admin/admins']);
                        }else if (this.role === 'SuperAdmin') {
                          this._Router.navigate(['super-admin/admins']);
                      } else {
                            this._Router.navigate(['home/admins']);
                        }
                    } else {

                        if (data.message === "Already Exist in System") {
                            this._NotifierService.showError('Admin Already Exist');
                        } else {
                            this._NotifierService.showError('Something went wrong..!');
                        }

                    }
                });
            } else {
                this.addAdminFormSubmitting = true;
                console.log(this.addAdminForm.valid,"this.addAdminForm.valid    badh me");

                this._MainService.registerAdmin(this.getFormData()).then((data: any) => {
                    this.addAdminFormSubmitting = false;

                    this.defaultPassword = data.data.defaultPassword
                    this.sentEmail = data.data.email
                    if (data.success === true) {
                        this._NotifierService.showSuccess('Admin added Successfully.');

                        this._MainService.updateAdminCount({ AdminId: [data.data._id] }).then((data: any) => {
                          console.log(data);
                      })

                        // if (data.sendEmail === true) {
                        //     this.sendEmail();

                        // }
                        if (this.role === 'Admin') {
                            this._Router.navigate(['admin/admins']);
                        }else if (this.role === 'SuperAdmin') {
                          this._Router.navigate(['super-admin/admins']);
                      } else {
                            this._Router.navigate(['home/admins']);
                        }

                    } else {

                        if (data.message === "Already Exist in System") {
                            this._NotifierService.showError('Admin Already Exist');
                        } else {
                            this._NotifierService.showError('Something went wrong..!');
                        }
                    }
                });
            }
        } else {
            if (!this.doesExist) {
                this.invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));

            }
        }
    }
    })

    }
    goBack(): void {
        window.history.back();
    }

    imgError(): void {
        this.tempPicture = null;
    }
    onBlur(data: any) {
        if (data.target.value !== "") {
            this._MainService.emailCheckProvider(data.target.value).then((data: any) => {
                if (data.success && this.editMode !== true) {
                    this.doesExist = true
                }
            })
        }
    }

    takeformData(): FormData {
        const formData: FormData = new FormData();
        formData.append('email', this.sentEmail)
        formData.append('html', this._EmailTemplate.welcomeEmailTemplate(this.sentEmail, this.defaultPassword, this.url))
        formData.append('message', "Login Credential")
        return formData

    }

    onFocus() {

        this.doesExist = false
    }

}
