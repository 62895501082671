import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    providerProfileUpdate: Subject<any>
    notificationData: Subject<any | null>;
    notification: any;
    constructor() {
        this.notificationData = new BehaviorSubject<any | null>(null);
        this.providerProfileUpdate = new BehaviorSubject<any>(null)
    }

    
    getnotificationData(): Observable<any | null> {
        return this.notificationData.asObservable();
    }

    getnotificationDataWithoutObservable(): any {
        return this.notification;
    }


    setnotificationData(_notification: any):void {
        this.notificationData.next(_notification);
        this.notification = _notification;
    }
    getProviderProfileData(): Observable<any> { return this.providerProfileUpdate.asObservable() }
    setProfileData(_profile: any) { this.providerProfileUpdate.next(_profile); }
}
