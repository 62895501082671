<div class="container-fluid m-0 p-0">
  <div class="bg-white mb-2" style="border: 1px solid #c6c6c6;">
    <div class="">
      <p class="heading_label">Records & Billing</p>
      <div class="d-flex justify-content-between">
        <h5 class="ml-3">Upload files</h5> <mat-paginator [length]="pagination?.totalCount" [pageSize]="10"
          (page)="pageChange($event)"> </mat-paginator>
      </div>
    </div>
    <div class="w-100 px-3">
      <form [formGroup]="addRecords" (submit)="isEdit? editSubmitRecord():recordsSubmitData() ">
        <div class="row mr-2">
          <div class="col-lg-4 col-md-6">
            <div class="row">
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <input type="text" placeholder="Select Files" [attr.disabled]="true" formControlName="file"
                    class="form-control bg-white"> <span class="text-danger" *ngIf="isfile">Required</span>
                </div>
              </div>
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <ng-select [items]="recordsTypeList" bindLabel="name" [clearable]="false" placeholder="Type"
                    bindValue="_id" formControlName="type" (change)="onSelect2($event)"></ng-select>
                  <span class="text-danger" *ngIf="isfilevalue">Required</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.addRecords.controls.file.value || this.addRecords.controls.type.value"  class="col-md-1">
            <button type="button" class="btn btn-danger" (click)="clearFields('first')">X</button>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="col-md-5 px-0 mr-3"> <button type="button" class="btn btn_m_primary btn-block c_btn_file">Select
                <input type="file" class="btn btn_m_primary" (change)="recordsFile($event)" id="fileUpload"> </button>
            </div>
          </div>
        </div>

        <div class="row mr-2">
          <div class="col-lg-4 col-md-6">
            <div class="row">
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <input type="text" placeholder="Select Additional Files" [attr.disabled]="true"
                    formControlName="Additionalfile" class="form-control bg-white"> <span class="text-danger"
                    *ngIf="isfile2">Required</span>
                </div>
              </div>
              <div class="col-md-6 pr-0">
                <div class="form-group"> <ng-select [items]="additionalRecordsTypeList" bindLabel="name"
                    [clearable]="false" placeholder="AdditionalRecordType" bindValue="_id"
                    formControlName="AdditionalRecordType" (change)="onSelect($event)"></ng-select> <span
                    class="text-danger" *ngIf="isfile3">Required</span> </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.addRecords.controls.Additionalfile.value || this.addRecords.controls.AdditionalRecordType.value"  class="col-md-1">
            <button type="button" class="btn btn-danger" (click)="clearFields('second')">X</button>
          </div>
          <div class="col-lg-3 col-md-6 d-flex">
            <div class="col-md-5 px-0 mr-3"> <button type="button" class="btn btn_m_primary btn-block c_btn_file">Select
                <input type="file" class="btn btn_m_primary" (change)="recordsFile2($event)" id="fileUpload2"> </button>
            </div>
            <div class="col-md-5 px-0 "> <button type="submit"
                class="btn bg_red text-white btn-block">{{isEdit ? 'Update':'Upload'}}</button> </div>
            <div *ngIf="isEdit" class="col-md-5 px-0 "> <button class="btn bg_red text-white btn-block ml-4"
                (click)="clearEdit()">Cancel</button> </div>
          </div>
        </div>
        <div class="row mr-2">
          <div class="col-lg-4 col-md-6">
            <label for="fileInput" class="custom-file-upload">
              Add more records
              <input type="file" id="fileInput" (change)="addMoreDocuments($event)" multiple>
            </label>

          </div>
        </div>
        <div *ngIf="selectedFileList && selectedFileList.length > 0">
          <h4>Selected Files:</h4>
          <ul>
            <li *ngFor="let file of selectedFileList; let i = index">
              {{file.name}}
              <button (click)="removeFile(i)">X</button>
            </li>
          </ul>
        </div>






      </form>
      <div class="row mt-2">
        <div class="table-responsive table_patient_info_table border-left-0 border-right-0 border-bottom-0">
          <table class="table mb-0" aria-describedby="describe">
            <thead>
              <tr>
                <th scope="col" style="width:50%">File</th>
                <th scope="col">Type</th>
                <th scope="col" style="width:30%">Updated By</th>
                <th *ngIf="role === 'Admin' || role === 'SuperAdmin'" scope="col" style="width:30%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tableRecords" class="border-bottom">
                <td> <a *ngIf="item.file"
                    [href]="'https://spineplusbe.azurewebsites.net/'+item.file?.substring(7, item.file.length)"
                    target="_blank">{{item.type ? item.file?.substring(36, item.file?.length ) : ''}}
                    <br>
                  </a>
                  <a *ngIf="item.Additionalfile"
                    [href]="'https://spineplusbe.azurewebsites.net/'+item.Additionalfile?.substring(7, item.Additionalfile?.length )"
                    target="_blank"> {{item.AdditionalRecordType ?  item.Additionalfile?.substring(36, item.Additionalfile?.length ) : ''}}
                    <br>
                  </a>
                  <span *ngIf="(item.extraDocuments !== undefined) && (item.extraDocuments.length > 0)" style="color: blue;cursor: pointer;"
                    (click)="openModal2(showRecord,item)"> More documents...
                  </span>
                </td>
                <td> <span *ngIf="item.file">{{item.type?.name}} <br></span> <span
                    *ngIf="item.AdditionalRecordType">{{item.AdditionalRecordType?.name}}</span></td>
                <td> {{item.createdBy?.firstName}} {{item.createdBy?.lastName}}
                  <small>({{item.updatedAt|date:'MM/dd/YYYY, hh:mm aa'}})</small>
                </td>
                <td *ngIf="role == 'Admin' || role === 'SuperAdmin'"> <button class="btn btn_m_primary min_width_btn"
                    (click)="editRecord(item)"><em class="fa fa-pencil-square-o" aria-hidden="true"></em></button>
                  <button class="btn min_width_btn bg_red text-white ml-2"
                    (click)="openModal(deleteRecord,item._id)"><em class="fa fa-trash" aria-hidden="true"></em></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #deleteRecord>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>Are you sure you want to delete the record?</p>
    </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <div class="row float-right"> <button class="btn min_width_btn bg_red text-white ml-2"
        (click)="_BsModalService.hide()">No</button> <button (click)="deleteRecords()"
        class="btn min_width_btn bg_green text-white ml-2 mr-4">Yes</button> </div>
  </div>
</ng-template>

<ng-template #showRecord>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>List of all the record files.</p>
    </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService2.hide()"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <ul>
      <li *ngIf="recordList.file  && recordList.type">
        <a [href]="'https://spineplusbe.azurewebsites.net/'+recordList.file?.substring(7, recordList.file?.length )"
          target="_blank">{{recordList.file?.substring(36, recordList.file?.length )}}</a>
      </li>
      <li *ngIf="recordList.Additionalfile && recordList.AdditionalRecordType">
        <a [href]="'https://spineplusbe.azurewebsites.net/'+recordList.Additionalfile?.substring(7, recordList.Additionalfile?.length )"
          target="_blank">{{recordList.Additionalfile?.substring(36, recordList.Additionalfile?.length )}}</a>
      </li>
      <li *ngFor="let url of recordList.extraDocuments">
        <a [href]="'https://spineplusbe.azurewebsites.net/'+url?.path?.substring(7, url?.path?.length )"
          target="_blank">{{url?.path?.substring(36, url?.path?.length )}}</a>
      </li>
    </ul>
  </div>
</ng-template>
