import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html'
})
export class ProvidersComponent implements OnInit {

 // constructor() { }

  ngOnInit(): void {
    console.log(" ");
    
  }

}
