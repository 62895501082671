<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">

          <span>New Attorney</span>
        </h1>
        <button class="btn btn-sm btn-outline-primary" (click)="goBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
    <div class="px-3 pb-3">
      <form [formGroup]="addOrEditAttorneyInsurance" (submit)="addOEditAttorneyInsurancFormSubmit()">
        <div class="main-patient-form">
          <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
            <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">General Info</span>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Name <span class="text-danger">*</span></label>
                  <div class="form-row">
                    <div class="col-6">
                      <input type="text" class="form-control" placeholder="First Name" formControlName="firstname" />
                      <small class="text-danger" *ngIf=" !!addOrEditAttorneyInsurance.controls.firstname.invalid && !!addOrEditAttorneyInsurance.controls.firstname.touched "></small>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" placeholder="Last Name" formControlName="lastname" />
                      <small class="text-danger"
                        *ngIf=" !!addOrEditAttorneyInsurance.controls.lastname.invalid && !!addOrEditAttorneyInsurance.controls.lastname.touched "></small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Phone <span class="text-danger">*</span></label>
                  <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" placeholder="Phone" formControlName="phone" />
                  <small class="text-danger" *ngIf=" addOrEditAttorneyInsurance.controls.phone.invalid && addOrEditAttorneyInsurance.controls.phone.touched "></small>
                </div>
              </div>
              <div class="col-sm-3" >
                <div class="form-group">
                  <label class="font-weight-600" for="">Gender<span class="text-danger">*</span></label>
                  <ng-select [items]="genders" bindLabel="name" [searchable]="true" bindValue="_id"
                    formControlName="genderId" placeholder="Select Gender" [clearable]="false"></ng-select>


              </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Fax  </label>
                  <input type="number"  maxlength="15" class="form-control" onkeypress="return event.charCode >= 48 && event.charCode <= 57;" placeholder="Fax" formControlName="fax" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Email
                     <!-- <span class="text-danger">*</span> -->
                    </label>
                  <input type="text" class="form-control" formControlName="email" placeholder="Email"
                    (focus)="onFocus()" (blur)="onBlur($event)" />
                    <small class="text-danger" *ngIf="doesExist">Email already exist</small>
                  <!-- <span class="text-danger" *ngIf=" addOrEditAttorneyInsurance.controls.email.invalid && addOrEditAttorneyInsurance.controls.email.touched ">
                    <small *ngIf="!!addOrEditAttorneyInsurance.controls.email.errors.pattern">Invalid email</small>
                  </span> -->
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Address</label>
                  <input type="text" class="form-control" formControlName="address" placeholder="Address" />


                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Case Manager<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="insurance" placeholder="Case Manager" />

                </div>
              </div>

            </div>
          </div>
          <div class="text-right mt-3">
            <button type="submit" class="btn bg_green text-white" [disabled]="addOrEditAttorneyInsuranceFormSubmitting">
              <ng-container *ngIf=" addOrEditAttorneyInsuranceFormSubmitting; else addOrEditAttorneyInsuranceFormSubmittingDone ">
                <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span>
                Submitting...
              </ng-container>
              <ng-template #addOrEditAttorneyInsuranceFormSubmittingDone>
                Submit
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </ng-template>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
