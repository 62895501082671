import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { MainService } from "@app/services/mainservice/main.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment'
@Injectable()

export class NotificationResolver implements Resolve<any | null>{
    myDate: any
    toDay: any
    fromDay: any
    constructor(private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService) { }
    resolve(): Promise<any> | undefined {
        this.myDate = new Date();
        this.toDay = (moment(this.myDate).format("YYYY-MM-DD"));
        this.myDate = this.myDate.setDate(this.myDate.getDate() - 7);
        this.fromDay = (moment(this.myDate).format("YYYY-MM-DD"));

        this.ngxLoader.start();
        this.ngxLoader.stop();
        return this._MainService.notifcationCountData(10, 1, '', false, this.fromDay, this.toDay).then(($: any) => $);
        
    }
}