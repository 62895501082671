import { Component, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { staffDetails } from '@app/models/staff/staff.model';
import { MainService } from '@app/services/mainservice/main.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotifierService } from '@app/services/notifierservice/notifier.service';


@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.scss']
})
export class StaffsComponent implements OnInit {

  staffsList: staffDetails[]
  addStaffForm: FormGroup;
  isAlertActive: boolean;
  isBusy: boolean;
  editMode!: boolean;
  doesExist: boolean = false;
  pagination: any;
  logProviderId: any;
  selectedStaff: any;
  userRole: any;
  staffID: any;
  currentUser: any;
  searchKey: string;
  isSearchKeyFilled: boolean;
  expressionUpdate: boolean = false;
  PageModalRef: BsModalRef | null;
  DeleteModalRef: BsModalRef | null;
  addStaffFormSubmitting = false;
  addStaffFormSubmitted = false;
  showPassword: boolean = false;
  initialFormValues: any; // to store initial form values

  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _MainService: MainService,
    private _NgxLoader: NgxUiLoaderService,
    private fb: FormBuilder,
    private _BsModalService: BsModalService,
    private _NotifierService: NotifierService,
  ) {
    this.PageModalRef = new BsModalRef();
    this.DeleteModalRef = new BsModalRef();
    this.staffsList = [];
    this.addStaffForm = this.fb.group({
      firstName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email: ['', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
      // providerId: [''],
      password: [''],
      isEdit: [''],
      isActive: [''],
      _id: [''],
    });
    this.isAlertActive = false;
    this.isBusy = false;
    this.pagination = 0
    this.searchKey = '';
    this.isSearchKeyFilled = false;
  }
  searchstaff(search: any) {
    this.isBusy = true;
    if (search.target.value.length < 3) {
      this.expressionUpdate = true;
    } else {
      this.expressionUpdate = false;
    }
    if (search.target.value.length % 3 === 0) {
      this._NgxLoader.start()
      this._MainService.getStaffs(1, 10).then((data: any) => {
        if (data.success) {
          this.staffsList = data.data;
          this.isBusy = false;
          this.sortStaffs();
          this._NgxLoader.stop()
        }
        else {
          this.isBusy = false;
          this._NgxLoader.stop()
        }
      })
    }
  }
  ngOnInit() {
    this.addStaffForm.valueChanges.subscribe(() => {
      this.checkIfFormChanged();
    });
    // this._ActivatedRoute.data.subscribe((data:any) => {
    this._MainService.getStaffs().then(data => {
      console.log(data, "123444");
      if (data && data.data || data.pagination)
        this.staffsList = data.data;

      this.staffsList?.forEach((e: any) => {
        console.log(e);
      });
      this.pagination = data.pagination?.totalCount
      this.staffsList?.forEach((photo: any) => {
        if (photo.photo) {
          photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
        }
      })
      this.sortStaffs();
      // if (data && data.staffs.data || data.staffs.pagination)
      //     this.staffsList = data.staffs.data;

      // this.staffsList?.forEach((e: any) => {
      //     console.log(e);
      // });
      // this.pagination = data.staffs.pagination?.totalCount
      // this.staffsList?.forEach((photo: any) => {
      //     if (photo.photo) {
      //         photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
      //     }
      // })
      // this.sortStaffs();

    });

    this._ActivatedRoute.queryParamMap.subscribe((data: any) => {
      const rtdbcs = data.get('rtdbcs');
      if (rtdbcs !== null) {
        this.isAlertActive = true;
      } else {
        this.isAlertActive = false;
      }
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      this.currentUser = data.data;
      this.logProviderId = data.data._id;
      this.userRole = data?.data?.role;
    })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.doctorsList) {
      // this.listRefresh();
      this.sortStaffs();
    }
  }

  sortStaffs() {
    this.staffsList?.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  showModal(template: TemplateRef<any>) {
    this.editMode = false;
    this.doesExist = false;
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'inviteCenter',
      backdrop: 'static',
    });
  }

  get activeStaffCount() {
    if (this.staffsList !== null) {
      return this.staffsList.filter(staff => staff.isActive).length;
    } else {
      return 0;
    }
  }

  openDeleteModal(template: TemplateRef<any>, id: any) {
    console.log(id, 'staffID');

    this.staffID = id
    this.DeleteModalRef = this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }

  checkIfFormChanged() {
    if (JSON.stringify(this.addStaffForm.value) === JSON.stringify(this.initialFormValues)) {
      this.addStaffForm.markAsPristine();
    } else {
      this.addStaffForm.markAsDirty();
    }
  }


  editStaff(template: TemplateRef<any>, staff: staffDetails) {
    this.editMode = true;
    this.selectedStaff = staff;
    this.addStaffForm.patchValue({
      ...staff,
      isEdit: true,
      password: staff?.defaultPassword
    });
    this.initialFormValues = this.addStaffForm.value;
    this.addStaffForm.markAsPristine();
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'inviteCenter',
      backdrop: 'static',
    });

    // this.showModal(template, true, staff);
  }

  deleteStaffs() {
    this._NgxLoader.start()
    this._MainService.deleteStaffByProvider(this.staffID).then((data: any) => {
      if (data.success) {
        this._NotifierService.showSuccess("Staff Deleted Successfully");
        this.listRefresh()
        this._NgxLoader.stop()
        this._BsModalService.hide()
      } else {
        this._NgxLoader.stop()
        this._NotifierService.showError("Staff cannot be deleted");
      }
    })
  }

  getFormData(): FormData {

    const formData: FormData = new FormData();

    formData.append('firstName', this.addStaffForm.controls.firstName.value);
    formData.append('email', this.addStaffForm.controls.email.value);
    formData.append('phone', this.addStaffForm.controls.phone.value);
    formData.append('isActive', JSON.stringify(true));
    if (this.editMode) {
      formData.append('isEdit', JSON.stringify(true));
      formData.append('_id', this.selectedStaff._id);
      formData.append('password', this.addStaffForm.controls.password.value);
    }
    else {
      formData.append('isEdit', JSON.stringify(false));
      formData.append('_id', '');
      formData.append('password', '');
    }

    return formData;
  }

  onSubmit() {
    debugger
    if (this.addStaffForm.invalid) {
      this.addStaffForm.markAllAsTouched();
      return;
    }

    const formmValue = this.addStaffForm.value;
    const formValue = {
      ...formmValue,
      providerId: this.logProviderId,
      role: 'Staff',
      isActive: true,
      isEdit: this.editMode ? true : false
    };
    console.log(formValue);
    // console.log(this.logProviderId);

    if (this.addStaffForm.valid) {
      this._NgxLoader.start();
      if (this.editMode === true) {
        this.addStaffFormSubmitting = true;
        this._MainService.registerStaff(formValue).then((data: any) => {
          this.addStaffFormSubmitting = false;
          if (data.success === true || data.success === 'true') {
            debugger
            this._NotifierService.showSuccess('Staff updated Successfully.');
            this.addStaffForm.reset();
            this.PageModalRef?.hide();
            this.showPassword = false;
            this.listRefresh();
            this._NgxLoader.stop();
          } else {
            if (data.message === "Already Exist in System" || data.message === "Staff already exists" || data.error === "Staff already exists") {
              debugger
              this._NgxLoader.stop();
              // this.addStaffForm.reset();
              this.doesExist = true;
              // this.PageModalRef?.hide();
              this._NotifierService.showError('Staff Already Exist');
              this.showPassword = false;
            } else {
              debugger
              this._NgxLoader.stop();
              this.addStaffForm.reset();
              this._NotifierService.showError('Something went wrong..!');
              this.showPassword = false;
            }
          }
        });

      } else {
        debugger
        this.addStaffFormSubmitting = true;
        this._MainService.registerStaff(formValue).then((data: any) => {
          this.addStaffFormSubmitting = false;
          if (data.success === true || data.success === 'true') {
            this._NotifierService.showSuccess('Staff added Successfully.');
            this.addStaffForm.reset();
            this.PageModalRef?.hide();
            this.showPassword = false;
            this.listRefresh();
            this._NgxLoader.stop();
          } else {
            if (data.message === "Already Exist in System" || data.message === "Staff already exists" || data.error === "Staff already exists") {
              this._NgxLoader.stop();
              this.doesExist = true;
              // this.PageModalRef?.hide();
              // this.addStaffForm.reset();
              this.showPassword = false;
              this._NotifierService.showError('Staff Already Exist');
            } else {
              this._NgxLoader.stop();
              this._NotifierService.showError('Something went wrong..!');
              this.showPassword = false;
              this.addStaffForm.reset();
            }
          }
        });
      }

    } else {
      this._NgxLoader.stop();
      this._NotifierService.showError('Something went wrong..!');
      this.showPassword = false;
    }
  }


  pageChange(pagechangedata: any) {
    this._NgxLoader.start()
    this._MainService.getStaffs(pagechangedata.pageIndex + 1, 10).then((data: any) => {
      if (data.success) {
        this.staffsList = data.data;
        this.sortStaffs();
        this.isBusy = false
        this._NgxLoader.stop()
        this.staffsList?.forEach((photo: any) => {
          if (photo.photo) {
            photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
          }
        })
      }
      else {
        this._NgxLoader.stop()
        this.isBusy = false

      }

    })

  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  listRefresh() {
    this._MainService.getStaffs(1, 10).then((data: any) => {
      if (data.success) {
        this.staffsList = data.data;
        this.isBusy = false
        this.staffsList?.forEach((photo: any) => {
          if (photo.photo) {
            photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
          }
        })
        this.sortStaffs();
        this._NgxLoader.stop()
      }
      else {
        this.isBusy = false
        this._NgxLoader.stop()

      }

    })
    this._NgxLoader.stop();
  }
}
