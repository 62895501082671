import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ChangePasswordComponent } from '@app/Login/change-password/change-password.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from '@app/services/mainservice/main.service';
import { UserService } from '../services/userservice/userservice';
import { EmailTemplate } from '../services/emailTemplate/emailTemplate.service';
//import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  // styleUrls: ['./admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuperAdminComponent implements OnInit {
  isContainer: boolean;
  notificationCounter: any
  currentUser: any;
  PageModalRef: BsModalRef | null
  invite: FormGroup;
  currEmail: any;
  doesExist: boolean = false;
  url: any;
  isSpinner: any = false;
  inviteLink: any;
  fname: any;
  lname: any;

  constructor(private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _NotificationService: NotificationService,
    private _NotifierService: NotifierService,
    private _BsModalService: BsModalService,
    private _FormBuilder: FormBuilder,
    private _MainService: MainService,
    private _EmailTemplate: EmailTemplate,
    private userService: UserService
  ) {
    this.PageModalRef = new BsModalRef()
    this.isContainer = false
    this.invite = this._FormBuilder.group({
      email: [null, [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
    });
  }


  ngOnInit() {
    this.url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') this.url += ':' + window.location.port;
    this.url += '/invite-signup';

    this._MainService.getCurrentUserInfo().then((data: any) => {
      this.currEmail = data.data.email;
    })
    this.userService.currentUserName.subscribe(name => {
      this.currentUser = name;
    });

    this._ActivatedRoute.data.subscribe(($: any) => {
      if ($ && $.admin) {
        this.notificationCounter = $.admin
        this._NotificationService.setnotificationData(this.notificationCounter);
      }
      this._NotificationService.getnotificationData().subscribe(x => {
        if (x) this.notificationCounter = x;
      })
    })
    // this.currentUser = window.localStorage.getItem('user')
  }

  openChangePassWordModal() {
    this.PageModalRef = this._BsModalService.show(ChangePasswordComponent, { class: 'otherlandingpageModal', backdrop: 'static' })
  }
  showModal(template: TemplateRef<any>) {
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'inviteCenter',
      backdrop: 'static',
    });
  }

  signout() {
    window.localStorage.clear();
    this._Router.navigate(['/login'])
  }
  showUD() {
    this._NotifierService.showError('Its under development')
  }
  refreshPage() {
    // this._Router.navigate(['/super-admin/dashboard'])
    window.location.href = '/super-admin/dashboard';
  }
  onInvitationSend() {
    if (this.invite.valid) {
      this._MainService.emailCheckProvider(this.invite.controls.email.value).then((data: any) => {
        if (!data.success) {
          this.doesExist = false
          this.isSpinner = true;
          this._MainService.sendInvitation({ url: this.url, byEmail: this.currEmail, forEmail: this.invite.controls.email.value }).then((data: any) => {
            if (data.success) {
              this.fname = data.data.firstName
              this.lname = data.data.lastname
              this.inviteLink = data.data.inviteLink
              this.inviteEmail()

              this._BsModalService.hide();
              this.invite.reset();
              this._NotifierService.showSuccess("Invitation Sent")
              this.isSpinner = false;
            } else {
              this.isSpinner = false;
            }
          })
        } else {
          this.doesExist = true
        }
      })
    }
  }
  onFocus() {

    this.doesExist = false
  }
  inviteEmail() {
    // console.log(this.takeformData(),"this.takeformData()");

    this._MainService.sendEmail(this.takeformData(), false).then((data: any) => {
      console.log(data,'data');
    })
  }
  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.invite.controls.email.value)
    formData.append('html', this._EmailTemplate.inviteEmailTemplate(this.inviteLink, this.fname, this.lname,))
    formData.append('message', "Invitation Link")

    return formData
  }

}
