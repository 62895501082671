import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safe'
})
export class DomSanatizePipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer
    ) { }

    transform(url: any) {
        debugger
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}