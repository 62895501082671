<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div [formGroup]="referralFormGroup" class="row align-items-center mb-1">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center "
          [ngClass]="role === 'Provider' || role === 'Staff' ?'col-sm-12':'col-sm-6'">
          <h1 class="h5 font-weight-bold mb-0">
            <!--{{isIncoming?"Incoming":"Sent"}} --> Referrals
          </h1>
          <div *ngIf="role=='Provider' || role === 'Staff'" class="form-row w-20">

            <div class="col-auto">
              <button class="btn btn-sm btn_m_outline_green" (click)="showModal(referral)">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Add New Referral
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="role === 'Admin' || role === 'SuperAdmin'" class="col-sm-3 d-flex align-items-center justify-content-end">
          <label class="font-weight-600 pr-2" for="">From:</label>
          <input type="text" (bsValueChange)="dateOrderMin($event)" [maxDate]="maxDate" style="width: 52%;"
            formControlName="fromDate" placeholder="MM/DD/YYYY" class="form-control custom_opacity" bsDatepicker>
        </div>

        <div *ngIf="role == 'Admin' || role === 'SuperAdmin'" class="col-sm-2 d-flex align-items-center justify-content-end">
          <label class="font-weight-600 pr-2" for="">To:</label>
          <input type="text" [minDate]="minDate" #dp="bsDatepicker" [maxDate]="maxDate" formControlName="toDate"
            placeholder="MM/DD/YYYY" class="form-control custom_opacity" bsDatepicker>
        </div>
        <div *ngIf="role == 'Admin' || role === 'SuperAdmin'" class="col-sm-1">
          <button type="submit" class="btn bg_green text-white float-right" (click)="referralByDate()">Filter</button>
        </div>
      </div>
      <hr>
      <div class="position-relative">
        <div class="table-responsive border border-bottom-0">
          <table class="table table-sp-sm table_border_top_remover mb-0" aria-describedby="describe">
            <thead class="header_main_bg border-bottom-0  text-white">
              <tr>
                <th scope="col" class="border-bottom-0 border-top-0">Refer By</th>
                <th scope="col" class="border-bottom-0 border-top-0">Refer To</th>
                <th scope="col" class="border-bottom-0 border-top-0">Patient</th>
                <th scope="col" class="border-bottom-0 border-top-0">Reason</th>
                <th scope="col" class="border-bottom-0 border-top-0">Status</th>
                <th scope="col" class="border-bottom-0 border-top-0">Date</th>
                <th scope="col" class="border-bottom-0 border-top-0">Action</th>
              </tr>
            </thead>
            <tbody>

              <ng-container *ngIf="receviedReferrals && receviedReferrals.length > 0; else noReferral">
                <tr class="border-bottom cursor-pointer" *ngFor="let referral of receviedReferrals">
                  <td class="name_limit">
                    <div class="d-flex align-items-center flex-nowrap">

                      <div *ngIf="referral.ReferredBy!==null;else noReferredBy">
                        <span> {{referral.ReferredBy.firstName}} {{referral.ReferredBy.lastName}}
                          <span *ngIf="referral.ReferredBy.highestQualification" class="d-inline">
                            {{referral.ReferredBy.highestQualification!=="null"?', ' + referral.ReferredBy.highestQualification:''}}</span>
                        </span>
                        <div class="w-100"></div>
                        <small class="text-muted">{{referral.ReferredBy.email}}</small>
                      </div>
                      <!-- <ng-template #noReferredBy>
                        <span>No Referral</span>
                      </ng-template> -->
                    </div>
                  </td>
                  <td class="name_limit">
                    <div class="d-flex align-items-center flex-nowrap">

                      <div>
                        <span> {{referral?.ReferredTo?.firstName}} {{referral?.ReferredTo?.lastName}}
                          <span *ngIf="referral?.ReferredTo?.highestQualification" class="d-inline">
                            {{referral?.ReferredTo?.highestQualification!=="null"?', '+referral?.ReferredTo?.highestQualification:''}}</span>
                        </span>
                        <div class="w-100"></div>
                        <small class="text-muted">{{referral.ReferredTo?.email}}</small>
                      </div>
                    </div>
                  </td>
                  <td class="name_limit">
                    <div *ngIf="referral.patientId!==null;else noPatientId">
                      <span>{{referral.patientId.firstName}} {{referral.patientId.lastName}}</span>
                      <div class="w-100"></div>
                      <small class="text-muted">{{referral.patientId.dateOfBirth | date : 'mediumDate' : '+0530'}}

                      </small>
                    </div>
                    <ng-template #noPatientId>
                      No Patient
                    </ng-template>
                  </td>

                  <td class="d-flex flex-column">
                    <ng-container *ngIf="referral.reasonForVisit !== null; else noReasonForVisit">
                      <ng-container *ngFor="let reason of referral.reasonForVisit;let i=index">
                        <ng-container *ngIf="i < referral.reasonForVisit.length">
                          <ng-container *ngIf="i<2;else elseRefMore"> <span> {{reason.name}} </span> </ng-container>
                          <ng-template #elseRefMore> <span *ngIf="ReasonMore"> {{reason.name}} </span> </ng-template>
                          <small *ngIf="i === 1 && referral.reasonForVisit.length > 2 " class="d-inline text-muted"
                            [style]="ReasonMore?'display:none !important':''" (click)="refReason()">
                            ({{referral.reasonForVisit.length - (i + 1)}} more) </small>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-template #noReasonForVisit>
                      No reason available
                    </ng-template>
                  </td>

                  <td>
                    <a class="text-primary" (click)="changeStatus($event, referral.id)">
                      {{referral.ReferralStatus}}
                    </a>

                  </td>
                  <td class="d-flex flex-column">
                    <span>{{referral.createdAt | date : 'mediumDate': '+0530'}}</span>
                    <small class="text-muted">{{referral.createdAt | date : 'shortTime': '+0530'}}</small>
                  </td>
                  <td *ngIf="referral.ReferralStatus == 'Incoming' && (role === 'Provider' || role === 'Staff')">
                    <button *ngIf="!referral.isAccepted" class="btn btn-primary position-relative"
                      (click)="Accepted(referral._id)">Accept<span *ngIf="referral.isSeen===false"
                        class="counter_ac">1</span></button>
                    <button *ngIf="referral.isAccepted" (click)="Accepted(referral._id)"
                      routerLink="/home/patient-details/{{referral.patientId?._id}}"
                      class="btn btn_m_outline_green position-relative">Patient Info<span
                        *ngIf="referral.isSeen===false" class="counter_ac">1</span></button>
                  </td>
                  <td *ngIf="role === 'Admin' || role === 'SuperAdmin'">
                    <button class="btn position-relative cons btn_m_outline_green ng-star-inserted" tabindex="0"
                      ng-reflect-ng-class="btn_m_outline_green"
                      routerLink="/admin/patient-details/{{referral?.patientId?._id}}">Patient
                      Info
                    </button>
                  </td>
                  <td *ngIf="role === 'Staff'">
                    <button class="btn position-relative cons btn_m_outline_green ng-star-inserted" tabindex="0"
                      ng-reflect-ng-class="btn_m_outline_green"
                      routerLink="/home/patient-details/{{referral?.patientId?._id}}">Patient
                      Info
                    </button>
                  </td>
                </tr>
              </ng-container>

              <ng-template #noReferral>
                <tr class="border-bottom cursor-pointer">
                  <td colspan="12">
                    <h5 class="text-center font-weight-bold">No referral found..!</h5>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)"></mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #referral>
  <div class="modal-header container-fluid">
    <h4 class="modal-title pull-left"></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-edit-referral (isAddReferralDone)="isAddReferralDone($event)"></app-add-edit-referral>
  </div>
</ng-template>
