<div class="container-fluid m-0 p-0">
    <div class="bg-white mb-2" style="border: 1px solid #c6c6c6;">
        <div class="">
            <p class="heading_label">Photos</p>
            <div class="d-flex justify-content-between">
                <h5 class="ml-3">Upload Photos</h5>
                <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)"> </mat-paginator>
            </div>
        </div>
        <div class="w-100 px-3">
            <form [formGroup]="addPhotos" (submit)="recordsSubmitData()">
                <div class="row mr-2">
                    <div class="col-md-4 pr-0">
                        <div *ngIf="role==='Provider' || role === 'Staff'" class="form-group">
                            <input type="text" placeholder="Select Files" [attr.disabled]="true" formControlName="photos" class="form-control bg-white">
                            <span class="text-danger" *ngIf="isfile">Required</span>
                        </div>
                        <div *ngIf="role==='SuperAdmin'" class="form-group">
                            <input type="text" placeholder="Select Files" [attr.disabled]="true" formControlName="photos" class="form-control bg-white">
                            <span class="text-danger" *ngIf="isfile">Required</span>
                        </div>
                        <div *ngIf="role==='Admin'" class="form-group">
                          <input type="text" placeholder="Select Files" [attr.disabled]="true" formControlName="photos"
                            class="form-control bg-white" disabled>
                            <span class="text-danger" *ngIf="isfile">Required</span>
                        </div>

                    </div>
                    <div class="col-md-3 d-flex pr-0">
                        <div class="col-md-5 px-0 mr-3">
                            <button type="button" class="btn btn_m_primary btn-block c_btn_file">Select
                                <input type="file" class="btn btn_m_primary" (change)="recordsFile($event)" multiple id="fileUpload">
                            </button>
                        </div>
                        <div class="col-md-5 px-0 ">
                            <button type="submit" class="btn bg_red text-white btn-block">Upload</button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row mt-2">
                <div class="table-responsive table_patient_info_table border-left-0 border-right-0 border-bottom-0">
                    <table class="table mb-0" aria-describedby="describe">
                        <thead>
                            <tr>
                                <th scope="col"style="width:40%">Files</th>
                                <th scope="col"style="width:30%">Updated By</th>
                                <th scope="col"style="width:30%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of imageList" class="border-bottom bg-white">
                                <td>
                                    <div class="d-flex flex-wrap">
                                        <a class="my-1" [href]="'https://spineplusbe.azurewebsites.net/'+image?.substring(7, image?.length)" *ngFor="let image of item?.photos" target="_blank">
                                            <img [src]="'https://spineplusbe.azurewebsites.net/'+image?.substring(7, image?.length)" style="width: 100px;height: 100px;object-fit: cover;" class="mr-2 border" alt="">
                                        </a>
                                    </div>
                                </td>
                                <td style="vertical-align: middle;"> {{item.createdBy?.firstName}} {{item.createdBy?.lastName}} <small>({{item.createdAt|date:'MM/dd/YYYY, hh:mm aa'}})</small> </td>
                                <td style="vertical-align: middle;">
                                  <button class="btn min_width_btn bg_red text-white ml-2"
                                    (click)="openModal(deletePhoto,item._id)"><em class="fa fa-trash" aria-hidden="true"></em></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #deletePhoto>
    <div class="modal-header container-fluid">
      <h5 class="modal-title pull-left">
        <p>Are you sure you want to delete the photo?</p>
      </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()"> <span
          aria-hidden="true">&times;</span> </button>
    </div>
    <div class="modal-body">
      <div class="row float-right">
        <button class="btn min_width_btn bg_red text-white ml-2"
          (click)="_BsModalService.hide()">No</button>
          <button class="btn min_width_btn bg_green text-white ml-2 mr-4" (click)="deletePhotos()">Yes</button>
        </div>
    </div>
  </ng-template>
