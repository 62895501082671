import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';

@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html'
})
export class FacilityDetailsComponent implements OnInit {

  isDeletingPatient:boolean=false
  currfacility: any
  
  constructor(private _ActivatedRoute:ActivatedRoute,private _Router:Router,private _MainService:MainService,private _NotifierService:NotifierService ) { }

  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe(data=>{
      if(data&&data.facilitydetails){
        debugger
        this.currfacility = data.facilitydetails
        

        
      }
    })
  }

  deleteFacility(id:any){
    this.isDeletingPatient=true
    this._MainService.deleteFacilitybyid(id).then((data:any)=>{
      if(data.success){
          this.isDeletingPatient=false
          this._NotifierService.showSuccess(data.message)
          this._Router.navigate(['/admin/facility'])

      }else{
        this.isDeletingPatient=false
        this._NotifierService.showSuccess("Message Can't Be Deleted")
      }
    })
  }

  goBack(){
    window.history.back()
  }

}
