import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class AccessTokenService {
    currentUserInfo: Subject<any>;

    constructor(

    ) {
        this.currentUserInfo = new BehaviorSubject<any>('');
    }

    getaccesstoken(): Observable<any> {
        debugger
        return this.currentUserInfo.asObservable();
    }

    setaccesstoken(userInfo: any): void {
        debugger
        this.currentUserInfo.next(userInfo);
    }


}
