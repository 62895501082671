import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MainService } from '../../../services/mainservice/main.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '@app/core/services/provider/notification.service';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  // styleUrls: ['./dash-board.component.scss']
})
export class DashBoardComponent implements OnInit {

  providersData: any;
  selectedProviderType:any
  notificationCounter: any;
  patientsData: any;
  searchValuee: any;
  providerCategory:any
  currentUser: any
  providerType: any = [];
  addDoctorForm: FormGroup;
  expressionUpdate: boolean = false;

  constructor(private _ActivatedRoute: ActivatedRoute, private _NgxLoader: NgxUiLoaderService, private _NotificationService: NotificationService, private _FormBuilder: FormBuilder, private _MainService: MainService, private _ngxLoader: NgxUiLoaderService, private _Router: Router) {

    this.addDoctorForm = this._FormBuilder.group({
      providerType: [null],

    })

    this.providerType = [];

  }

  ngOnInit(): void {

    this._ActivatedRoute.data.subscribe(data => {

      if (data && data.dashboard) {
        debugger
        this._NotificationService.getnotificationData().subscribe((x: any) => {
          debugger
          this.notificationCounter = x
          console.log(this.notificationCounter );


        })



        this._ngxLoader.stop()
        this.providersData = data?.dashboard[0]?.data
        console.log(this.providersData);


        this.patientsData = data.dashboard[3].data;


      }

    })

    this._MainService.getProviderType().then(($: any) => {


      this.providerType = $.data

      this.providerType.forEach((e: any) => {

        this.providerCategory = e
        this.providerChange(this.providerCategory)


      });


    });

    this.sortDoctors();
    this.currentUser = window.localStorage.getItem('user')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.providersData) {
      this.sortDoctors();
    }
  }

  sortDoctors() {
    this.providersData?.sort((a:any, b:any) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  providerChange(data: any) {

    if (data == undefined) {
      this.selectedProviderType=""
    }
    else {
      this.selectedProviderType = data._id

    }

   }

   searchProviderfilter(search: any) {
    if (search.target.value.length < 3) {
      this.expressionUpdate = true;
    } else {
      this.expressionUpdate = false;
    }
    if (search.target.value.length % 3 === 0) {
      this.searchProvider();
    }
  }

  navigatePatient(item: any) {
    if (window.localStorage.getItem('role') === "Admin") {
      this._Router.navigate([`/admin/patient-details/${item._id}`])
    } else {
      this._Router.navigate([`/home/patient-details/${item._id}`])
    }
  }
  navigateProvider(item: any) {
    if (window.localStorage.getItem('role') === "Admin") {
      this._Router.navigate([`admin/provider-details/${item._id}`])
    } else {
      this._Router.navigate([`home/provider-details/${item._id}`])
    }
  }

  searchProvider() {
    this._NgxLoader.start();
    this._MainService.getDoctors(1, 25, this.searchValuee, this.selectedProviderType).then((data: any) => {
      if (data.success) {
        this.providersData = data.data;
        this.sortDoctors();
        this._NgxLoader.stop();
      } else {
        this.providersData = null;
        this._NgxLoader.stop();
      }
    });
  }

  filterProvider() {
    this._NgxLoader.start();
    this._MainService.getDoctors(1, 25, this.searchValuee, this.selectedProviderType).then((data: any) => {

      if (data.success) {
        this.providersData = data.data;
        this.sortDoctors();
        this._NgxLoader.stop();

      } else {
        this.providersData = null;
        this._NgxLoader.stop();
      }
    });


  }

  navigatetoList(path: string) {
    if (window.localStorage.getItem('role') === "Admin") {
      this._Router.navigate([`admin/${path}`])
    } else {
      this._Router.navigate([`home/${path}`])
    }
  }
  gotoviewadd() {
    if (window.localStorage.getItem('role') === 'Admin') {
        this._Router.navigate([`admin/edit-add-doctor/0`]);
    }else if (window.localStorage.getItem('role') === 'SuperAdmin'){
      this._Router.navigate([`super-admin/edit-add-doctor/0`]);
    } else {
        this._Router.navigate([`home/edit-add-doctor/0`]);
    }
}

}
