<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0 name_limit">{{currentAdmin?.firstName}} {{currentAdmin?.lastName}}</h1>
        <div class="btn-group btn-group-sm">
          <button class="btn btn-outline-primary" (click)="goBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          <a class="btn btn-outline-primary" routerLink="/{{role}}/edit-add-admin/{{currentAdmin._id}}">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            Edit
          </a>
          <button class="btn btn-outline-primary" disabled>
            <i class="fa fa-eye" aria-hidden="true" ></i>
            Referrals
          </button>
          <button class="btn btn-outline-danger" (click)="openModal()" [disabled]="isDeletingAdmin">
            <ng-container *ngIf="isDeletingAdmin; else isDeletingDone">
              <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span>
              Deleting...
            </ng-container>
            <ng-template #isDeletingDone>
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </ng-template>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3">
      <div class="row">
        <div class="col-sm-12">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="font-weight-bold">Details:</h5>

          </div>
          <hr />
          <div class="row">

            <div class="col">
              <div class="row">
                <div class="col-6 py-2 name_limit">
                  <span class="font-weight-600 w-100 mb-0" for="">Firstname:</span> {{currentAdmin?.firstName}}
                </div>
                <div class="col-6 py-2 name_limit">
                  <span class="font-weight-600 w-100 mb-0" for="">Lastname:</span> {{currentAdmin?.lastName}}
                </div>
                <div class="col-6 py-2">
                  <span class="font-weight-600 w-100 mb-0" for="">Gender:</span> {{currentAdmin?.gender?.name}}
                </div>
                <div class="col-6 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Highest Qualification:</label>
                  <span
                    *ngIf="currentAdmin.highestQualification!=='null'">{{currentAdmin.highestQualification}}</span>
                </div>
                <div class="col-6 py-2">
                  <span class="font-weight-600 w-100 mb-0" for="">Phone:</span> {{currentAdmin.phone}}
                </div>
                <div class="col-6 py-2">
                  <span class="font-weight-600 w-100 mb-0" for="">Total Providers:</span>
                  {{ currentAdmin.invitedProviders && currentAdmin.invitedProviders.length > 0 ?currentAdmin.invitedProviders.length:'0' }}
                </div>
                <div class="col-6 py-2">
                  <span class="font-weight-600 w-100 mb-0" for="">Email:</span>
                  {{ currentAdmin.email!=="null" && currentAdmin.email!=="" && currentAdmin.email ?currentAdmin.email:'' }}
                </div>
                <div class="col-12 py-2 d-flex">
                  <span class="font-weight-600 mb-0 mr-1" for="">Address: </span>
                  <p class="mb-0">
                    <span *ngIf="currentAdmin.addressLine1 !== 'null'"> {{currentAdmin.addressLine1}}</span>
                    <span *ngIf="currentAdmin.addressLine2 !== 'null'"> {{currentAdmin.addressLine2}}</span>
                  </p>
                </div>
                 <div class="col-12 py-2 d-flex">
                   <span class="font-weight-600 mb-0 mr-1" for="">Business Name: </span>
                   <p class="mb-0">
                     <span *ngIf="currentAdmin.businessName !== 'null'"> {{currentAdmin.businessName}}</span>

                   </p>
                 </div>
                 <div class="col-12 py-2 d-flex">
                   <span class="font-weight-600 mb-0 mr-1" for="">Referrals:</span>
                   <p class="mb-0">
                    {{ currentAdmin.totalNewReferral!=="null" && currentAdmin.totalNewReferral!=="" && currentAdmin.totalNewReferral ?currentAdmin.totalNewReferral:0 }}
                   </p>
                 </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- Modal HTML -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'d-block': isModalOpen, 'd-none': !isModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Modal content goes here -->
        <p>Do you want to delete selected admin?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">No</button>
        <button type="button" class="btn btn-primary" (click)="deleteDoc(currentAdmin.id)">Yes</button>
      </div>
    </div>
  </div>
</div>
