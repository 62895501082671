export class AddPatient {
    firstName: string = '';
    lastName: string = '';
    dateOfBirth: any = null;
    email: string = '';
    cellNumber: string = '';
    homeNumber: string = '';
    workMobileNumber: string = '';
    addressLine1: string = '';
    addressLine2: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    addICD10: string = '';
    socialSecurityNumber: string = '';
    occuption: string = '';
    doi: string = '';
    employeer: string = ''
    gender: string = '';
    provider: string = '';
    photo: string = '';

    attorney: attorneyDetails;
    insurence: insuranceDetails;
    workComp: workingCompanyDetails;
    constructor() {
        this.attorney = new attorneyDetails();
        this.insurence = new insuranceDetails();
        this.workComp = new workingCompanyDetails();

    }



}

export class attorneyDetails {
    lastName: string = '';
    firstName: string = '';
    phone: string = '';
    fax: string = '';
    addressLine1: string = '';
    addressLine2: string = '';
    email: string = '';
    caseManager: string = '';
    lawFirm: string = '';
    attorneypatientHavePIP: string = '';
    attorneycoveredByInsurance: string = '';
}
export class insuranceDetails {
    primaryInsurence: string = '';
    otherInsurence: string = '';
    subscribersName: string = '';
    group: string = '';
    policy: string = '';
}

export class workingCompanyDetails {
    insurenceName: string = '';
    phone: string = '';
    adjuster: string = '';
    fax: string = '';
    address: string = '';
    adjustersPhone: string = '';
    adjustersFax: string = '';
    network: boolean = false;
    networkName: string = '';
    networkClaimNumber: string = '';
}
export class patientList {
    addICD10: any = '';
    addressLine1: any = '';
    addressLine2: any = '';
    attorney: any = '';

    city: any = '';
    createdAt: any = '';
    dateOfBirth: any = '';
    email: any = '';
    firstName: any = '';

    id: any = '';
    lastName: any = '';
    patientUID: any = '';
    socialSecurityNumber: any = '';
    state: any = '';
    updatedAt: any = '';
    zip: any = '';
    __v: any = '';
    _id: any = '';
}