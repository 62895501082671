export class ResponseBase {
    success: boolean;
    token: any = '';
    message: string;
    statusCode: number;
    data: any;
    newPassword: any = ''
    senderName:any={}
    constructor() {
        this.success = false;
        this.message = '';
        this.statusCode = 0;
        this.data = {};
    }
}
export class ResponseBaseTemp {
    success: boolean;
    token: any = '';
    message: string;
    statusCode: number;
    data: any;
    role:any;
    providerType:any;
    newPassword: any = ''
    senderName:any={}
    constructor() {
        this.success = false;
        this.message = '';
        this.statusCode = 0;
        this.data = {};
    }
}
