import { Injectable } from '@angular/core';
import { MainService } from '../../../services/mainservice/main.service';
import { Resolve } from '@angular/router';
import { DashboardCount } from '../../../models/dashboard';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Injectable()

export class SuperAdminDashboarddResolver implements Resolve<Array<DashboardCount> | null | any > {
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService

    ) { }

    resolve(): Promise<Array<DashboardCount> | null | any > {
        const user = 'user'
        if (user !== null) {
            if (user) {
                this.ngxLoader.start();
                return Promise.all([
                    this._MainService.getDoctors(1, 25, '').then(x => {
                        // this.ngxLoader.stop()
                        return x;
                    }),
                    this._MainService.getAdmins(1, 25, '').then(x => {
                        // this.ngxLoader.stop()
                        return x;
                    }),
                    this._MainService.getattorneyinsuranceList(1, 5).then((data: any) => {
                        // this.ngxLoader.stop()
                        return data;
                    }),
                    this._MainService.getfacilityList(1, 5).then((data: any) => {
                        // this.ngxLoader.stop()
                        return data;
                    }),
                    this._MainService.getPatients(1, 5, '').then((x: any) => {
                        // this.ngxLoader.stop()
                        return x;
                    }),
                    this._MainService.getCurrentUserInfo().then((x:any)=>{
                        // this.ngxLoader.stop()
                        return x;
                    })

                ]);
            } else {
                return Promise.all([
                    this._MainService.getDashboardCountById(user).then((dashInfo: any) => {
                        return dashInfo.data;
                    }),
                    this._MainService.getDashboardCountById(user).then((dashInfo: any) => {
                        return dashInfo.data;
                    }),
                    this._MainService.getDashboardCountById(user).then((dashInfo: any) => {
                        return dashInfo.data;
                    }),
                    this._MainService.getDashboardCountById(user).then((dashInfo: any) => {
                        return dashInfo.data;
                    }),
                    this._MainService.getDashboardCountById(user).then((dashInfo: any) => {
                        return dashInfo.data;
                    })
                ]);
            }
        } else {
            return Promise.resolve(null);
        }
    }
}
