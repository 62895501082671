import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { AddEditFacility } from "../../../../models/add-edit-facility.model";

@Component({
  selector: 'app-add-or-edit-facility',
  templateUrl: './add-or-edit-facility.component.html'
})
export class AddOrEditFacilityComponent implements OnInit {
  speciality: any;
  addOrEditFacility: FormGroup
  providerList: any
  provider_id: any
  addOrEditFacilityFormSubmitting: boolean = false
  isEditTrue: boolean = false
  facilityDetails: any
  doesExist: boolean = false;
  role: any
  facility_id: any
  facilityForm: AddEditFacility

  constructor(private _FormBuilder: FormBuilder, private _ActivatedRoute: ActivatedRoute, private _MainService: MainService, private _NotifierService: NotifierService, private _Router: Router) {

    this.addOrEditFacility = this._FormBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      fax: [''],

      email: ['', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
      address: [''],
      specialityList: [null],
      associatedprovider: [null],
      contactperson: ['']
    })
    this.facilityForm = new AddEditFacility()

    this.speciality = [];
  }

  ngOnInit() {
    this._MainService.getProviderNameList(true, false,false).then((data: any) => {
      if (data.success) {

        this.providerList = data.data.filter((e: any) => {

          if (e.providerType.name == 'Doctor') {

            return e;
          }

        })


      }

    })

    this._MainService.getspecialitiesList().then((data: any) => {
      this.speciality = data.data;
    })


    this._MainService.getProviderType().then((data: any) => {
      

      data.data.forEach((e: any) => {

        if (e.name == 'Facility') {

          this.facility_id = e._id



        }

      })




    })


    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.role = data.data.role
      }
    })
    this._ActivatedRoute.data.subscribe((data: any) => {
      
      this.provider_id = data.facility?._id
     




      debugger
      if (data && data.facility !== null && data.facility !== undefined) {
        this.isEditTrue = true
        this.facilityDetails = data.facility
        this.addOrEditFacility.patchValue(data.facility)
        
        this.addOrEditFacility.controls.name.setValue(data.facility.firstName)
        this.addOrEditFacility.controls.address.setValue(data.facility.addressLine1)

        let associated: any = [];
        data.facility.associatedDoctor?.forEach((x: any) => {
          associated.push(x._id);
          

          this.addOrEditFacility.controls.associatedprovider.setValue(associated)

        })

        let special: any = [];
        data.facility.specialilties?.forEach((y: any) => {
          

          special.push(y._id);
        
          this.addOrEditFacility.controls.specialityList.setValue(special)
        })


        debugger
        if (data.facility?.contactPerson === "null") { this.addOrEditFacility.controls.contactPerson?.setValue("") } else {
          this.addOrEditFacility.controls.contactperson.setValue(data.facility.contactPerson)
        }

      }


    })
  }

  goBack() {
    window.history.back()
  }

  onBlur(data: any) {

    if (data.target.value !== "") {
      

      this._MainService.emailCheckProvider(data.target.value).then((data: any) => {
        if (data.success) {

          this.doesExist = true
        }
      })
    }
  }

  onFocus() {

    this.doesExist = false
  }



  getFormData(): FormData {
    const formData: FormData = new FormData();
    debugger;
    formData.append('firstName', this.addOrEditFacility.controls.name.value);
    formData.append('email', this.addOrEditFacility.controls.email.value);
    formData.append('phone', this.addOrEditFacility.controls.phone.value);
    formData.append('fax', this.addOrEditFacility.controls.fax.value);
    formData.append('addressLine1', this.addOrEditFacility.controls.address.value);
    formData.append('contactPerson', this.addOrEditFacility.controls.contactperson.value);
    formData.append('specialilties', JSON.stringify(this.addOrEditFacility.controls.specialityList.value));
    formData.append('associatedDoctor', JSON.stringify(this.addOrEditFacility.controls.associatedprovider.value));
    formData.append('isUpdate', this.isEditTrue ? JSON.stringify(true) : JSON.stringify(false));
    formData.append('isFacility', JSON.stringify(true));
    formData.append('providerType', this.facility_id);
    if (this.isEditTrue) {
      formData.append('providerId', this.provider_id)
    }



    return formData;
  }
  addOEditFacilityFormSubmit() {
    if (this.isEditTrue) {
      debugger
      if (this.addOrEditFacility.dirty) {
        this.addOrEditFacilityFormSubmitting = true
        this._MainService.registerDoctor(this.getFormData()).then((data: any) => {
          if (data.success) {
            this.addOrEditFacilityFormSubmitting = false
            this._NotifierService.showSuccess("Facility Updated Successfully!")
            if (this.role === 'Admin') {
              this._Router.navigate(['/admin/facility'])
            } else {
              this._Router.navigate(['/home/facility'])
            }
          } else {
            this.addOrEditFacilityFormSubmitting = false
            this._NotifierService.showError("Can't UpdateFacility")
          }
        })
      } else {
        this.addOrEditFacilityFormSubmitting = false
        this._NotifierService.showError("It seems that you have not made any change..!")
      }
    } else {
      if (this.addOrEditFacility.valid && !this.doesExist) {
        this.addOrEditFacilityFormSubmitting = true
        this._MainService.registerDoctor(this.getFormData()).then((data: any) => {
          if (data.success) {
            this.addOrEditFacilityFormSubmitting = false
            this._NotifierService.showSuccess("Facility Added Successfully!")
            if (this.role === 'Admin') {
              this._Router.navigate(['/admin/facility'])
            } else {
              this._Router.navigate(['/home/facility'])
            }
          } else {
            this.addOrEditFacilityFormSubmitting = false
            if (data.error === "Email Already Exist") {
              this._NotifierService.showError("Email Already Exist")
            } else {
              this._NotifierService.showError("Can't Add Facility")
            }
          }
        })
      }
    }

  }
}
