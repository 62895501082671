import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-specialist',
  templateUrl: './referral-specialist.component.html'
})
export class ReferralSpecialistComponent implements OnInit {

 // constructor() { }

  ngOnInit(): void {
    console.log(" ");
    
  }

}
