<div class="container-fluid m-0 p-0 ">
  <div class="bg-white mx-4">
    <div class="pt-3 px-3">
      <h3>Patient Information</h3>
    </div>
    <button class="btn btn-sm btn-primary ml-3" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
      Back
    </button>
    <div class="p-3">
      <div class="row" style="background-color: #ededed;padding:15px 0;">
        <div class="col-md-9 p-0">
          <div class="row p-3 bg-white mx-auto" style="width: 97%;">
            <div class="col-md-4 d-flex flex-column">
              <label class="font-weight-600">Patient ID: <span class="font-weight-light">{{currPatient?.patientUID}}</span></label>
              <label class="font-weight-600">Patient Name: <span
                  class="font-weight-light name_limit">{{currPatient?.firstName}}
                  {{currPatient?.lastName}}</span></label>
              <label class="font-weight-600">Patient Phone Number: <span class="font-weight-light">{{currPatient?.cellNumber}} </span></label>
              <label class="font-weight-600">Patient Type: <span class="font-weight-light">{{currPatient?.patientType ? currPatient?.patientType : ''}} </span></label>
              <label class="font-weight-600">Date of Birth: <span class="font-weight-light">{{currPatient?.dateOfBirth | date: 'MM/dd/yyyy'}} </span></label>
              <label class="font-weight-600">Associated Providers: <span
                  class="font-weight-light"
                  *ngIf="currPatient?.treatingDoctor!=='null'">
                  <ng-template ngFor let-provider let-last="last" let-i="index" [ngForOf]="currPatient?.treatingDoctor">
                    {{provider?.firstName}} {{provider?.lastName}}
                     <span *ngIf="!last">, </span>
                      <!-- <span class="text-muted"> ({{provider?.providerType?.name}})</span> -->
                  </ng-template>
                  <!-- {{assocProvider?.firstName}} {{assocProvider?.lastName}} -->
                  </span></label>
              <label class="font-weight-600">Gender: <span class="font-weight-light">{{currPatient?.gender?.name}}</span></label>
              <ng-container *ngIf="role === 'admin' || role === 'super-admin' ? !showMore1:showMore1">
                <label class="font-weight-600">Date of Injury: <span class="font-weight-light">{{currPatient?.doi|date: "MM/dd/YYYY"}} </span></label>
                <label class="font-weight-600">Employeer: <span class="font-weight-light">{{currPatient?.employeer}}</span></label>
                <label class="font-weight-600">Attorney Name: <span class="font-weight-light">{{currPatient?.attorney?.firstName}} {{currPatient?.attorney?.lastName}}</span></label>
                <label class="font-weight-600">Attorney Fax: <span class="font-weight-light">{{currPatient?.attorney?.fax}}</span></label>
                <label class="font-weight-600">Attorney Address: <span class="font-weight-light">{{currPatient?.attorney?.addressLine1}} {{currPatient?.attorney?.addressLine2}}</span></label>
                <label class="font-weight-600">Attorney Law Firm: <span class="font-weight-light">{{matchingLawFirm ? matchingLawFirm.name : "N/A"}}</span></label>
                <label class="font-weight-600">Is Patient Covered By Insurance?: <span class="font-weight-light">{{currPatient?.attorney?.attorneycoveredByInsurance?"Yes":"No"}}</span></label>
                <label class="font-weight-600">Other Insurance: <span class="font-weight-light">{{currPatient?.insurence?.otherInsurence}}</span></label>
                <label class="font-weight-600">Group: <span class="font-weight-light">{{currPatient?.insurence?.policy}}</span></label>
                <label class="font-weight-600">Work Comp Insurance Name: <span class="font-weight-light">{{currPatient?.workComp?.insurenceName}}</span></label>
                <label class="font-weight-600">Work Comp Phone: <span class="font-weight-light">{{currPatient?.workComp?.phone}}</span></label>
                <label class="font-weight-600">Work Comp Adjuster: <span class="font-weight-light">{{currPatient?.workComp?.adjuster}}</span></label>
                <label class="font-weight-600">Work Comp Adjuster Fax: <span class="font-weight-light">{{currPatient?.workComp?.adjustersFax}}</span></label>
                <label class="font-weight-600">Work Comp Network Name: <span class="font-weight-light">{{currPatient?.workComp?.networkName}}</span></label>
              </ng-container>
            </div>
            <div class="col-md-5 d-flex flex-column">
              <label class="font-weight-600">Address: <span class="font-weight-light">{{currPatient?.addressLine1}} {{currPatient?.addressLine2}} {{currPatient?.city}} {{currPatient?.state?", "+ currPatient?.state:""}} {{currPatient?.zip}} </span></label>
              <label class="font-weight-600">SSN: <span class="font-weight-light">{{currPatient?.socialSecurityNumber ? "***-**-"+currPatient?.socialSecurityNumber?.slice(-4):""}} </span></label>
              <label class="font-weight-600">ICD10: <span class="font-weight-light">{{currPatient?.addICD10}} </span></label>
              <label class="font-weight-600">Primary Insurance: <span class="font-weight-light">{{currPatient?.insurence?.primaryInsurence}} </span></label>
              <label class="font-weight-600">Policy: <span class="font-weight-light">{{currPatient?.insurence?.policy}} </span></label>
              <ng-container *ngIf="role === 'admin' || role === 'super-admin'? !showMore1:showMore1">
                <label class="font-weight-600">Patient Email: <span class="font-weight-light">{{currPatient?.email}}</span></label>
                <label class="font-weight-600">Patient Occupation: <span class="font-weight-light">{{currPatient?.occuption}}</span></label>
                <label class="font-weight-600">Work Mobile Number: <span class="font-weight-light">{{currPatient?.workMobileNumber}}</span></label>
                <label class="font-weight-600">Attorney Phone: <span class="font-weight-light">{{currPatient?.attorney?.phone}}</span></label>
                <label class="font-weight-600">Attorney Email: <span class="font-weight-light">{{currPatient?.attorney?.email}}</span></label>
                <label class="font-weight-600">Attorney Case Manager: <span class="font-weight-light">{{currPatient?.attorney?.caseManager}}</span></label>
                <label class="font-weight-600">Does Patient have PIP?: <span class="font-weight-light">{{currPatient?.attorney?.attorneypatientHavePIP?"Yes":"No"}}</span></label>
                <label class="font-weight-600">Primary Insurance: <span class="font-weight-light">{{currPatient?.insurence?.primaryInsurence}}</span></label>
                <label class="font-weight-600">Subscriber's Name: <span class="font-weight-light">{{currPatient?.insurence?.subscribersName}}</span></label>
                <label class="font-weight-600">Policy: <span class="font-weight-light">{{currPatient?.insurence?.group}}</span></label>
                <label class="font-weight-600">Work Comp Address: <span class="font-weight-light">{{currPatient?.workComp?.address}}</span></label>
                <label class="font-weight-600">Work Comp Adjuster Phone: <span class="font-weight-light">{{currPatient?.workComp?.adjustersPhone}}</span></label>
                <label class="font-weight-600">Work Comp Fax: <span class="font-weight-light">{{currPatient?.workComp?.fax}}</span></label>
                <label class="font-weight-600">Work Comp Network: <span class="font-weight-light">{{currPatient?.workComp?.network?"Yes":"No"}}</span></label>
                <label class="font-weight-600">Work Comp Network Claim No: <span class="font-weight-light">{{currPatient?.workComp?.networkClaimNumber}}</span></label>
              </ng-container>
            </div>
            <div class="col-md-3"> <a class="btn common_btn_activator float-right" [ngClass]="currPatient?.caseStatus?.name == 'Active'? 'bg_green':'' ||
                currPatient?.caseStatus?.name == 'Closed'? 'bg_red':'' || currPatient?.caseStatus?.name == 'Pending'? 'bg_org':''">
                {{currPatient?.caseStatus?.name}} </a> </div>
            <div *ngIf="role ==='home' || role === 'super-admin'" class="w-100 text-center"> <span style="color:blue; cursor: pointer;" (click)="showMore1= !showMore1">
                View {{showMore1?'less':'more'}} <i [ngClass]="showMore1?'fas fa-chevron-up':'fas fa-chevron-down'"></i></span> </div>
          </div>
        </div>
        <div class="col-md-3 p-0">
          <div class="bg-white h-100 res_margin" style="width: 97%;">
            <div class="p-3">
              <div class=" header_main_bg">
                <div class="card-header text-white font-weight-600">
                  <h5 class="font-weight-bold mb-0">Associated Providers</h5>
                </div>
                <ng-container *ngIf="currPatient?.treatingDoctor;else noClinicFound">
                  <div class="bg-white " [ngClass]="showMore1?'res_height':'header_width_dy_scroller'" [style]="showMore1?'height: 615px;':''" style=" overflow: auto;">
                    <ng-template ngFor let-provider [ngForOf]="currPatient?.treatingDoctor">
                      <h5 class="mb-0 py-2">{{provider?.firstName}} {{provider?.lastName}}
                        <!-- <span class="text-muted"> ({{provider?.providerType?.name}})</span> -->
                      </h5>
                    </ng-template>
                  </div>
                </ng-container>
                <ng-template #noClinicFound>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item py-2">No Associated Providers..!</li>
                  </ul>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="role==='home'" class="pt-3 px-3"> <a style="cursor: pointer;" class="btn btn_m_green" routerLink="/home/edit-add-patient/{{ currPatient?.id }}"> Edit </a> </div>
        <div *ngIf="role === 'admin'" class="pt-3 px-3"> <a style="cursor: pointer;" class="btn btn_m_green" routerLink="/admin/edit-add-patient/{{ currPatient?.id }}"> Edit </a> </div>
        <div *ngIf="role === 'super-admin'" class="pt-3 px-3"> <a style="cursor: pointer;" class="btn btn_m_green" routerLink="/super-admin/edit-add-patient/{{ currPatient?.id }}"> Edit </a> </div>
      <div *ngIf="role === 'admin' || role === 'super-admin'" class="pt-3 px-3"> <a style="cursor: pointer;" class=" common_btn_activator btn bg_red"
          (click)="openModal()"> Delete </a> </div>
      </div>
    </div>
    <tabset #staticTabs *ngIf="role==='home'">
      <tab heading="Notes">
        <app-notes [patientID]="currPatient?._id" [linkedProvider]="currPatient?.linkedProvider"></app-notes>
      </tab>
      <tab heading="Diagnostics & Imaging" [active]="showDiagnoxtic">
        <app-diagnostic-imaging [patientID]="currPatient?._id" [providerNameList]="providerNameList"></app-diagnostic-imaging>
      </tab>
      <tab heading="Consultations" [active]="showConsultation">
        <app-consultation [providerListForConsultation]="providerListForConsultation" [patientID]="currPatient?._id"></app-consultation>
      </tab>
      <tab heading="Records & Billing">
        <app-records [patientID]="currPatient?._id"></app-records>
      </tab>
      <tab heading="Photos">
        <app-photos [patientID]="currPatient?._id"></app-photos>
      </tab>
    </tabset>
    <tabset #staticTabs *ngIf="role==='super-admin'">
      <tab heading="Notes">
        <app-notes [patientID]="currPatient?._id" [linkedProvider]="currPatient?.linkedProvider"></app-notes>
      </tab>
      <tab heading="Diagnostics & Imaging" [active]="showDiagnoxtic">
        <app-diagnostic-imaging [patientID]="currPatient?._id" [providerNameList]="providerNameList"></app-diagnostic-imaging>
      </tab>
      <tab heading="Consultations" [active]="showConsultation">
        <app-consultation [providerListForConsultation]="providerListForConsultation" [patientID]="currPatient?._id"></app-consultation>
      </tab>
      <tab heading="Records & Billing">
        <app-records [patientID]="currPatient?._id"></app-records>
      </tab>
      <tab heading="Photos">
        <app-photos [patientID]="currPatient?._id"></app-photos>
      </tab>
    </tabset>
      <tabset #staticTabs *ngIf="role === 'admin'">
        <tab heading="Notes">
          <app-notes [patientID]="currPatient?._id" [linkedProvider]="currPatient?.linkedProvider"></app-notes>
        </tab>
        <tab heading="Diagnostics & Imaging" [active]="showDiagnoxtic">
          <app-diagnostic-imaging [patientID]="currPatient?._id" [providerNameList]="providerNameList">
          </app-diagnostic-imaging>
        </tab>
        <tab heading="Consultations" [active]="showConsultation">
          <app-consultation [providerListForConsultation]="providerListForConsultation" [patientID]="currPatient?._id"></app-consultation>
        </tab>
        <tab heading="Records & Billing">
          <app-records [patientID]="currPatient?._id"></app-records>
        </tab>
        <tab heading="Photos">
          <app-photos [patientID]="currPatient?._id"></app-photos>
        </tab>
      </tabset>
  </div>
  <!-- Modal HTML -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'d-block': isModalOpen, 'd-none': !isModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Modal content goes here -->
        <p>Do you want to delete selected patient?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">No</button>
        <button type="button" class="btn btn-primary" (click)="deletePatient(currPatient?._id)">Yes</button>
      </div>
    </div>
  </div>
</div>
</div>
