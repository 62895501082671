import { Component, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { MainService } from '@app/services/mainservice/main.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({
  selector: 'app-web-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./otherlandingpageModal.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('hippaModal', { static: true }) hippaModal: any;
  pagination: any;
  openingRefModal: boolean;
  patientsData: any;
  doctorsData: any;
  attorneyInsurance: any = [];
  facilityList: any = [];
  currentUser: any;
  addressList: any;
  selectedAddress: string = 'all';
  providerEmail: any;
  locationId: any;
  providerId: any;
  notificationCounter: any;
  isModal: boolean;
  searchValuee: any;
  PageModalRef: BsModalRef | null;
  isChecked: boolean;
  modalRef: BsModalService | any = null;
  role: any;
  expressionUpdate: boolean = false;
  constructor(
    private _ActivatedRoute: ActivatedRoute,

    private _Router: Router,
    private _MainService: MainService,
    private _NgxLoader: NgxUiLoaderService,
    private _BsModalService: BsModalService,
    private _NotificationService: NotificationService
  ) {

    this.PageModalRef = new BsModalRef();
    this.openingRefModal = false;
    this.isModal = false;
    this.isChecked = false;
    this.providerId = '';
    this.locationId = '';
    this.addressList = [{ _id: 'all', addressLine1: 'All' }];
  }

  ngOnInit() {
    this.role = window.localStorage.getItem('role');

    this._NgxLoader.start();
    this._ActivatedRoute.data.subscribe((data) => {
      if (data && data.dashboard) {

        this._NotificationService.getnotificationData().subscribe(x => this.notificationCounter = x)

        this.patientsData = data.dashboard[3].data;
        this.pagination = data.dashboard[3].pagination;
        this.currentUser = data.dashboard[4].data;
      }
    });

    // this._MainService.getCurrentUserInfo().then((data: any) => {
    //   if (data.success) {
    //     this.addressList = [{ _id: 'all', addressLine1: 'All' }, ...(Array.isArray(data?.Address) ? data.Address : [])];
    //     this.providerEmail = data?.data?.email;
    //   }
    // })

    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.addressList = [{ _id: 'all', addressLine1: 'All' }, ...(Array.isArray(data?.Address) ? data.Address : [])];
      if (data?.data?.role === "Staff") {
        this.providerId = data?.data?.providerId;
        this.locationId = '';
      } else {
        this.providerId = data?.data?.id;
        this.locationId = '';
      }
      }
    }).catch(error => console.error('Error fetching current user info:', error));

    if (!this.currentUser?.HIPAAAuthorised) {
      this._NgxLoader.stop();
      this.modalRef = this._BsModalService.show(this.hippaModal, {
        class: 'modal-lg hipaa_modal',
        backdrop: 'static',
        keyboard: false,
      });
    }
    this.sortPatients();
    this._NgxLoader.stop();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientsData) {
      this.sortPatients();
    }
  }

  sortPatients() {
    this.patientsData?.sort((a:any, b:any) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getLinkForBox(key: string): string {
    if (key) {
      switch (key.toLowerCase()) {
        case 'patients':
          this._Router.navigate(['/home/patients']);
        default:
          return '';
      }
    } else return '';
  }
  showModal(template: TemplateRef<any>) {
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'full_screen otherlandingpageModal',
      backdrop: 'static',
    });
  }
  getLowerCase(key: string): string {
    return key ? key.toLowerCase() : '';
  }
  navigatePatient(item: any) {
    this._Router.navigate([`/home/patient-details/${item._id}`]);
  }
  navigateProvider(item: any) {
    this._Router.navigate([`home/provider-details/${item._id}`]);
  }
  navigatetoList(path: string) {
    this._Router.navigate([`home/${path}`]);
  }
  searchPatientList() {
    this._NgxLoader.start();
    // this._MainService
    //   .getPatients(1, 10, this.searchValuee)
    this._MainService.getPatientListByProvider(1, 10, this.searchValuee, this.providerId, this.locationId)
      .then((data: any) => {
        if (data.success) {
          this.patientsData = data.data;
          this.pagination = data.pagination;
          this.sortPatients();
          this._NgxLoader.stop();
        } else this._NgxLoader.stop();
      });
  }
  pageChange(data: any) {
    this._NgxLoader.start();
    // this._MainService
    //   .getPatients(data.pageIndex + 1, 10, '')
    this._MainService.getPatientListByProvider(data.pageIndex + 1, 10, this.searchValuee, this.providerId, this.locationId)
      .then((data: any) => {
        if (data.success) {
          this.patientsData = data.data;
          this.pagination = data.pagination;
          this.sortPatients();
          this._NgxLoader.stop();
        } else this._NgxLoader.stop();
      });
  }

  isChecking(check: any): void {
    if (check.target.checked) {
      this.isChecked = true;
    } else this.isChecked = false;
  }
  HippaSubmitForm() {
    this._MainService.getHippaAuthorization().then((data) => {
      if (data.success) {
        this.modalRef = this._BsModalService.hide();
      }
    });
  }

  isBlank() {
    if (this.searchValuee.length < 3) {
      this.expressionUpdate = true;
    } else {
      this.expressionUpdate = false;
    }
    if (this.searchValuee.length % 3 === 0) {
      this.searchPatientList();
    }
  }
  onAddressSelect(event: any): void {
    const selectedAddress = event;
    if (selectedAddress?._id === 'all') {
      this.locationId = "";
    } else {
      this.locationId = selectedAddress?._id;
    }

    this._NgxLoader.start();
    this._MainService.getPatientListByProvider(1, 10, this.searchValuee, this.providerId, this.locationId)
      .then((data: any) => {
        if (data.success) {
          this.patientsData = data.data;
          this.pagination = data.pagination;
          this.sortPatients();
          this._NgxLoader.stop();
        } else this._NgxLoader.stop();
      });
  }
}
