import { Component, OnInit, TemplateRef, } from '@angular/core';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '@app/core/services/provider/notification.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit {
  PageModalRef: BsModalRef | null;
  messageData: any
  incomingName: any
  outName: any
  outgoingName: any
  pagination: any
  isLoading: boolean = false;
  isIncoming: boolean;
  currentUser: any;
  chatSectionList: any;
  image: string;
  chatID: any;
  userInput: string = '';


  constructor(
    private sanitizer: DomSanitizer,
    private _NotificationService: NotificationService,
    private _BsModalService: BsModalService,
    private _MainService: MainService,
    private _NotifierService: NotifierService,
    private _NgxLoader: NgxUiLoaderService,
  ) {
    this.PageModalRef = new BsModalRef();
    this.isIncoming = true;
    this.image = "";
  }

  ngOnInit(): void {
    this._NgxLoader.start();
    this.isLoading = true;

    this._MainService.messageSeen().then((x: any) => {
      if (x.success) {
        this.updateNotificationData();
      }
    })

    this._MainService.getCurrentUserInfo().then((data: any) => {
      this.currentUser = data.data
    })

    this._MainService.getChatMessages(10, 1, this.isIncoming).then((data: any) => {
      if (data.success) {
        this._NgxLoader.stop();
        this.isLoading = false;
        this.messageData = data.data;
        console.log(this.messageData, "this.messageData");

        this.pagination = data.pagination
      } else this._NgxLoader.stop();
    });
  }


  isMessageAdded(MessageAddeddata: any) {
    if (MessageAddeddata) {
      this._NgxLoader.start();
      this.isLoading = true;
      this.isIncoming = true
      this._MainService.getChatMessages(10, 1, this.isIncoming).then((data: any) => {
        if (data.success) {
          this._NgxLoader.stop();
          this.isLoading = false;
          this.messageData = data.data;
          this.pagination = data.pagination;
        } else {
          this._NgxLoader.stop();
          this._NotifierService.showError('No Data Found!');
        }
      });
    }
  }

  openModal(template: TemplateRef<any>, chat: any) {
    this.chatID = chat.chatID;
    this._NgxLoader.start();
    this._MainService.getChatMessagesByChatId(100, 1, chat.chatID).then((data: any) => {
      if (data.success) {
        this.pagination = data.pagination
        this.chatSectionList = data.data[0]
        console.log('====================================');
        console.log(data.data[0].provider[0]._id ,"chatData");
        console.log('====================================');
        this._NgxLoader.stop()
      } else this._NgxLoader.stop()
    })

    this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
    this.scrollToInput();
  }


  scrollToInput() {
    const myElement = document.getElementById("messageInput");
    setTimeout(() => {
      if (myElement) {
        myElement.focus();
      }
    }, 1000);
  }
  close() {
    this.chatID = '';
    this._BsModalService.hide();
    // Dashboard Message Update
    this._MainService.getChatMessages(10, 1, this.isIncoming).then((data: any) => {
      if (data.success) {
        this.pagination = data.pagination
        this.messageData = data.data
      }
    })
  }

  showModal(template: TemplateRef<any>) {
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'otherlandingpageModal',
      backdrop: 'static',
    });
  }

  messageState() {
    this.isIncoming = !this.isIncoming
    this._NgxLoader.start()
    this._MainService.getChatMessages(10, 1, this.isIncoming).then((data: any) => {
      if (data.success) {
        this.pagination = data.pagination
        this.messageData = data.data
        this._NgxLoader.stop()
      } else this._NgxLoader.stop()
    })
  }

  pageChange(pagechangedata: any) {
    this._NgxLoader.start()
    this._MainService.getChatMessages(10, pagechangedata.pageIndex + 1, this.isIncoming).then((data: any) => {
      if (data.success) {
        this._NgxLoader.stop()
        this.messageData = data.data;
      } else this._NgxLoader.stop()
    })
  }

  updateNotificationData() {
    this._MainService.getNotificationCounter().then(x => {
      this._NotificationService.setnotificationData(x.data);
    })
  }

  messagePath(photoPath: any): void {
    let imageUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl(`https://spineplusbe.azurewebsites.net/${photoPath?.substring(7, photoPath?.length)}`)

    this.image = imageUrl.changingThisBreaksApplicationSecurity;

  }
  getData() {
    const messagesValue = this.userInput;
    const formattedMessages = [
      {
        senderID: this.currentUser?._id,
        message: messagesValue
      }
    ];

    const payload = {
      chatID: this.chatSectionList?.chatID,
      messages: formattedMessages,
      userId: this.currentUser?._id,
      subject: this.chatSectionList?.subject,
      provider: this.chatSectionList?.provider[0]?._id
    };

    return payload;
  }
  sendReply() {
    if (this.userInput) {
      this._MainService.createChatNewMessage(this.getData()).then((data: any) => {
        if (data.success) {
          this.userInput = '';
          this._MainService.getChatMessagesByChatId(100, 1, this.chatSectionList?.chatID).then((data: any) => {
            if (data.success) {
              this.pagination = data.pagination;
              this.chatSectionList = data.data[0];

              // Dashboard Message Update
              this._MainService.getChatMessages(10, 1, this.isIncoming).then((data: any) => {
                if (data.success) {
                  this.pagination = data.pagination
                  this.messageData = data.data
                }
              })
            }
          })
        }
      })
    }
  }
}
