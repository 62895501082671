<div class="spinner-overlay w-100 h-100 d-flex align-items-center justify-content-center" [ngStyle]="{'position': isFixed ? 'fixed': null}">
    <div>
        <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
        <label class="font-weight-600 mb-0 mt-2">Please wait...</label>
    </div>
</div>
