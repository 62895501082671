import { Component, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, ValidatorFn,FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import * as moment from 'moment'


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

  resetPwdForm: FormGroup;
  isFormSubmitted: boolean;
  isFormSubmitting: boolean;
  key: any;
  myYear:any;

  constructor(private _ActivatedRoute: ActivatedRoute, private _FormBuilder: FormBuilder, private _Router: Router, private _MainService: MainService, private _NotifierService: NotifierService) {
    this.resetPwdForm = this._FormBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$<=>?@])/), Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required], this.checkconfirmPassword()]
    });
    this.isFormSubmitted = false;
    this.isFormSubmitting = false;
    this.key = ''
    this.myYear = new Date();
  }

  ngOnInit(): void {




    debugger
    this.key = this._ActivatedRoute.snapshot.params.id
    this.myYear = (moment(this.myYear).format("YYYY"));

  }
  checkconfirmPassword(): ValidatorFn {
    return (constrol: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (constrol.value === this.resetPwdForm.controls.newPassword.value) {
        return of(null);
      } else {
        return of({ 'notSame': true });
      }
    };
  }

  updatedconfirmPassword(): void {
    this.resetPwdForm.controls.confirmPassword.updateValueAndValidity();
  }
  goToLogin(): void {
    this._Router.navigate(['/login']);
  }
  // assignValuesresetPwd() {
  //     debugger
  //     return this.key
  // }
  resetPwdFormSubmit() {
    this.isFormSubmitted = true;
    if (this.resetPwdForm.valid) {
      this.isFormSubmitting = true;
      this._MainService.resetPassword(this.key, { 'password': this.resetPwdForm.controls.newPassword.value }).then(data => {
        if (data.success === true) {
          this._NotifierService.showSuccess('Password has been reseted successfuly..!');
          this._Router.navigate(['/login']);
        } else {
          this._NotifierService.showError('Something went wrong..!');
          this.isFormSubmitting = false
        }
      });
    }
  }

}
