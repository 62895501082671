import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { ChangePassWord } from "../../models/change-password.model";
import { EmailTemplate } from '../../services/emailTemplate/emailTemplate.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  newlyPassword: any 
  url:any
  newlyEmail:any
  isChangePwdFrmSubmitting = false;
  isChangePwdFrmSubmitted = false;
  changePasswordForm: FormGroup;
  showPasswordOld: boolean = false;
  showPasswordNew: boolean = false;
  showPasswordConfirm: boolean = false;

  constructor(private _FormBuilder: FormBuilder, private _EmailTemplate: EmailTemplate, private _MainService: MainService, private _NotifierService: NotifierService, private _BsModalService: BsModalService) {
    this.changePasswordForm = this._FormBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$<=>?@])/),
        this.checkNewPasswordNotSameAsOld()]
      ],
      confirmPassword: ['', [Validators.required], this.checkconfirmPassword()]
    });
  }

  ngOnInit(): void {
    this.url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') this.url += ':' + window.location.port;
    console.log(this.url);


  }

  checkNewPasswordNotSameAsOld(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control && control.value && this.changePasswordForm) {
        return control.value === this.changePasswordForm.controls.currentPassword.value ? { 'sameAsOld': true } : null;
      }
      return null;
    };
  }

  checkconfirmPassword(): ValidatorFn {
    return (constrol: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (constrol.value === this.changePasswordForm.controls.newPassword.value) {
        return of(null);
      } else {
        return of({ 'notSame': true });
      }
    };
  }

  togglePasswordVisibility1() {
    this.showPasswordOld = !this.showPasswordOld;
  }
  togglePasswordVisibility2() {
    this.showPasswordNew = !this.showPasswordNew;
  }
  togglePasswordVisibility3() {
    this.showPasswordConfirm = !this.showPasswordConfirm;
  }

  modalClose() {
    this._BsModalService.hide()
  }

  changepswrdEmail() {



    this._MainService.sendEmail(this.takeformData(),false).then((data: any) => {

      console.log(data);

    })

  }
  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.newlyEmail)
    formData.append('html', this._EmailTemplate.changepswrdTemplate(this.newlyEmail, this.newlyPassword, this.url))
    formData.append('message', "Changed Password")

    return formData
  }



  changePasswordFormSubmit(): void {

    this.isChangePwdFrmSubmitted = true;
    if (this.changePasswordForm.valid) {
      this.isChangePwdFrmSubmitting = true;
      let form: ChangePassWord = new ChangePassWord();
      form.oldPassword = this.changePasswordForm.controls.currentPassword.value
      form.newPassword = this.changePasswordForm.controls.newPassword.value
      this._MainService.changePassword(form).then(data => {
        this.isChangePwdFrmSubmitting = false;
        this.isChangePwdFrmSubmitted = false;
        this.changePasswordForm.reset();
        if (data.success === true) {

          this.newlyEmail = data.data.email
          this.newlyPassword = data.newPassword
          this._NotifierService.showSuccess('Password changed Successfully..!');
          this.changepswrdEmail()
          this._BsModalService.hide()
        } else {
          this._NotifierService.showError('Something went wrong..!');
        }
      });
    }
  }

}
