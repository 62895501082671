<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom mb-1">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">
          <ng-container *ngIf="editMode; else addMode">
            {{ currePatient.firstName }} {{ currePatient.lastName }}
          </ng-container> 
          <ng-template #addMode> New Patient </ng-template>
        </h1>
        <div *ngIf="(addressList?.length >= 2)" class="form-group ml-3" style="width: 35%;">
          <label class="font-weight-600" for="">Select Location<span *ngIf="role === 'Provider' || role === 'Staff'" class="text-danger">*</span></label>
          <ng-select [items]="addressList" bindLabel="addressLine1" bindValue="_id" placeholder="Select location"
          (change)="onAddressSelect($event)" [(ngModel)]="selectedAddress"></ng-select>
        </div>
        <button class="btn btn-sm btn-primary" (click)="goBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
    <div style="float: right; margin-right: 15px; margin-top: 10px my-1">
      <button class="btn bg_green text-white" [disabled]="addOEditPatientForm?.pristine" (click)="addOEditPatientFormSubmit()">
        <ng-container *ngIf="editMode; else add" >Update </ng-container>
        <ng-template #add> Submit </ng-template>
        <!-- <i class="fa fa-arrow-right" aria-hidden="true"></i> -->
      </button>
    </div>
    <div class="px-3 pb-3">
      <form [formGroup]="addOEditPatientForm" (submit)="addOEditPatientFormSubmit()">
        <div class="main-patient-form">
          <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
            <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">General Info</span>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
                  <div class="form-row">
                    <div class="col-6">
                      <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" />
                      <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.firstName.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.firstName.touched) "></small>
                    </div>
                    <div class="col-6">
                      <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName" />
                      <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.lastName.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.lastName.touched) "></small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Gender<span class="text-danger">*</span></label>
                  <ng-select [items]="genders" bindLabel="name" [searchable]="true" bindValue="_id" formControlName="genderId" placeholder="Select" [clearable]="false"></ng-select>
                  <small class="text-danger" *ngIf=" addOEditPatientForm.controls.genderId.invalid && (addOEditPatientFormSubmitted || addOEditPatientForm.controls.genderId.touched) "></small>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">Date of Birth<span class="text-danger">*</span></label>

                  <input type="text" formControlName="dateOfBirth" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker [maxDate]="maxDate" (click)="test1()">

                  <small class="text-danger" *ngIf=" addOEditPatientForm.controls.dateOfBirth.invalid && (addOEditPatientFormSubmitted || addOEditPatientForm.controls.dateOfBirth.touched) "></small>
                </div>
                <!-- <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.dOI.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.dOI.touched) ">Required</small> -->
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Email</label>
                  <input type="text" class="form-control" formControlName="email" />
                  <span class="text-danger" *ngIf=" !!addOEditPatientForm.controls.email.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.email.touched) ">

                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
                  <div class="form-row">
                    <div class="col">
                      <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" formControlName="cellNo" placeholder="Personal" />
                      <span class="text-danger" *ngIf=" !!addOEditPatientForm.controls.cellNo.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.cellNo.touched) ">
                        <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.cellNo.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.cellNo.touched) "></small>
                        <!-- <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.cellNo.errors ?.required "></small> -->
                        <small *ngIf=" !!addOEditPatientForm.controls.cellNo.errors ?.minlength "> Minimum length should be 10 </small>
                        <small *ngIf=" !!addOEditPatientForm.controls.cellNo.errors ?.maxlength "> Maximum length should be 15 </small>
                      </span>
                    </div>
                    <div class="col">
                      <input appNumberOnly type="number" class="form-control" formControlName="homeNo" placeholder="Home" maxlength="15" />
                      <span class="text-danger" *ngIf=" !!addOEditPatientForm.controls.homeNo.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.homeNo.touched) ">
                        <!-- <small *ngIf=" !!addOEditPatientForm.controls.homeNo.errors .required ">Required</small> -->
                        <small *ngIf=" !!addOEditPatientForm.controls.homeNo.errors .minlength "> Minimum length should be 10 </small>
                        <small *ngIf=" !!addOEditPatientForm.controls.homeNo.errors .maxlength "> Maximum length should be 15 </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Associated Providers<span class="text-danger">*</span></label>
                  <ng-select *ngIf="role === 'Admin' || role === 'SuperAdmin'" [items]="providerList" bindLabel="name" [multiple]="true" (change)="changeFn($event)" bindValue="_id" [searchable]="true" formControlName="provider" placeholder="Select Provider"></ng-select>
                  <input *ngIf="role === 'Provider' || role ==='Staff'" type="text" class="form-control" formControlName="provider" disabled />
                  <!-- <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.provider.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.provider.touched) ">Required</small> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Case Status<span class="text-danger">*</span></label>
                  <ng-select [items]="caseStatus" bindLabel="name" bindValue="_id" formControlName="caseStatus" placeholder="Select case status"></ng-select>
                  <!-- <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.caseStatus.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.caseStatus.touched) ">Required</small> -->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Address Line 1<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="addressLine1" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Address Line 2</label>
                  <input type="text" class="form-control" formControlName="addressLine2" />

                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">City</label>
                  <input type="text" class="form-control" formControlName="city" />

                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="font-weight-600" for="">State</label>
                  <input type="text" class="form-control" formControlName="state" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Zip<span class="text-danger">*</span></label>
                  <input maxlength="15" type="text" class="form-control" formControlName="zip" />
                  <span class="text-danger" *ngIf=" !!addOEditPatientForm.controls.zip.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.zip.touched) ">

                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Date of Injury</label>
                  <input type="text" formControlName="dOI" placeholder="MM/DD/YYYY" class="form-control" bsDatepicker>

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">ICD10</label>
                  <input class="form-control" type="text" formControlName="addICD10" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Social Security Number</label>
                  <input [hiddenInput]="true" mask="XXXXXAAAA" type="text" class="form-control" formControlName="socialSecurityNumber" autocomplete="off" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Occupation</label>
                  <input type="text" class="form-control" formControlName="occupation" />

                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Employer</label>
                  <input type="text" class="form-control" formControlName="employer" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Work Mobile No.</label>
                  <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" formControlName="workMobileNo" />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Patient Type<span class="text-danger">*</span></label>
                  <ng-select [items]="patientType" bindLabel="name" bindValue="_id" formControlName="patientType" placeholder="Select patient type"></ng-select>
                  <!-- <small class="text-danger" *ngIf=" !!addOEditPatientForm.controls.patientType.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.patientType.touched) ">Required</small> -->
                </div>
              </div>


            </div>
          </div>
          <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
            <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">Attorney Info</span>
            <div class="form-row" formGroupName="attorney">
              <ng-container>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Attorney Name</label>
                    <div class="form-row">
                      <div class="col">
                        <input type="text" class="form-control" placeholder="Firstname" formControlName="firstName" />
                      </div>
                      <div class="col">
                        <input type="text" class="form-control" placeholder="Lastname" formControlName="lastName" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Phone</label>
                    <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" formControlName="phone" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Fax</label>
                    <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57;"  maxlength="15" class="form-control" formControlName="fax" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Address Line 1</label>
                    <input type="text" class="form-control" formControlName="addressLine1" />
                  </div>
                </div>
                <div class="col-sm-6">

                  <div class="form-group">
                    <label class="font-weight-600" for="">Address Line 2</label>
                    <input type="text" class="form-control" formControlName="addressLine2" />

                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Email</label>
                    <input type="text" class="form-control" formControlName="email" />
                    <span class="text-danger" *ngIf=" !!addOEditPatientForm.controls.email.invalid && (addOEditPatientFormSubmitted || !!addOEditPatientForm.controls.email.touched) ">

                    </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Case Manager</label>
                    <input type="text" class="form-control" formControlName="caseManager" />

                  </div>
                </div>
                <div class="col-sm-4 mt-2">
                  <div class="form-row">
                    <div class="col">
                      <input type="checkbox" style="transform: translateY(-5px)" (click)="lawFirm($event)" [checked]="isLawFirmChecked" />
                      <label class="font-weight-600 ml-2" for="" style="margin-bottom: 0; transform: translateY(-5px)">Law Firm</label>
                      <!-- <input type="text" class="form-control" formControlName="lawFirm" *ngIf="isLawFirmChecked" /> -->
                      <ng-select [items]="lawFirmList" *ngIf="isLawFirmChecked" bindLabel="name" bindValue="_id" formControlName="lawFirm" placeholder="Select Law Firm Type"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mt-2">
                  <div class="form-row">
                    <div class="col">
                      <label class="font-weight-600" for="">Does Patient have PIP?</label>
                    </div>
                    <div class="col-auto mr-5">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="patientHavePIPYes" (click)="doesPIP('yes')" [checked]="isPIP" class="custom-control-input" name="isPIP">
                        <label class="custom-control-label" for="patientHavePIPYes">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline mr-0">
                        <input type="radio" id="patientHavePIPNo" (click)="doesPIP('no')" [checked]="!isPIP" class="custom-control-input" name="isPIP">
                        <label class="custom-control-label" for="patientHavePIPNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 mt-2">
                  <div class="form-row">
                    <div class="col">
                      <label class="font-weight-600" for="">Is Patient Covered By Insurance?</label>
                    </div>
                    <div class="col-auto mr-5">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="coveredByInsuranceYes" (click)="isCovered('yes')" [checked]="isCheckedInsurance" class="custom-control-input">
                        <label class="custom-control-label" for="coveredByInsuranceYes">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline mr-0">
                        <input type="radio" id="coveredByInsuranceNo" (click)="isCovered('no')" [checked]="!isCheckedInsurance" class="custom-control-input">
                        <label class="custom-control-label" for="coveredByInsuranceNo">No</label>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-container>
            </div>
          </div>
          <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
            <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">Insurance Info</span>
            <div class="form-row">
              <ng-container formGroupName="insurence">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Primary Insurance</label>
                    <input type="text" class="form-control" formControlName="primaryInsurence" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Other Insurance</label>
                    <input type="text" class="form-control" formControlName="otherInsurence" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Subscriber's Name</label>
                    <input type="text" class="form-control" formControlName="subscribersName" />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Group</label>
                    <input type="text" class="form-control" formControlName="group" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Policy</label>
                    <input type="text" class="form-control" formControlName="policy" />
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="isCoveredByInsurance">
            <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5">
              <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">Insurance Info</span>
              <div class="form-row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Primary Insurance</label>
                    <input type="text" class="form-control" formControlName="primaryInsurence" />

                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Other Insurance</label>
                    <input type="text" class="form-control" formControlName="otherInsurence" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="font-weight-600" for="">Subscriber's Name</label>
                    <input type="text" class="form-control" formControlName="subscribersName" />

                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="p-3 border border-primary rounded-right rounded-bottom position-relative mt-5" formGroupName="workCompany">
            <span class="fieldset-heading bg-primary d-inline-block px-2 py-1 text-white">Work Comp Info</span>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Insurance Name</label>
                  <input type="text" class="form-control" formControlName="insurenceName" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Address</label>
                  <input type="text" class="form-control" formControlName="address" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Phone</label>
                  <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" formControlName="phone" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Fax</label>
                  <input onkeypress="return event.charCode >= 48 && event.charCode <= 57;"  maxlength="15" class="form-control" formControlName="fax" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Adjuster</label>
                  <input type="text" class="form-control" formControlName="adjuster" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Adjuster's Phone</label>
                  <input mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" formControlName="adjustersPhone" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Adjuster's Fax</label>
                  <input class="form-control" onkeypress="return event.charCode >= 48 && event.charCode <= 57;" maxlength="15" formControlName="adjustersFax" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600 w-100" for="">Network</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="workCompNetworkYes" [checked]="networkData" class="custom-control-input" value="yes" name="network" (click)="network('yes')" />
                    <label class="custom-control-label" for="workCompNetworkYes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="workCompNetworkNo" [checked]="!networkData" class="custom-control-input" value="no" name="network" (click)="network('no')" />
                    <label class="custom-control-label" for="workCompNetworkNo">No</label>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Network Name</label>
                  <input type="text" class="form-control" formControlName="networkName" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">Network Claim Number</label>
                  <input type="text" class="form-control" formControlName="networkClaimNumber" />
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-3">
            <button type="submit" class="btn bg_green text-white" [disabled]="addOEditPatientForm?.pristine">
              <ng-container *ngIf=" !editMode; else adding">
              <ng-container *ngIf="
                  addOEditPatientFormSubmitting;
                  else addOEditPatientFormSubmittingDone
                ">
                <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
                Submitting...
              </ng-container>
              <ng-template #addOEditPatientFormSubmittingDone>
                Submit
                <!-- <i class="fa fa-arrow-right" aria-hidden="true"></i> -->
              </ng-template>
              </ng-container>
              <ng-template #adding >
                Update
              </ng-template>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
