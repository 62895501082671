import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { FacilityModule } from './facility/facility.module';
import { HomeModule } from '../app/home/home.module';
import { LoginComponent } from './Login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { httpInterceptorProviders } from './services/interceptors';
import { ResetPasswordComponent } from './Login/reset-password/reset-password.component';
import { AdminModule } from './admin/admin.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InviteSignupComponent } from './Login/invite-signup/invite-signup.component';
import { NgxMaskModule } from 'ngx-mask';
import { SuperAdminModule } from './super-admin/super-admin.module';




@NgModule({
  declarations: [
    AppComponent, LoginComponent, ResetPasswordComponent, InviteSignupComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    HomeModule,
    AdminModule,
    SuperAdminModule,
    CoreModule,
    FacilityModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    BrowserAnimationsModule,
    NgxMaskModule.forChild(),
    // MyDatePickerModule,
    ToastrModule.forRoot({              // Notifier
      preventDuplicates: true,
      maxOpened: 1
    }),
  ],
  providers: [DatePipe, httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
