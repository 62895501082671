export class addReferral {
    patientId: string;
    reasonForVisit: any
    ReferredTo: string;
    ReferredBy: string;
    BringImgDoc: boolean;
    callInSAT: string;
    MRI: string;
    CT: string;
    BillUpperExtremities: string;
    BillLowerExtremities: string;
    xRay: string;
    alreadyLinked: boolean
    bodyParts: any
    specialities: any
    constructor() {
        this.patientId = '';
        this.reasonForVisit = [];
        this.ReferredTo = '';
        this.ReferredBy = '';
        this.BringImgDoc = false;
        this.callInSAT = '';
        this.MRI = '';
        this.CT = '';
        this.BillUpperExtremities = '';
        this.BillLowerExtremities = '';
        this.xRay = '';
        this.alreadyLinked = false;
        this.bodyParts = [];
        this.specialities = [];

    }
}