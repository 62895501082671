import { Injectable } from '@angular/core';
import { MainService } from '../../../services/mainservice/main.service';
import { Resolve } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment'



@Injectable()

export class ReferralResolver implements Resolve<any | null> {
    myDate: any
    toDay: any
    fromDay:any
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService
    ) { }



    

resolve(): Promise<any> | null {
    this.myDate = new Date();
    this.toDay = (moment(this.myDate).format("YYYY-MM-DD"));
    this.myDate = this.myDate.setDate(this.myDate.getDate() - 7);
    this.fromDay = (moment(this.myDate).format("YYYY-MM-DD"));

        this.ngxLoader.start();
        return Promise.all([
            this._MainService.getReferrals(1, 10, this.fromDay, this.toDay).then(data => {
                this.ngxLoader.stop()
                return data; 
            })
        ])
    }
    
}
