<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">

      <div [formGroup]="notificationFormGroup" class="row align-items-center mb-1">

        <div class="col-sm-6">
          <h2 class="h5 font-weight-bold"> Notifications </h2>
        </div>
        <div *ngIf="role == 'Admin' || 'SuperAdmin'" class="col-sm-3 d-flex align-items-center justify-content-end">
          <label class="font-weight-600 pr-2" for="">From:</label>
          <input type="text" (bsValueChange)="dateOrderMin($event)" [maxDate]="maxDate" style="width: 52%;"
            formControlName="fromDate" placeholder="MM/DD/YYYY" class="form-control custom_opacity" bsDatepicker>
        </div>
        <div *ngIf="role == 'Admin' || 'SuperAdmin'" class="col-sm-2 d-flex align-items-center justify-content-end">
          <label class="font-weight-600 pr-2" for="">To:</label>
          <input type="text" [minDate]="minDate" #dp="bsDatepicker" [maxDate]="maxDate" formControlName="toDate"
            placeholder="MM/DD/YYYY" class="form-control custom_opacity" bsDatepicker>
        </div>
        <div *ngIf="role == 'Admin' || 'SuperAdmin'" class="col-sm-1">
          <button type="submit" class="btn bg_green text-white float-right" (click)="notifcsByDate()">Filter</button>
        </div>

      </div>

      <div *ngIf="isRadioUnReviewedChecked" class="position-relative">
        <div class="table-responsive border border-bottom-0">
          <table class="border table table-sp-sm mb-0" aria-describedby="describe">
            <thead class="header_main_bg border-bottom-0 text-white">
              <tr>
                <th scope="col" class="border-bottom-0 border-top-0">Patient Id</th>
                <th scope="col" class="border-bottom-0 border-top-0">Patient Name</th>
                <th scope="col" class="border-bottom-0 border-top-0">Action Completed</th>
                <th scope="col" class="border-bottom-0 border-top-0">Completed Date</th>
                <th scope="col" class="border-bottom-0 border-top-0">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="notificationList !== null ;else noMessage">
                <ng-container *ngFor="let notification of notificationList">
                  <tr>
                    <td> <span class="d-inline">{{ notification?.patientId?.patientUID || "No Patient ID" }}</span>
                    </td>
                    <td> <span class="d-inline"> {{ notification?.patientId?.fullName || 'No Patient Name'}} </span>
                    </td>
                    <td class="inline_giver">
                      <ng-container *ngIf="notification?.actionName?.length >1;else def">
                        <span class="completedActionCounter"
                          *ngFor="let action of notification?.actionName;let i = index"> {{ action }}
                          <span [ngClass]="notification.isSeen?'counter_ac_green border-0 shadow':'counter_ac'"
                            *ngIf="i===0">{{notification.patientDiagnosticCount}}</span> <br />
                          <span [ngClass]="notification.isSeen?'counter_ac_green border-0 shadow':'counter_ac'"
                            *ngIf="i===1">{{notification.patientConsultationCount}}</span>
                        </span>
                      </ng-container>
                      <ng-template #def>
                        <span class="completedActionCounter"
                          *ngFor="let action of notification?.actionName;let i = index"> {{ action }}
                          <span [ngClass]="notification.isSeen?'counter_ac_green border-0 shadow':'counter_ac'"
                            *ngIf="i===0 && notification.patientDiagnosticCount !==0">{{notification.patientDiagnosticCount}}</span>
                          <br />
                          <span [ngClass]="notification.isSeen?'counter_ac_green border-0 shadow':'counter_ac'"
                            *ngIf="i===0 && notification.patientConsultationCount !==0">{{notification.patientConsultationCount}}</span>
                        </span>
                      </ng-template>

                    </td>
                    <td>
                      <span class="d-inline" *ngIf="notification?.updatedAt">
                        {{ notification?.updatedAt | date:"MM/dd/YYYY , hh:mm aa" }} </span>
                      <br *ngIf="notification?.updatedAt">

                    </td>
                    <td>
                      <ng-container *ngIf="notification?.patientDiagnosticCount!==0 ;else cons">
                        <button class="btn  position-relative diag"
                          [ngClass]="notification.isSeen?'bg_green text-white':'btn btn-primary'"
                          routerLink="../patient-details/{{ notification?.patientId?._id }}"
                          (click)="!notification.isSeen?isSeenTrueStatus():''"
                          [queryParams]="{ tab: 2,hktpjKqxxLkY:!notification.isSeen?'HwC1fN':'HwC0fN' }">Details
                          <span
                            class="counter_ac">{{notification?.patientConsultationCount + notification?.patientDiagnosticCount}}</span>
                        </button>
                      </ng-container>
                      <ng-template #cons>
                        <button class="btn position-relative cons"
                          [ngClass]="notification.isSeen?'bg_green text-white':'btn btn-primary'"
                          routerLink="../patient-details/{{ notification?.patientId?._id }}"
                          (click)="!notification.isSeen?isSeenTrueStatus():''"
                          [queryParams]="{ tab: 3,hktpjKqxxLkY:!notification.isSeen?'HwC1fN':'HwC0fN' }">Details
                          <span
                            class="counter_ac">{{notification?.patientConsultationCount + notification?.patientDiagnosticCount}}</span>
                        </button>
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <ng-template #noMessage>
                <td colspan="12">
                  <h2 class="text-center pt-3">No Notifications</h2>
                </td>
              </ng-template>
            </tbody>
          </table>
        </div>
        <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)">
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
