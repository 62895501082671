import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplate } from '../../../services/emailTemplate/emailTemplate.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  //styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  // @Output() patientNotesSender()
  viewPermissions = ['Everyone', 'Only me'];
  patientNotes: FormGroup;
  data: any;
  role: any;
  roleID: any;
  notesList: any;
  deleteId: any
  pagination: any;
  PatientDetails: any;
  PatientProviderList: any = [];
  toEmail: any = [];
  CurrentUserInfo: any;
  @Input() patientID: any;
  @Input() linkedProvider: any;
  isSubmitted: boolean
  isEdit: boolean;
  notesUpdatedId: string = '';
  patientId: string = '';
  constructor(
    /* private _ActivatedRoute:ActivatedRoute, */ private _MainService: MainService,
    private _FormBuilder: FormBuilder,
    private _NgxLoader: NgxUiLoaderService,
    private _NotifierService: NotifierService,
    private _EmailTemplate: EmailTemplate,
    private _ActivatedRoute: ActivatedRoute,
    private _BsModalService: BsModalService,
  ) {
    this.patientNotes = this._FormBuilder.group({
      viewStatus: [],
      message: [null, [Validators.required]],
    });
    this.isSubmitted = false;
    this.isEdit = false;
  }

  ngOnInit(): void {
    this._ActivatedRoute.params.subscribe((params: Params) => {
      this.patientId = params['id'];
    });

    this.role = window.localStorage.getItem("role")
    this.roleID = window.localStorage.getItem("roleID")
    // if (this.role === 'Admin') {
    //   this.patientNotes.disable();
    // }
    if (this.role === 'Admin') {
      this._MainService.getAllNotes(this.patientId).then((data: any) => {
        this.notesList = data?.data;
        // this.patientNotes.disable();
      })
    }
    if (this.role === 'SuperAdmin') {
      this._MainService.getAllNotes(this.patientId).then((data: any) => {
        this.notesList = data?.data;
      })
    }
    if (this.role === 'Provider' || this.role === "Staff") {
      this._ActivatedRoute.data.subscribe(data => {
        this.notesList = data?.notes?.data;
        this.pagination = data?.notes?.pagination;
      })
    }

    this.patientNotes.controls.viewStatus.setValue(this.viewPermissions[0]);

    // Extraction for Sending Email
    this._MainService.getPatientsbyPatientID(this.patientId, false).then(data => {
      this.PatientDetails = data.data;
      if (this.PatientDetails.provider && this.PatientDetails.provider.length > 0) {
        this.PatientProviderList = this.PatientDetails.provider.map((provider:any) => ({
          firstName: provider?.firstName || '',
          lastName: provider?.lastName || '',
          _id: provider?._id || '',
        }));
      }
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.CurrentUserInfo = data.data
      }
    })

    this._MainService.getProviderNameList(true, false, false).then((data) => {
      const emailList = [];
      if (this.PatientProviderList && this.PatientProviderList.length > 0) {
        for (const provider of this.PatientProviderList) {
          const matchedProvider = data.data.find((p: any) => p._id === provider._id);
          const email = matchedProvider ? matchedProvider.email : '';
          if (email && email !== '') {
            emailList.push(email);
          }
        }
      }
      this.toEmail = emailList;
    });
  }

  prepareData() {
    this.data = {
      viewStatus: this.patientNotes.controls.viewStatus.value,
      message: this.patientNotes.controls.message.value,
      patientId: this.patientID,
      linkedProvider: this.linkedProvider,
    };
  }

  messageEmail() {
    this._MainService.sendEmail(this.takeformData(), true).then((data: any) => {
      console.log(data);
    })
  }

  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.toEmail)
    formData.append('html', this._EmailTemplate.updateEmailTemplate(
      this.PatientDetails.fullName,
      this.PatientDetails.patientUID,
      this.CurrentUserInfo?.fullName,
      "Notes"
    ))
    formData.append('message', "Patient Notes Update")

    return formData
  }
  submitNotes() {
    this.isSubmitted = true
    this.prepareData();
    if (this.patientNotes.valid) {
      this._NgxLoader.start();
      this._MainService.setNewNotes(this.data).then((data: any) => {
        if (data.success) {
          this._NotifierService.showSuccess('Note Added Successfully');
          this.patientNotes.controls.message.reset()
          this.messageEmail();
          this.isSubmitted = false;
          if (this.role === 'SuperAdmin' || this.role === 'Admin') {
            this._MainService.getAllNotes(this.patientId).then((data: any) => {
              if(data.success){
                this.notesList = data?.data;
                this.patientNotes.controls.message.setValue('');
                this._NgxLoader.stop();
              }else {
                this._NotifierService.showError('Notes not available');
                this._NgxLoader.stop();
              }
            })
          }else{
          this._MainService
            .getNotes(10, 1, '', this.patientID)
            .then((Notesdata: any) => {
              if (Notesdata.success) {
                this.patientNotes.controls.message.setValue('');
                this._NgxLoader.stop();
                this.notesList = Notesdata.data;
              } else {
                this._NotifierService.showError('Something went Wrong');
                this._NgxLoader.stop();
              }
            });
          }
        }
      });
    }

  }
  updateNotes() {
    this.prepareData();
    if (this.patientNotes.valid) {
      this._NgxLoader.start();
      this._MainService.updateNotes(this.data, this.notesUpdatedId).then((data: any) => {
        if (data.success) {
          this._NotifierService.showSuccess('Note Updated Successfully');
          this.messageEmail();
          this.patientNotes.controls.message.reset();
          this.patientNotes.controls.viewStatus.setValue(this.viewPermissions[0]);
          this.isEdit = false;
          if (this.role === 'SuperAdmin' || this.role === 'Admin') {
            this._MainService.getAllNotes(this.patientId).then((data: any) => {
              if(data.success){
                this.notesList = data?.data;
                this.patientNotes.controls.message.setValue('');
                this._NgxLoader.stop();
              }else {
                this._NotifierService.showError('Notes not available');
                this._NgxLoader.stop();
              }
            })
          }else{
          this._MainService
            .getNotes(100, 1, '', this.patientID)
            .then((Notesdata: any) => {
              if (Notesdata.success) {
                this.patientNotes.controls.message.setValue('');
                this._NgxLoader.stop();
                this.notesList = Notesdata.data;
              } else {
                this._NotifierService.showError('Something went Wrong');
                this._NgxLoader.stop();
              }
            });
          }
        }
      });
    }
  }


  editNote(item: any) {
    this.notesUpdatedId = '';
    this.isEdit = true;
    this.notesUpdatedId = item?._id;
    this.patientNotes.controls.message.setValue(item?.message);
    this.patientNotes.controls.viewStatus.setValue(item?.viewStatus);
  }

  pageChange(data: any) {

    this._NgxLoader.start();
    this._MainService
      .getNotes(10, data.pageIndex + 1, '', this.patientID)
      .then((x: any) => {
        if (x.success) {
          this._NgxLoader.stop();
          this.notesList = x.data;
        } else this._NgxLoader.stop();
      });
  }
  openModal(template: TemplateRef<any>, id: any) {
    this.deleteId = id
    this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }
  deleteRecords() {
    this._NgxLoader.start()
    this._MainService.deleteNote(this.deleteId).then((data: any) => {
      if (data.success) {
        // this._NgxLoader.stop();
        this.getPatientNotes();
        // location.reload();
        this._NotifierService.showSuccess("Note Deleted Successfully");
        this._BsModalService.hide()
      } else {
        this._NgxLoader.stop()
        this._NotifierService.showError("Note cannot be deleted");
      }
    })
  }

  getPatientNotes(){
    if (this.role === 'SuperAdmin' || this.role === 'Admin') {
      this._MainService.getAllNotes(this.patientId).then((data: any) => {
        if(data.success){
          this.notesList = data?.data;
          this.patientNotes.controls.message.setValue('');
          this._NgxLoader.stop();
        }else {
          this._NotifierService.showError('Notes not available');
          this._NgxLoader.stop();
        }
      })
    }else{
    this._MainService
      .getNotes(100, 1, '', this.patientID)
      .then((Notesdata: any) => {
        if (Notesdata.success) {
          this.patientNotes.controls.message.setValue('');
          this._NgxLoader.stop();
          this.notesList = Notesdata.data;
        } else {
          this._NotifierService.showError('Something went Wrong');
          this._NgxLoader.stop();
        }
      });
    }
  }

}
