<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0 name_limit">{{currentDoctor?.firstName}} {{currentDoctor.lastName}}</h1>
        <div class="btn-group btn-group-sm">
          <button class="btn btn-outline-primary" (click)="goBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          <a class="btn btn-outline-primary" routerLink="/{{role}}/edit-add-doctor/{{currentDoctor._id}}">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            Edit
          </a>
          <button class="btn btn-outline-primary" disabled>
            <i class="fa fa-eye" aria-hidden="true"></i>
            Referrals
          </button>
          <button class="btn btn-outline-danger" (click)="openModal()" [disabled]="isDeletingDoc">
            <ng-container *ngIf="isDeletingDoc; else isDeletingDone">
              <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span>
              Deleting...
            </ng-container>
            <ng-template #isDeletingDone>
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </ng-template>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3">
      <div class="row">
        <div class="col-sm-8">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="font-weight-bold">Details:</h5>

          </div>
          <hr />
          <div class="row">

            <div class="col">
              <div class="row">
                <div class="col-6 py-2 name_limit">
                  <span class="font-weight-600 w-100 mb-0" for="">Firstname:</span> {{currentDoctor.firstName}}
                </div>
                <div class="col-6 py-2 name_limit" *ngIf="currentDoctor.lastName">
                  <span class="font-weight-600 w-100 mb-0" for="">Lastname:</span> {{currentDoctor.lastName}}
                </div>
                <div class="col-6 py-2" *ngIf="currentDoctor?.gender?.name">
                  <span class="font-weight-600 w-100 mb-0" for="">Gender:</span> {{currentDoctor?.gender?.name}}
                </div>
                <!-- <div class="col-6 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Highest Qualification:</label>
                  <span
                    *ngIf="currentDoctor.highestQualification!=='null'">{{currentDoctor.highestQualification}}</span>
                </div> -->
                <div class="col-6 py-2">
                  <span class="font-weight-600 w-100 mb-0" for="">Phone:</span> {{currentDoctor.phone}}
                </div>
                <div class="col-12 py-2">
                  <span class="font-weight-600 w-100 mb-0" for="">Email:</span>
                  {{ currentDoctor.email!=="null" && currentDoctor.email!=="" && currentDoctor.email
                  ?currentDoctor.email:'' }}
                </div>
                <div class="col-6 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Contact Person:</label>
                  <span *ngIf="currentDoctor.contactPerson!=='null'">{{currentDoctor.contactPerson}}</span>
                </div>

                <div class="col-12 py-2">
                  <div class="d-flex">
                    <Label class="font-weight-600 mb-0">Associated Doctor:</Label>
                    <ng-container *ngFor="let asso of currentDoctor?.associatedDoctor">
                      <span>{{ asso?.firstName }} {{ asso?.lastName +" ,"}} </span>
                    </ng-container>
                  </div>


                </div>
                <div class="col-12 py-2" *ngIf="currentDoctor.insurance">
                  <span class="font-weight-600 w-100 mb-0" for="">Case Manager:</span> {{currentDoctor.insurance}}
                </div>
                <div class="col-12 py-2 d-flex">
                  <span class="font-weight-600 mb-0 mr-1" for="">Address: </span>
                  <p class="mb-0">
                    <span *ngIf="currentDoctor.addressLine1 !== 'null'"> {{currentDoctor.addressLine1}}</span>
                    <span *ngIf="currentDoctor.addressLine2 !== 'null'"> {{currentDoctor.addressLine2}}</span>
                  </p>
                </div>
                <div class="col-12 py-2 d-flex">
                  <span class="font-weight-600 mb-0 mr-1" for="">Business Name: </span>
                  <p class="mb-0">
                    <span *ngIf="currentDoctor.businessName !== 'null'"> {{currentDoctor.businessName}}</span>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card bg-gradient-primary mb-4">
            <div class="card-header text-white font-weight-600">
              <h5 class="font-weight-bold mb-0">Associated Clinics</h5>
            </div>
            <ng-container *ngIf="currentDoctor.clinicAffiliations !== null; else noClinicFound">
              <ul class="list-group list-group-flush">
                <ng-template ngFor let-clinic [ngForOf]="currentDoctor.clinicAffiliations">
                  <li *ngIf="clinic !== ''" class="list-group-item py-2">{{clinic}}</li>
                </ng-template>
              </ul>
            </ng-container>
            <ng-template #noClinicFound>
              <ul class="list-group list-group-flush">
                <li class="list-group-item py-2">No clinic associated..!</li>
              </ul>
            </ng-template>
          </div>
          <div class="card bg-gradient-primary mb-4">
            <div class="card-header text-white font-weight-600">
              <h5 class="font-weight-bold mb-0">Specialties</h5>
            </div>
            <ng-container
              *ngIf="currentDoctor.specialilties !== null && (currentDoctor?.providerType?.name !== 'Imaging Center'); else noSpeciality">
              <ul class="list-group list-group-flush">
                <ng-template ngFor let-speciality [ngForOf]="currentDoctor.specialilties">
                  <li class="list-group-item py-2">{{speciality.name}}</li>
                </ng-template>
              </ul>
            </ng-container>
            <ng-template #noSpeciality>
              <ul class="list-group list-group-flush">
                <li class="list-group-item py-2">No speciality..!</li>
              </ul>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- Modal HTML -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'d-block': isModalOpen, 'd-none': !isModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Modal content goes here -->
        <p>Do you want to delete selected provider?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">No</button>
        <button type="button" class="btn btn-primary" (click)="deleteDoc(currentDoctor.id)">Yes</button>
      </div>
    </div>
  </div>
</div>


  
  <div class="name-container" *ngIf="this.role == 'admin'">
    <h1 class="h5 font-weight-bold mb-0 name_limit mt-3 mb-3 ml-2">Total Patient</h1>
    <ng-container *ngIf="patientsList && patientsList.length > 0; else noPatientFound">
      <div class="patient-list" style="cursor: pointer;">
        <div *ngFor="let patient of patientsList; let i = index" class="patient-item" (click)="goToView(patient._id)" [style.background-color]="patient?.patientType === 'General Patient' ? '' : patient?.patientType === 'Commercial Patient' ? '#4aa93c' :patient?.patientType === 'Dropped Patient' ? '#FF0000' :patient?.patientType === 'Completed' ? '#FFFF00' :  patient?.patientType === 'Surgical Patient' ? '#b2d7f0' : 'white'">
          <p>{{ i + 1 }}.  {{ patient.firstName }} {{ patient.lastName }}</p>
        </div>
      </div>
    </ng-container>
          <ng-template #noPatientFound>
            <tr class="border-bottom">
              <td colspan="12">
                <div class="text-center py-5">
                  <h1 class="display-4">

                  </h1>
                  <h5 class="font-weight-bold text-muted mt-3">
                    No new patient found
                  </h5>
                </div>
              </td>
            </tr>
          </ng-template>
    </div>
  </div>
  <!-- <mat-paginator [length]="pagination" [pageSize]="10" (page)="pageChange($event)">
  </mat-paginator> -->