<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">
          {{attorneyInsuranceDetails.name}}
        </h1>
        <div class="btn-group btn-group-sm">
          <button class="btn btn-outline-primary" (click)="goBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          <a class="btn btn-outline-primary" routerLink="../../edit-add-attorney-insurance/{{attorneyInsuranceDetails._id}}">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            Edit
          </a>
          
          <button class="btn btn-outline-danger" (click)="deleteAttorInsurance(attorneyInsuranceDetails._id)" [disabled]="isDeletingAttorIns">
            <ng-container *ngIf="isDeletingAttorIns; else isDeletingAttorInsDone">
              <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span>
              Deletting...
            </ng-container>
            <ng-template #isDeletingAttorInsDone>
              <i class="fa fa-trash" aria-hidden="true"></i>
              Delete
            </ng-template>
          </button>
        </div>
      </div>
    </div>
    <div class="p-3">
      <div class="row">
        <div class="col-12">
          <h5 class="font-weight-bold d-flex align-items-center justify-content-between">
            <span>Details:</span>
            
          </h5>
          <hr />
          <div class="row">
            <div class="col p-3">
              <div class="row">
                <div class="col-4 pb-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Name:</label>
                  <span>{{ attorneyInsuranceDetails.firstName }} {{ attorneyInsuranceDetails.lastName }}</span>
                </div>
                <div class="col-4 pb-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Email:</label>
                  <span>{{ attorneyInsuranceDetails.email!=="null" && attorneyInsuranceDetails.email!=="" && attorneyInsuranceDetails.email ?attorneyInsuranceDetails.email:'' }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Phone:</label>
                  <span>{{ attorneyInsuranceDetails.phone }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Fax:</label>
                  <span *ngIf=" attorneyInsuranceDetails.fax!=='null'">{{
                    attorneyInsuranceDetails.fax
                    }}</span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Address:</label>
                  <span *ngIf=" attorneyInsuranceDetails.addressLine1!=='null'">{{
                    attorneyInsuranceDetails.addressLine1
                    }} </span>
                </div>
                <div class="col-4 py-2">
                  <label class="font-weight-600 w-100 mb-0" for="">Case Manager:</label>
                  <span>{{
                    attorneyInsuranceDetails.insurance
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>