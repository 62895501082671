import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplate } from '../../../../services/emailTemplate/emailTemplate.service';
import { noWhitespaceValidator } from '@app/validators/noWhitespaceValidator';



@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
})
export class NewMessageComponent implements OnInit {
  createMessage: FormGroup;
  providerList: any = [];
  toEmail: any
  url:any
  fileViewer: string;
  selectedFile: any;
  providerId: any = [];
  msgFname: any
  msgLname:any
  currentUser:any
  isFormSubmitting: boolean = false
  @Output() isMessageAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _FormBuilder: FormBuilder,
    private _EmailTemplate: EmailTemplate,
    private _MainService: MainService,
    private _NotifierService: NotifierService,
    private _BsModalService: BsModalService,
  ) {
    this.fileViewer = 'Choose File';
    this.createMessage = this._FormBuilder.group({
      email: [null, [Validators.required]],
      subject: [null, [Validators.required, noWhitespaceValidator()]],
      messages: [null, [Validators.required, noWhitespaceValidator()]],
      chatID: [""],
      userId: [""],
    });

  }
  ngOnInit() {
    this.url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') this.url += ':' + window.location.port;
    console.log(this.url);
    this._MainService.GetAlluserNameList().then((data) => {
      this.providerList = data.data;
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      this.currentUser = data.data
    })
  }

  fileChange(event: Event): void {
    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files !== null) {
        debugger;
        const fileList: FileList = files;
        if (fileList && fileList.length > 0) {
          const file: File = fileList[0];
          this.selectedFile = file;
          this.fileViewer = file.name;
        }
      }
    }
  }
  onEmailSelect(data: any) {
    this.providerId = []
    if (data.length > 0) {
      data.forEach((element: any) => {
        this.providerId.push(element._id)
      });
    }
  }

  getData() {
    const messagesValue = this.createMessage.controls.messages?.value;
    const formattedMessages = [
      {
        senderID: this.currentUser?.role === "Staff" ? this.currentUser?.providerId : this.currentUser?._id,
        message: messagesValue
      }
    ];

    const payload = {
      chatID: '',
      messages: formattedMessages,
      userId: this.currentUser.role === 'Staff' ? this.currentUser?.providerId : this.currentUser?._id,
      subject: this.createMessage.controls.subject?.value || null,
      provider: this.providerId.length !== 0 ? this.providerId : null
    };
    return payload;
  }

  messageEmail() {
    this._MainService.sendEmail(this.takeformData(),true).then((data: any) => {
      console.log(data);
    })
  }

  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.toEmail )
    formData.append('html', this._EmailTemplate.messageEmailTemplate(this.msgFname, this.msgLname,this.url))
    formData.append('message', "New Message")
    return formData
  }

  onMessageSend() {
    console.log(this.getData(),"this.getData()");

    if (this.createMessage.invalid) {
      this.isFormSubmitting = false;
      this._NotifierService.showError('Please fill all required fields.');
      return;
    }

    this.isFormSubmitting = true
    this._MainService.createChatNewMessage(this.getData()).then((data: any) => {
      if (data.success) {
        this.isFormSubmitting = false
        this._NotifierService.showSuccess('Message Sent Successfully');
        this._BsModalService.hide()
        this.isMessageAdded.emit(true)
      } else {
        this.isFormSubmitting = false
        this._NotifierService.showError('Email Could Not Be Send!');
      }
    })
  }
}






























//   ngOnInit() {
//     this.url = window.location.protocol + '//' + window.location.hostname;
//     if (window.location.port !== '') this.url += ':' + window.location.port;
//     console.log(this.url);
//     this._MainService.getProviderNameList(true, false,false).then((data) => {
//       this.providerList = data.data;

//     });
//   }

//   fileChange(event: Event): void {
//     if (event.target !== null) {
//       const files = (<HTMLInputElement>event.target).files;
//       if (files !== null) {
//         debugger;
//         const fileList: FileList = files;
//         if (fileList && fileList.length > 0) {
//           const file: File = fileList[0];
//           this.selectedFile = file;
//           this.fileViewer = file.name;
//         }
//       }
//     }
//   }
//   onEmailSelect(data: any) {
//     this.providerId = []
//     if (data.length > 0) {
//       data.forEach((element: any) => {
//         this.providerId.push(element._id)
//       });
//     }
//   }

//   getData() {
//     let formData = new FormData()

//     if (this.createMessage.controls.email?.value != null) {
//       formData.append('email', JSON.stringify(this.createMessage.controls.email?.value))
//     }
//     if (this.createMessage.controls.subject?.value != null) {
//       formData.append('subject', this.createMessage.controls.subject?.value)
//     }
//     if (this.createMessage.controls.message?.value != null) {
//       formData.append('message', this.createMessage.controls.message?.value)
//     }
//     if (this.providerId.length != 0) {
//       formData.append('provider', JSON.stringify(this.providerId))
//     }
//     // if (this.selectedFile && this.selectedFile.name) {
//     //   formData.append('photo', this.selectedFile, this.selectedFile.name)
//     // }
//     return formData

//   }
//   messageEmail() {
//     this._MainService.sendEmail(this.takeformData(),true).then((data: any) => {
//       console.log(data);
//     })
//   }

//   takeformData(): FormData {
//     const formData: FormData = new FormData();
//     formData.append('email', this.toEmail )
//     formData.append('html', this._EmailTemplate.messageEmailTemplate(this.msgFname, this.msgLname,this.url))
//     formData.append('message', "New Message")
//     return formData
//   }


//   onMessageSend() {
//     this.isFormSubmitting = true
//     this._MainService.createNewMessage(this.getData()).then((data: any) => {
//       if (data.success) {
//         this.msgFname = data.senderName.firstName
//         this.msgLname = data.senderName.lastName
//         this.toEmail=data.senderName.toEmail
//         this.isFormSubmitting = false
//         this._NotifierService.showSuccess('Message Sent Successfully');
//         this.messageEmail()
//         this._BsModalService.hide()
//         this.isMessageAdded.emit(true)
//       } else {
//         this.isFormSubmitting = false
//         this._NotifierService.showError('Email Could Not Be Send!');
//       }
//     })
//   }
// }
