<div class="mh-100vh d-flex justify-content-center align-items-center">
    <div class="bg-white rounded w-cxs-75 w-sm-50 w-lg-25 p-3 p-xl-4 d-flex flex-column login_view ">
        <img src="../.././../assets/img/HSCT_Logo_White-1.png" alt="no image" class="img-fluid mb-3 p-2 rounded logo">
        <div class="position-relative">
            <form class="form-signin" [formGroup]="resetPwdForm" (submit)="resetPwdFormSubmit()">
                <div class="form-group">
                    <label for="inputEmail" class="font-weight-600 mb-1">New Password</label>
                    <input type="password" id="email" class="form-control" placeholder="New Password" formControlName="newPassword" (keyup)="updatedconfirmPassword()" autocomplete="off" />
                    <span class="text-danger" *ngIf="resetPwdForm.controls.newPassword.invalid && (isFormSubmitted || resetPwdForm.controls.newPassword.touched)">
                        <small *ngIf="resetPwdForm.controls.newPassword.errors.required">Required</small>
                        <small *ngIf="resetPwdForm.controls.newPassword.errors.minlength && !resetPwdForm.controls.newPassword.errors.pattern">
                            Minimum 8 characters required
                        </small>
                        <small *ngIf="resetPwdForm.controls.newPassword.errors.pattern">
                            Password should contain lower case and upper case characters, digits and special symbols.
                        </small>
                    </span>
                </div>
                <div class="form-group">
                    <label for="inputPassword" class="font-weight-600 mb-1">Confirm Password</label>
                    <input type="password" id="inputPassword" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword" />
                    <span class="text-danger" *ngIf="resetPwdForm.controls.confirmPassword.invalid && (isFormSubmitted || resetPwdForm.controls.confirmPassword.touched)">
                        <small *ngIf="resetPwdForm.controls.confirmPassword.errors.required">Required</small>
                        <small *ngIf="resetPwdForm.controls.confirmPassword.errors.notSame">
                            Password not same
                        </small>
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-end mb-3">
                    <a class="text-primary cursor-pointer" (click)="goToLogin()">Back to Login</a>
                </div>
                <button class="bg_green btn btn-block text-white" type="submit" [disabled]="isFormSubmitting">
                    <ng-container *ngIf="isFormSubmitting; else isFormSubmittingDone"> <span class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Resetting... </ng-container>
                    <ng-template #isFormSubmittingDone> Reset Password <i class="fa fa-arrow-right" aria-hidden="true"></i> </ng-template>
                </button>
            </form>
        </div>
        <p class="mt-3 mb-0 text-muted text-center">&copy; HealthSight Care Tracker 2021-{{myYear}}</p>
    </div>
</div>
