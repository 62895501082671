import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { providerDetails } from '@app/models/provider/prodider.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MainService } from '../../../services/mainservice/main.service';


@Component({
    selector: 'app-doctors',
    templateUrl: './provider.component.html'
})
export class ProviderComponent implements OnInit {

    doctorsList: providerDetails[]

    isAlertActive: boolean;
    isBusy: boolean;
    pagination: any;
    searchKey: string;
    isSearchKeyFilled: boolean;
    expressionUpdate: boolean = false;

    constructor(
        private _Router: Router,
        private _ActivatedRoute: ActivatedRoute,
        private _MainService: MainService,
        private _NgxLoader: NgxUiLoaderService
    ) {
        this.doctorsList = [];

        this.isAlertActive = false;
        this.isBusy = false;
        this.pagination = 0
        this.searchKey = '';
        this.isSearchKeyFilled = false;
    }
    searchDoctor(search: any) {
        this.isBusy = true;
        if (search.target.value.length < 3) {
            this.expressionUpdate = true;
          } else {
            this.expressionUpdate = false;
          }
          if (search.target.value.length % 3 === 0) {
            this._NgxLoader.start();
        this._MainService.getDoctors(1, 10, search.target.value, "").then((data: any) => {
            if (data.success) {
                this.doctorsList = data.data;
                this.isBusy = false;
                this.sortDoctors();
                this._NgxLoader.stop();
            }
            else {
                this.isBusy = false;
                this._NgxLoader.stop()
            }
        })
    }
    }
    ngOnInit() {
        this._ActivatedRoute.data.subscribe(data => {
            console.log(data);

            if (data && data.doctors.data || data.doctors.pagination)
                this.doctorsList = data.doctors.data;

            this.doctorsList.forEach((e: any) => {
                console.log(e);


            });
            this.pagination = data.doctors.pagination?.totalCount
            this.doctorsList.forEach((photo: any) => {
                if (photo.photo) {
                    photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
                }
            })

            this.sortDoctors();
        });

        this._ActivatedRoute.queryParamMap.subscribe(data => {
            const rtdbcs = data.get('rtdbcs');
            if (rtdbcs !== null) {
                this.isAlertActive = true;
            } else {
                this.isAlertActive = false;
            }
        });
    }
    ngOnChanges(changes: SimpleChanges) {
      if (changes.doctorsList) {
        this.sortDoctors();
      }
    }

    sortDoctors() {
      this.doctorsList?.sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    gotoviewadd() {
        if (window.localStorage.getItem('role') === 'Admin') {
            this._Router.navigate([`admin/edit-add-doctor/0`]);
        }else if (window.localStorage.getItem('role') === 'SuperAdmin'){
          this._Router.navigate([`super-admin/edit-add-doctor/0`]);
        } else {
            this._Router.navigate([`home/edit-add-doctor/0`]);
        }
    }
    goToView(id: any): void {
        debugger
        if (id) {
            if (window.localStorage.getItem('role') === 'Admin') {
                this._Router.navigate([`admin/provider-details/${id}`]);
            }else if(window.localStorage.getItem('role') === 'SuperAdmin'){
              this._Router.navigate([`super-admin/provider-details/${id}`]);
            } else {
                this._Router.navigate([`home/provider-details/${id}`]);
            }
        }
    }



    pageChange(pagechangedata: any) {
        this._NgxLoader.start()
        this._MainService.getDoctors(pagechangedata.pageIndex + 1, 10, "").then((data: any) => {
            if (data.success) {
                this.doctorsList = data.data;
                this.sortDoctors();
                this._NgxLoader.stop()
                this.isBusy = false
                this.doctorsList.forEach((photo: any) => {
                    if (photo.photo) {
                        photo.customphotourl = `https://spineplusbe.azurewebsites.net/${photo.photo.substring(7, photo.photo.length)}`
                    }
                })
            }
            else {
                this._NgxLoader.stop()
                this.isBusy = false

            }

        })

    }


}
