import { Component, Input, OnInit,TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplate } from '../../../services/emailTemplate/emailTemplate.service';


@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  //styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {
  selectedFile: any = [];
  addPhotos: FormGroup;
  role:any;
  fileName: any = [];
  imageList: any;
  PatientDetails: any;
  PatientProviderList: any = [];
  toEmail: any = [];
  CurrentUserInfo: any;
  @Input() patientID: any;
  pagination: any;
  isInvalid: boolean;
  isfile: boolean;
  deleteId: any;
  constructor(
    private _FormBuilder: FormBuilder,
    private _MainService: MainService,
    private _NgxLoader: NgxUiLoaderService,
    private _NotifierService: NotifierService,
    private _EmailTemplate: EmailTemplate,
    private _BsModalService: BsModalService
    ) {
    this.addPhotos = this._FormBuilder.group({ photos: null })
    this.isfile = false
    this.isInvalid = false;
  }

  ngOnInit(): void {

    this.role = window.localStorage.getItem("role")
    this._MainService.getPatientPhotos(10, 1, this.patientID).then((data: any) => {
      if (data?.success) {
        this.imageList = data.data;
        this.pagination = data.pagination;
      }
    })
     // Extraction for Sending Email
     this._MainService.getPatientsbyPatientID(this.patientID, false).then(data => {
      this.PatientDetails = data.data;

      if (this.PatientDetails.provider && this.PatientDetails.provider.length > 0) {
        this.PatientProviderList = this.PatientDetails.provider.map((provider:any) => ({
          firstName: provider?.firstName || '',
          lastName: provider?.lastName || '',
          _id: provider?._id || '',
        }));
      }
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.CurrentUserInfo = data.data
      }
    })

    this._MainService.getProviderNameList(true, false, false).then((data) => {
      const emailList = [];
      if (this.PatientProviderList && this.PatientProviderList.length > 0) {
        for (const provider of this.PatientProviderList) {
          const matchedProvider = data.data.find((p: any) => p._id === provider._id);
          const email = matchedProvider ? matchedProvider.email : '';
          if (email && email !== '') {
            emailList.push(email);
          }
        }
      }
      this.toEmail = emailList;
    });
  }

  recordsFile(event: any) {
    debugger
    this.addPhotos.reset()
    this.selectedFile = []
    this.fileName = []

    if (event.target !== null) {
        const files = (<HTMLInputElement>event.target).files;
        if (files?.length) {
            const fileList: FileList = files;
            for (let i = 0; i < fileList.length; i++) {
                const file: File = fileList[i];
                const fileTester = file.name.substring((file.name.length - 3), file.name.length).toLowerCase();

                if (fileTester === "jpg" || fileTester === "peg" || fileTester === "png") {
                    this.isInvalid = false;
                    this.selectedFile.push(file);
                    this.fileName.push(file.name);
                } else {
                    this.isInvalid = true;
                    this._NgxLoader.stop();
                    this._NotifierService.showError("Only PNG/JPEG is accepted");
                    return; // Stop processing further files
                }
            }
            this.addPhotos.controls.photos.setValue(this.fileName);
            this.isfile = false;
        }
    }
}


  getPreparedFormData() {
    let formData = new FormData()

    if (this.selectedFile.length) {
      this.selectedFile.forEach((x: any) => {
        formData.append('photos', x, x.name)
      });
    }
    if (this.patientID !== null) { formData.append('patientId', this.patientID) }
    return formData
  }
  messageEmail() {
    this._MainService.sendEmail(this.takeformData(), true).then((data: any) => {
      console.log(data);
    })
  }

  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.toEmail)
    formData.append('html', this._EmailTemplate.updateEmailTemplate(
      this.PatientDetails.fullName,
      this.PatientDetails.patientUID,
      this.CurrentUserInfo?.fullName,
      "Photos"
    ))
    formData.append('message', "Patient Notes Update")

    return formData
  }

  recordsSubmitData() {
    debugger
    if (this.addPhotos.controls?.photos.value !== null) {
      this.isfile = false;
      this._NgxLoader.start()
      if (this.isInvalid) {
        this._NgxLoader.stop()
        this._NotifierService.showError("Only PNG/JPEG is accepted")
      } else {
        this._MainService.addPatientPhotos(this.getPreparedFormData()).then((data: any) => {
          if (data?.success) {
            this.addPhotos.reset()
            this.messageEmail()
            this.selectedFile = []
            this._NotifierService.showSuccess("Photos added Successfully")
            this._MainService.getPatientPhotos(10, 1, this.patientID).then((data: any) => {
              if (data?.success) {
                this.imageList = data.data;
                this.pagination = data.pagination;
              }
            })
            this._NgxLoader.stop()
          } else {
            this._NotifierService.showError("Something went wrong")
            this._NgxLoader.stop()
          }
        })
      }
    } else {
      this.isfile = true;
    }
  }
  pageChange(pagechangedata: any) {
    this._NgxLoader.start();
    this._MainService.getPatientPhotos(10, pagechangedata.pageIndex + 1, this.patientID).then((data: any) => {
      if (data.success) {
        this.imageList = data.data;

        this.pagination = data.pagination
        this._NgxLoader.stop();
      } else this._NgxLoader.stop();
    });
  }

  openModal(template: TemplateRef<any>, id: any) {
    this.deleteId = id;
    this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }

  deletePhotos() {
    this._NgxLoader.start()
    this._MainService.deletePhoto(this.deleteId).then((data: any) => {
      if (data.success) {
        this._MainService.getPatientPhotos(10, 1, this.patientID).then((data: any) => {
          if (data?.success) {
            this.imageList = data.data;
            this.pagination = data.pagination;
          }
        })
        this._NgxLoader.stop()
        this._NotifierService.showSuccess("Photos Deleted Successfully");
        this._BsModalService.hide()
      } else {
        this._NgxLoader.stop()
        this._NotifierService.showError("Photos cannot be deleted");
      }
    })
  }
}
