import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { MainService } from '../../../../services/mainservice/main.service';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';


@Component({
    selector: 'app-admins-details',
    templateUrl: './admins-details.component.html',
    styles: []
})
export class AdminsDetailsComponent implements OnInit, OnDestroy {

    currentAdmin: any;
    isDeletingAdmin: boolean;
    isPwdShown: boolean;
    subscriptions: boolean;
    currentUser: any;
    isLoading: boolean = true;
    doctorPicurl: string = ''
    role: any;
    isModalOpen: boolean = false;

    constructor(
        private _ActivatedRoute: ActivatedRoute,
        private _MainService: MainService,
        private _NotifierService: NotifierService,
        private _Router: Router,

    ) {

        this.isDeletingAdmin = false;
        this.isPwdShown = false;
        this.subscriptions = true;
    }

    ngOnInit() {

        this._MainService.getCurrentUserInfo().then((data: any) => {
            if (data.success) {
                this.currentUser = data.data

            }
        })


        this.isLoading = true;
        this._ActivatedRoute.data.subscribe(data => {
            if (data.adminDetails !== null) {
                this.isLoading = false
                this.currentAdmin = data.admindetails;


                if (this.currentAdmin.insurance === "null") {
                    this.currentAdmin.insurance = ""
                }

                if (this.currentAdmin.photo) {
                    let photo = this.currentAdmin.photo;
                    this.doctorPicurl = `https://spineplusbe.azurewebsites.net/${photo.substring(7, photo.length)}`
                }
            }
        });

        if (window.localStorage.getItem('role') === "Admin") {
            this.role = 'admin'
        }else  if (window.localStorage.getItem('role') === "SuperAdmin") {
          this.role = 'super-admin'
        } else {
            this.role = 'home'
        }
    }

    ngOnDestroy() {
        this.subscriptions = false;
    }
    openModal() {
        this.isModalOpen = true;
      }

      closeModal() {
        this.isModalOpen = false;
      }
    deleteDoc(AdminId: number): void {
        if (AdminId) {
            this.isDeletingAdmin = true;
            this._MainService.deleteAdminbyid(AdminId).then(data => {
                this.isDeletingAdmin = false;
                if (data.success === true) {
                    this._NotifierService.showSuccess('Admin has been deleted Successfully.');
                    this._Router.navigate(['../../admins'], { relativeTo: this._ActivatedRoute });

                } else {
                    this._NotifierService.showError('Something went wrong');
                }
            });
        }
    }
    goBack(): void { window.history.back(); }



}
