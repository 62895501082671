import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  // styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit {

  //constructor() { }

  ngOnInit(): void {
    console.log(" ");

  }

}
