import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { MainService } from '../../../../services/mainservice/main.service';

@Injectable()

export class PatientDetailsResolver implements Resolve<Array<any> | null | any> {
    constructor(
        private _MainService: MainService
    ) { }
    resolve(route: ActivatedRouteSnapshot): Promise<Array<any> | null | any> {
        var isSeen = false;
        if (route.queryParams.hktpjKqxxLkY === "HwC1fN") {
            isSeen = true;
        }
        var tab = route.queryParams.tab;

        if (route.params.id && route.params.id !== "0") {
            return Promise.all([
                this._MainService.getPatientsbyPatientID(route.params.id, isSeen).then(data => {
                    return data.data;
                }),
                this._MainService.getLawFirmNameList().then((value) => {
                    return value?.data;
                  }),
                Promise.resolve(tab),
                this._MainService.getProviderNameList(true,true,false).then($ => $.data),
                this._MainService.getProviderNameList(true,false,false).then($ => $.data),
            ])
        }
        return Promise.resolve(null);
    }
}
