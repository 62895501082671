import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacilityComponent } from './facility.component';



@NgModule({
  declarations: [FacilityComponent],
  imports: [
    CommonModule
  ]
})
export class FacilityModule { }
