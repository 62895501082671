import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReferralDeatilsComponent } from '@app/shared/components/referral/referral-details/referral-details.component';
import { ReferralComponent } from '@app/shared/components/referral/referral.component';
import { ReferralResolver } from '@app/shared/components/referral/referral.resolver';
import { AttorneyInsuranceDetailsComponent } from './Component/attorney-insurance/attorney-insurance-details/attorney-insurance-details.component';
import { AttorneyinsuranceDetailsResolver } from './Component/attorney-insurance/attorney-insurance-details/attorney-insurance-details.resolver';
import { AttorneyInsuranceComponent } from './Component/attorney-insurance/attorney-insurance.component';
import { EditAddAttorneyInsuranceComponent } from './Component/attorney-insurance/edit-add-attorney-insurance/edit-add-attorney-insurance.component';
import { DashboardComponent } from './Component/Dashboard/dashboard.component';
import { DashboarddResolver } from './Component/Dashboard/dashboard.resolver';
import { AddOrEditFacilityComponent } from './Component/Facility/add-or-edit-facility/add-or-edit-facility.component';
import { FacilityDetailsComponent } from './Component/Facility/facility-details/facility-details.component';
import { FacilityDetailsResolver } from './Component/Facility/facility-details/facility-details.resolver';
import { FacilityComponent } from './Component/Facility/facility.component';
import { FacilityResolver } from './Component/Facility/facility.resolver';
import { MessageComponent } from './Component/Message/message.component';
import { MyAccountComponent } from './Component/My-Account/my-account.component';
import { NotesResolver } from './Component/notes/notes.resolver';
import { NotificationComponent } from './Component/notification/notification.component';
import { NotificationResolver } from './Component/notification/notification.resolver';
import { AddOEditPatientComponent } from './Component/Patient/Add-edit-patient-details/add-edit-patient-details.component';
import { PatientDetailsComponent } from './Component/Patient/Patient-details/patient-details.component';
import { PatientDetailsResolver } from './Component/Patient/Patient-details/patient-details.resolver';
import { PatientsComponent } from './Component/Patient/patient.component';
import { PatientsResolver } from './Component/Patient/patient.resolver';
import { AddOEditDoctorComponent } from './Component/Provider/Add-edit-provider-details/Add-edit-patient-details.component';
import { ProviderDetailsComponent } from './Component/Provider/Provider-details/provider-details.component';
import { ProviderDetailsResolver } from './Component/Provider/Provider-details/provider-details.resolver';
import { ProviderComponent } from './Component/Provider/provider.component';
import { ProviderResolver } from './Component/Provider/provider.resolver';

import { HomeComponent } from './home.component';
import { HomeResolver } from './home.resolver';
import { AdminsComponent } from './Component/Admins/admins.component';
import { AdminsResolver } from './Component/Admins/admins.resolver';
import { AdminsDetailsComponent } from './Component/Admins/Admins-details/admins-details.component';
import { AdminsDetailsResolver } from './Component/Admins/Admins-details/admins-details.resolver';
import { AddOEditAdminComponent } from './Component/Admins/Add-edit-admin-details/Add-edit-admin-details.component';
import { StaffsComponent } from './Component/staffs/staffs.component';
import { StaffsResolver } from './Component/staffs/staffs.resolver';
import { MyBusinessComponent } from './Component/my-business/my-business.component';

const homeRoutes: Routes = [
    {
        path: 'home', component: HomeComponent, resolve: { home: HomeResolver },
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'referrals', component: ReferralComponent, resolve: { referrals: ReferralResolver } },

            { path: 'referral-details', component: ReferralDeatilsComponent },
            { path: 'dashboard', component: DashboardComponent, resolve: { dashboard: DashboarddResolver } },
            { path: 'providers', component: ProviderComponent, resolve: { doctors: ProviderResolver } },
            { path: 'patients', component: PatientsComponent, resolve: { patient: PatientsResolver } },
            { path: 'admins', component: AdminsComponent, resolve: { admins: AdminsResolver } },
            { path: 'facility', component: FacilityComponent },
            { path: 'message', component: MessageComponent },
            { path: 'myaccount', component: MyAccountComponent },
            { path: 'attorney-insurance', component: AttorneyInsuranceComponent },
            { path: 'provider-details/:id', component: ProviderDetailsComponent, resolve: { providerdetails: ProviderDetailsResolver } },
            { path: 'admins-details/:id', component: AdminsDetailsComponent, resolve: { admindetails: AdminsDetailsResolver } },
            { path: 'edit-add-doctor/:id', component: AddOEditDoctorComponent, resolve: { doctor: ProviderDetailsResolver } },
            { path: 'edit-add-patient/:id', component: AddOEditPatientComponent, resolve: { patient: PatientDetailsResolver } },
            { path: 'edit-add-admin/:id', component: AddOEditAdminComponent, resolve: { admin: AdminsDetailsResolver } },
            { path: 'edit-add-facility/:id', component: AddOrEditFacilityComponent, resolve: { facility: FacilityDetailsResolver } },
            { path: 'patient-details/:id', component: PatientDetailsComponent, resolve: { patientdetails: PatientDetailsResolver, notes: NotesResolver } },
            { path: 'edit-add-attorney-insurance/:id', component: EditAddAttorneyInsuranceComponent, resolve: { attorney: AttorneyinsuranceDetailsResolver } },
            { path: 'facility-details/:id', component: FacilityDetailsComponent, resolve: { facilitydetails: FacilityDetailsResolver } },
            { path: 'attorney-insurance-details/:id', component: AttorneyInsuranceDetailsComponent, resolve: { attorneyInsurance: AttorneyinsuranceDetailsResolver } },
            { path: 'notification', component: NotificationComponent, resolve: { notification: NotificationResolver } },

             // Staff routes
             { path: 'staff', component: ProviderComponent, resolve: { doctors: ProviderResolver } },
             { path: 'staff-details/:id', component: ProviderDetailsComponent, resolve: { providerdetails: ProviderDetailsResolver } },
             { path: 'edit-add-staff/:id', component: AddOEditDoctorComponent, resolve: { doctor: ProviderDetailsResolver } },

             { path: 'staffs', component: StaffsComponent, resolve: { staffs: StaffsResolver } },
             { path: 'myBusiness', component: MyBusinessComponent, },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(homeRoutes),RouterModule.forRoot(homeRoutes,{useHash:true})],
    exports: [RouterModule],
    providers: [DashboarddResolver
        , ProviderResolver, PatientsResolver, ProviderDetailsResolver, AttorneyinsuranceDetailsResolver, PatientDetailsResolver, ReferralResolver, FacilityDetailsResolver, FacilityResolver,
        NotificationResolver, AdminsResolver, AdminsDetailsResolver, StaffsResolver]
})
export class HomeRoutingModule { }
