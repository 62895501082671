import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AdminComponent } from "./admin.component";
import { AdminRoutesModule } from "./admin.routing";
import { DashBoardComponent } from './Components/DashBoard/dash-board.component';
import { PatientComponent } from './Components/Patient/patient.component';
import { ProvidersComponent } from './Components/Providers/providers.component';
import { ReferralSpecialistComponent } from './Components/referral-specialist/referral-specialist.component';
import { EditAddReferralspecialistComponent } from './Components/referral-specialist/edit-add-referralspecialist/edit-add-referralspecialist.component';
import { BnNgIdleService } from 'bn-ng-idle';

@NgModule({
    declarations:[
        AdminComponent,
        DashBoardComponent,
        PatientComponent,
        ProvidersComponent,
        ReferralSpecialistComponent,
        EditAddReferralspecialistComponent,
    ],
    imports:[
        // BrowserModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        AdminRoutesModule,
        NgxUiLoaderModule,
        NgbModule,
    ],
providers: [ BnNgIdleService]
})

export class AdminModule{}