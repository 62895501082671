<div *ngIf="isAlertActive" class="alert alert-danger alert-dismissible fade show mb-4" role="alert">
  <strong>Alert!</strong> Please, select any staff first, for whom you want to view the referrals.
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="container my-5" style="padding-left: 0px; padding-right: 0px;">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Staffs</h1>
        <div class="form-row">
          <!-- <div class="col">
            <input
              type="text"
              class="form-control form-control-sm"
              (input)="searchstaff($event)"
              placeholder="Search by: staff name"
              aria-label="Search staff" />
            <span class="alertUpdate" *ngIf="expressionUpdate">Please enter at least 3 characters.</span>
          </div> -->
          <!-- <div class="col-auto" *ngIf="isSearchKeyFilled">
            <button
              class="btn btn-sm btn_m_outline_green"
              type="button"
              id="btn-search-doctor"
              (click)="clearSearch()">
              Clear Filters
            </button>
          </div> -->
          <div class="col-auto">
            <button
            [disabled]="activeStaffCount >= 10"
              class="btn btn-sm btn_m_outline_green"
              (click)="showModal(addStaffTemplate)">
              <i class="fa fa-plus"></i>
              Add New Staff
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 position-relative">
      <div class="table-responsive border border-bottom-0">
        <table class="table table-sp-sm mb-0" aria-describedby="describe">
          <thead class="header_main_bg border-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Staff Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Contacts</th>
              <th scope="col" class="border-bottom-0 border-top-0">Email</th>
              <th scope="col" class="border-bottom-0 border-top-0">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="staffsList !== null; else nostaffFound">
              <ng-container *ngFor="let staff of staffsList">
                <tr class="border-bottom cursor-pointer" >
                  <td>
                    <div class="form-row">
                      <div class="col">
                        <p class="mb-0">
                          <span class="name_limit">{{ staff?.firstName }}</span>
                          <span class="d-inline" *ngIf="staff.highestQualification !== 'null' && staff.highestQualification">
                            ({{ staff.highestQualification }})
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>{{ staff.phone }}</td>
                  <td>{{ staff.email !== "null" && staff.email !== "" ? staff.email : 'No Email' }}</td>
                  <td>
                    <button class="btn btn_m_primary min_width_btn" (click)="editStaff(addStaffTemplate,staff)">
                      <em class="fa fa-pencil-square-o" aria-hidden="true"></em>
                    </button>
                    <button
                      class="btn min_width_btn bg_red text-white ml-2"
                      (click)="openDeleteModal(deleteRecord, staff._id)">
                      <em class="fa fa-trash"></em>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-template #nostaffFound>
              <tr class="border-bottom">
                <td colspan="12">
                  <div class="text-center py-5">
                    <h5 class="font-weight-bold text-muted mt-3">No staff found</h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <mat-paginator [length]="pagination" [pageSize]="10" (page)="pageChange($event)"></mat-paginator>
    </div>
  </div>
</div>

<ng-template #addStaffTemplate>
  <div class="modal-body p-0">
    <div class="modal-header header_main_bg text-white mb-3">
      <h5 class="modal-title font-weight-bold">Add New Staff</h5>
      <button type="button" class="close pull-right" (click)="this.addStaffForm.reset();PageModalRef.hide();showPassword = false;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="px-3">
      <form [formGroup]="addStaffForm">
        <div class="form-group">
          <label for="full-Name-1">Full Name<span class="text-danger">*</span></label>
          <input
            type="text"
            id="full-Name-1"
            class="form-control"
            [ngClass]="{'is-invalid': addStaffForm.controls.firstName.touched && addStaffForm.controls.firstName.invalid}"
            formControlName="firstName"
            placeholder="Enter Full Name">
        </div>
        <div class="form-group">
          <label for="phone-number">Phone<span class="text-danger">*</span></label>
          <input
            type="text"
            appNumberOnly mask="(000)-000-0000" [showMaskTyped]="true"
            id="phone-number"
            class="form-control"
            [ngClass]="{'is-invalid': addStaffForm.controls.phone.touched && addStaffForm.controls.phone.invalid}"
            formControlName="phone"
            placeholder="Enter Phone number">
        </div>
        <div class="form-group">
          <label for="email-address-2">Email<span class="text-danger">*</span></label>
          <input
            type="text"
            id="email-address-2"
            class="form-control"
            [ngClass]="{'is-invalid': addStaffForm.controls.email.touched && addStaffForm.controls.email.invalid}"
            formControlName="email"
            placeholder="Enter Email">
            <span class="text-danger"
                *ngIf=" !!addStaffForm.controls.email.invalid && (addStaffFormSubmitted || !!addStaffForm.controls.email.touched) ">
                <small *ngIf="!!addStaffForm.controls.email.errors.required"></small>
                <small *ngIf="!!addStaffForm.controls.email.errors.pattern">Invalid Email</small>
              </span>
              <small class="text-danger" *ngIf="doesExist">Email already exist</small>
        </div>
        <div *ngIf="addStaffForm.controls.isEdit.value" class="form-group">
          <label for="password-setup-3">Password</label>
          <div class="password-wrapper">
          <input
           [type]="showPassword ? 'text' : 'password'"
            id="password-setup-3"
            class="form-control"
            formControlName="password"
            placeholder="Password">
            <i
              class="fa"
              [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"
              (click)="togglePasswordVisibility()"
              aria-hidden="true">
            </i>
          </div>
        </div>
        <div class="col text-right align-self-end">
          <div class="form-group">
            <button type="submit" class="btn bg_green text-white" [disabled]="addStaffForm.pristine || addStaffForm.invalid"
              (click)="onSubmit()">
              <ng-container *ngIf=" addStaffFormSubmitting; else addStaffFormSubmittingDone "> <span
                  class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Submitting... </ng-container>
              <ng-template #addStaffFormSubmittingDone> Submit <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </ng-template>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #deleteRecord>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>Are you sure you want to delete the staff?</p>
    </h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row float-right">
      <button class="btn min_width_btn bg_red text-white ml-2" (click)="_BsModalService.hide()">No</button>
      <button class="btn min_width_btn bg_green text-white ml-2 mr-4" (click)="deleteStaffs()">Yes</button>
    </div>
  </div>
</ng-template>

