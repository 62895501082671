import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MainService } from "@app/services/mainservice/main.service";

@Injectable()

export class FacilityDetailsResolver implements Resolve<any | null> {
    constructor(
        private _MainService: MainService
    ) { }
    resolve(route: ActivatedRouteSnapshot): Promise<any> | null {
        if (route.params.id && route.params.id !== "0") {
            debugger
            return this._MainService.getFacilityByFacilityID(route.params.id).then(data => {
                return data.data;
            });
        } else {
            return null;
        }
    }
}