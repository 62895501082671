import { Component, OnInit, TemplateRef } from '@angular/core';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ChangePasswordComponent } from '@app/Login/change-password/change-password.component';
import { MainService } from '@app/services/mainservice/main.service';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailTemplate } from '../services/emailTemplate/emailTemplate.service';
import { LogoService } from '@app/services/logo/logo.service';
import { UserService } from '@app/services/userservice/userservice';

//import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  //styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isContainer: boolean
  profilePic: any
  currentUser: any;
  notificationCounter: any
  invite: FormGroup
  inviteLink: any
  fname: any
  lname:any
  role:any

  PageModalRef: BsModalRef | null
  isInviting: boolean = false;
  currEmail: any;
  doesExist: boolean = false;
  url: any;
  isSpinner: any = false;
  profileUpdate: any = false;
  imageUrl: string = '';
  constructor(private _NotifierService: NotifierService,
    private _LogoService: LogoService,
    private _Router: Router,
    private _EmailTemplate: EmailTemplate,
    private userService: UserService,
    //private bnIdle: BnNgIdleService,
     public _BsModalService: BsModalService,
    private _MainService: MainService,
    private _NotificationService: NotificationService,
    private _ActivatedRoute: ActivatedRoute,
    private _FormBuilder: FormBuilder) {
    this.isContainer = false;
    this.PageModalRef = new BsModalRef()
    this.invite = this._FormBuilder.group({
      email: [null, [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
    });
  }


  ngOnInit() {
    this.url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') this.url += ':' + window.location.port;
    this.url += '/invite-signup';

    this.userService.currentUserName.subscribe(name => {
      this.currentUser = name;
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      this.role = data?.data?.role;
      this.imageUrl = data.LogoUrl ? data.LogoUrl : '';
      if (data?.data?.role === "Staff") {
        this._MainService.getProviderNameList(true, false, false).then(data1 => {
          const provider = data1.data.find((x: any) => x._id === data.data.providerId);
          if (provider) {
            this.currEmail = provider.email;
          }
        }).catch(error => console.error('Error fetching provider name list:', error));
      } else {
        this.currEmail = data.data.email;
      }
    }).catch(error => console.error('Error fetching current user info:', error));



    this._ActivatedRoute.data.subscribe(($: any) => {

      if ($ && $.home) {
        this.notificationCounter = $.home
        this._NotificationService.setnotificationData(this.notificationCounter);
      }
    })
    this._NotificationService.getnotificationData().subscribe(x => {
      if (x) this.notificationCounter = x;
    })

    this._NotificationService.getProviderProfileData().subscribe($ => {
      debugger


      if ($) {

        this.currentUser = `${$?.firstName} ${$?.lastName}`;


        window.localStorage.setItem('user', `${$?.firstName} ${$?.lastName}`)
      }
    })

    this._LogoService.logoUrl$.subscribe((url: string) => {
      console.log(url,"url dassy");
      this.imageUrl = url;
    });



    // console.log(this.currEmail,"pppppo");

  }


  showUD() {
    this._NotifierService.showError('Its under development')
  }
  signout() {
    this._Router.navigate(['/login']);
    this._NotificationService.setnotificationData(null);
    this._NotificationService.setProfileData(null)
  }

  myAccount() {
    this._Router.navigate(['/home/myaccount'])
  }
  myStaff() {
    this._Router.navigate(['/home/staffs'])
  }
  myBusiness() {
    this._Router.navigate(['/home/myBusiness'])
  }

  refreshPage() {
    // this._Router.navigate(['/home/dashboard'])
    window.location.href = '/home/dashboard';
  }

  openChangePassWordModal() {
    this.PageModalRef = this._BsModalService.show(ChangePasswordComponent, { class: 'otherlandingpageModal', backdrop: 'static' })
  }

  showModal(template: TemplateRef<any>) {
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'inviteCenter',
      backdrop: 'static',
    });
  }

  // onBlur(data: any) {

  //   if (data !== "") {


  //     this._MainService.emailCheckProvider(data).then((data: any) => {
  //       if (data.success) {

  //         this.doesExist = true
  //       }
  //     })
  //   }
  // }
  onFocus() {

    this.doesExist = false
  }




  inviteEmail() {



    this._MainService.sendEmail(this.takeformData(),false).then((data: any) => {

      console.log(data);

    })

  }
  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.invite.controls.email.value)
    formData.append('html', this._EmailTemplate.inviteEmailTemplate(this.inviteLink, this.fname, this.lname,))
    formData.append('message', "Invitation Link")

    return formData
  }




  onInvitationSend() {
    if (this.invite.valid) {
      this._MainService.emailCheckProvider(this.invite.controls.email.value).then((data: any) => {
        if (!data.success) {
          this.doesExist=false
          this.isSpinner = true;
          this._MainService.sendInvitation({ url: this.url, byEmail: this.currEmail, forEmail: this.invite.controls.email.value }).then((data: any) => {



            if (data.success) {


              this.fname = data.data.firstName
              this.lname = data.data.lastname
              this.inviteLink = data.data.inviteLink
              this.inviteEmail()

              this._BsModalService.hide();
              this.invite.reset();
              this._NotifierService.showSuccess("Invitation Sent")
              this.isSpinner = false;
            } else {
              this.isSpinner = false;
            }
          })
        } else {
          this.doesExist=true
        }
      })
    }
  }
}






