import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../services/mainservice/main.service';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientDetails, ICD10 } from '../../../../models/users/patient';
import { DoctorDLL } from '../../../../models/users/doctor';

import { AddPatient } from 'src/app/models/add-edit-patient.model';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
    selector: 'app-add-o-edit-patient',
    templateUrl: './add-edit-patient-details.component.html'
})
export class AddOEditPatientComponent implements OnInit, OnDestroy {
    public maxDate: Date;
    editMode: boolean;
    currentProviderName: any;
    currentProviderId: any = [];
    providerupdateid: any = {}
    role: any;
    isBusy: boolean = false;
    addOEditPatientForm: FormGroup;
    addOEditPatientFormSubmitted = false;
    addOEditPatientFormSubmitting = false;
    picture: any;
    caseStatus: any
    fileViewer: string;
    providersList: Array<DoctorDLL>;
    ICD10List: Array<ICD10>;
    genders: any;
    isCoveredByInsurance: boolean;
    currePatient: PatientDetails;
    tempPicture: string | null;
    subscriptions: boolean;
    invalidFields: any[];
    attorneyInput$: EventEmitter<string>;
    attorneyLoading: boolean;
    attorneyData: { id: number, name: string }[];
    alive: boolean;
    attorneyFieldToggle: boolean;
    isLawFirmChecked: boolean = false;
    isPIPCheckedYes: boolean;
    isPIPCheckedNo: boolean;
    AddPatientModal: AddPatient;
    patientid: any;
    genderList: any = [];
    showPassword: boolean = false;
    passwordType: string = '';
    providerList: any = [];
    attachment: File | any;
    patientDetails: any;
    networkData: boolean = false;
    isPIP: boolean = false;
    isCheckedInsurance: boolean = false;
    currentUser: any;
    allSelectedProvider: any = [];
    allSelectedProviderAddress: any;
    addressList: any = [];
    selectedAddress: string = '';
    providerEmail: any;
    providerId: any;
    locationId: any;
    locationName: any;
    initialFormState: any;
    isFormChanged = false;
    // myDate: any;
    patientType = [{ name: 'General Patient', _id: 'General Patient' }, { name: 'Commercial Patient', _id: 'Commercial Patient' }, { name: 'Surgical Patient', _id: 'Surgical Patient' }, { name: 'Dropped Patient', _id: 'Dropped Patient' }, { name: 'Completed', _id: 'Completed' }]
    lawFirmList = [];
    constructor(private _ActivatedRoute: ActivatedRoute, private _Router: Router, private _FormBuilder: FormBuilder, private _MainService: MainService, private _NotifierService: NotifierService, public datepipe: DatePipe,private _NgxLoader: NgxUiLoaderService) {
        this.AddPatientModal = new AddPatient();
        this.editMode = false;
        this.fileViewer = 'Choose File';
        this.locationId = '';
        this.providersList = Array<DoctorDLL>();
        this.ICD10List = Array<ICD10>();
        this.genders = [];
        this.isCoveredByInsurance = true;
        this.currePatient = new PatientDetails();
        this.tempPicture = null;
        this.subscriptions = true;
        this.invalidFields = [];
        this.passwordType = 'password'
        this.isPIPCheckedYes = false
        this.isPIPCheckedNo = false
        this.maxDate = new Date();

        this.addOEditPatientForm = this._FormBuilder.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            dateOfBirth: [null, [Validators.required]],
            email: [null, [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
            cellNo: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            homeNo: [null, [Validators.maxLength(15)]],
            workMobileNo: [null],
            addressLine1: [null, [Validators.required]],
            addressLine2: [null],
            city: [null],
            caseStatus: [null, [Validators.required]],
            state: [null],
            // zip: [null, [Validators.minLength(5)]],
            zip: [null, [Validators.required]],
            addICD10: [null],
            socialSecurityNumber: [null],
            occupation: [null],
            dOI: [null],
            employer: [null],
            genderId: [null, [Validators.required]],
            provider: [null, [Validators.required]],
            picture: [null],
            treatingDoctor: [null],
            patientType: [null, [Validators.required]],
            attorney: this._FormBuilder.group({
                firstName: [null],
                lastName: [null],
                phone: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
                fax: [null],
                addressLine1: [null],
                addressLine2: [null],
                email: ['', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
                caseManager: [null],
                lawFirm: [null],
                attorneypatientHavePIP: [false],
                attorneycoveredByInsurance: [false],
            }),
            insurence: this._FormBuilder.group({
                primaryInsurence: [null],
                otherInsurence: [null],
                subscribersName: [null],
                group: [null],
                policy: [null]
            }),
            workCompany: this._FormBuilder.group({
                insurenceName: [null],
                phone: [null, [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
                adjuster: [null],
                fax: [null],
                address: [null],
                adjustersPhone: [null, [Validators.maxLength(15)]],
                adjustersFax: [null],
                network: [false],
                networkName: [null],
                networkClaimNumber: [null]

            })

        })
        this.attorneyData = [];
        this.attorneyLoading = false;
        this.attorneyInput$ = new EventEmitter<string>();
        this.alive = false;
        this.attorneyFieldToggle = false;
    }
    // toggleShow() {

    //     this.passwordType == 'password' ? this.passwordType = 'text' : this.passwordType = 'password';


    // }
    test1() {
        console.log(this.addOEditPatientForm.controls.dateOfBirth?.value);
    }
    getpreparedFormData() {
        let formData = new FormData();
        this.AddPatientModal = this.addOEditPatientForm?.value;
        let dob = this.addOEditPatientForm.controls.dateOfBirth?.value
        formData.append('dateOfBirth', moment(dob).format("YYYY-MM-DDT00:00:00.000Z"));
        if (this.addOEditPatientForm?.controls?.dOI?.value != null) {
            let doi = moment(this.addOEditPatientForm?.controls?.dOI?.value).format('YYYY-MM-DD');
            formData.append('doi', doi);
        }
        formData.append('firstName', this.addOEditPatientForm.controls.firstName?.value);
        if (this.addOEditPatientForm.controls.caseStatus?.value != null) formData.append('caseStatus', this.addOEditPatientForm.controls.caseStatus?.value);
        if (this.addOEditPatientForm.controls.lastName?.value != null) formData.append('lastName', this.addOEditPatientForm.controls.lastName?.value);
        if (this.addOEditPatientForm.controls.email?.value != null) formData.append('email', this.addOEditPatientForm.controls.email?.value);
        if (this.addOEditPatientForm.controls.cellNo?.value != null) formData.append('cellNumber', this.addOEditPatientForm.controls.cellNo?.value);
        if (this.addOEditPatientForm.controls.homeNo?.value != null) formData.append('homeNumber', this.addOEditPatientForm.controls.homeNo?.value);
        if (this.addOEditPatientForm.controls.workMobileNo?.value != null) formData.append('workMobileNumber', this.addOEditPatientForm.controls.workMobileNo?.value);
        if (this.addOEditPatientForm.controls.addressLine1?.value != null) formData.append('addressLine1', this.addOEditPatientForm.controls.addressLine1?.value);
        if (this.addOEditPatientForm.controls.addressLine2?.value != null) formData.append('addressLine2', this.addOEditPatientForm.controls.addressLine2?.value);
        if (this.addOEditPatientForm.controls.city?.value != null) formData.append('city', this.addOEditPatientForm.controls.city?.value);
        if (this.addOEditPatientForm.controls.state?.value != null) formData.append('state', this.addOEditPatientForm.controls.state?.value);
        if (this.addOEditPatientForm.controls.zip?.value != null) formData.append('zip', this.addOEditPatientForm.controls.zip?.value);
        if (this.addOEditPatientForm.controls.addICD10?.value != null) formData.append('addICD10', this.addOEditPatientForm.controls.addICD10?.value);
        if (this.addOEditPatientForm.controls.socialSecurityNumber?.value != null) formData.append('socialSecurityNumber', this.addOEditPatientForm.controls.socialSecurityNumber?.value);
        if (this.addOEditPatientForm.controls.occupation?.value != null) formData.append('occuption', this.addOEditPatientForm.controls.occupation?.value);
        if (this.addOEditPatientForm.controls.employer?.value != null) formData.append('employeer', this.addOEditPatientForm.controls.employer?.value);
        if (this.addOEditPatientForm.controls.patientType?.value != null) formData.append('patientType', this.addOEditPatientForm.controls.patientType?.value);
        formData.append('gender', this.addOEditPatientForm.controls.genderId?.value);
        if (this.picture && this.picture.name) formData.append('photo', this.picture, this.picture.name);
        if (this.role === 'Admin' || this.role === 'SuperAdmin') {
        this.allSelectedProviderAddress = this.allSelectedProvider.map((provider:any) => {

          let location = { locationId: null, locationName: '' };
          if (Array.isArray(provider.Address) && provider.Address.length > 0) {
            const matchedAddress = provider.Address.find((address:any) => address._id === this.locationId);
                if (matchedAddress) {
                    location = { locationId: matchedAddress._id , locationName: matchedAddress.addressLine1 };
                }
             }
               return { providerId: provider._id, location };
            });
             formData.append('treatingDoctor', JSON.stringify(this.addOEditPatientForm.controls.provider?.value));
             // formData.append('provider', JSON.stringify([this.addOEditPatientForm.controls.provider?.value]))
             formData.append('provider', JSON.stringify(this.allSelectedProviderAddress));
        }
        if (this.role === 'Provider' || this.role === 'Staff') {
            if (this.editMode) {
          const allProviderAttached = this.patientDetails?.provider.map((provider:any) => provider.providerId);
          const providers = this.patientDetails?.provider.map((provider: any) => {
            let location = { locationId: null, locationName: '' };

            if (provider.providerId === this.providerId) {
              location = {
                  locationId: this.locationId,
                  locationName: this.locationName
              };
          }
            else if (provider.providerId !== this.providerId) {
                location = {
                    locationId: provider.location.locationId ?? null ,
                    locationName: provider.location.locationName ?? ""
                };
            }

            return {
                providerId: provider.providerId,
                location: location
            };
        });
        formData.append('treatingDoctor', JSON.stringify(allProviderAttached));
        // formData.append('treatingDoctor', JSON.stringify(this.currentProviderId));
        // formData.append('provider', JSON.stringify([this.providerId]));
        formData.append('provider', JSON.stringify(providers));

        }else{
            let location = { locationId: null, locationName: '' };
            if (this.providerId) {
                location = { locationId: this.locationId, locationName: this.locationName };
                }
                const providers = [
                    {
                        providerId: this.providerId,
                        location: location
                        }
                        ];
                        formData.append('treatingDoctor', JSON.stringify(this.currentProviderId));
                        formData.append('provider', JSON.stringify(providers));
    }

        }
        // formData.append('providerLocationId', (this.locationId));
        formData.append('attorney', JSON.stringify(this.addOEditPatientForm.controls.attorney?.value));
        formData.append('insurence', JSON.stringify(this.addOEditPatientForm.controls.insurence?.value));
        formData.append('workComp', JSON.stringify(this.addOEditPatientForm.controls.workCompany?.value));
        if (this.editMode) {
            formData.append('patientId', this.patientid);
            formData.append('isUpdate', JSON.stringify(true));
        }
        else formData.append('isUpdate', JSON.stringify(false));
        return formData
    }


    ngOnInit() {
      this._NgxLoader.start();
        this._MainService.getLawFirmNameList().then((data) => {
            this.lawFirmList = data?.data;
        });

        this.role = window.localStorage.getItem('role')
        // this.myDate = (moment(this.myDate).format("dd-MM-YYYY"));
        if (this.role === 'Provider') {
          // const providerWithLocationId = this.patientDetails?.provider.find((provider:any) => provider.location.locationId !== null);
            this._MainService.getCurrentUserInfo().then(data => {

                this.providerId = data?.data?._id;
                this.addressList = [...data.Address];
                if (this.addressList?.length === 1) {
                    this.locationId = this.addressList[0]?._id;
                    this.locationName = this.addressList[0]?.addressLine1;
                }else{

                const selectedAddressId = this.selectedAddress;
                const selectedAddress = this.addressList.find((address:any) => address._id === selectedAddressId);

                  this.locationId = selectedAddress?._id;
                  this.locationName = selectedAddress?.addressLine1;
                }
                this.providerEmail = data?.data?.email;
                this.currentProviderName = data.data.fullName
                this.currentProviderId.push(data.data._id)


                this.addOEditPatientForm.controls.provider.setValue(this.currentProviderName)
            })
        } else if (this.role === "Staff") {
            this._MainService.getProviderNameList(true, false, false).then(data => {
                this.providerList = data.data;
                this._MainService.getCurrentUserInfo().then(data => {
                    this.addressList = [...data.Address];

                    if (this.addressList?.length === 1) {
                      this.locationId = this.addressList[0]?._id;
                      this.locationName = this.addressList[0]?.addressLine1;
                  }else{
                  const selectedAddressId = this.selectedAddress;
                  const selectedAddress = this.addressList.find((address:any) => address._id === selectedAddressId);

                    this.locationId = selectedAddress?._id;
                    this.locationName = selectedAddress?.addressLine1;
                  }
                    this.currentProviderId.push(data.data.providerId);
                    const matchedProvider = this.providerList.find((provider: any) => provider._id === data.data.providerId);
                    if (matchedProvider) {
                        this.currentProviderName = matchedProvider.name.replace(/\s*\(.*?\)\s*/g, "").trim();
                        this.addOEditPatientForm.controls.provider.setValue(this.currentProviderName);
                    }
                });
            });
        } else {
            this._MainService.getCurrentUserInfo().then((data: any) => {
                if (data.success) {
                    this.providerEmail = data?.data?.email;
                }
            })
            this._MainService.getProviderNameList(true, false, false).then(data => {
                this.providerList = data.data;
            })
        }
        this.isCoveredByInsurance = false;
        this._MainService.getgenderList().then((data: any) => { this.genders = data.data; })
        this._MainService.getCaseStatusList().then((data: any) => { this.caseStatus = data.data })
        this._MainService.getCurrentUserInfo().then((data: any) => {

          if (data.success) {
            if(data.data.role === 'Provider'){
              this.providerId = data?.data?._id;
            }
            if(data.data.role === 'Staff'){
              this.providerId = data?.data?.providerId;
            }
          }

        this._ActivatedRoute.data.subscribe(data => {
            if (data && data?.patient !== null && data.patient !== undefined) {

              this.editMode = true;
              this.patientid = data.patient[0]._id;
              this.patientDetails = data.patient[0];
              this.currePatient = data.patient[0]
                this.addOEditPatientForm.patchValue(data.patient[0]);
                this.addOEditPatientForm.controls.occupation.patchValue(data.patient[0].occuption);
                this.addOEditPatientForm.controls.employer.patchValue(data.patient[0].employeer);
                this.addOEditPatientForm.controls.workMobileNo.patchValue(data.patient[0].workMobileNumber);
                this.addOEditPatientForm.controls.homeNo.patchValue(data.patient[0].homeNumber);
                if (data.patient[0].caseStatus?._id) { this.addOEditPatientForm.controls.caseStatus.setValue(data.patient[0]?.caseStatus?._id) }
                if (this.role === 'Provider' || this.role === 'Staff') {
                  this.addOEditPatientForm.controls.provider.setValue(this.currentProviderName)
                  const providerWithLocationId = data?.patient[0]?.provider.find((provider:any) => provider.providerId === this.providerId);
                  if (providerWithLocationId?.location?.locationId) {
                    this.locationId = providerWithLocationId.location.locationId ;
                    this.locationName = providerWithLocationId.location.locationName ;
                    this.selectedAddress = providerWithLocationId.location.locationId ;
                  } else {
                    this.selectedAddress = '';
                  }
                  // this.selectedAddress = data?.patient[0]?.providerLocationId;
                  // if (this.role === 'Staff') {
                  //   this.providerId = data?.patient[0]?.provider[0]?._id;
                  // }

                  // Admin/SuperAdmin case
                } else {

                  // this.selectedAddress = data?.patient[0]?.providerLocationId;
                  const providerWithLocationId = data?.patient[0]?.provider.find((provider:any) => provider.location.locationId !== null);
                  if (providerWithLocationId) {
                    this.selectedAddress = providerWithLocationId?.location?.locationId;
                  } else {
                    this.selectedAddress = '';
                  }

                  this.locationId = providerWithLocationId?.location?.locationId;

                  const allProviderAttached = data?.patient[0]?.provider.map((provider:any) => provider.providerId);
                  this.addOEditPatientForm.controls.provider.setValue(allProviderAttached);

                  let treatingDoctor: any = [];
                  this.allSelectedProvider = [];
                  // this.addressList = [];
                  let processedProviders = new Set();
                  data.patient[0].provider?.forEach((x: any) => {
                      treatingDoctor.push(x._id);
                      this.providerId = allProviderAttached;

                      if (this.role === 'SuperAdmin' || this.role === 'Admin') {
                          this._MainService.getProviderNameList(true, false, false).then(data => {

                              for (let provider of data.data) {

                                  if (allProviderAttached.includes(provider._id) && !processedProviders.has(provider._id)) {
                                        processedProviders.add(provider._id);
                                        this.allSelectedProvider.push(provider);

                                      if (provider.Address) {
                                        this.addressList.push(...provider.Address);
                                    }
                                      // if (provider.Address?.length === 1) {
                                      //     this.locationId = this.addressList[0]?._id;
                                      // } else {
                                      //     this.locationId = '';
                                      // }
                                      // break;
                                    }
                                }
                            })
                        }
                    });
                }
                let dob = this.addOEditPatientForm.controls.dateOfBirth.value;
                this.addOEditPatientForm.controls.dateOfBirth.setValue(this.datepipe.transform(dob, 'MM/dd/yyyy'))
                if (data.patient[0].doi) {
                    let doi = data.patient[0].doi.substring(0, 10);
                    this.addOEditPatientForm.controls.dOI.setValue(this.datepipe.transform(doi, 'MM/dd/YYYY'))
                }
                if (data.patient[0].attorney.lawFirm !== null) { this.isLawFirmChecked = true; }
                if (data.patient[0].workComp.network) { this.networkData = true } else { this.networkData = false }
                if (data.patient[0].attorney.attorneypatientHavePIP) { this.isPIP = true } else { this.isPIP = false }
                if (data.patient[0].attorney.attorneycoveredByInsurance) { this.isCheckedInsurance = true } else { this.isCheckedInsurance = false }
                this.addOEditPatientForm.controls.cellNo.patchValue(data.patient[0]?.cellNumber);
                if (data.patient[0].gender._id) { this.addOEditPatientForm.controls.genderId.setValue(data?.patient[0]?.gender?._id); }
                if (data.patient[0].patientType) { this.addOEditPatientForm.controls.patientType.setValue(data?.patient[0]?.patientType); }
                this.addOEditPatientForm.controls.workCompany.patchValue(data.patient[0].workComp);
                this.addOEditPatientForm.controls.insurence.patchValue(data.patient[0].insurence);
                this.attorneyFieldToggle = true;
                this.tempPicture = this.currePatient.picture;
              }
            });
          });
          this.initialFormState = this.addOEditPatientForm.value;

          this.addOEditPatientForm.valueChanges.subscribe(() => {
            this.checkFormChanges();
          });

          this._NgxLoader.stop();

    }

    checkFormChanges() {
        // Compare the current form value with the initial form value
        this.isFormChanged = JSON.stringify(this.addOEditPatientForm.value) !== JSON.stringify(this.initialFormState);
    }

    lawFirm(event: any) {
        if (event.target.checked) {
            this.isLawFirmChecked = true;
        }
        else {
            this.isLawFirmChecked = false;
            this.addOEditPatientForm.controls.attorney.get('lawFirm')?.setValue(null)
        }
    }
    ngOnDestroy() {
        this.subscriptions = false;
    }
    network(data: string) {
        if (data === 'yes') {
            this.networkData = true
            this.addOEditPatientForm.controls.workCompany.get('network')?.setValue(true)
        } else {
            this.networkData = false
            this.addOEditPatientForm.controls.workCompany.get('network')?.setValue(false)
        }
    }
    doesPIP(data: string) {
        if (data === 'yes') {
            this.isPIP = true
            this.addOEditPatientForm.controls.attorney.get('attorneypatientHavePIP')?.setValue(true)
        } else {
            this.isPIP = false
            this.addOEditPatientForm.controls.attorney.get('attorneypatientHavePIP')?.setValue(false)
        }
    }

    isCovered(data: string) {
        debugger
        if (data === 'yes') {
            this.isCheckedInsurance = true
            this.addOEditPatientForm.controls.attorney.get('attorneycoveredByInsurance')?.setValue(true)
        } else {
            this.isCheckedInsurance = false
            this.addOEditPatientForm.controls.attorney.get('attorneycoveredByInsurance')?.setValue(false)
        }
    }
    goBack(): void {
        window.history.back();
    }

    fileChange(event: Event): void {
        if (event.target !== null) {
            const files = (<HTMLInputElement>event.target).files;
            if (files !== null) {
                const fileList: FileList = files;
                if (fileList && fileList.length > 0) {
                    const file: File = fileList[0];
                    this.picture = file;
                    this.fileViewer = file.name;
                }
            }
        }
    }
    onAttachementChange(event: Event) {
        debugger
        if (event.target) {
            const files: FileList | null = (event.target as HTMLInputElement).files;
            if (files !== null && files.length) {
                this.attachment = files[0];
            }
        }
    }
    updatePatient() {
        if (this.role === 'Provider' || this.role === 'Staff') {
            this.providerupdateid = {
                providerId: this.currentProviderId
            }
        }
        if (this.role === 'Admin') {
            this.providerupdateid = {
                providerId: this.addOEditPatientForm.controls.provider?.value
            }
        }
        if (this.role === 'SuperAdmin') {
            this.providerupdateid = {
                providerId: this.addOEditPatientForm.controls.provider?.value
            }
        }

    }
  //   addOEditPatientFormSubmit(): void {
  //     console.log(this.getpreparedFormData() ,'formdata');
  // }
    addOEditPatientFormSubmit(): void {
      console.log(this.getpreparedFormData(),'formdata');
      console.log(this.providerId,'this.providerId');

        if ((this.role === 'Provider' || this.role === 'Staff') && (this.addressList?.length >= 2)) {
            if (!this.locationId) {
                this._NotifierService.showError('Select the location field');
                return;
            }
        }

        if (!this.addOEditPatientForm.valid) {
            this._NotifierService.showError('Fill out all the required fields');
            return;
        }

        this.addOEditPatientFormSubmitted = true;
        if (this.addOEditPatientForm.valid) {
            this.isBusy = true;
            if (this.editMode === true) {
                this.addOEditPatientFormSubmitting = true;
                this._MainService.addPatient(this.getpreparedFormData()).then((data: any) => {
                    this.addOEditPatientFormSubmitting = false;
                    if (data.success === true) {
                        this.isBusy = false;
                        this.updatePatient()
                        this._MainService.updateCount(this.providerupdateid).then((data: any) => {
                            console.log(data);
                        })
                        this._NotifierService.showSuccess("Patient Updated Successfully");
                        this._Router.navigate([`../../patient-details/${data.data._id}`], { relativeTo: this._ActivatedRoute });
                    } else {
                        if (data?.message === "Same Patient is already present") {
                            this._NotifierService.showError('Same Patient is already present');
                        } else {
                            this._NotifierService.showError('Something Went Wrong..!');
                        }
                    }
                });
            } else {
                this.addOEditPatientFormSubmitting = true;
                this._MainService.addPatient(this.getpreparedFormData()).then((data: any) => {
                    this.addOEditPatientFormSubmitting = false;
                    if (data.success === true) {
                        this.isBusy = false;
                        this.updatePatient()
                        this._MainService.updateCount(this.providerupdateid).then((data: any) => {
                            console.log(data);
                        })
                        this._NotifierService.showSuccess("Patient Added Successfully");
                        this._Router.navigate([`../../patient-details/${data.data._id}`], { relativeTo: this._ActivatedRoute });
                    } else {
                        if (data?.message === "User Already Exist") {
                            this._NotifierService.showError('Same Patient is already present');
                        }else if (data?.message === "Same Patient is already present") {
                            this._NotifierService.showError('Same Patient is already present');
                        } else {
                            this._NotifierService.showError('Something Went Wrong..!');
                        }
                    }
                });
            }
        }
        else this.invalidFields = [].slice.call(document.querySelectorAll('.main-patient-form .ng-invalid'));
    }
    imgError(): void { this.tempPicture = null; }

    test(data: any) { console.log(data) }

    changeFn(value: any) {

      this.addressList = [];
      this.allSelectedProvider = value ;

        if (this.role === 'SuperAdmin' || this.role === 'Admin') {
            if (Array.isArray(value)) {
              value.forEach(provider => {
                  if (Array.isArray(provider?.Address) && provider.Address.length > 0) {
                      this.addressList.push(...provider.Address);
                  }
              });
          } else {
              if (Array.isArray(value?.Address) && value.Address.length > 0) {
                  this.addressList = value.Address;
              }
          }

      }else{
          if (this.addressList.length === 1) {
              this.locationId = this.addressList[0]?._id;
          }
      }

        const inputString = value?.name;
        if (inputString === undefined) {
            this.providerId = '';
            this.addOEditPatientForm.controls.attorney.get('firstName')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('lastName')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('phone')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('fax')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('addressLine1')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('addressLine2')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('email')?.setValue('')
            this.addOEditPatientForm.controls.attorney.get('caseManager')?.setValue('');
        } else {
            this.providerId = value?._id;
            const match = inputString.match(/\(([^)]+)\)/);

            const valueInsideParentheses = match ? match[1] : null;
            if (valueInsideParentheses === "Attorney") {
                this._MainService.getProvidersbyProviderID(value?._id).then(data => {
                    this.addOEditPatientForm.controls.attorney.get('firstName')?.setValue(data?.data?.firstName ? data?.data?.firstName : '')
                    this.addOEditPatientForm.controls.attorney.get('lastName')?.setValue(data?.data?.lastName ? data?.data?.lastName : '')
                    this.addOEditPatientForm.controls.attorney.get('phone')?.setValue(data?.data?.phone ? data?.data?.phone : '')
                    this.addOEditPatientForm.controls.attorney.get('fax')?.setValue((data?.data?.fax && data?.data?.fax !== "null") ? data?.data?.fax : '')
                    this.addOEditPatientForm.controls.attorney.get('addressLine1')?.setValue((data?.data?.addressLine1 && data?.data?.addressLine1 !== "null") ? data?.data?.addressLine1 : '')
                    this.addOEditPatientForm.controls.attorney.get('addressLine2')?.setValue((data?.data?.addressLine2 && data?.data?.addressLine2 !== "null") ? data?.data?.addressLine2 : '')
                    this.addOEditPatientForm.controls.attorney.get('email')?.setValue(data?.data?.email ? data?.data?.email : '')
                    this.addOEditPatientForm.controls.attorney.get('caseManager')?.setValue((data?.data as any)?.insurance ? (data?.data as any)?.insurance : '');
                })
            } else {
                this.addOEditPatientForm.controls.attorney.get('firstName')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('lastName')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('phone')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('fax')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('addressLine1')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('addressLine2')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('email')?.setValue('')
                this.addOEditPatientForm.controls.attorney.get('caseManager')?.setValue('');
            }
        }
    }
    onAddressSelect(event: any): void {
        if (event === undefined) {
            this.locationId = ''
            this.locationName = ''
            if (this.role === "Provider" || this.role === "Staff") {
                this.addOEditPatientForm.markAsPristine();
            }
        } else {
            const selectedAddress = event;
            this.locationId = selectedAddress?._id;
            if (this.role === "Provider" || this.role === "Staff") {
            this.locationName = selectedAddress?.addressLine1;
            }
            if (this.editMode && this.addOEditPatientForm.valid) {
                this.addOEditPatientForm.markAsDirty();
            }
        }
    }
}
