import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { MainService } from '../../../../services/mainservice/main.service';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';
import { MasterDataDDL } from '../../../../models/masterdata-ddl';
import { EmailTemplate } from '../../../../services/emailTemplate/emailTemplate.service';
import { Observable, of } from 'rxjs';
import { ChangeProviderPassWord } from "../../../../models/change-password.model";



@Component({
    selector: 'app-add-o-edit-doctor',
    templateUrl: './Add-edit-patient-details.component.html',
    styles: []
})
export class AddOEditDoctorComponent implements OnInit {
    isAttorney = false
    url: any
    isFacility = false
    insuranceValidator = false
    associatedDoctorValidator = false
    providerList: any
    editMode: boolean;
    addDoctorForm: FormGroup;
    addDoctorFormSubmitting = false;
    addDoctorFormSubmitted = false;
    firstName: any;
    selectedProviderType: string = "";
    lastName: any;
    genders: any;
    defaultPassword: any;
    sentEmail: any;
    providerType: any;
    roleId: number;
    profileImage: any;
    fileViewer: string;
    specialities: Array<MasterDataDDL>;
    currDortor: any;
    tempPicture: string | null;
    invalidFields: any[];
    speciality: any;
    doesExist: boolean = false;
    providerId: any;
    role: any;
    specialityValidator: any;
    genderValidator: any;
    submitValidating: any;
    isModalOpen: boolean = false;
    isModalOpen2: boolean = false;
    changePasswordForm: FormGroup;
    isChangePwdFrmSubmitted = false;
    isChangePwdFrmSubmitting = false;
    showPassword: boolean = false;
    constructor(
        private _ActivatedRoute: ActivatedRoute,
        private _FormBuilder: FormBuilder,
        private _MainService: MainService,
        private _NotifierService: NotifierService,
        private _Router: Router,
        private _EmailTemplate: EmailTemplate

    ) {
        this.editMode = false;
        this.specialityValidator = true;
        this.genderValidator = true;
        this.addDoctorForm = this._FormBuilder.group({
            firstName: ['', [Validators.required]],
            lastName: [''],
            genderId: [],
            phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            email: ['', [Validators.required,Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
            fax: [null, [Validators.minLength(10), Validators.maxLength(15)]],
            // highestQualification: [null],
            ClinicAffiliationsList: this._FormBuilder.array([this._FormBuilder.control('')]),
            specialityList: [null],
            providerType: [null, [Validators.required]],
            photo: [''],
            addressLine1: [null, [Validators.required]],
            addressLine2: [''],
            zip: [null, [Validators.required]],
            businessName: [''],
            associatedprovider: [null],
            contactPerson: [null],
            insurance: [null]
        });
        this.changePasswordForm = this._FormBuilder.group({
            newPassword: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$<=>?@])/)]
            ],
            confirmPassword: ['', [Validators.required], this.checkconfirmPassword()]
        });
        this.speciality = [];
        this.genders = [];
        this.providerType = [];
        this.roleId = 0;
        this.fileViewer = 'Choose File';
        this.specialities = Array<MasterDataDDL>();
        this.tempPicture = null;

        this.invalidFields = [];
    }


    ngOnInit() {
        this.url = window.location.protocol + '//' + window.location.hostname;
        if (window.location.port !== '') this.url += ':' + window.location.port;
        console.log(this.url);

        this._MainService.getProviderNameList(true, false, false).then((data: any) => {
            if (data.success) {
                this.providerList = data?.data?.filter((e: any) => {
                    if (e.providerType?.name == 'Doctor') {
                        return e;
                    }
                })
            }
        })
        this._MainService.getProviderType().then(($: any) => {
            this.providerType = $.data
        });
        this._MainService.getgenderList().then((data: any) => {
            this.genders = data.data;
        })
        this._MainService.getCurrentUserInfo().then((data: any) => {
            if (data.success) {
                this.role = data.data.role
            }
        })

        this._MainService.getspecialitiesList().then((data: any) => {
            this.speciality = data.data;
        })

        this._ActivatedRoute.data.subscribe(data => {
            if (data.doctor !== null && data.doctor !== undefined) {
                this.editMode = true;
                this.providerId = data.doctor.id
                this.currDortor = data.doctor;
                this.providerChange(this.currDortor.providerType);
                let speciality: any = [];
                data.doctor.specialilties?.forEach((x: any) => {
                    speciality.push(x._id);
                    this.addDoctorForm.controls.specialityList.setValue(speciality)
                });
                this.addDoctorForm.patchValue(data.doctor);
                this.addDoctorForm.controls.providerType.setValue(this.currDortor.providerType._id);
                let associated: any = [];
                data.doctor.associatedDoctor?.forEach((x: any) => {
                    associated.push(x._id);
                    this.addDoctorForm.controls.associatedprovider.setValue(associated)
                })
                this.addDoctorForm.controls.addressLine1.patchValue(data.doctor.addressLine1);
                this.addDoctorForm.controls.businessName.patchValue(data.doctor.businessName);
                this.addDoctorForm.controls.zip.patchValue(data.doctor.zip);
                if (this.addDoctorForm.controls.contactPerson.value === "null") { this.addDoctorForm.controls.contactPerson?.setValue("") }
                if (this.addDoctorForm.controls.addressLine1.value === "null") { this.addDoctorForm.controls.addressLine1?.setValue("") }
                if (this.addDoctorForm.controls.businessName.value === "null") { this.addDoctorForm.controls.businessName?.setValue("") }
                if (this.addDoctorForm.controls.addressLine2.value === "null") { this.addDoctorForm.controls.addressLine2?.setValue("") }
                if (this.addDoctorForm.controls.zip.value === "null") { this.addDoctorForm.controls.zip?.setValue("") }
                if (this.addDoctorForm.controls.insurance.value === "null") { this.addDoctorForm.controls.insurance?.setValue("") }
                if (this.addDoctorForm.controls.email.value === "null") { this.addDoctorForm.controls.email?.setValue("") }
                if (this.addDoctorForm.controls.fax.value === "null") { this.addDoctorForm.controls.fax?.setValue("") }
                // if (this.addDoctorForm.controls.highestQualification.value === "null") { this.addDoctorForm.controls.highestQualification?.setValue("") }
                this.addDoctorForm.controls.genderId.setValue(data.doctor.gender?._id);
                this.addDoctorForm.setControl('ClinicAffiliationsList', this._FormBuilder.array(data.doctor.clinicAffiliations || ['']));
                this.tempPicture = this.currDortor.photo;
            }
        });
        this.providerType.map(($: any) => {
            return console.log($)
        })
    }

    getFormData(): FormData {
        const formData: FormData = new FormData();
        let speciality = this.addDoctorForm.controls.specialityList.value
        formData.append('firstName', this.addDoctorForm.controls.firstName.value);
        formData.append('lastName', this.addDoctorForm.controls.lastName.value);
        formData.append('email', this.addDoctorForm.controls.email.value);
        formData.append('phone', this.addDoctorForm.controls.phone.value);
        formData.append('associatedDoctor', JSON.stringify(this.addDoctorForm.controls.associatedprovider.value));
        formData.append('contactPerson', this.addDoctorForm.controls.contactPerson.value);
        formData.append('insurance', this.addDoctorForm.controls.insurance.value);
        if (this.addDoctorForm.controls.genderId.value !== null && this.genderValidator) {
            formData.append('gender', this.addDoctorForm.controls.genderId.value);
        }
        formData.append('providerType', this.addDoctorForm.controls.providerType.value);
        formData.append('addressLine1', this.addDoctorForm.controls.addressLine1.value);
        formData.append('addressLine2', this.addDoctorForm.controls.addressLine2.value);
        formData.append('zip', this.addDoctorForm.controls.zip.value);
        formData.append('businessName', this.addDoctorForm.controls.businessName.value);
        formData.append('fax', this.addDoctorForm.controls.fax.value);
        formData.append('isFacility', JSON.stringify(this.isFacility));
        formData.append('isAttorney', JSON.stringify(this.isAttorney));
        formData.append('specialilties', JSON.stringify(speciality));
        if (this.addDoctorForm.controls.ClinicAffiliationsList.value[0] !== null && this.addDoctorForm.controls.ClinicAffiliationsList.value[0] !== '') {
            formData.append('clinicAffiliations', JSON.stringify(this.addDoctorForm.controls.ClinicAffiliationsList.value));
        }
        //  formData.append('highestQualification', this.addDoctorForm.controls.highestQualification.value);
        if (this.editMode) {
            formData.append('isUpdate', JSON.stringify(true));
            formData.append('providerId', this.providerId);
        }
        else {
            formData.append('isUpdate', JSON.stringify(false));
        }

        if (this.profileImage && this.profileImage.name) {
            formData.append('photo', this.profileImage, this.profileImage.name);
        }
        return formData;
    }
    get ClinicAffiliationsLists(): FormArray {
        return this.addDoctorForm.controls.ClinicAffiliationsList as FormArray;
    }

    addClinicAffiliationsList(): void {
        this.ClinicAffiliationsLists.push(this._FormBuilder.control('', [Validators.required]));
    }

    removeClinicAffiliationsList(): void {
        this.ClinicAffiliationsLists.removeAt(this.ClinicAffiliationsLists.length - 1);
    }



    fileChange(event: Event): void {

        if (event.target !== null) {
            const files = (<HTMLInputElement>event.target).files;
            if (files !== null) {
                const fileList: FileList = files;
                if (fileList && fileList.length > 0) {
                    const file: File = fileList[0];
                    this.profileImage = file;
                    this.fileViewer = file.name;
                }
            }
        }
    }
    checkconfirmPassword(): ValidatorFn {
        return (constrol: AbstractControl): Observable<{ [key: string]: any } | null> => {
            if (constrol.value === this.changePasswordForm.controls.newPassword.value) {
                return of(null);
            } else {
                return of({ 'notSame': true });
            }
        };
    }

    addDoctorFormSubmit(): void {
        this.addDoctorFormSubmitted = true;
        if (this.addDoctorForm.valid && !this.doesExist) {
            if (this.editMode === true) {
                this.addDoctorFormSubmitting = true;
                this._MainService.registerDoctor(this.getFormData()).then((data: any) => {
                    this.addDoctorFormSubmitting = false;
                    if (data?.success === true || data?.success === 'true') {
                        this.defaultPassword = data.data.defaultPassword
                        this.sentEmail = data.data.email
                        this._NotifierService.showSuccess('Provider updated Successfully.');
                        if (data.sendEmail === true) {
                            this.sendEmail();
                        }
                        if (this.role === 'Admin') {
                            this._Router.navigate(['admin/providers']);
                        } else if (this.role === 'SuperAdmin') {
                            this._Router.navigate(['super-admin/providers']);
                        }
                        else {
                            this._Router.navigate(['home/providers']);
                        }
                    } else {
                        if(data?.message === "User Already Exist"){

                            this._NotifierService.showError('Provider with same location already exist');
                        }else if (data?.message === "Already Exist in System") {
                            this._NotifierService.showError('Provider Already Exist');
                        } else {
                            this._NotifierService.showError('Something went wrong..!');
                        }
                    }
                });
            } else {
                this.addDoctorFormSubmitting = true;
                this._MainService.registerDoctor(this.getFormData()).then((data: any) => {
                    this.addDoctorFormSubmitting = false;
                    if (data?.success === true || data?.success === 'true') {
                    this.defaultPassword = data.data.defaultPassword
                    this.sentEmail = data.data.email
                        this._NotifierService.showSuccess('Provider added Successfully.');
                        this._MainService.updateCount({ providerId: [data.data._id] }).then((data: any) => {
                            console.log(data);
                        })
                        if (data.sendEmail === true) {
                            this.sendEmail();
                        }
                        if (this.role === 'Admin') {
                            this._Router.navigate(['admin/providers']);
                        } else if (this.role === 'SuperAdmin') {
                            this._Router.navigate(['super-admin/providers']);
                        }
                        else {
                            this._Router.navigate(['home/providers']);
                        }
                    } else {

                        if(data?.message === "User Already Exist"){
                            this._NotifierService.showError('Provider with same location already exist');
                        }else if (data?.message === "Already Exist in System") {
                            this._NotifierService.showError('Provider Already Exist');
                        } else {
                            this._NotifierService.showError('Something went wrong..!');
                        }
                    }
                });
            }
        } else {
            if (!this.doesExist) {
                this.invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            }
        }
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.changePasswordForm.reset();
        this.isModalOpen = false;
    }
    openModal2() {
        this.isModalOpen2 = true;
    }

    closeModal2() {
        this.isModalOpen2 = false;
    }
    updatePassword(): void {
        this.isChangePwdFrmSubmitted = true;
        if (this.changePasswordForm.valid) {
            this.isChangePwdFrmSubmitting = true;
            let form: ChangeProviderPassWord = new ChangeProviderPassWord();
            form.id = this.providerId;
            form.newPassword = this.changePasswordForm.controls.newPassword.value
            this._MainService.changeProviderPassword(form).then(data => {

                this.isChangePwdFrmSubmitting = false;
                this.isChangePwdFrmSubmitted = false;
                if (data.success === true) {
                    this.changePasswordForm.reset();
                    this.isModalOpen = false;
                    this._NotifierService.showSuccess('Password changed Successfully..!');
                } else {
                    this._NotifierService.showError('Something went wrong..!');
                }
            });
        }
    }
// togglePasswordVisibility(controlName: string): void {
//     const control = this.changePasswordForm.get(controlName);

//     if (control) {
//       const currentType = control.get('type')?.value;

//       if (currentType === 'password') {
//         control.get('type')?.setValue('text');
//       } else {
//         control.get('type')?.setValue('password');
//       }
//     }
//   }

  changeRole(){
    this._MainService.changeRole(this.providerId, 'Admin').then((data: any) => {
        if (data.success === true) {
            this.isModalOpen2 = false;
            this._NotifierService.showSuccess('User Role Updated Successfully..!');
            this._Router.navigate(['super-admin/providers']);
        } else {
            this._NotifierService.showError('Something went wrong..!');
        }

      })
  }


    goBack(): void {
        window.history.back();
    }

    imgError(): void {
        this.tempPicture = null;
    }
    onBlur(data: any) {

        if (data.target.value !== "") {


            this._MainService.emailCheckProvider(data.target.value).then((data: any) => {
                if (data.success) {

                    this.doesExist = true
                }
            })
        }
    }

    sendEmail() {
        this._MainService.sendEmail(this.takeformData(), false).then((data: any) => {
            console.log(data);
        })
    }

    takeformData(): FormData {
        const formData: FormData = new FormData();
        formData.append('email', this.sentEmail)
        formData.append('html', this._EmailTemplate.welcomeEmailTemplate(this.sentEmail, this.defaultPassword, this.url))
        formData.append('message', "Login Credential")
        return formData
    }

    onFocus() {
        this.doesExist = false
    }

    providerChange(data: any) {
        this.selectedProviderType = data.name
        if (data.name === 'Attorney') {
            this.isAttorney = true;
        }
        else {
            this.isAttorney = false;
        }
        if (data.name === 'Facility') {
            this.isFacility = true;
        }
        else {
            this.isFacility = false;
        }
        if (data.name !== 'Referral Coordinator' && data.name !== 'Attorney' && data.name !== 'Facility' && data.name !== 'Imaging Center') {
            this.specialityValidator = true;
        } else {
            this.specialityValidator = false;
        }
        if (data.name === 'Imaging Center' || data.name === 'Facility') {
            this.genderValidator = false;
        } else {
            this.genderValidator = true;
        }

        if (data.name === 'Attorney') {
            this.insuranceValidator = true;
        } else {
            this.insuranceValidator = false;
        }
        if (data.name === 'Facility') {
            this.associatedDoctorValidator = true;
        } else {
            this.associatedDoctorValidator = false;
        }
        if (data.name === 'Doctor') {
            this.addDoctorForm.get('specialityList')?.setValidators([Validators.required])
            this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
            this.addDoctorForm.get('genderId')?.setValidators([Validators.required])
            this.addDoctorForm.get('genderId')?.updateValueAndValidity();
            this.addDoctorForm.get('lastName')?.setValidators([Validators.required])
            this.addDoctorForm.get('lastName')?.updateValueAndValidity();
            this.addDoctorForm.get('associatedprovider')?.setValidators([])
            this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
            this.addDoctorForm.get('insurance')?.setValidators([])
            this.addDoctorForm.get('insurance')?.updateValueAndValidity();
        } else {
            if (data.name === 'Referral Coordinator' || data.name === 'Attorney') {
                this.addDoctorForm.get('specialityList')?.setValidators([])
                this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
                this.addDoctorForm.get('genderId')?.setValidators([Validators.required])
                this.addDoctorForm.get('genderId')?.updateValueAndValidity();
                this.addDoctorForm.get('associatedprovider')?.setValidators([])
                this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
            }
            if (data.name === 'Imaging Center') {
                this.addDoctorForm.get('genderId')?.setValidators([])
                this.addDoctorForm.get('genderId')?.updateValueAndValidity();
                // this.addDoctorForm.get('specialityList')?.setValidators([Validators.required])
                // this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
                this.addDoctorForm.get('associatedprovider')?.setValidators([])
                this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
                this.addDoctorForm.get('insurance')?.setValidators([])
                this.addDoctorForm.get('insurance')?.updateValueAndValidity();
            }
            if (data.name === 'Facility') {
                this.addDoctorForm.get('genderId')?.setValidators([])
                this.addDoctorForm.get('genderId')?.updateValueAndValidity();
                this.addDoctorForm.get('lastName')?.setValidators([])
                this.addDoctorForm.get('lastName')?.updateValueAndValidity();
                this.addDoctorForm.get('specialityList')?.setValidators([]);
                this.addDoctorForm.get('specialityList')?.updateValueAndValidity();
                // this.addDoctorForm.get('associatedprovider')?.setValidators([Validators.required])
                // this.addDoctorForm.get('associatedprovider')?.updateValueAndValidity();
                this.addDoctorForm.get('insurance')?.setValidators([])
                this.addDoctorForm.get('insurance')?.updateValueAndValidity();
            }
            if (data.name === 'Attorney') {
                this.addDoctorForm.get('insurance')?.setValidators([Validators.required])
                this.addDoctorForm.get('insurance')?.updateValueAndValidity();
                this.addDoctorForm.get('lastName')?.setValidators([Validators.required])
                this.addDoctorForm.get('lastName')?.updateValueAndValidity();
            }
            if (data.name === 'Referral Coordinator') {
                this.addDoctorForm.get('lastName')?.setValidators([Validators.required])
                this.addDoctorForm.get('lastName')?.updateValueAndValidity();
            }
        }
    }
}
