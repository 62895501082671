import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MainService } from '../../../services/mainservice/main.service';

// import { FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from '@app/core/services/provider/notification.service';
// import { SharedService } from '@app/core/services/shared.service';

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  // styleUrls: ['./dash-board.component.scss']
})
export class SuperAdminDashBoardComponent implements OnInit {

  adminsData: any;
  notificationCounter: any;
  patientsData: any;
  searchValuee: any;
  providerCategory:any
  currentUser: any
  providerType: any = [];
  expressionUpdate: boolean = false;

  // notificationCounter: number = 0;


  constructor(private _ActivatedRoute: ActivatedRoute, private _NgxLoader: NgxUiLoaderService, private _NotificationService: NotificationService, private _MainService: MainService, private _ngxLoader: NgxUiLoaderService, private _Router: Router) {

  }

  ngOnInit(): void {

    window.addEventListener('storage', (event) => {
      if (event.key === 'notificationCounter') {
        if (event.newValue !== null) {
          this.notificationCounter = +JSON.parse(event.newValue);
        }
      }
    });


    this._ActivatedRoute.data.subscribe(data => {

      console.log("data:",data );
      if (data && data.dashboard) {
        debugger
        this._NotificationService.getnotificationData().subscribe((x: any) => {
          debugger
          this.notificationCounter = x

        })
        this._MainService.getNotificationCounter().then(x => {
          localStorage.setItem('notificationCounter', JSON.stringify(x));
          this._NotificationService.setnotificationData(x.data);
          // this.sharedService.updateNotificationCounter(x.data);
        })

        this._ngxLoader.stop()
        this.adminsData = data?.dashboard[1]?.data
        this.patientsData = data.dashboard[3].data;
      }
    })
    this.sortAdmins();
    // this.sharedService.notificationCounter$.subscribe(count => {
    //   this.notificationCounter = count;
    // });

    this.currentUser = window.localStorage.getItem('user')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.adminsData) {
      this.sortAdmins();
    }
  }

  sortAdmins() {
    this.adminsData?.sort((a :any, b:any) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  searchAdmin() {
    this._NgxLoader.start();
    this._MainService.getAdmins(1, 25, this.searchValuee,"").then((data: any) => {
      if (data.success) {
        this.adminsData = data.data;
        this.sortAdmins();
        this._NgxLoader.stop();
      } else {
        this.adminsData = null;
        this._NgxLoader.stop();
      }
    });
  }
  searchAdminsfilter(search: any) {
    if (search.target.value.length < 3) {
      this.expressionUpdate = true;
    } else {
      this.expressionUpdate = false;
    }
    if (search.target.value.length % 3 === 0) {
      this.searchAdmin();
    }
  }

  navigatetoList(path: string) {
    if (window.localStorage.getItem('role') === "Admin") {
      this._Router.navigate([`admin/${path}`])
    }else if (window.localStorage.getItem('role') === "SuperAdmin") {
      this._Router.navigate([`super-admin/${path}`])
    } else {
      this._Router.navigate([`home/${path}`])
    }
  }

}
