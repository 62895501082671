import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../services/mainservice/main.service';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';
import { patientDetails } from '@app/models/patient/patient.model';

@Component({
    selector: 'app-doctor-details',
    templateUrl: './provider-details.component.html',
    styles: []
})
export class ProviderDetailsComponent implements OnInit, OnDestroy {


    currentDoctor: any;

    isDeletingDoc: boolean;
    isPwdShown: boolean;
    subscriptions: boolean;
    currentUser: any;
    isLoading: boolean = true;
    doctorPicurl: string = ''
    role: any;
    isModalOpen: boolean = false;
    patientsList: patientDetails[];

    constructor(
        private _ActivatedRoute: ActivatedRoute,
        private _MainService: MainService,
        private _NotifierService: NotifierService,
        private _Router: Router,

    ) {
        this.patientsList = [];
        this.isDeletingDoc = false;
        this.isPwdShown = false;
        this.subscriptions = true;
    }

    ngOnInit() {  
        this._MainService.getCurrentUserInfo().then((data: any) => {
            if (data.success) {
                this.currentUser = data.data
            }
        })

        this.isLoading = true;
        this._ActivatedRoute.data.subscribe(data => {
            if (data.providerdetails !== null) {
                this.isLoading = false
                this.currentDoctor = data.providerdetails;


                if (this.currentDoctor.insurance === "null") {
                    this.currentDoctor.insurance = ""
                }

                if (this.currentDoctor.photo) {
                    let photo = this.currentDoctor.photo;
                    this.doctorPicurl = `https://spineplusbe.azurewebsites.net/${photo.substring(7, photo.length)}`
                }
                if(data.providerdetails._id){
                    // this._MainService.getPatientListByProvider(1, 100, '',data.providerdetails._id).then((value: any)=>{
                        this._MainService.getPatientListByProvider(1, 100, '',data.providerdetails._id, "").then((value: any)=>{
                        if(value){
                            this.patientsList = value.data;
                        }
                    })
                }
            }
        });

        if (window.localStorage.getItem('role') === "Admin") {
            this.role = 'admin'
        } else if (window.localStorage.getItem('role') === "SuperAdmin") {
            this.role = 'super-admin'
        } else {
            this.role = 'home'
        }
    }

    ngOnDestroy() {
        this.subscriptions = false;
        // this.routerSubscription.unsubscribe();
    }
    openModal() {
        this.isModalOpen = true;
      }

      closeModal() {
        this.isModalOpen = false;
      }
    deleteDoc(docId: number): void {
        if (docId) {
            this.isDeletingDoc = true;
            this._MainService.deleteProviderbyid(docId).then(data => {
                this.isDeletingDoc = false;
                if (data.success === true) {
                    this._NotifierService.showSuccess('Provider has been deleted Successfully.');
                    this._Router.navigate(['../../providers'], { relativeTo: this._ActivatedRoute });

                } else {
                    this._NotifierService.showError('Something went wrong');
                }
            });
        }
    }
    goBack(): void { window.history.back(); }

    goToView(id: any): void {
        if (id) {
            if (window.localStorage.getItem('role') === 'Admin') {
                this._Router.navigate([`admin/patient-details/${id}`]);
            }else if (window.localStorage.getItem('role') === 'SuperAdmin') {
              this._Router.navigate([`super-admin/patient-details/${id}`]);
            } 
        }
    }


}
