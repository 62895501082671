<div *ngIf="isAlertActive" class="alert alert-danger alert-dismissible fade show mb-4" role="alert">
  <strong>Redirected!</strong> Please, select any doctor first, for whom you
  want to view the referrals.
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="container-fuild my-5" style="padding: 0 25px;">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Providers</h1>
        <div class="w-50 form-row">
          <div class="col">
            <input type="text" class="form-control form-control-sm" (input)="searchDoctor($event)"
              placeholder="Search by: Provider name" aria-label="Search doctor" />
              <span class="alertUpdate" *ngIf="expressionUpdate">Please enter at least 3 characters.</span>
          </div>
          <div class="col-auto" *ngIf="isSearchKeyFilled">
            <button class="btn btn-sm btn_m_outline_green" type="button" id="btn-search-doctor" (click)="clearSearch()">

              Clear Filters
            </button>
          </div>
          <div class="col-auto">
            <button class="btn btn-sm btn_m_outline_green" (click)="gotoviewadd()">
              <i class="fa fa-plus"></i>
              Add New Provider
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 position-relative">

      <div class="table-responsive border border-bottom-0">

        <table class="table table-sp-sm mb-0" aria-describedby="describe">
          <thead class="header_main_bg border-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Provider Type</th>
              <th scope="col" class="border-bottom-0 border-top-0">Contacts</th>
              <th scope="col" class="border-bottom-0 border-top-0">Fax</th>
              <th scope="col" class="border-bottom-0 border-top-0">Email</th>
              <th scope="col" class="border-bottom-0 border-top-0">Clinics</th>
              <th scope="col" class="border-bottom-0 border-top-0">Specialties</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="doctorsList?.length > 0; else noDoctorFound">
              <ng-container *ngFor="let doctor of doctorsList">
                <tr class="border-bottom cursor-pointer" (click)="goToView(doctor._id)">
                  <td>
                    <div class="form-row">

                      <div class="col">
                        <p class="mb-0">
                          <span class="name_limit">{{ doctor?.firstName }} {{ doctor?.lastName }}</span>
                          <span class="d-inline"
                            *ngIf="doctor.highestQualification!=='null' && doctor.highestQualification!=='' && doctor.highestQualification">
                            ({{ doctor.highestQualification }})
                          </span>

                          <ng-container *ngIf="doctor.address">
                            <small class="text-muted">
                              <span class="d-inline">{{
                                doctor.address || noAddressProvided
                                }}</span>

                            </small>
                          </ng-container>
                          <ng-template #noAddressProvided>
                            <small class="text-muted">
                              No address provided
                            </small>
                          </ng-template>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td> {{doctor?.providerType?.name || 'No Provider Type'}} </td>
                  <td>
                    {{ doctor.phone }}

                  </td>
                  <td>
                    {{ doctor.fax!=="null" && doctor.fax!=="" && doctor.fax ?doctor.fax:'No Fax' }}

                  </td>
                  <td>
                    {{ doctor.email!=="null" && doctor.email!=="" && doctor.email ?doctor.email:'No Email' }}

                  </td>

                  <td>
                    <p class="mb-0">
                      <ng-container *ngIf="doctor?.clinicAffiliations?.length; else noClinicAssociated ">
                        <ng-template ngFor let-clinic [ngForOf]="doctor.clinicAffiliations" let-i="index">
                          <div *ngIf="i < 2">
                            <span [ngClass]="{ 'd-inline': i === 1 }">{{ clinic }}</span>
                            <small *ngIf=" i === 1 && doctor.clinicAffiliations.length > 2 "
                              class="d-inline text-muted"> ({{ doctor.clinicAffiliations.length - (i + 1) }} more)
                            </small>
                          </div>
                        </ng-template>
                      </ng-container>
                      <ng-template #noClinicAssociated>
                        No clinic associated
                      </ng-template>
                    </p>
                  </td>
                  <td>
                    <!-- <p class="mb-0">
                      <ng-container
                        *ngIf="doctor.specialilties !== null && doctor.specialilties !== '' && doctor.specialilties.length else noSpeciality">
                        <ng-template ngFor let-speciality [ngForOf]="doctor.specialilties" let-i="index">
                          <div *ngIf="i < 2">
                            <span [ngClass]="{ 'd-inline': i === 1 }">{{ speciality?.name }}</span>
                            <small *ngIf="i === 1 && doctor.specialilties.length > 2" class="d-inline text-muted">
                              ({{ doctor.specialilties.length - (i + 1) }} more) </small>
                          </div>
                        </ng-template>
                      </ng-container>
                      <ng-template #noSpeciality> No speciality </ng-template>
                    </p> -->
                    <p class="mb-0">
                      <ng-container *ngIf="doctor.specialilties !== null && doctor.specialilties !== '' && doctor.specialilties.length; else checkProviderType">
                        <ng-template ngFor let-speciality [ngForOf]="doctor.specialilties" let-i="index">
                          <ng-container *ngIf="doctor?.providerType?.name === 'Imaging Center' || doctor?.providerType?.name === ''; else displaySpeciality">
                            No speciality
                          </ng-container>
                          <ng-template #displaySpeciality>
                            <div *ngIf="i < 2">
                              <span [ngClass]="{ 'd-inline': i === 1 }">{{ speciality?.name }}</span>
                              <small *ngIf="i === 1 && doctor.specialilties.length > 2" class="d-inline text-muted">
                                ({{ doctor.specialilties.length - (i + 1) }} more)
                              </small>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                      <ng-template #checkProviderType>
                        <ng-container *ngIf="doctor?.providerType?.name === 'Imaging Center' || doctor?.providerType?.name === ''; else noSpeciality">
                          No speciality
                        </ng-container>
                      </ng-template>
                      <ng-template #noSpeciality> No speciality </ng-template>
                    </p>
                  </td>
                </tr>
              </ng-container>
            </ng-container>

            <ng-template #noDoctorFound>
              <tr class="border-bottom">
                <td colspan="12">
                  <div class="text-center py-5">
                    <h1 class="display-12">

                    </h1>
                    <h5 class="font-weight-bold text-muted mt-3">
                      No doctor found
                    </h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div style="width: 97%;">
      <mat-paginator [length]="pagination" [pageSize]="10" (page)="pageChange($event)">
      </mat-paginator>
    </div>
    </div>
  </div>
</div>
