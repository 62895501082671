import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EmailTemplate } from '../../../services/emailTemplate/emailTemplate.service';

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  //styleUrls: ['./records.component.scss'],
})
export class RecordsComponent implements OnInit {
  data: any;
  role: any
  recordsTypeList: any;
  additionalRecordsTypeList: any;
  addRecords: FormGroup;
  selectedFile: any;
  selectedFile2: any;
  selectedFileList: any;
  FirstFile: boolean = false;
  SecondFile: boolean = false;
  FirstFileEdit: boolean = false;
  SecondFileEdit: boolean = false;
  File1Extension: boolean = false;
  File2Extension: boolean = false;
  fileViewer: any;
  PatientDetails: any;
  PatientProviderList: any = [];
  toEmail: any = [];
  CurrentUserInfo: any;
  @Input() patientID: any;
  tableRecords: any;
  pagination: any;
  isSubmitted: boolean;
  isfile: boolean;
  isfilevalue: boolean;
  isfile2: boolean;
  isfile3: boolean;
  deleteId: any;
  recordList: any;
  isEdit: boolean = false
  idToEdit: any;
  recordFile: File | null = null ;
  recordFile2: File | null = null ;
  constructor(
    private _MainService: MainService,
    private _FormBuilder: FormBuilder,
    private _NgxLoader: NgxUiLoaderService,
    private _NotifierService: NotifierService,
    private _EmailTemplate: EmailTemplate,
    private _BsModalService: BsModalService,
    private _BsModalService2: BsModalService,
  ) {
    this.addRecords = this._FormBuilder.group({
      file: null,
      type: [null, [Validators.required]],
      Additionalfile: null,
      AdditionalRecordType: null,
      extraDocuments: [null],
    });
    this.isSubmitted = false;
    this.isfile = false;
    this.isfilevalue = false;
    this.isfile2 = false;
    this.isfile3 = false;
  }
  ngOnInit() {

    this.role = window.localStorage.getItem("role")
    this._MainService.getRecordsNameList().then((data) => {
      this.recordsTypeList = data.data;
    });
    this._MainService.getAdditionalRecordsNameList().then((data) => {
      this.additionalRecordsTypeList = data.data;
    });
    this.getRecords()

     // Extraction for Sending Email
     this._MainService.getPatientsbyPatientID(this.patientID, false).then(data => {
      this.PatientDetails = data.data;

      if (this.PatientDetails.provider && this.PatientDetails.provider.length > 0) {
        this.PatientProviderList = this.PatientDetails.provider.map((provider:any) => ({
          firstName: provider?.firstName || '',
          lastName: provider?.lastName || '',
          _id: provider?._id || '',
        }));
      }
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.CurrentUserInfo = data.data
      }
    })

    this._MainService.getProviderNameList(true, false, false).then((data) => {
      const emailList = [];
      if (this.PatientProviderList && this.PatientProviderList.length > 0) {
        for (const provider of this.PatientProviderList) {
          const matchedProvider = data.data.find((p: any) => p._id === provider._id);
          const email = matchedProvider ? matchedProvider.email : '';
          if (email && email !== '') {
            emailList.push(email);
          }
        }
      }
      this.toEmail = emailList;
    });
  }

  getRecords() {
    this._MainService.getPatientRecords(10, 1, this.patientID).then((data: any) => {
      this.tableRecords = data?.data;
      this.pagination = data?.pagination;

    })
  }
  // recordsFile(event: Event): void {
  //   if (event.target !== null) {
  //     const files = (<HTMLInputElement>event.target).files;
  //     if (files !== null) {
  //       const fileList: FileList = files;
  //       if (fileList && fileList.length > 0) {
  //         const file: File = fileList[0];
  //         this.selectedFile = file;
  //         this.addRecords.controls.file.setValue(file.name)
  //         this.isfile = false;
  //       } else {
  //         this.selectedFile = '';
  //       }
  //     }
  //   }
  // }

  // recordsFile2(event: Event): void {
  //   if (event.target !== null) {
  //     const files = (<HTMLInputElement>event.target).files;
  //     if (files !== null) {
  //       const fileList: FileList = files;
  //       if (fileList && fileList.length > 0) {
  //         const file: File = fileList[0];
  //         this.selectedFile2 = file;
  //         this.addRecords.controls.Additionalfile.setValue(file.name)
  //         this.isfile2 = false;
  //       } else {
  //         this.selectedFile2 = '';
  //       }
  //     }
  //   }
  // }
  recordsFile(event: Event): void {
    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files !== null && files.length > 0) {
        this.recordFile = files[0];
        const fileExtension = this.recordFile.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'pdf') {
          this.selectedFile = this.recordFile;
          this.addRecords.controls.file.setValue(this.recordFile.name);
          this.isfile = false;
        } else {
          // Invalid file type, clear the selection
          this.recordFile = null;
          this.selectedFile = '';
          this.addRecords.controls.file.setValue(null);
          this._NotifierService.showError("Only PDF is accepted");
        }
      } else {
        // No files selected, clear the selection
        this.recordFile = null;
        this.selectedFile = '';
        this.addRecords.controls.file.setValue(null);
      }
    }
  }

  recordsFile2(event: Event): void {

    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files !== null && files.length > 0) {
        this.recordFile2 = files[0];
        const fileExtension = this.recordFile2.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'pdf') {
          this.selectedFile2 = this.recordFile2;
          this.addRecords.controls.Additionalfile.setValue(this.recordFile2.name);
          this.isfile2 = false;
        } else {
          this.recordFile2 = null;
          this.selectedFile2 = '';
          this.addRecords.controls.Additionalfile.setValue(null);
          this._NotifierService.showError("Only PDF is accepted");
        }
      }else {
        // No files selected, clear the selection
        this.recordFile2 = null;
        this.selectedFile2 = '';
        this.addRecords.controls.Additionalfile.setValue(null);
      }
    }
  }
  validatorsCheckFunction() {
    debugger
    if (this.addRecords.controls.Additionalfile.value && this.addRecords.controls.AdditionalRecordType.value) {
      if (!this.addRecords.controls.file.value && !this.addRecords.controls.type.value) {
        this.isfile = false;
        this.isfilevalue = false;
        this.SecondFile = true;
      }
    }

    if (this.addRecords.controls.file.value && this.addRecords.controls.type.value) {
      if (!this.addRecords.controls.Additionalfile.value && !this.addRecords.controls.AdditionalRecordType.value) {
        this.isfile = false;
        this.isfilevalue = false;
        this.FirstFile = true;
      }
    }

    if (this.addRecords.controls.file.value && !this.addRecords.controls.type.value) {
      this.isfilevalue = true;
      this.isfile = false;
    }
    if (!this.addRecords.controls.file.value && this.addRecords.controls.type.value) {
      this.isfile = true;
      this.isfilevalue = false;
    }

    if (this.addRecords.controls.file.value && this.addRecords.controls.type.value && this.addRecords.controls.Additionalfile.value && this.addRecords.controls.AdditionalRecordType.value) {
      this.SecondFile = true;
      this.FirstFile = true;
    }

    if (!this.addRecords.controls.file.value && !this.addRecords.controls.type.value && !this.addRecords.controls.Additionalfile.value && !this.addRecords.controls.AdditionalRecordType.value) {
      this.isfile = true;
      this.isfilevalue = true;
    }

    if (this.addRecords.controls.AdditionalRecordType.value && this.addRecords.controls.Additionalfile.value === null) {
      this.isfile2 = true;
    }

    if (this.addRecords.controls.AdditionalRecordType.value === null && this.addRecords.controls.Additionalfile.value) {
      this.isfile3 = true;
    }

  }


  onSelect(event: any) {
    if (event?._id && event?.name) {
      this.isfile3 = false;
    } else {
      this.isfile3 = true;
    }
  }
  onSelect2(event: any) {
    if (event?._id && event?.name) {
      this.isfilevalue = false;
    } else {
      this.isfilevalue = true;
    }
  }

  getFormData() {
    let formData = new FormData()
    if (this.addRecords.controls.type?.value) {
      formData.append('type', this.addRecords.controls.type?.value)
    }
    if (this.selectedFile && this.selectedFile.name) {
      formData.append('file', this.selectedFile, this.selectedFile.name)
    }
    if (this.addRecords.controls.AdditionalRecordType?.value) {
      formData.append('AdditionalRecordType', this.addRecords.controls?.AdditionalRecordType?.value)
    }
    if (this.selectedFile2 && this.selectedFile2.name) {
      formData.append('Additionalfile', this.selectedFile2, this.selectedFile2.name)
    }
    if (this.selectedFileList) {
      this.selectedFileList.forEach((file: any) => {
        const blob = new Blob([file], { type: file.type });
        formData.append('extraDocuments[]', blob, file.name);
      });
    }
    if (this.patientID !== null) {
      formData.append('patientId', this.patientID)
    }
    if (this.isEdit) {
      formData.append('id', this.idToEdit)
    }
    return formData
  }

  fileExtentionCheck() {
    if (this.selectedFile) {
      if (this.selectedFile.name.substring(this.selectedFile.name.length - 3).toLowerCase() === "pdf") {
        this._NgxLoader.start()
        this.File1Extension = true;
      } else {
        this.File1Extension = false;
      }
    }
    if (this.selectedFile2) {
      if (this.selectedFile2.name.substring(this.selectedFile2.name.length - 3).toLowerCase() === "pdf") {
        this._NgxLoader.start()
        this.File2Extension = true;
      } else {
        this.File2Extension = false;
      }
    }
  }
  checkIfAllPDF(): boolean {
    debugger
    if (this.selectedFileList && this.selectedFileList.length > 0) {
      for (const file of this.selectedFileList) {
        const fileExtension = file.name.substring(file.name.length - 3).toLowerCase();
        if (fileExtension !== 'pdf') {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  messageEmail() {
    this._MainService.sendEmail(this.takeformData(), true).then((data: any) => {
      console.log(data);
    })
  }

  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.toEmail)
    formData.append('html', this._EmailTemplate.updateEmailTemplate(
      this.PatientDetails.fullName,
      this.PatientDetails.patientUID,
      this.CurrentUserInfo?.fullName,
      "Records and Billings"
    ))
    formData.append('message', "Patient Notes Update")

    return formData
  }

  recordsSubmitData() {
    debugger
    this.validatorsCheckFunction();
    this.checkIfAllPDF();
    this.isSubmitted = true
    if (this.FirstFile || this.SecondFile) {
      this.fileExtentionCheck();

      console.log(this.File2Extension,"File2Extension");
      console.log(this.File1Extension,"File1Extension");
      console.log(this.selectedFile2,"selectedFile2");
      console.log(this.selectedFile,"selectedFile");
      console.log(this.checkIfAllPDF(),"this.checkIfAllPDF()");
      this.isSubmitted = false;
      if (((this.selectedFile2) ? this.File2Extension : !this.File2Extension) &&
        ((this.selectedFile) ? this.File1Extension : !this.File1Extension) &&
        ((this.addRecords.controls?.extraDocuments.value !== null) ? this.checkIfAllPDF() : !this.checkIfAllPDF())) {

        this._MainService.addPatientRecords(this.getFormData()).then((data: any) => {
          if (data.success) {
            this.messageEmail()
            this._NgxLoader.stop();
            this._NotifierService.showSuccess("Records added Successfully");
            this._MainService.getPatientRecords(10, 1, this.patientID).then((data: any) => {
              this.tableRecords = data?.data
            })
            this.addRecords.reset();
            this.selectedFileList = [];
            const UploadFile = <HTMLInputElement>document.getElementById("fileUpload");
            UploadFile.value = "";
            const UploadFile2 = <HTMLInputElement>document.getElementById("fileUpload2");
            UploadFile2.value = "";
            this._MainService.getPatientRecords(10, data.pageIndex + 1, this.patientID).then((data: any) => {
              if (data.success) {
                this.pagination = data.pagination
                this._NgxLoader.stop();
              } else this._NgxLoader.stop();
            });
            this.FirstFile = this.SecondFile = this.isfile = this.isfilevalue = false;
          } else {
            this._NgxLoader.stop()
            this._NotifierService.showError("Something went wrong")
          }
        })
      } else {
        this._NotifierService.showError("Only PDF file is accepted")
        this._NgxLoader.stop()
      }
    } else {
      this._NgxLoader.stop()
    }
  }
  pageChange(pagechangedata: any) {
    this._NgxLoader.start();
    this._MainService.getPatientRecords(10, pagechangedata.pageIndex + 1, this.patientID).then((data: any) => {
      if (data.success) {
        this.tableRecords = data.data;
        this.pagination = data.pagination
        this._NgxLoader.stop();
      } else this._NgxLoader.stop();
    });
  }

  openModal(template: TemplateRef<any>, id: any) {
    this.deleteId = id
    this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }
  openModal2(template: TemplateRef<any>, value: any) {
    this.recordList = value
    this._BsModalService2.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }

  deleteRecords() {
    this._NgxLoader.start()
    this._MainService.deleteRecord(this.deleteId).then((data: any) => {
      if (data.success) {
        this._NgxLoader.stop()
        this._NotifierService.showSuccess("Records Deleted Successfully");
        this.getRecords()
        this._BsModalService.hide()
      } else {
        this._NgxLoader.stop()
        this._NotifierService.showError("Records cannot be deleted");
      }
    })
  }
  addFileInput(): void {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.className = 'btn btn_m_primary';
    fileInput.addEventListener('change', (event) => this.recordsFile(event));
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  addMoreDocuments(event: any): void {
    const files = event.target.files;
    if (files !== null) {
      const fileList: FileList = files;
      if (fileList && fileList.length > 0) {
        if (this.addRecords.controls.extraDocuments.value === null) {
          const currentExtraDocuments = this.addRecords.get('extraDocuments')!.value || [];
          this.addRecords.controls.extraDocuments.setValue(currentExtraDocuments.concat(Array.from(fileList)));
          this.selectedFileList = this.addRecords.get('extraDocuments')!.value;
        } else {
          const currentExtraDocuments = this.addRecords.get('extraDocuments')!.value || [];
          const updatedFiles = currentExtraDocuments.concat(Array.from(fileList));
          this.addRecords.controls.extraDocuments.setValue(updatedFiles);
          this.selectedFileList = updatedFiles;
        }
      }
    }
  }

  removeFile(index: number): void {
    this.selectedFileList.splice(index, 1);
    this.addRecords.controls.extraDocuments.setValue(this.selectedFileList);
  }



  editRecord(item: any) {
    this.idToEdit = item._id
    this.isEdit = true
    this.isfile = false
    this.isfile2 = false
    this.isfile3 = false
    this.FirstFile = false
    this.SecondFile = false
    this.File1Extension = false
    this.File2Extension = false
    this.selectedFileList = [];
    if (item?.type && item?.file) {
      this.addRecords.controls.file.setValue(item.file.substring(36, item.file.length))
      this.addRecords.controls.type.setValue(item.type._id)
    }
    if (item?.Additionalfile && item?.AdditionalRecordType) {
      this.addRecords.controls.Additionalfile.setValue(item?.Additionalfile?.substring(36, item.Additionalfile.length))
      this.addRecords.controls.AdditionalRecordType.setValue(item.AdditionalRecordType._id)
    }
    if (item?.extraDocuments) {
      const paths = item.extraDocuments.map((doc: any) => doc.path);

      const filesArray: File[] = [];
      paths.forEach((fileName: string) => {
        const blob = new Blob([''], { type: 'application/pdf' });
        const file = new File([blob], fileName, { type: 'application/pdf' });
        filesArray.push(file);
      });
      const filesArrayUpdated: File[] = filesArray.map((file: File) => {
        const updatedFileName = file.name.split('-').slice(2).join('-');
        const blob = new Blob([''], { type: 'application/pdf' });
        return new File([blob], updatedFileName, { type: 'application/pdf' });
      });
      // this.addRecords.controls.extraDocuments.setValue(filesArrayUpdated);
      this.selectedFileList = filesArrayUpdated;
    }
  }

  editSubmitRecord() {
    if (this.addRecords.controls.Additionalfile.value && this.addRecords.controls.AdditionalRecordType.value) {
      if ((this.addRecords.controls.file.value && !this.addRecords.controls.type.value) || (!this.addRecords.controls.file.value && this.addRecords.controls.type.value)) {
        this.isfile = false;
        this.isfilevalue = false;
        this.SecondFileEdit = true;
        this.FirstFileEdit = false;
      } else {
        this.SecondFileEdit = true;
        this.FirstFileEdit = true;
      }
    }

    if (this.addRecords.controls.file.value && this.addRecords.controls.type.value) {
      if ((this.addRecords.controls.Additionalfile.value && !this.addRecords.controls.AdditionalRecordType.value) || (!this.addRecords.controls.Additionalfile.value && this.addRecords.controls.AdditionalRecordType.value)) {
        this.isfile = false;
        this.isfilevalue = false;
        this.FirstFileEdit = true;
        this.SecondFileEdit = false;
      } else {
        this.FirstFileEdit = true;
        this.SecondFileEdit = true;
      }
    }
    this._NgxLoader.start()
    if (this.FirstFileEdit && this.SecondFileEdit) {
      this._MainService.editRecord(this.getFormData()).then(data => {
        if (data.success) {
          this._NotifierService.showSuccess("Record Updated Successfully")
          this.messageEmail()
          this._NgxLoader.stop()
          this.isEdit = false
          this.addRecords.reset();
          this.selectedFileList = [];
          this.FirstFile = this.SecondFile = this.isfile = this.isfilevalue = false;
          this.getRecords()
        } else {
          this._NotifierService.showError("Something went wrong")
          this._NgxLoader.stop()
        }
      })
    } else {
      this._NotifierService.showError("Fill the required fields")
      this._NgxLoader.stop()
    }
  }

  clearEdit() {
    this.isEdit = false
    this.addRecords.reset()
    this.selectedFile = null
    this.selectedFile2 = null
    this.idToEdit = null
    this.selectedFileList = [];
  }
  clearFields(item: any) {
    if (item === 'first') {
      this.addRecords.controls.type.setValue(null);
      this.addRecords.controls.file.setValue(null);
      this.selectedFile  = null;
    } else {
      this.addRecords.controls.AdditionalRecordType.setValue(null);
      this.addRecords.controls.Additionalfile.setValue(null);
      this.selectedFile2  = null;
    }
  }
}
