import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { AddEditAttorneyInsurance } from "../../../../models/add-edit-attorney-insurance.model";

@Component({
  selector: 'app-edit-add-attorney-insurance',
  templateUrl: './edit-add-attorney-insurance.component.html'
})
export class EditAddAttorneyInsuranceComponent implements OnInit {

  addOrEditAttorneyInsurance: FormGroup
  formData: AddEditAttorneyInsurance
  isEditing: boolean = false
  addOrEditAttorneyInsuranceFormSubmitting: boolean = false
  role: any
  attorney_id: any
  doesExist: boolean = false;
  provider_id: any
  genders: any;

  constructor(private _FormBuilder: FormBuilder, private _MainService: MainService, private _ActivatedRoute: ActivatedRoute, private _NotifierService: NotifierService, private _Router: Router) {
    this.addOrEditAttorneyInsurance = this._FormBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      fax: [''],
      email: ['', [ Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)]],
      address: [''],
      insurance: ['', [Validators.required]],
       genderId: [null, [Validators.required]],
    })

    this.formData = new AddEditAttorneyInsurance()
  }

  ngOnInit() {
    debugger
    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.role = data.data.role
      }
    })
    this._MainService.getProviderType().then((data: any) => {
      
      data.data.forEach((e: any) => {
        if (e.name == 'Attorney') {
          this.attorney_id = e._id
         

        }
      })

    })
    this._MainService.getgenderList().then((data: any) => {
      
      this.genders=data.data
    })

    this._ActivatedRoute.data.subscribe((data: any) => {
      
      this.provider_id = data.attorney?._id
     


      if (data && data.attorney !== null && data.attorney !== undefined) {

        this.isEditing = true
        this.addOrEditAttorneyInsurance.patchValue(data.attorney)
        this.addOrEditAttorneyInsurance.controls.genderId.setValue(data.attorney.gender)
        this.addOrEditAttorneyInsurance.controls.firstname.setValue(data.attorney.firstName)
        this.addOrEditAttorneyInsurance.controls.lastname.setValue(data.attorney.lastName)
        if (data.attorney.addressLine1 !== "null") { this.addOrEditAttorneyInsurance.controls.address.setValue(data.attorney.addressLine1) }

      }
    })
  }

  getFormData(): FormData {
    const formData: FormData = new FormData();
    formData.append('firstName', this.addOrEditAttorneyInsurance.controls.firstname.value);
    formData.append('lastName', this.addOrEditAttorneyInsurance.controls.lastname.value);

    formData.append('email', this.addOrEditAttorneyInsurance.controls.email.value);
    formData.append('phone', this.addOrEditAttorneyInsurance.controls.phone.value);
    formData.append('addressLine1', this.addOrEditAttorneyInsurance.controls.address.value);
    formData.append('fax', this.addOrEditAttorneyInsurance.controls.fax.value);
    formData.append('insurance', this.addOrEditAttorneyInsurance.controls.insurance.value);
    formData.append('gender', this.addOrEditAttorneyInsurance.controls.genderId.value);
    formData.append('isAttorney', JSON.stringify(true));
    formData.append('isUpdate', this.isEditing ? JSON.stringify(true) : JSON.stringify(false));
    formData.append('providerType', this.attorney_id);

    if (this.isEditing) {
      formData.append('providerId', this.provider_id)
    }
    return formData
  }



  // prepareFormData() {
  //   this.formData.name = this.addOrEditAttorneyInsurance.controls.name.value
  //   this.formData.phone = this.addOrEditAttorneyInsurance.controls.phone.value
  //   this.formData.fax = this.addOrEditAttorneyInsurance.controls.fax.value
  //   this.formData.email = this.addOrEditAttorneyInsurance.controls.email.value
  //   this.formData.address = this.addOrEditAttorneyInsurance.controls.address.value
  //   this.formData.insurance = this.addOrEditAttorneyInsurance.controls.insurance.value
  // }

  goBack() {
    window.history.back()
  }

  onBlur(data: any) {

    if (data.target.value !== "") {
     

      this._MainService.emailCheckProvider(data.target.value).then((data: any) => {
        if (data.success) {

          this.doesExist = true
        }
      })
    }
  }

  onFocus() {

    this.doesExist = false
  }



  addOEditAttorneyInsurancFormSubmit() {
    if (this.isEditing) {
      if (this.addOrEditAttorneyInsurance.valid) {
        this.addOrEditAttorneyInsuranceFormSubmitting = true
        //this.prepareFormData()
        this._MainService.registerDoctor(this.getFormData()).then((data: any) => {
          this.addOrEditAttorneyInsuranceFormSubmitting = false
          if (data.success) {
            this.addOrEditAttorneyInsuranceFormSubmitting = false
            this._NotifierService.showSuccess("Attorney Updated Successfully")
            this._Router.navigate(["/admin/attorney-insurance"])
          } else {
            this.addOrEditAttorneyInsuranceFormSubmitting = false
            this._NotifierService.showError("Something Went Wrong")
          }
        })


      }






    } else {
      if (this.addOrEditAttorneyInsurance.valid) {
        this.addOrEditAttorneyInsuranceFormSubmitting = true
        //this.prepareFormData()
        this._MainService.registerDoctor(this.getFormData()).then((data: any) => {
          this.addOrEditAttorneyInsuranceFormSubmitting = false
          if (data.success) {
            this.addOrEditAttorneyInsuranceFormSubmitting = false
            this._NotifierService.showSuccess("Attorney Added Successfully")
            this._Router.navigate(["/admin/attorney-insurance"])
          } else {
            this.addOrEditAttorneyInsuranceFormSubmitting = false
            this._NotifierService.showError("Something Went Wrong")
          }
        })
      }


    }


  }
}
