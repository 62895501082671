

import { Component } from "@angular/core";
import { referralD } from "./referral-details";

@Component({
    selector: 'app-referral-details',
    templateUrl: './referral-details.component.html'
})

export class ReferralDeatilsComponent{
    referral = referralD
    

    print() {
        window.print()
    }
}