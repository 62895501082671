import { Injectable } from '@angular/core';
import { MainService } from '../../../services/mainservice/main.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { patientdetailswithpaginationResponse } from '@app/models/patient/patient.model';


@Injectable()

export class PatientsResolver implements Resolve<Array<patientdetailswithpaginationResponse> | any | null> {
  locationId: any;
    providerID: any;
    userRole:any;
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService
    ) { }

    async resolve(route: ActivatedRouteSnapshot): Promise<Array<patientdetailswithpaginationResponse> | any | any> {
      const tab = route.queryParams.tab;

      // Start the loader
      this.ngxLoader.start();

      return this._MainService.getCurrentUserInfo().then((data: any) => {
        if (data.success) {
          this.userRole = data?.data?.role;

          debugger
          if (this.userRole === "Staff") {
            // If user is Staff, fetch provider ID first
            return this._MainService.getProviderNameList(true, false, false).then(data1 => {
              debugger
              const provider = data1.data.find((x: any) => x._id === data.data.providerId);
              if (provider) {
                this.providerID = data.data.providerId;
              }
              return this.fetchPatientData(tab); // Fetched patient data once providerID is resolved
            }).catch(error => {
              console.error('Error fetching provider name list:', error);
              return this.fetchPatientData(tab);
            });
          } else {
            // For Admin or SuperAdmin roles
            this.providerID = data?.data?.id;
            this.locationId = '';
            return this.fetchPatientData(tab);
          }
        } else {

          return this.fetchPatientData(tab);
        }
      }).catch(error => {
        console.error('Error fetching current user info:', error);
        return this.fetchPatientData(tab);
      });
    }

    private fetchPatientData(tab: any): Promise<Array<patientdetailswithpaginationResponse> | any | any> {
      const patientPromise = (this.userRole === 'Admin' || this.userRole === 'SuperAdmin')
        ? this._MainService.getPatients(1, 10, '')
        : this._MainService.getPatientListByProvider(1, 10, '', this.providerID, '');

      return Promise.all([
        patientPromise.then(data => {
          this.ngxLoader.stop();
          return data;
        }),
            Promise.resolve(tab),
            this._MainService.statusNeededCountData(10, 1, '').then((data: any) => {
                this.ngxLoader.stop();
                return data;
            }),
            this._MainService.urgentStatusCountData(10, 1, '', true).then((data: any) => {
                this.ngxLoader.stop();
                return data;
            }),
            // this._MainService.getPatientListByProvider(1, 10, '', this.providerID, this.locationId).then(data => {
            //     this.ngxLoader.stop();
            //     return data;
            // }),

        ]);

    }
}
