import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { MainService } from "@app/services/mainservice/main.service";
@Injectable({
    providedIn: 'root',
})


export class AdminResolver implements Resolve<any | null>{
    constructor(private _MainService: MainService) { }
    resolve(): Promise<any> | undefined {
        return this._MainService.getNotificationCounter().then(($: any) => $.data);
    }
}