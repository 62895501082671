import { Component, EventEmitter, OnInit, Output, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { addReferral } from '@app/models/add-referral.model';
import { patientDetails } from '@app/models/patient/patient.model';
import { MainService } from '@app/services/mainservice/main.service';
import { EmailTemplate } from '../../../../services/emailTemplate/emailTemplate.service';
import {
  faArrowRight,
  faArrowLeft,
  faUserAlt,
  faUserMd,
  faMortarPestle,
  faBriefcaseMedical,
  faSearch,
  faTimes,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotifierService } from '../../../../services/notifierservice/notifier.service';


@Component({
  selector: 'app-add-edit-referral',
  templateUrl: './add-referral.component.html',
  styleUrls: ['./add-referral.component.css']
})

export class AddReferralComponent implements OnInit {
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faUserAlt = faUserAlt;
  faUserMd = faUserMd;
  faMortarPestle = faMortarPestle;
  faBriefcaseMedical = faBriefcaseMedical;
  faTimes = faTimes;
  faSearch = faSearch;
  faSpinner = faSpinner;
  toEmail: any
  url: any
  role: any;
  addReferralForm: FormGroup
  referralFname: any
  referralLname: any
  addreferralStep: string;
  patientList: patientDetails[];
  // providerId: any;
  bodypartsList: any = []
  visitReasonList: any = []
  providerList: any = []
  refferedBy: any = []
  providerSpecialityList: any = []
  patientdetails: any = []
  providerDetails: any = [];
  providerId: any;
  AddreferralModel: addReferral
  isSeen: boolean = false;
  isSubmittedRequiredValue: boolean;
  @Output() isAddReferralDone: EventEmitter<boolean> = new EventEmitter<any>();


  constructor(private _FormBuilder: FormBuilder, private _MainService: MainService, private ngxLoader: NgxUiLoaderService,
    private _NotifierService: NotifierService, private _EmailTemplate: EmailTemplate) {
    this.addreferralStep = 'search-patient';
    this.AddreferralModel = new addReferral();
    this.isSubmittedRequiredValue = false;
    this.patientList = [];

    this.addReferralForm = this._FormBuilder.group({
      patient: [null, [Validators.required]],
      visitReason: [null, Validators.required],
      callInSAT: [null],
      bringImgDoc: [null],
      referredTo: [null, [Validators.required]],
      speciality: [null],
      bodyPart: [null],
      referredBy: [null],
      MRI: [null],
      CT: [null],
      billUpperExtremities: [null],
      billLowerExtremities: [null],
      xRay: [null],
    })

  }
  ngOnInit() {

    this.url = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port !== '') this.url += ':' + window.location.port;
    console.log(this.url);
    this.role = window.localStorage.getItem('role');

    this._MainService.getVistReason().then(data => {
      if (data.success) {
        this.visitReasonList = data.data;
      }
    });
    this._MainService.getProviderNameList(false, false, false).then(data => {
      if (data.success) {
        this.providerList = data.data;
      }
    })
    this._MainService.getProviderNameList(true, false, false).then(data => {
      if (data.success) {
        this.refferedBy = data.data;
      }
    })
    this._MainService.getbodyParts().then(data => {
      if (data.success) {
        this.bodypartsList = data.data;
      }
    })
    this._MainService.getCurrentUserInfo().then(data => {
      if (data.success) {
        this.ngxLoader.stop()
        this.providerDetails = data.data;
      } else this.ngxLoader.stop();
    });
    if(this.role === 'Admin' || this.role === 'SuperAdmin'){
      this._MainService.getPatients(1, 100, '').then(data => {
        if (data.data) {
          this.ngxLoader.stop()
          this.patientList = data.data;
          this.patientList.forEach((data: any) => {
            data.customname = `${data.firstName}-${data.lastName}`
          })
        } else {
          this.ngxLoader.stop()
        }
      })
    }if (this.role === 'Provider' || this.role === 'Staff') {
      this._MainService.getCurrentUserInfo().then(data => {
        if (data.success) {
          this.ngxLoader.stop()
          this.providerDetails = data.data;
          if (data?.data?.role === "Staff") {
            this.providerId = data?.data?.providerId;
            this._MainService.getPatientListByProvider(1, 10, '', data?.data?.providerId, '')
           .then((data: any) => {
            if (data.success) {
              this.ngxLoader.stop()
              this.patientList = data.data;
              this.patientList.forEach((data: any) => {
                data.customname = `${data.firstName}-${data.lastName}`
              })
            }
        });
        } else {
            this.providerId = data?.data?._id
            this._MainService.getPatientListByProvider(1, 10, '', data?.data?._id, '')
           .then((data: any) => {
            if (data.success) {
              this.ngxLoader.stop()
              this.patientList = data.data;
              this.patientList.forEach((data: any) => {
                data.customname = `${data.firstName}-${data.lastName}`
              })
            }
        });
        }

        }
        else this.ngxLoader.stop();
      });




  }
  }

  getpreparedforData() {
    this.AddreferralModel.patientId = this.addReferralForm.controls.patient.value;
    this.AddreferralModel.reasonForVisit = this.addReferralForm.controls.visitReason.value;
    this.AddreferralModel.ReferredTo = this.addReferralForm.controls.referredTo.value;
    debugger
    this.AddreferralModel.ReferredBy = this.addReferralForm.controls.referredBy.value;
    this.AddreferralModel.BringImgDoc = this.addReferralForm.controls.bringImgDoc.value === 'yes' ? true : false;
    this.AddreferralModel.callInSAT = this.addReferralForm.controls.callInSAT.value;
    this.AddreferralModel.MRI = this.addReferralForm.controls.MRI.value;
    this.AddreferralModel.CT = this.addReferralForm.controls.CT.value;
    this.AddreferralModel.BillUpperExtremities = this.addReferralForm.controls.billUpperExtremities.value;
    this.AddreferralModel.BillLowerExtremities = this.addReferralForm.controls.billLowerExtremities.value;
    this.AddreferralModel.xRay = this.addReferralForm.controls.xRay.value;
    this.AddreferralModel.alreadyLinked = false;
    this.AddreferralModel.bodyParts = this.addReferralForm.controls.bodyPart.value;
    this.AddreferralModel.specialities = this.addReferralForm.controls.speciality.value;

  }
  sendReferral() {



    this._MainService.sendEmail(this.takeformData(), false).then((data: any) => {

      console.log(data.success);

    })

  }
  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.toEmail)
    formData.append('html', this._EmailTemplate.refferalEmailTemplate(this.referralFname, this.referralLname, this.url))
    formData.append('message', "New Referral")
    return formData

  }

  onSubmitAddReferral() {
    this.getpreparedforData();
    this.ngxLoader.start();
    debugger;
    this._MainService.addReferral(this.AddreferralModel).then((data: any) => {
      if (data.success) {


        this.referralFname = data.senderName.firstName
        this.referralLname = data.senderName.lastName
        this.toEmail = data.senderName.toEmail
        this.isAddReferralDone.emit(true)
        this.addReferralForm.reset();
        this._NotifierService.showSuccess('Referral Sent')


        this._MainService.updateCount({ providerId: [data.data.ReferredTo] }).then((data: any) => {
          console.log(data);

        })


        this.sendReferral()
        this.ngxLoader.stop();
      }
    })
  }




  getPatientDetails(value: any) {
    debugger
    if (value?._id !== null && value?._id !== undefined) {
      this.ngxLoader.start()
      this._MainService.getPatientsbyPatientID(value?._id, this.isSeen).then((data: any) => {
        if (data.success) {
          this.ngxLoader.stop()
          this.patientdetails = data.data
        } else {
          this.ngxLoader.stop()
        }
      })
    }

  }
  // onReferredByProviderSelected(value: any) {
  //   this.ngxLoader.start()
  //   this._MainService.getProvidersbyProviderID(value._id).then((data: any) => {
  //     if (data.success) {
  //       this.ngxLoader.stop()
  //       this.providerDetails = data.data
  //     } else {
  //       this.ngxLoader.stop()
  //     }
  //   }).then(() => {
  //     this.nextButton()
  //   })
  // }

  onProviderSelected(value: any) {
    debugger
    if (value !== undefined) {
      this.ngxLoader.start()
      this._MainService.getProvidersbyProviderID(value._id).then((data: any) => {
        if (data.success) {
          this.ngxLoader.stop()
          this.providerSpecialityList = data.data.specialilties
        } else {
          this.ngxLoader.stop()
        }
      })
    } else {
      this.providerSpecialityList = []
    }
  }



  nextButton() {
    this.isSubmittedRequiredValue = true
    switch (this.addreferralStep) {
      case 'search-patient':
        debugger
        if ((<FormGroup>this.addReferralForm.get('patient')).valid) {
          this.isSubmittedRequiredValue = false
          this.addreferralStep = 'patient-details'
        }
        break;
      case 'patient-details':
        if ((<FormGroup>this.addReferralForm.get('visitReason')).valid && (<FormGroup>this.addReferralForm.get('callInSAT')).valid && (<FormGroup>this.addReferralForm.get('bringImgDoc')).valid) {
          this.isSubmittedRequiredValue = false
          this.addreferralStep = 'referral-details'
        }
        break;
      case 'referral-details':
        if ((<FormGroup>this.addReferralForm.get('referredTo')).valid && (<FormGroup>this.addReferralForm.get('speciality')).valid && (<FormGroup>this.addReferralForm.get('bodyPart')).valid) {
          this.isSubmittedRequiredValue = false
          this.addreferralStep = 'attorney-details'
        }
        break;
      case 'attorney-details':
        this.addreferralStep = 'select-doctor'
        break;
      case 'select-doctor':
        this.addreferralStep = 'doctor-details'
        break;
    }



  }

  prevButton() {
    switch (this.addreferralStep) {
      case 'patient-details':
        this.addreferralStep = 'search-patient'
        break;
      case 'referral-details':
        this.addreferralStep = 'patient-details'
        break;
      case 'attorney-details':
        this.addreferralStep = 'referral-details'
        break;
    }
  }
}

