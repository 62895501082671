import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InviteSignupComponent } from './Login/invite-signup/invite-signup.component';
import { LoginComponent } from './Login/login.component';
import { ResetPasswordComponent } from './Login/reset-password/reset-password.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((x) => x.HomeModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((x) => x.AdminModule),
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then((x) => x.SuperAdminModule),
  },
  { path:'invite-signup/:id',component:InviteSignupComponent},
  { path: 'resetpassword/:id', component: ResetPasswordComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '404', component: PagenotfoundComponent },
  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
