import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LogoService } from '@app/services/logo/logo.service';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-my-business',
  templateUrl: './my-business.component.html',
  styleUrls: ['./my-business.component.scss'],
})
export class MyBusinessComponent implements OnInit {
  businessForm: FormGroup;
  role: any;
  userInfo: any;
  logProviderId: any;
  providerId: any;
  isUpdating: boolean = true;
  isSubmitting: any = false;
  addressList: any;
  isEdit!: boolean;
  photo: any;
  imageUrl: string = '';
  imageShow: string | ArrayBuffer = '';
  fileViewer: string = 'Choose Image';
  selectedFile: File[] = [];
  fileName: any = '';
  CurrentUserInfo: any;
  businessDetails: any;
  isInvalid: boolean = false;
  isEditCheck: boolean = false;

  constructor(
    private _MainService: MainService,
    private _FormBuilder: FormBuilder,
    private _NgxLoader: NgxUiLoaderService,
    private _NotifierService: NotifierService,
    private _LogoService: LogoService,
    private _BsModalService: BsModalService,
    private _Router: Router,
  ) {
    this.businessForm = this._FormBuilder.group({
      fullName: [null, [Validators.required]],
      email: ["",],
      phone: ["",],
      addressLine: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null, [Validators.required]],
      zip: [null, [Validators.required]],
      ImageUrl: [[]]
    });

    this.businessForm.valueChanges.subscribe(() => {
      if (this.businessDetails) {
        this.isEdit = true;
      }
    });
  }

  ngOnInit(): void {
    this.role = window.localStorage.getItem("role")
    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.CurrentUserInfo = data.data;
        this.logProviderId = data.data._id;
      }
    });
    this._MainService.getCompanyBusinessDetails().then((data: any) => {
      if (data.success) {
        if (data.data) {
          this.isEditCheck = true;
        } else {
          this.isEditCheck = false;
        }
        this.businessDetails = data?.data;
        this.populateForm(data?.data);
      }
    })

  }

  populateForm(data: any) {
    this.businessForm.patchValue({
      fullName: data?.fullName,
      email: data?.email,
      phone: data?.phone,
      addressLine: data?.addressLine,
      city: data?.city,
      state: data?.state,
      zip: data?.zip,
      ImageUrl: data?.ImageUrl ? [data?.ImageUrl] : []
    });
    this.imageUrl = data?.ImageUrl ? data.ImageUrl : '';
    this.isEdit = false;
  }

  openModal(template: TemplateRef<any>) {
    this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }

  deleteLogos() {    
    this.businessForm.controls.ImageUrl.setValue([]);
    this.selectedFile = [];
    this.fileName = '';
    this.imageShow = '';
    if(this.imageUrl || this.isEditCheck){
      this.imageUrl = '';
      this.businessForm.markAsDirty();
    }    
    this._BsModalService.hide();
  }
  // fileChange(event: any) {
  //   this.selectedFile = []
  //   this.fileName = ''
  //   if (event.target !== null) {
  //     const files = (<HTMLInputElement>event.target).files;
  //     if (files?.length) {
  //       const file = files[0];
  //       const fileTester = file.name.substring(file.name.length - 3).toLowerCase();

  //         if (fileTester === "jpg" || fileTester === "png") {
  //           this.isInvalid = false;
  //           this.selectedFile.push(file);
  //           this.fileName = file.name;

  //           const reader = new FileReader();
  //           reader.onload = (e) => {
  //             this.imageShow = e?.target?.result ?? '';
  //             this.businessForm.controls.ImageUrl.setValue(this.fileName);
  //             this.businessForm.markAsDirty();
  //           };
  //           reader.readAsDataURL(file);
  //         } else {
  //           this.isInvalid = true;
  //           this.businessForm.controls.ImageUrl.setValue([]);
  //           this._NgxLoader.stop();
  //           this._NotifierService.showError("Only PNG/JPEG is accepted");
  //         }
  //       }
  //   }
  // }
  fileChange(event: any) {
    this.selectedFile = [];
    this.fileName = '';
    if (event.target !== null) {
      const files = (<HTMLInputElement>event.target).files;
      if (files?.length) {
        const file = files[0];
        const fileTester = file.name.substring(file.name.length - 3).toLowerCase();
        if (fileTester === "jpg" || fileTester === "png") {
          const img = new Image();
          const reader = new FileReader();
          reader.onload = (e: any) => {
            img.onload = () => {
              // 250x46 in size
              if (img.width <= 250 && img.height <= 46 && img.width !== 0 && img.height !== 0) {
              // if (img.width <= 250 && img.height <= 46) {
              this.isInvalid = false;
              this.selectedFile.push(file);
              this.fileName = file.name;

              this.imageShow = e.target.result ?? '';
              this.businessForm.controls.ImageUrl.setValue(this.fileName);
              this.businessForm.markAsDirty();
              } else {
                this._NotifierService.showError("Use logo with size 250*46 in .PNG OR .JPEG format");
                // this._NotifierService.showError("Image size must be 250x46 pixels.");
                this.businessForm.controls.ImageUrl.setValue([]);
                this._NgxLoader.stop();
              }
            };
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.isInvalid = true;
          this.businessForm.controls.ImageUrl.setValue([]);
          this._NgxLoader.stop();
          this._NotifierService.showError("Only PNG/JPEG is accepted");
        }
      }
    }
  }


  getPreparedFormData() {
    let formData = new FormData();
    const formValues = this.businessForm.value;
    formData.append('fullName', formValues.fullName);
    formData.append('email', formValues.email);
    formData.append('phone', formValues.phone);
    formData.append('addressLine', formValues.addressLine);
    formData.append('city', formValues.city);
    formData.append('state', formValues.state);
    formData.append('zip', formValues.zip);
    formData.append('providerId', this.logProviderId);
    formData.append('active', 'true');
    formData.append('isEdit', this.isEditCheck ? 'true' : 'false');
    if (this.fileName != '') {
      this.selectedFile.forEach((x: any) => {
        formData.append('ImageUrl', x, x.name)
      });
    } else {
      formData.append('ImageUrl', "")
    }

    return formData;
  }

  onBlur(controlName: string) {
    const control = this.businessForm.controls[controlName];
    if (control.invalid) {
      control.markAsTouched();
    }
  }

  businessFormSubmit() {
    this.businessForm.markAllAsTouched();
    if (this.businessForm.invalid) {
      return;
    }
    
    if (this.businessForm.valid) {
      this._MainService
        .addEditMyBusiness(this.getPreparedFormData())
        .then((data: any) => {
          if (data.success) {
            this._NotifierService.showSuccess(
              'Business profile has been updated successfully.'
            );
            this.isSubmitting = false;
            this.isEdit = false;
            this.businessForm.markAsPristine();
            this.getBusinessDetails();
            this._NgxLoader.stop();
            this._Router.navigate(['/home/dashboard'])
          } else {
            this._NotifierService.showError('Something went wrong..!');
            this.isSubmitting = false;
            this._NgxLoader.stop();
          }
        })
        .catch((error) => {
          console.log(error);
          this._NotifierService.showError('An error occurred. Please try again.');
          this.isSubmitting = false;
          this._NgxLoader.stop();
        });
    }
  }

  getBusinessDetails() {
    this._MainService.getCompanyBusinessDetails().then((data: any) => {
      if (data.success) {
        if (data.data) {
          this.isEditCheck = true;
        } else {
          this.isEditCheck = false;
        }
        this.businessDetails = data.data;
        this.populateForm(data.data);
        this._LogoService.updateLogoUrl(data?.data?.ImageUrl ? data.data.ImageUrl : '');
      }
    })
  }
}
