import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from '../sessionservice/session.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  Authorization: any;
  token: any;
  tagToken: any;
  TOKEN: String;
  constructor(
    private _SessionService:SessionService) {
    this.TOKEN = '';
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    const token= this._SessionService.getSession('token');
    if (token !== null) {
      const headers = req.headers.set('Authorization', `Bearer ${token}`);
      const authReq = req.clone({ headers });
      return next.handle(authReq);
  } else {
      return next.handle(req);
  }
  }
}
