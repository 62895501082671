<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Attorney</h1>
        <div class="w-50 form-row">
          <div class="col">
            <input type="text" class="form-control form-control-sm" (input)="searchAttorney($event)"
              placeholder="Search attorney" aria-label="Search attorney" />
          </div>
          <div class="col-auto">
            <a class="btn btn-sm btn_m_outline_green" routerLink='../edit-add-attorney-insurance/0'>

              <i class="fa fa-plus"></i>
              Add New Attorney
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 position-relative">

      <div class="table-responsive border border-bottom-0">
        <table class="table table-sp-sm borde mb-0" aria-describedby="describe">
          <thead class="header_main_bg order-bottom-0 text-white">
            <tr>
              <th scope="col" class="border-bottom-0 border-top-0">Name</th>
              <th scope="col" class="border-bottom-0 border-top-0">Email</th>
              <th scope="col" class="border-bottom-0 border-top-0">Phone</th>
              <th scope="col" class="border-bottom-0 border-top-0">Fax</th>
              <th scope="col" class="border-bottom-0 border-top-0">Address</th>
              <th scope="col" class="border-bottom-0 border-top-0">Case Manager</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="attorneyInsuranceData?.length>0 || isLoading; else noMessage">
              <ng-container *ngFor="let attorneyInsurance of attorneyInsuranceData">
                <tr (click)="goToView(attorneyInsurance._id)">
                  <td> <span> {{attorneyInsurance.firstName}} {{attorneyInsurance.lastName}}<br> </span> </td>
                  <td>
                    <div> <span class="d-inline">
                        {{ attorneyInsurance.email!="null"?attorneyInsurance.email!==""?attorneyInsurance.email:'No Email':'No Email'}}
                      </span> </div>
                  </td>
                  <td> <span class="d-inline">{{ attorneyInsurance.phone }}</span> </td>
                  <td> <span
                      class="d-inline">{{ attorneyInsurance.fax!="null"?attorneyInsurance.fax!==""?attorneyInsurance.fax:'No Fax':'No Fax'}}</span>
                  </td>
                  <td> <span
                      class="d-inline">{{ attorneyInsurance.addressLine1!="null"?attorneyInsurance.addressLine1!==""?attorneyInsurance.addressLine1:'No Address':'No Address'}}
                    </span> </td>
                  <td> <span class="d-inline">{{ attorneyInsurance.insurance }}</span> </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-template #noMessage>
              <td colspan="12">
                <h3 class="text-center pt-3"> No Attorney/Insurance </h3>
              </td>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)"></mat-paginator>
</div>
