<div class="nav-toggle1">
  <nav class="navbar navbar-expand-lg navbar-light navbar navbar-expand-lg navbar-light admin_header_bg py-1 px-4
    text-nowrap" aria-label="navbar" id="notification">
    <a class="navbar-brand" (click)="refreshPage()">
      <img src="../.././../assets/img/HSCT_Logo_White-1.png" alt="no image" class="img-fluid logo1" />
    </a>
    <button class="navbar-toggler px-2 border-white text-white" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation"></button>
    <div class="collapse navbar-collapse justify-content-between admin_header_navbar" id="navbarSupportedContent">
      <ul class="navbar-nav custom-navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="dashboard" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}" style="cursor: pointer;">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="providers" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}" style="cursor: pointer;">Providers</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="patients" routerLinkActive="active" style="cursor: pointer;">Patients</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="admins" routerLinkActive="active" style="cursor: pointer;">Admins</a>
        </li>
        <li class="nav-item bg_green">
          <a class="nav-link" routerLink="edit-add-patient/0" routerLinkActive="active" style="cursor: pointer;">New
            Patient</a>
        </li>


      </ul>
      <ul class="navbar-nav custom-navbar-nav ml-2">
        <li class="nav-item bg_org">
          <a class="nav-link" routerLink="/super-admin/notification" routerLinkActive="active"
            style="cursor: pointer">Notifications</a>
          <span class="indicator"
            *ngIf="notificationCounter?.notificationCount">{{notificationCounter?.notificationCount}}</span>
        </li>


        <li class="nav-item">
          <a class="nav-link" routerLink="message" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}">Messages</a>

          <span class="indicator" *ngIf="notificationCounter?.messageCount">{{notificationCounter?.messageCount}}</span>
        </li>
        <li class="nav-item">
          <a class="nav-link" style="border: none" routerLink="referrals" routerLinkActive="active"
            style="cursor: pointer">Referrals</a>
          <!-- <span class="indicator"
            *ngIf="notificationCounter?.referrelCount">{{notificationCounter?.referrelCount}}</span> -->
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="showUD()" routerLinkActive="active" style="cursor: pointer">Reports</a>
        </li> -->
        <li class="nav-item bg_invite">
          <a class="nav-link" (click)="showModal(invites)" routerLinkActive="active" style=" cursor: pointer">Invite</a>
        </li>
      </ul>
    </div>

    <ul class="navbar-nav ml-auto" id="nav-2">

      <li class="dropdown">
        <div class="nav-link d-flex align-items-center justify-content-between dropdown-toggle text-white"
          data-toggle="dropdown">
          <a class="ml-1 cursor-pointer" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
            {{ currentUser }} </a>
        </div>
        <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdown" style="cursor: pointer">
          <div class="border_anim">
            <a class="dropdown-item cursor-pointer" routerLink="/super-admin/myaccount"> My Account</a>
            <!-- <a class="dropdown-item cursor-pointer"> Privacy & Policy</a> -->
            <!-- <a class="dropdown-item cursor-pointer"> My Business</a> -->
            <a class="dropdown-item cursor-pointer" (click)="openChangePassWordModal()"> Change Password</a>
            <a class="dropdown-item cursor-pointer" (click)="signout()"> Sign Out</a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</div>

<div class="nav-toggle2">
  <nav class="navbar navbar-expand-lg navbar-light admin_header_bg py-1" aria-label="navbar" id="notification">
    <a class="navbar-brand mr-5" (click)="refreshPage()"> <img src="../.././../assets/img/HSCT_Logo_White-1.png"
        alt="no image" class="img-fluid logo1" /> </a>
    <ul class="navbar-nav ml-auto" id="nav-2">
      <li class="nav-item dropdown mr-3">
        <a class="nav-link text-white position-relative" href="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="badge badge-success rounded-circle"> </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right cust-pro-dropdowm-menu border-0 py-0"
          aria-labelledby="navbarDropdown"></div>
      </li>
      <li class="nav-item dropdown mr-3">
        <a class="nav-link text-white position-relative" href="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="badge badge-success rounded-circle"> </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right cust-pro-dropdowm-menu border-0 py-0"
          aria-labelledby="navbarDropdown">

        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ currentUser }}
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <!-- <a class="dropdown-item cursor-pointer" (click)="myAccount()"> My Account </a> -->
          <a class="dropdown-item cursor-pointer">Privacy & Policy</a>
          <a class="dropdown-item cursor-pointer">My Business</a>
          <a class="dropdown-item cursor-pointer" (click)="openChangePassWordModal()">Change Password</a>
          <a class="dropdown-item cursor-pointer" (click)="signout()">Signout</a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler px-2 border-0 text-white ml-2" style="outline: none;" type="button"
      data-toggle="collapse" data-target="#navbarSupportedContentM" aria-controls="navbarSupportedContentM"
      aria-expanded="false" aria-label="Toggle navigation">

      <i class="fas fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContentM">
      <ul class="navbar-nav custom-navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="dashboard" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}">Dashboard</a>
        </li>

        <li class="nav-item ml-0 mt-3">
          <a class="nav-link" routerLink="patients" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}">Patients</a>
        </li>
        <li class="nav-item ml-0 mt-3">
          <a class="nav-link" routerLink="admins" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}">Admins</a>
        </li>
        <li class="nav-item border-0 ml-0 mt-3" style="background: var(--btn_bg_color_green);">
          <a class="nav-link" routerLinkActive="active" style="cursor: pointer">New Patient</a>
        </li>

        <li class="nav-item ml-0 mt-3">
          <a class="nav-link" routerLink="message" routerLinkActive="active"
            routerLinkActiveOpotions="{'exact': true}">Message</a>
          <!-- <span class="indicator">{{notificationCounter?.messageCount || 0}}</span> -->
        </li>
        <li class="nav-item ml-0 mt-3">
          <a class="nav-link" style="border: none">Referrals</a>
          <!-- <span class="indicator">{{notificationCounter?.referrelCount || 0}}</span> -->
        </li>
        <li class="nav-item ml-0 mt-3">
          <a class="nav-link" (click)="showUD()">Reports</a>
        </li>

      </ul>
    </div>
  </nav>
</div>
<ng-container *ngIf="isContainer; else isFluid">
  <div class="py-5">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ng-template #isFluid>
  <router-outlet></router-outlet>
</ng-template>


<ng-template #invites>
  <div class="modal-body p-0">
    <div class="modal-header header_main_bg text-white mb-3">
      <h5 class="modal-title font-weight-bold">Invite</h5>
      <button type="button" class="close pull-right" (click)="_BsModalService.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="px-3">
      <h5 class="mt-4">Grow your network, invite a admin<span class="text-danger">*</span></h5>
      <form [formGroup]="invite" (submit)="onInvitationSend()">
        <div class="form-group">
          <input type="text" class="form-control" formControlName="email" placeholder="Email" (focus)="onFocus()" />
          <small class="text-danger"
            *ngIf="invite.controls.email.invalid && invite.controls.email.touched">Required</small>
          <small class="text-danger" *ngIf="doesExist">Email already exist</small>
          <div class="form-group text-right mt-3">
            <button class="btn bg_green text-white" type="submit"> Invite Now <span *ngIf="isSpinner"
                class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
