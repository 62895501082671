<div class="container-fluid m-0 p-0">
  <div class="bg-white mb-2" style="border: 1px solid #c6c6c6;">
    <div class="">
      <p class="heading_label">Diagnostics & Imaging</p>
      <div class="d-flex justify-content-between">
        <h5 class="ml-3">Add Diagnostics & Imaging</h5>
        <mat-paginator [length]="pagination?.totalCount" (page)="pageChange($event)" [pageSize]="10"> </mat-paginator>
      </div>
    </div>
    <div class="w-100 px-3">
      <form [formGroup]="addDiagnosticsImaging">
        <div class="row mr-2">
          <div class="col-lg-4 col-md-6">
            <div class="row">
              <div class="col-md-5 pr-0">
                <div class="form-group">
                  <label for="my-input">Type</label>
                  <ng-select [items]="type" bindLabel="name" formControlName="type" [clearable]="false"
                    autocomplete="off" placeholder="Type"></ng-select>
                </div>

              </div>
              <div class="col-md-7 pr-0">
                <div class="form-group">
                  <label for="my-input">Body Part</label>
                  <ng-select [items]="bodyParts" bindLabel="name" formControlName="bodyPart" bindValue="_id"
                    [multiple]="true" [clearable]="false" placeholder="Select Body Parts"></ng-select>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="row">
              <div class="col-md-7 pr-0">
                <div class="form-group">
                  <label for="my-input">Provider</label>
                  <ng-select [items]="providerList" formControlName="provider" bindLabel="name" bindValue="_id"
                    [clearable]="false" placeholder="Select Provider"></ng-select>
                </div>

              </div>
              <div class="col-md-5 pr-0">
                <div class="form-group">
                  <label for="my-input">Date Ordered</label>
                  <input type="text" formControlName="dateOrdered" (bsValueChange)="dateOrderMin($event)"
                    [maxDate]="maxDate" placeholder="Date Ordered" class="form-control" bsDatepicker
                    >
                </div>


              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="row">
              <div class="col-md-7 pr-0">
                <div class="form-group">
                  <label for="my-input">Date Completed</label>
                  <input type="text" formControlName="dateCompleted" #dp="bsDatepicker" [minDate]="minDate"
                    placeholder="Date Completed" [maxDate]="maxDate" class="form-control" bsDatepicker
                    >
                </div>

              </div>
              <div class="col-md-5 d-flex align-items-center pr-0">
                <button type="submit" class="btn btn-block mt-3" [ngClass]="editDisabled?'btn_m_green':'btn_m_primary'"
                  (click)="submitDiagnostic()"
                  [disabled]="!editDisabled?false:this.addDiagnosticsImaging.controls.dateCompleted?.value!==null?false:true">{{editDisabled?"Update":"Add"}}</button>
              </div>

            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive table_patient_info_table border-left-0 border-right-0 border-bottom-0">
            <table class="table mb-0" aria-describedby="describe">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Body Part</th>
                  <th scope="col">Provider</th>
                  <th scope="col">Date Ordered</th>
                  <th scope="col">Date Completed</th>
                  <th scope="col">Updated By</th>
                  <th *ngIf="role !== 'Staff'" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of diagnosticImagingList" class="border-bottom">
                  <td> {{item.type?.name}} </td>
                  <td style="width: 25%">
                    <div class="d-flex flex-wrap"> <span *ngFor="let body of item.bodyPart;let i =index"> <span
                          *ngIf="i>0" class="ml-1"> | </span> {{body.name}} </span> </div>
                  </td>
                  <td> {{item.provider?.firstName}} {{item.provider?.lastName}} </td>
                  <td> {{item.orderDate | date:'MM/dd/YYYY'}} </td>
                  <td> {{item.completedDate | date:'MM/dd/YYYY' }} <button *ngIf="item.completedDate===null"
                      (click)="dp?.toggle();dateEdit(item._id)" [attr.aria-expanded]="dp?.isOpen" type="button"
                      class="btn bg_green text-white" [disabled]="(role === 'Admin' || role === 'SuperAdmin') ? true : false">Add</button></td>
                  <td>
                    <ng-container *ngFor="let user of item.createdBy">
                      {{user.user?.firstName}} {{user.user?.lastName}}
                      <small>({{user?.updateDate|date:'MM/dd/YYYY, hh:mm aa'}})</small> <br />
                    </ng-container>
                  </td>
                  <td *ngIf="role !== 'Staff'">
                    <!-- <button class="btn btn_m_primary min_width_btn" (click)="editRecord(item)"><em
                        class="fa fa-pencil-square-o" aria-hidden="true"></em></button> -->
                    <button class="btn min_width_btn bg_red text-white ml-2"
                      (click)="openModal(deleteRecord,item._id)"><em class="fa fa-trash"
                        aria-hidden="true"></em></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #deleteRecord>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>Are you sure you want to delete the diagnostic?</p>
    </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <div class="row float-right"> <button class="btn min_width_btn bg_red text-white ml-2"
        (click)="_BsModalService.hide()">No</button> <button (click)="deleteRecords()"
        class="btn min_width_btn bg_green text-white ml-2 mr-4">Yes</button> </div>
  </div>
</ng-template>
