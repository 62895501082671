<div class="container-fluid p-0">
  <div class="px-5">
    <div class="py-3">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h4 font-weight-bold mb-0">Dashboard</h1>

      </div>
    </div>
    <div class="">

      <div class="row">
        <div class="col-md-6 row_cborder_admin py-3 d-flex align-items-center">
          <form>
            <h5 class="mb-0">Search</h5>
            <div class="input-group mb-2">
              <div class="d-flex mb-2">
                <i class="fas fa-search"></i>
                <input type="text" class="form-control border-left-0" name="searchValuee" autocomplete="off"
                  [(ngModel)]="searchValuee" (input)="searchAdminsfilter($event)" placeholder="Search by: Admin name">
              </div>
              <button class="btn btn_m_green" style="height: 38px;" (click)="searchAdmin()">Search</button>
            </div>
            <span class="alertUpdate" *ngIf="expressionUpdate">Please enter at least 3 characters.</span>
          </form>
        </div>
        <div class="col-md-6 row_cborder_admin py-3">
          <h2>Overview</h2>
          <div class="ml-3">
            <div class="d-flex ">
              <div class="overview_inner_w">
                <span class="font-weight-600">New Patients</span>
                <button class="btn btn_m_primary min_width_btn">{{notificationCounter?.patientCount}}</button>
              </div>
              <div class="overview_inner_w ml-4">
                <span class="font-weight-600">New Provider</span>
                <button class="btn text-white min_width_btn bg_green">{{notificationCounter?.providerCount}}</button>
              </div>
              <div class="overview_inner_w ml-4">
                <span class="font-weight-600">Total Admin</span>
                <button class="btn text-white min_width_btn bg_green">{{notificationCounter?.AdminCount}}</button>
              </div>
            </div>
            <div class="d-flex ">
              <div class="overview_inner_w">
                <span class="font-weight-600">Status Needed</span>
                <button class="btn btn_m_primary_org min_width_btn" [queryParams]="{ tab: 1 }"
                  routerLink="/super-admin/patients">{{notificationCounter?.statusNeededCount}}</button>
              </div>
              <div class="overview_inner_w ml-4">
                <span class="font-weight-600">Urgent Status</span>
                <button class="btn min_width_btn text-white bg_red" [queryParams]="{ tab: 2 }"
                  routerLink="/super-admin/patients">{{notificationCounter?.statusNeededCountUrgent}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row boca ">

        <div class="table-responsive overflow_height_vh " style="border-bottom: 15px solid var(--bg_color_grey);">
          <table class="table m_table row_cborder_admin border-top-0 border-bottom-0 py-3" style="width: 99.9%;"
            aria-describedby="info">
            <thead>
              <th scope="col">Admin Name</th>
              <th scope="col">Business Name</th>
              <th scope="col">Address</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Total Providers</th>
              <th scope="col">New Referrals</th>
              <th scope="col">Status Needed</th>
              <th scope="col">Urgent Status</th>

            </thead>
            <tbody>
              <ng-container *ngIf="adminsData !== null ; else noAdminFound">
                <tr *ngFor="let item of adminsData">
                  <td style="cursor: pointer" class="name_limit">
                    <a routerLink="../admins-details/{{item._id}}"><strong>{{ item?.firstName }}
                        {{ item?.lastName }}</strong></a>

                  <td>
                    <ng-container *ngIf=" item?.businessName; else noBusinessName">
                      <span class="d-inline"><strong>{{ item?.businessName }}</strong></span>
                    </ng-container>

                    <ng-template #noBusinessName>
                      <span><strong>No business name</strong></span>
                    </ng-template>


                  </td>
                  <td>
                    <ng-container *ngIf=" item?.addressLine1 && item?.addressLine1!=='null' && item?.addressLine1!==''; else
                      noAddressProvided ">

                      <span class="d-inline"><strong>{{ item?.addressLine1 }}</strong></span>
                      <span class="d-inline" *ngIf="item?.addressLine2"><span *ngIf="item.addressLine1">,
                        </span><strong>{{ item?.addressLine2 }}</strong></span>

                    </ng-container>
                    <ng-template #noAddressProvided>
                      <span><strong>No address provided</strong></span>
                    </ng-template>
                  </td>
                  <td style="cursor: pointer"><strong>{{ item?.phone || "No Phone" }}</strong></td>
                  <td>
                    <span><strong>{{ admin?.invitedProviders && admin?.invitedProviders?.length > 0 ?admin?.invitedProviders?.length:'0' }}</strong></span>
                  <td>
                    <span class="pl-3 " style="color: green;"><strong>{{item?.totalNewReferral}}</strong></span>
                  </td>
                  <td>
                    <span class="pl-3 " style="color: orange;"><strong>{{item?.statusNeededCount}}</strong></span>
                  </td>
                  <td>
                    <span class="pl-3 " style="color: red;"><strong>{{item?.urgentCount}}</strong></span>
                  </td>
                </tr>
              </ng-container>
              <ng-template #noAdminFound>
                <tr class="border-bottom">
                  <td colspan="12">
                    <div class="text-center py-5">
                      <h1 class="display-4">

                      </h1>
                      <h5 class="font-weight-bold text-muted mt-3">
                        No admin found
                      </h5>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div class="w-100 mb-3">
          <!-- <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)">
          </mat-paginator> -->
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #hippaModal>

  <h3 class="modal-body hippa_header"> HIPAA AUTHORIZATION </h3>
  <div class="modal-body">
    <h5 class="text-danger">Choosing not to accept HIPAA authorization may result in not having access to HealthSight Care Tracker Services.</h5>
    <hr>
    <label for="authorization" class="text-danger">[not authorized]</label>
    <div class="form-check">
      <input class="form-check-input mt-1" type="checkbox" value="" id="flexCheckDefault" (change)="isChecking($event)">
      <label class="form-check-label" for="flexCheckDefault"> I have read and accept <span class="text-primary">
          <a href="https://privacyruleandresearch.nih.gov/authorization.asp" target="_blank">HIPAA Authorization.</a>
        </span> </label>
    </div>
  </div>
  <div class="w-100 px-3 pb-3">
    <button type="submit" style="width: 120px;" class="float-right btn btn_m_green" (click)="HippaSubmitForm()"
      [disabled]="!isChecked">Save</button>
  </div>
</ng-template>
