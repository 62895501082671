import { Injectable } from '@angular/core';
import { MainService } from '../../../services/mainservice/main.service';
import { Resolve } from '@angular/router';
import { DashboardCount } from '../../../models/dashboard';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Injectable()

export class DashboarddResolver implements Resolve<Array<DashboardCount> | null | any | any | any | any> {
  locationId: any;
    providerID: any;
    userRole!:string;
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService

    ) { }

   async resolve(): Promise<Array<DashboardCount> | null | any | any | any | any> {
        const user = 'user'
        if (user !== null) {
          this.ngxLoader.start();

          return this._MainService.getCurrentUserInfo().then((data: any) => {
            if (data.success) {
              this.userRole = data?.data?.role;

              if (this.userRole === "Staff") {
                return this._MainService.getProviderNameList(true, false, false).then(data1 => {
                  const provider = data1.data.find((x: any) => x._id === data.data.providerId);
                  if (provider) {
                    this.locationId = '';
                    this.providerID = data.data.providerId;
                  }
                  return this.getDashboardData();
                });
              } else {
                this.locationId = '';
                this.providerID = data.data.id;
                return this.getDashboardData();
              }
            }
            return null;
          }).catch(error => {
            console.error('Error fetching current user info:', error);
            return null;
          }).finally(() => {
            this.ngxLoader.stop();
          });
        } else {
          return Promise.resolve(null);
        }
      }

      private getDashboardData(): Promise<Array<DashboardCount> | null | any | any | any | any> {
        const patientPromise = (this.userRole === 'Admin' || this.userRole === 'SuperAdmin')
          ? this._MainService.getPatients(1, 10, '')
          : this._MainService.getPatientListByProvider(1, 10, '', this.providerID, this.locationId);

        return Promise.all([
          this._MainService.getDoctors(1, 5, '').then(x => x),
          this._MainService.getattorneyinsuranceList(1, 5).then((data: any) => data),
          this._MainService.getfacilityList(1, 5).then((data: any) => data),
          patientPromise.then((x: any) => x),
          this._MainService.getCurrentUserInfo().then((x: any) => x),
          // patientPromise.then((x: any) => console.log(x, "CCCVVVV")),
          // this._MainService.getNotificationCounter().then(($: any) => $)
        ]);
      }
    }

