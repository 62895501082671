
<div>
  <div class="header_main_bg modal-header text-light">
    <h5 class="modal-title font-weight-bold">Change Password</h5>
    <button type="button" class="close pull-right" (click)="modalClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="changePasswordForm" (submit)="changePasswordFormSubmit()" class="p-3">
    <div class="form-group">
      <label for="" class="font-weight-600">Old Password</label>
      <div class="password-wrapper">
      <input [type]="showPasswordOld ? 'text' : 'password'" class="form-control" formControlName="currentPassword">
      <i
              class="fa"
              [ngClass]="{'fa-eye-slash': !showPasswordOld, 'fa-eye': showPasswordOld}"
              (click)="togglePasswordVisibility1()"
              aria-hidden="true">
            </i>
          </div>
      <small class="text-danger" *ngIf="changePasswordForm?.controls?.currentPassword?.invalid && (isChangePwdFrmSubmitted || changePasswordForm?.controls?.currentPassword?.touched)">Required</small>
    </div>
    <div class="form-group">
      <label for="" class="font-weight-600">New Password</label>
      <div class="password-wrapper">
      <input [type]="showPasswordNew ? 'text' : 'password'" class="form-control" formControlName="newPassword" autocomplete="off">
      <i
              class="fa"
              [ngClass]="{'fa-eye-slash': !showPasswordNew, 'fa-eye': showPasswordNew}"
              (click)="togglePasswordVisibility2()"
              aria-hidden="true">
            </i>
          </div>
      <span class="text-danger" *ngIf="changePasswordForm?.controls?.newPassword?.invalid && (isChangePwdFrmSubmitted || changePasswordForm?.controls?.newPassword?.touched)">
        <small *ngIf="changePasswordForm?.controls?.newPassword?.errors?.required">Required</small>
        <small *ngIf="changePasswordForm?.controls?.newPassword?.errors?.minlength && !changePasswordForm?.controls?.newPassword?.errors?.pattern">
          Minimum 6 characters required
        </small>
        <small *ngIf="changePasswordForm?.controls?.newPassword?.errors?.pattern">
          Password should contain lower case and upper case characters, digits and special symbols.
        </small>
        <small *ngIf="changePasswordForm?.controls?.newPassword?.errors?.sameAsOld">
          New password cannot be the same as the old password.
        </small>
      </span>
    </div>
    <div class="form-group">
      <label for="" class="font-weight-600">Confirm Password</label>
      <div class="password-wrapper">
      <input [type]="showPasswordConfirm ? 'text' : 'password'" class="form-control" formControlName="confirmPassword" autocomplete="off">
      <i class="fa"
              [ngClass]="{'fa-eye-slash': !showPasswordConfirm, 'fa-eye': showPasswordConfirm}"
              (click)="togglePasswordVisibility3()"
              aria-hidden="true">
            </i>
          </div>
      <span class="text-danger" *ngIf="changePasswordForm?.controls?.confirmPassword?.invalid && (isChangePwdFrmSubmitted || changePasswordForm?.controls?.confirmPassword?.touched)">
        <small *ngIf="changePasswordForm?.controls?.confirmPassword?.errors?.required">Required</small>
        <small *ngIf="changePasswordForm?.controls?.confirmPassword?.errors?.notSame">
          Password not same
        </small>
      </span>
    </div>
    <div class="text-right">
      <button type="submit" class="btn btn_m_green" [disabled]="isChangePwdFrmSubmitting">
        <ng-container *ngIf="isChangePwdFrmSubmitting; else isChangePwdFrmSubmittingDone">
          <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon> Changing...
        </ng-container>
        <ng-template #isChangePwdFrmSubmittingDone> Change Password <fa-icon [icon]="faArrowRight"></fa-icon>
        </ng-template>
      </button>
    </div>
  </form>
</div>
