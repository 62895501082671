<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">
          <ng-container *ngIf="editMode; else addMode">
            {{ currAdmin.firstName }} {{ currAdmin.lastName }}
          </ng-container>
          <ng-template #addMode> New Admin </ng-template>
        </h1>
        <button class="btn btn-sm btn-outline-primary" (click)="goBack()">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
          Back
        </button>
      </div>
    </div>
    <div class="p-3">
      <form [formGroup]="addAdminForm">

        <div class="form-row">

          <div class="col-sm-4">
            <div class="form-group">
              <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
              <div class="form-row">
                <input type="text" class="form-control" maxlength="50"
                  [placeholder]="'First Name'"
                  formControlName="firstName" />
                <small class="text-danger"
                  *ngIf=" !!addAdminForm.controls?.firstName.invalid && (addAdminFormSubmitted || !!addAdminForm.controls.firstName.touched) "></small>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <label style="visibility: hidden;" class="font-weight-600" for="">Name<span
                class="text-danger">*</span></label>
            <input type="text" class="form-control" placeholder="Last Name " formControlName="lastName" />
            <small class="text-danger"
              *ngIf=" !!addAdminForm.controls.lastName.invalid && (addAdminFormSubmitted || !!addAdminForm.controls.lastName.touched) "></small>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="font-weight-600" for="">Gender<span class="text-danger"
                  *ngIf="genderValidator">*</span></label>
              <ng-select [items]="genders" bindLabel="name" [searchable]="true" bindValue="_id"
                formControlName="genderId" placeholder="Select Gender" [clearable]="false"></ng-select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
              <input appNumberOnly mask="(000)-000-0000" [showMaskTyped]="true" class="form-control" placeholder="Phone"
                formControlName="phone" />
              <span class="text-danger"
                *ngIf=" !!addAdminForm.controls.phone.invalid && (addAdminFormSubmitted || !!addAdminForm.controls.phone.touched) ">
                <small *ngIf="!!addAdminForm.controls.phone.errors.required"></small>
                <small *ngIf="!!addAdminForm.controls.phone.errors.minlength">Minimum length should be
                  10</small>
                <small *ngIf="!!addAdminForm.controls.phone.errors.maxlength">Maximum length should be
                  15</small>
              </span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Fax</label>
              <input appNumberOnly type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57;" class="form-control" formControlName="fax" placeholder="Fax"
                maxlength="15" />
              <span class="text-danger"
                *ngIf=" !!addAdminForm.controls.fax.invalid && (addAdminFormSubmitted || !!addAdminForm.controls.phone.touched) ">
                <small *ngIf="!!addAdminForm.controls.fax.errors.minlength">Minimum length should be
                  10</small>
                <small *ngIf="!!addAdminForm.controls.fax.errors.maxlength">Maximum length should be
                  15</small>
              </span>
            </div>
          </div>
          <div class="col-sm-6">

            <div class="form-group">
              <label class="font-weight-600" for="">Email</label>
              <input type="text" class="form-control" formControlName="email" placeholder="Email" (focus)="onFocus()"
                (blur)="onBlur($event)"/>
              <span class="text-danger"
                *ngIf=" !!addAdminForm.controls.email.invalid && (addAdminFormSubmitted || !!addAdminForm.controls.email.touched) ">
                <small *ngIf="!!addAdminForm.controls.email.errors.required"></small>
                <small *ngIf="!!addAdminForm.controls.email.errors.pattern">Invalid Email</small>
              </span>
              <small class="text-danger" *ngIf="doesExist">Email already exist</small>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Address Line 1</label>
              <input type="text" class="form-control" formControlName="addressLine1" placeholder="Address Line 1" />
            </div>
          </div>
          <div class="col-sm-6" *ngIf="selectedProviderType!=='Facility' && selectedProviderType!=='Attorney'">
            <div class="form-group">
              <label class="font-weight-600" for="">Address Line 2</label>
              <input type="text" class="form-control" formControlName="addressLine2" placeholder="Address Line 2" />
            </div>
          </div>
          <!-- <div class="col-sm-6" *ngIf="selectedProviderType==='Doctor'">
            <div class="form-group">
              <label class="font-weight-600" for="">Highest Qualification</label>
              <input type="text" class="form-control" formControlName="highestQualification"
                placeholder="Highest Qualification" />
            </div>
          </div> -->

          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Business Name</label>
              <input type="text" class="form-control" formControlName="businessName" placeholder="Business Name"
                maxlength="60" />
            </div>
          </div>



          <div class="col text-right align-self-end">
            <div class="form-group">
              <button type="submit" class="btn bg_green text-white" [disabled]="addAdminFormSubmitting"
                (click)="addAdminFormSubmit()">
                <ng-container *ngIf=" addAdminFormSubmitting; else addAdminFormSubmittingDone "> <span
                    class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Submitting... </ng-container>
                <ng-template #addAdminFormSubmittingDone> Submit <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </ng-template>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
