<div class="d-flex justify-content-center align-items-center" style="height:100vh">
  <div class="bg-white rounded  w-sm-50  p-3 p-xl-4 d-flex flex-column login_view ">
    <img src="../.././../assets/img/HSCT_Logo Black-1.png" alt="no image"
      class="img-fluid mb-3 p-2 rounded mx-auto d-block logo">
    <div class="position-relative">
      <form [formGroup]="addDoctorForm">

        <div class="form-row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="font-weight-600" for="">Provider Type<span class="text-danger">*</span></label>
              <ng-select [items]="providerType" bindLabel="name" [searchable]="true" bindValue="_id"
                formControlName="providerType" placeholder="Select Provider Type" [clearable]="false"
                (change)="providerChange($event)"></ng-select>
              <small class="text-danger"
                *ngIf=" !!addDoctorForm.controls.providerType.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.providerType.touched) ">required</small>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label class="font-weight-600" for="">Name<span class="text-danger">*</span></label>
              <div class="form-row">
                <div class="col-6">
                  <input type="text" class="form-control" maxlength="60"
                    [placeholder]="selectedProviderType==='Facility' || selectedProviderType==='Imaging Center' ?'Name':'First Name'"
                    formControlName="firstName" />
                  <small class="text-danger"
                    *ngIf=" !!addDoctorForm.controls.firstName.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.firstName.touched) "></small>
                </div>
                <div *ngIf="selectedProviderType!=='Imaging Center' && selectedProviderType!=='Facility' "
                  class="col-6">
                  <input type="text" class="form-control" maxlength="60" placeholder="Last Name "
                    formControlName="lastName" />
                  <small class="text-danger"
                    *ngIf=" !!addDoctorForm.controls.lastName.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.lastName.touched) "></small>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedProviderType!=='Facility' &&
            selectedProviderType!=='Imaging Center'" class="col-sm-6 ">
            <div class="form-group">
              <label class="font-weight-600" for="">Gender<span class="text-danger"
                  *ngIf="genderValidator">*</span></label>
              <ng-select [items]="genders" bindLabel="name" [searchable]="true" bindValue="_id"
                formControlName="genderId" placeholder="Select Gender" [clearable]="false"></ng-select>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label class="font-weight-600" for="">Phone<span class="text-danger">*</span></label>
                  <input appNumberOnly mask="(000)-000-0000" [showMaskTyped]="true" class="form-control"
                    placeholder="Phone" formControlName="phone" />
                  <span class="text-danger"
                    *ngIf=" !!addDoctorForm.controls.phone.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.phone.touched) ">
                    <small *ngIf="!!addDoctorForm.controls.phone.errors.required"></small>
                    <small *ngIf="!!addDoctorForm.controls.phone.errors.minlength">Minimum length should be 10</small>
                    <small *ngIf="!!addDoctorForm.controls.phone.errors.maxlength">Maximum length should be 15</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Email<span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="email" placeholder="Email" (focus)="onFocus()"
                (blur)="onBlur($event)" />
              <span class="text-danger"
                *ngIf=" !!addDoctorForm.controls.email.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.email.touched) ">
                <small *ngIf="!!addDoctorForm.controls.email.errors.required"></small>
                <small *ngIf="!!addDoctorForm.controls.email.errors.pattern">Invalid Email</small>
              </span>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Password<span class="text-danger">*</span></label>
              <input type="password" class="form-control" formControlName="newPassword" placeholder="Password" />
              <span class="text-danger" *ngIf="addDoctorForm?.controls?.newPassword?.invalid && (addDoctorFormSubmitted || addDoctorForm?.controls?.newPassword?.touched)">
                <small *ngIf="addDoctorForm?.controls?.newPassword?.errors?.minlength && !addDoctorForm?.controls?.newPassword?.errors?.pattern">
                  Minimum 6 characters required
                </small>
                <small *ngIf="addDoctorForm?.controls?.newPassword?.errors?.pattern">
                  Password should contain lower case and upper case characters, digits and special symbols.
                </small>
              </span>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Confirm Password<span class="text-danger">*</span></label>
              <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Enter same password" />
              <span class="text-danger" *ngIf="addDoctorForm?.controls?.confirmPassword?.invalid && (addDoctorFormSubmitted || addDoctorForm?.controls?.confirmPassword?.touched)">
                <small *ngIf="addDoctorForm?.controls?.confirmPassword?.errors?.notSame">
                  Password not same
                </small>
              </span>
            </div>

          </div>

          <div *ngIf="selectedProviderType==='Doctor' || selectedProviderType==='Imaging Center' ||
            selectedProviderType==='Facility'" class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600 w-100" for="">Specialty<span class="text-danger"
                  *ngIf="specialityValidator">*</span></label>
              <ng-select [items]="speciality" bindLabel="name" bindValue="_id" [searchable]="true"
                formControlName="specialityList" [multiple]="true" placeholder="Select specialities"> </ng-select>
              <small class="text-danger"
                *ngIf="!!addDoctorForm.controls.specialityList.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.specialityList.touched)"></small>
            </div>
          </div>
          <div *ngIf="selectedProviderType==='Facility'" class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600 w-100" for="">Associated Doctor<span class="text-danger"
                  *ngIf="specialityValidator">*</span></label>
              <ng-select [items]="providerList" bindLabel="name" bindValue="_id" [searchable]="true"
                formControlName="associatedprovider" [multiple]="true" placeholder="Select Associated Doctor">
              </ng-select>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Fax</label>
              <input type="text" class="form-control" onkeypress="return event.charCode >= 48 && event.charCode <= 57;"
                maxlength="15" formControlName="fax" placeholder="Fax" />
              <!-- <span class="text-danger"
                 *ngIf=" !!addDoctorForm.controls.email.invalid && (addDoctorFormSubmitted || !!addDoctorForm.controls.email.touched) ">
                 <small *ngIf="!!addDoctorForm.controls.email.errors.required"></small>
                 <small *ngIf="!!addDoctorForm.controls.email.errors.pattern">Invalid Email</small>
               </span> -->

            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Address Line 1<span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="addressLine1" placeholder="Address Line 1" />
            </div>

          </div>
          <div *ngIf="selectedProviderType!=='Facility' && selectedProviderType!=='Attorney'" class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Address Line 2</label>
              <input type="text" class="form-control" formControlName="addressLine2" placeholder="Address Line 2" />
            </div>

          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Zip<span class="text-danger">*</span></label>
              <input type="text" maxlength="15" class="form-control" formControlName="zip" placeholder="Zip Code" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Business Name</label>
              <input type="text" class="form-control" formControlName="businessName" placeholder="Business Name" />
            </div>

          </div>
          <div *ngIf="selectedProviderType==='Facility'" class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="">Contact Person</label>
              <input type="text" class="form-control" formControlName="contactPerson" placeholder="Contact Person" />
            </div>

          </div>
          <div *ngIf="selectedProviderType==='Attorney'" class="col-sm-6">
            <div class="form-group">
              <label class="font-weight-600" for="" *ngIf="insuranceValidator">Case Manager<span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="caseManager" placeholder="Case Manager" />
            </div>

          </div>
          <ng-container formArrayName="ClinicAffiliationsList">
            <ng-template ngFor let-ClinicAffiliationsList [ngForOf]="ClinicAffiliationsLists.controls" let-isLast="last"
              let-i="index">
              <div *ngIf="selectedProviderType==='Doctor'" class="col-sm-6">
                <div class="form-group">
                  <label class="font-weight-600" for="">
                    <span>Clinic Affiliation</span>
                    <span>{{ ClinicAffiliationsLists.length > 1 ? " " + (i + 1) : "" }}</span>
                  </label>
                  <div class="input-group">
                    <input type="text" class="form-control" [formControlName]="i" placeholder="Clinic Affiliation" />
                    <div class="input-group-append cursor-pointer">
                      <ng-container *ngIf="i == ClinicAffiliationsLists.length - 2">
                        <span class="input-group-text" (click)="removeClinicAffiliationsList()">
                          <i class="fa fa-minus"></i>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="isLast">
                        <span class="input-group-text" (click)="addClinicAffiliationsList()">
                          <i class="fa fa-plus"></i>
                        </span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>


          <div class="col text-right align-self-end">
            <div class="form-group">
              <button type="submit" class="btn bg_green text-white" [disabled]="addDoctorFormSubmitting"
                (click)="addDoctorFormSubmit()">
                <ng-container *ngIf="addDoctorFormSubmitting; else addDoctorFormSubmittingDone "> <span
                    class="dots-circle-spinner loading mr-1" aria-hidden="true"></span> Sign Up </ng-container>
                <ng-template #addDoctorFormSubmittingDone> Sign Up <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </ng-template>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <p class="mt-3 mb-0 text-muted text-center">&copy; HealthSight Care Tracker 2021</p>
  </div>
</div>
