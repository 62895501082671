import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { MainService } from '@app/services/mainservice/main.service';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder } from '@angular/forms';
//import { DatePipe } from '@angular/common';
import * as moment from 'moment'

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
})
export class ReferralComponent implements OnInit {
  isContainer: boolean;
  currentUser: string;
  referredToid: any
  fromDay: any
  toDay: any
  minDate: Date;
  maxDate: Date;
  role: any
  myDate: any
  receviedReferrals: any = [];
  PageModalRef: BsModalRef | null;
  isIncoming: boolean;
  referralFormGroup: FormGroup;
  pagination: any
  ReasonMore: boolean;
  ReasonLess: boolean;

  constructor(
    private _NotificationService: NotificationService,
    private _ActivatedRoute: ActivatedRoute,
    private _MainService: MainService,

    private _NgxLoader: NgxUiLoaderService,
    private _NgxService: NgxUiLoaderService,
    private _BsModalService: BsModalService,
    // private datePipe: DatePipe,
    private _FormBuilder: FormBuilder
  ) {
    this.isContainer = false;
    this.minDate = new Date(),
      this.maxDate = new Date(),
      this.currentUser = 'Test Provider';
    this.PageModalRef = new BsModalRef();
    this.isIncoming = true;
    this.ReasonMore = false;
    this.ReasonLess = true;
    this.referralFormGroup = this._FormBuilder.group({


      fromDate: [],
      toDate: []
    })

  }

  ngOnInit() {
    this.role = window.localStorage.getItem('role')
    

    this.referralFormGroup.controls.toDate.setValue(new Date())
   
    this.myDate = new Date();
    this.myDate.setDate(this.myDate.getDate() - 7);

    this.referralFormGroup.controls.fromDate.setValue(this.myDate)
    this.dateFilter()


    this.currentUser = 'Test Provider';
    this._ActivatedRoute.data.subscribe((data) => {
      

      if (data && data.referrals) {
        this.receviedReferrals = data.referrals[0].data;
       
        this.pagination = data.referrals[0].pagination

      }
    });
  }



  isAddReferralDone(ReferralDonedata: any) {
    if (ReferralDonedata) {
      this._MainService.getReferrals(1, 10, this.fromDay, this.toDay).then((data: any) => {
        

        this.receviedReferrals = data.data
        this.pagination = data.pagination
      })
      this._BsModalService.hide();
    }
  }
  showModal(template: TemplateRef<any>) {
    this.PageModalRef = this._BsModalService.show(template, {
      class: 'full_screen ref otherlandingpageModal',
      backdrop: 'static',
    });
  }

  dateFilter() {

    this.fromDay = (moment(this.referralFormGroup.controls.fromDate.value).format("YYYY-MM-DD"));
   


    this.toDay = (moment(this.referralFormGroup.controls.toDate.value).format("YYYY-MM-DD"));
    


  }



  dateOrderMin(mDate: any) { this.minDate = mDate; }
  referralByDate() {
    this.dateFilter();
    this._NgxService.start()
    

    this._MainService.getReferrals(1, 10, this.fromDay, this.toDay).then((data: any) => {
     

      this.receviedReferrals = data.data
      this.pagination = data.pagination
      this._NgxService.stop()
    })


  }


  
  pageChange(pagechangedata: any) {
    this.dateFilter()
    this._NgxLoader.start()
    this._MainService.getReferrals(pagechangedata.pageIndex + 1, 10, this.fromDay, this.toDay).then((data: any) => {
      

      if (data.success) {
        this.receviedReferrals = data.data
        this.pagination = data.pagination
        this._NgxLoader.stop()
      } else {
        this._NgxLoader.stop()
      }
    })
  }
  Accepted(_id: any): void {
   
    this._NgxLoader.start()
    this._MainService.getproviderAcceptIncomingReferral(_id).then((x: any) => {
      
      if (x.success) {
        this.updateNotificationData();

        this._MainService.getReferrals(1, 10, this.fromDay, this.toDay).then((data: any) => {

          data.data.forEach((x: any) => {

            this.referredToid = x.ReferredTo._id
            this._MainService.updateCount({ providerId: [this.referredToid] }).then((data: any) => {
              console.log(data);

            })

          });




          if (data.success) {
            this.receviedReferrals = data.data
            this.pagination = data.pagination
            this._NgxLoader.stop()
            this._MainService.updateCount({ providerId: [this.referredToid] }).then((data: any) => {
              console.log(data);

            })
          } else this._NgxLoader.stop()
        })
      } else this._NgxLoader.stop()
    })


  }

  updateNotificationData() {
    this._MainService.getNotificationCounter().then(x => {
      this._NotificationService.setnotificationData(x.data);
    })
  }
  refReason(): void {
    this.ReasonMore = true;
  }
  refReasonLess(): void {
    this.ReasonLess = false;
  }

  
}
