import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MainService } from '../../../services/mainservice/main.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// import { providerdetailswithPaginationandresponse } from '@app/models/provider/prodider.model';
import { adminDetailswithPaginationandresponse } from '@app/models/admin/admin.model';

@Injectable()

export class AdminsResolver implements Resolve<adminDetailswithPaginationandresponse | null> {
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService
    ) { }
    resolve(): Promise<adminDetailswithPaginationandresponse> | null {
        this.ngxLoader.start();
        return this._MainService.getAdmins().then(data => {
            this.ngxLoader.stop()
            return data;
        });
    }
}
