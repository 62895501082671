import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core/services/provider/notification.service';
import { MainService } from '@app/services/mainservice/main.service';
import { NotifierService } from '@app/services/notifierservice/notifier.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailTemplate } from '../../../services/emailTemplate/emailTemplate.service';

@Component({
  selector: 'app-diagnostic-imaging',
  templateUrl: './diagnostic-imaging.component.html',
  //styleUrls: ['./diagnostic-imaging.component.scss']
})
export class DiagnosticImagingComponent implements OnInit {
  providerList: any;
  bodyParts: any;
  type: any;
  data: any;
  deleteId: any;
  addDiagnosticsImaging: FormGroup;
  diagnosticImagingList: any
  PatientDetails: any;
  PatientProviderList: any = [];
  toEmail: any = [];
  CurrentUserInfo: any;
  @Input() patientID: any;
  @Input() providerNameList: any;
  minDate: Date;
  maxDate: Date;
  pagination: any;
  role: any
  editDisabled: boolean = false;
  currentConsultationImaging: any;
  orderDateCopy: any;
  @Output() notificationCounter: EventEmitter<any>;
  constructor(
    public datepipe: DatePipe,
    private _FormBuilder: FormBuilder,
    private _MainService: MainService,
    private _NgxLoader: NgxUiLoaderService,
    private _EmailTemplate: EmailTemplate,
    private _NotifierService: NotifierService,
    private _NotificationService: NotificationService,
    private _BsModalService: BsModalService,
  ) {
    this.addDiagnosticsImaging = this._FormBuilder.group({
      type: [null, [Validators.required]],
      bodyPart: [null, [Validators.required]],
      provider: [null, [Validators.required]],
      dateOrdered: [null, [Validators.required]],
      dateCompleted: [null]
    })
    this.providerList = [];
    this.bodyParts = [];
    this.minDate = new Date();
    this.maxDate = new Date();
    this.notificationCounter = new EventEmitter();
  }

  ngOnInit(): void {

    this.role = window.localStorage.getItem("role")

    // if (this.role === 'Admin' || this.role === 'SuperAdmin') {
    //   this.addDiagnosticsImaging.disable();
    // }
    this._MainService.getPatientDiagnosticList(10, 1, '', this.patientID).then((data: any) => {
      if (data.success) {
        this.diagnosticImagingList = data.data
        this.pagination = data.pagination;
      }
    })
    this._MainService.getbodyParts().then(data => { if (data.success) { this.bodyParts = data.data; } })
    this._MainService.getProviderNameList(true, false, false).then(data => { this.providerList = data.data })
    this._MainService.getDiagnosticList().then(data => { this.type = data.data })

    // Extraction for Sending Email
    this._MainService.getPatientsbyPatientID(this.patientID, false).then(data => {
      this.PatientDetails = data.data;

      if (this.PatientDetails.provider && this.PatientDetails.provider.length > 0) {
        this.PatientProviderList = this.PatientDetails.provider.map((provider:any) => ({
          firstName: provider?.firstName || '',
          lastName: provider?.lastName || '',
          _id: provider?._id || '',
        }));
      }
    });

    this._MainService.getCurrentUserInfo().then((data: any) => {
      if (data.success) {
        this.CurrentUserInfo = data.data
      }
    })

    this._MainService.getProviderNameList(true, false, false).then((data) => {
      const emailList = [];
      if (this.PatientProviderList && this.PatientProviderList.length > 0) {
        for (const provider of this.PatientProviderList) {
          const matchedProvider = data.data.find((p: any) => p._id === provider._id);
          const email = matchedProvider ? matchedProvider.email : '';
          if (email && email !== '') {
            emailList.push(email);
          }
        }
      }
      this.toEmail = emailList;
    });
  }

  prepareFormData() {
    this.data = {
      "type": this.addDiagnosticsImaging.controls.type?.value,
      "bodyPart": this.addDiagnosticsImaging.controls.bodyPart?.value,
      "provider": this.addDiagnosticsImaging.controls.provider?.value,
      "orderDate": this.editDisabled ? this.orderDateCopy : this.addDiagnosticsImaging.controls.dateOrdered?.value,
      "completedDate": this.addDiagnosticsImaging.controls.dateCompleted?.value,
      "patientId": this.patientID,
      "isUpdate": this.editDisabled ? true : false,
      "isSeen": false
    }
  }
  messageEmail() {
    this._MainService.sendEmail(this.takeformData(), true).then((data: any) => {
      console.log(data);
    })
  }

  takeformData(): FormData {
    const formData: FormData = new FormData();
    formData.append('email', this.toEmail)
    formData.append('html', this._EmailTemplate.updateEmailTemplate(
      this.PatientDetails.fullName,
      this.PatientDetails.patientUID,
      this.CurrentUserInfo?.fullName,
      "Diagnostic"
    ))
    formData.append('message', "Patient Notes Update")

    return formData
  }

  submitDiagnostic() {
    debugger
    this.prepareFormData()
    if (this.addDiagnosticsImaging.valid) {
      this._NgxLoader.start()
      this._MainService.addPatientDiagnostic({ ...this.data, _id: this.currentConsultationImaging?._id }).then((data: any) => {
        debugger
        if (data.success) {
          this._MainService.updateCount({ providerId: data.data.linkedProvider }).then((data: any) => {
            console.log(data.success);
          })
          this._MainService.getPatientDiagnosticList(10, 1, '', this.patientID).then((data: any) => {
            if (data.success) {
              this.diagnosticImagingList = data.data
              this.pagination = data.pagination;
            }
          })
          this.messageEmail()
          this.addDiagnosticsImaging.reset()
          this.updateNotificationData();
          this._NgxLoader.stop()
          if (this.editDisabled) this._NotifierService.showSuccess("Diagnostic and Imaging Updated Successfully");
          else this._NotifierService.showSuccess("Diagnostic and Imaging added Successfully")
        } else {
          this._NotifierService.showError("Something went wrong")
          this._NgxLoader.stop()
        }
      })
    }
    if (this.editDisabled) {
      this.addDiagnosticsImaging?.get("type")?.enable();
      this.addDiagnosticsImaging?.get("bodyPart")?.enable();
      this.addDiagnosticsImaging?.get("provider")?.enable();
      this.addDiagnosticsImaging?.get("dateOrdered")?.enable();
      this.editDisabled = false;
    }
  }

  updateNotificationData() {
    debugger
    this._MainService.getNotificationCounter().then(x => {
      this._NotificationService.setnotificationData(x.data);
    })
  }
  dateOrderMin(mDate: any) {

    this.minDate = mDate;
  }
  pageChange(pagechangedata: any) {
    this._NgxLoader.start()
    this._MainService.getPatientDiagnosticList(10, pagechangedata.pageIndex + 1, '', this.patientID).then((data: any) => {
      if (data.success) {
        this._NgxLoader.stop()
        this.diagnosticImagingList = data.data;
        this.pagination = data.pagination;
      } else this._NgxLoader.stop()
    })
  }
  dateEdit(id: any): void {
    debugger
    this.editDisabled = true;
    if (this.editDisabled) {
      this._MainService.editConsultationImaging("imaging", id, this.patientID).then((data: any) => {
        if (data.success) {
          this.currentConsultationImaging = data?.data;
          this.addDiagnosticsImaging?.get("type")?.setValue(data.data.type);
          var bodyParts = data.data.bodyPart.map((x: any) => x._id);
          this.addDiagnosticsImaging?.get("bodyPart")?.setValue(bodyParts);

          this.addDiagnosticsImaging?.get("provider")?.setValue(data.data.provider._id);
          this.orderDateCopy = data.data.orderDate;
          this.addDiagnosticsImaging?.get("dateOrdered")?.setValue(this.datepipe.transform(data.data.orderDate, 'MM/dd/YYYY'));
          this.addDiagnosticsImaging?.get("type")?.disable();
          this.addDiagnosticsImaging?.get("bodyPart")?.disable();
          this.addDiagnosticsImaging?.get("provider")?.disable();
          this.addDiagnosticsImaging?.get("dateOrdered")?.disable();
        }
      });
    }
  }

  openModal(template: TemplateRef<any>, id: any) {
    this.deleteId = id
    this._BsModalService.show(template, {
      class: 'center-modal',
      backdrop: 'static',
    });
  }

  deleteRecords() {
    this._NgxLoader.start()
    this._MainService.deleteDiagnostic(this.deleteId).then((data: any) => {
      if (data.success) {
        this._MainService.getPatientDiagnosticList(10, 1, '', this.patientID).then((data: any) => {
          if (data.success) {
            this.diagnosticImagingList = data.data
            this.pagination = data.pagination;
          }
        })
        this._NotifierService.showSuccess("Diagnostic Deleted Successfully");
        this._BsModalService.hide()
        this._NgxLoader.stop()
      } else {
        this._NgxLoader.stop()
        this._NotifierService.showError("Diagnostic cannot be deleted");
      }
    })
  }
}
