<div class="privacy-policy-content">
  <div class="modal-header header_main_bg text-white mb-3">
    <h2 class="modal-title font-weight-bold">Privacy Policy</h2>
    <!-- <button type="button" class="close pull-right" (click)="closePrivacyModal()"> -->
      <!-- <span aria-hidden="true">&times;</span> -->
    <!-- </button> -->
  </div>
  <div class="px-3">
    <div class="privacy-policy-content">
      <h3>1. Introduction</h3>
      <p>
        Health Sight Tracker ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application (the "HealthSight Care Tracker").
      </p>

      <h3>2. Information We Collect</h3>
      <ul>
        <li><strong>Personal Data:</strong> Name, email address, phone number, date of birth.</li>
        <li><strong>Health Data:</strong> Medical records, health status, treatment history.</li>
        <li><strong>Usage Data:</strong> Information about how you use the App.</li>
      </ul>

      <h3>3. How We Use Your Information</h3>
      <ul>
        <li>To provide, operate, and maintain our services.</li>
        <li>To improve, personalize, and expand our App.</li>
        <li>To communicate with you, including customer service and updates.</li>
        <li>To comply with legal obligations and protect against legal liability.</li>
      </ul>

      <h3>4. Sharing Your Information</h3>
      <ul>
        <li>With healthcare providers to facilitate medical services.</li>
        <li>With third-party service providers to perform functions on our behalf.</li>
        <li>As required by law, such as to comply with a subpoena or similar legal process.</li>
      </ul>

      <h3>5. Security of Your Information</h3>
      <p>We use administrative, technical, and physical security measures to protect your personal information.</p>

      <h3>6. Your Data Protection Rights</h3>
      <ul>
        <li>Right to access, update, or delete your information.</li>
        <li>Right to restrict or object to the processing of your data.</li>
      </ul>

      <h3>7. Changes to This Privacy Policy</h3>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h3>8. Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at [email address].</p>
    </div>
  </div>
</div>
