<div class="container-fluid m-0 p-0">
  <div class="bg-white mb-2" style="border: 1px solid #c6c6c6;">
    <div class="">
      <p class="heading_label">Consultations</p>
      <div class="d-flex justify-content-between">
        <h5 class="ml-3">Add Consultations</h5>
        <mat-paginator [length]="pagination?.totalCount" [pageSize]="10" (page)="pageChange($event)"> </mat-paginator>
      </div>
    </div>
    <div class="w-100 px-3">
      <form [formGroup]="addConsultation">
        <div class="row mr-2">
          <div class="col-lg-6 col-md-12">
            <div class="row">
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <label for="my-input">Provider of Service</label>
                  <ng-select [items]="facilityNameList" bindLabel="name" [clearable]="false"
                    formControlName="mdOrFacility" bindValue="_id" autocomplete="off" placeholder="Choose Provider">
                  </ng-select>
                </div>

              </div>
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <label for="my-input">Reason of Consult</label>
                  <ng-select [items]="procedureList" bindLabel="name" [clearable]="false" [multiple]="true"
                    placeholder="Procedure" bindValue="_id" formControlName="procedure"></ng-select>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8">
            <div class="row">
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <label id="dateLabel" for="my-input">Date Referred</label>
                  <input type="text" (bsValueChange)="dateOrderMin($event)" [maxDate]="maxDate"
                  placeholder="{{ placeholderText }}" formControlName="dateReferred" class="form-control" bsDatepicker>
                </div>

              </div>
              <div class="col-md-6 pr-0">
                <div class="form-group">
                  <label for="my-input">Date Completed</label>
                  <input type="text" [minDate]="minDate" #dp="bsDatepicker" [maxDate]="maxDate"
                    placeholder="Date Completed" formControlName="dateCompleted" class="form-control" bsDatepicker>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 d-flex pr-0">
            <div style="width: 138px;">
              <label style="visibility:hidden">hidden</label>
              <button type="submit" class="btn  btn-block" [ngClass]="editDisabled?'btn_m_green':'btn_m_primary'"
                (click)="consultationFormSubmit()" [disabled]="!editDisabled?false:this.addConsultation.controls.dateCompleted?.value
                !== null?false:true">
                {{editDisabled ? 'Update' :'Add'}}</button>
            </div>

          </div>
        </div>
        <div class="row mt-2">
          <div class="table-responsive table_patient_info_table border-left-0 border-right-0 border-bottom-0">
            <table class="table mb-0" aria-describedby="describe">
              <thead>
                <tr>
                  <th scope="col">Provider of Service</th>
                  <th scope="col">Reason of Consult</th>
                  <th scope="col">Date Ordered</th>
                  <th scope="col">Date Completed</th>
                  <th scope="col" style="width:30%">Updated By</th>
                  <th *ngIf="role !== 'Staff'" scope="col" style="width:30%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of consultationList" class="border-bottom">
                  <td> {{item.mdOrFacility?.firstName}} {{item.mdOrFacility?.lastName}} </td>

                  <td style="width:25%">
                    <div class="d-flex flex-wrap"> <span *ngFor="let type of item.procedure;let i =index"> <span
                          *ngIf="i>0" class="ml-1"> | </span> {{type.name}} </span> </div>
                  </td>
                  <td> {{item.dateReferred | date:'MM/dd/YYYY'}} </td>
                  <td> {{item.dateCompleted | date:'MM/dd/YYYY'}}
                    <button *ngIf="item.dateCompleted===null || !item.dateCompleted"
                      (click)="dp.toggle() ; dateEdit(item._id)" [attr.aria-expanded]="dp?.isOpen" type="button"
                      class="btn bg_green text-white" [disabled]="(role === 'Admin' || role === 'SuperAdmin') ? true : false">Add</button>
                    <button *ngIf="item.dateCompleted"
                      (click)="dp.toggle() ; dateEdit(item._id)" [attr.aria-expanded]="dp?.isOpen" type="button"
                      class="btn p-0 ml-1" [disabled]="(role === 'Admin' || role === 'SuperAdmin') ? true : false">
                      <i class="fas fa-pencil-alt"></i> </button>
                    </td>

                  <td>
                    <ng-container *ngFor="let user of item.createdBy">
                      {{user.user?.firstName}} {{user.user?.lastName}}
                      <small>({{user?.updateDate|date:'MM/dd/YYYY, hh:mm aa'}})</small> <br />
                    </ng-container>
                  </td>
                  <td *ngIf="role !== 'Staff'">
                    <!-- <button class="btn btn_m_primary min_width_btn" (click)="editRecord(item)"><em
                        class="fa fa-pencil-square-o" aria-hidden="true"></em></button> -->
                    <button class="btn min_width_btn bg_red text-white ml-2"
                      (click)="openModal(deleteRecord,item._id)"><em class="fa fa-trash"
                        aria-hidden="true"></em></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #deleteRecord>
  <div class="modal-header container-fluid">
    <h5 class="modal-title pull-left">
      <p>Are you sure you want to delete the consultation?</p>
    </h5> <button type="button" class="close pull-right" aria-label="Close" (click)="_BsModalService.hide()"> <span
        aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
    <div class="row float-right"> <button class="btn min_width_btn bg_red text-white ml-2"
        (click)="_BsModalService.hide()">No</button> <button (click)="deleteRecords()"
        class="btn min_width_btn bg_green text-white ml-2 mr-4">Yes</button> </div>
  </div>
</ng-template>
