import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EmailTemplate {
  welcomeEmailTemplate(email: any, password: any, url: any) {
    let template = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>EmailTemplate</title>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="open_sans" rel="stylesheet" type="text/css">
  <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="ubuntu" rel="stylesheet" type="text/css">
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }

    .table_res {
      width: 38%;
      text-align: right;
    }

    .table_p_res {
      padding: 18px;
    }

    .font_14_res {
      font-size: 14px;
    }

    .font_16_res {
      font-size: 16px;
    }

    .font_32_res {
      font-size: 32px;
    }

    @media screen and (max-width:500px) {
      .table_res {
        width: 30%;
      }

      .font_14_res {
        font-size: 13px;
      }

      .font_16_res {
        font-size: 15px;
      }

      .font_32_res {
        font-size: 28px;
      }
    }

    @media screen and (max-width:400px) {
      .table_res {
        width: 21%;
      }

      .table_p_res {
        padding: 0;
      }

      .font_14_res {
        font-size: 12px;
      }

      .font_16_res {
        font-size: 14px;
      }

      .font_32_res {
        font-size: 26px;
      }
    }

    .abtn {
      font-size: 14px;
      color: #ffffff;
      font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: normal;
      text-decoration: none;
      display: inline-block;
      background-color: #00f;
      border: 1px solid rgb(170, 149, 149);
      padding: 5px 10px;
      text-align: center;
      cursor: pointer;
      border-radius: 6px;

    }

    .abtn:hover {
      background-color: #08328b;
    }

    .bbtn {
      font-size: 14px;
      color: rgb(250, 246, 246);
      font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: normal;
      text-decoration: none;
      display: inline-block;
      background-color: rgb(43, 85, 177);
      border: 1px solid rgb(170, 149, 149);
      padding: 5px 10px;
      text-align: center;
      margin: 10px 30px;
      cursor: pointer;

    }

    .btn:hover {
      background-color: rgb(3, 31, 92);
    }

    .power_btn {
      background-color: #00f;
      transition: all .2s;
    }

    .power_btn:hover {
      background-color: #0000c2;
    }

  </style>
</head>

<body>
  <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
    <tbody>
      <tr>
        <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
          style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
          <table cellspacing="0" cellpadding="0" border="0"
            style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
            <tbody>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                            <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                              alt="Image"
                              src="https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png"
                              width="434" class="CToWUd a6T" tabindex="0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p class="font_32_res"
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Welcome to
                                HealthSight Care Tracker!</strong>
                            </p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="text-align:center;margin:0px;padding:0px"><span class="font_16_res">Please use the
                                credentials below to access our server. You will be able to manage your account and also
                                change your password once you are logged in.</span></p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><br></span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" class="table_p_res"
                            style="text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="text-align:center;margin:0px;padding:0px"><span style="font-size:14px">-Login
                                Credentials Here-</span></p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><br></span></p>
                            <p style="text-align: center;"> <span class="font_14_res">
                                <strong> <span style="color:#0000ff">Email</span> </strong>
                              </span>
                              <span class="font_14_res" style="font-weight: normal;color: rgb(95, 95, 95);">:
                                ${email}</span></p>
                            <p style="text-align: center;"><span class="font_14_res">
                                <strong> <span style="color:#0000ff">Password</span>
                                </strong>
                              </span><span class="font_14_res" style="font-weight: normal;color: rgb(95, 95, 95);">:
                                ${password}</span></p>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">

                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              Access Link:</p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">Click on the button below to be redirected onto our website
                            </p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><strong><br></strong></span>
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                              <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                    href=${url} ><strong><span style="font-size:14px">
                                        Log
                                        In</span></strong></a></span></strong></a></span><br>
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px;box-sizing:border-box;text-align:center">
                            <img
                              src="https://api.elasticemail.com/userfile/5f8e6df9-a0d4-4390-84f5-7e6efb0f5776/Daco_4844228.png"
                              alt="Image"
                              style="border-width:0px;font-size:12px;border-style:none;max-width:382px;width:100%"
                              width="382" class="CToWUd a6T" tabindex="0">

                          </td>
                        </tr>
                        <tr>
                          <td valign="top"
                            style="padding:20px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:16px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <b><span
                                  style="font-size:18px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Introduction
                                  to HealthSight Care Tracker</span></b>
                            </p>
                            <p style="margin:0px;padding:0px"><b><span style="font-size:18px"><br></span></b></p>
                            <p style="text-align:center;margin:0px;padding:0px">
                            </p>
                            <p style="text-align:center;margin:0px;padding:0px">We've attatched a link to an
                              introduction
                              powerpoint. This powerpoint will help introduce you to our system and explains its many
                              functionalities.</p>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top" style="padding:20px;text-align:center">
                            <table style="text-align:center;margin:0 auto" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td class="power_btn" style="border-radius:8px;padding:14px;text-align:center">
                                    <a href="https://1348F.trk.elasticemail.com/tracking/click?d=9AT5DOOSSpB99x-HvYb76FK6EbkjMfGjU-rGEAxQs3_0OKus-a-t0cMhD2vcIivQLijGra4_qia1c-0gQo_5VrO41fwuCLzCCj_-XR2SDjH585VKFMBWhqw_iYpugBFw6ZzyJNnW0050jD5GL19-vCIveCNxnGKyQa7sinvsZ6__vgNa2ZYgcXyZz0g5IKhUf6YuFzo9Ih0oNLrzUIHP3lopzXKx0Fq2oY_AhyAqVlLU0"
                                      style="font-size:14px;color:rgb(255,255,255);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight:normal;text-decoration:none;display:inline-block"
                                      target="_blank">
                                      <span style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                        Power
                                        Point! </span> </a> </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px"> </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px"> </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                  <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="font-size:11px;margin:0px;padding:0px">If you no longer wish to receive mail from
                              us, you can&nbsp;
                              <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O"><span
                                  style="font-size:11px"><u>unsubscribe</u></span></a>
                              <br>Raymond Torres, United States
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</body>

</html>
`
    return template
  }



  inviteEmailTemplate(inviteLink: any, fname: any, lname: any) {
    let template = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>EmailTemplate</title>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="open_sans" rel="stylesheet" type="text/css">
  <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="ubuntu" rel="stylesheet" type="text/css">
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }

    .abtn {
    font-size: 14px;
    color: #ffffff;
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    background-color: #00f;
    border: 1px solid rgb(170, 149, 149);
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    }

    .abtn:hover {
    background-color: #08328b;
    }

    .bbtn {
    font-size: 14px;
    color: rgb(250, 246, 246);
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    background-color: rgb(43, 85, 177);
    border: 1px solid rgb(170, 149, 149);
    padding: 5px 10px;
    text-align: center;
    margin: 10px 30px;
    cursor: pointer;

    }

    .btn:hover {
    background-color: rgb(3, 31, 92);
    }

    .power_btn {
    background-color: #00f;
    transition: all .2s;
    }

    .power_btn:hover {
    background-color: #0000c2;
    }

  </style>
</head>

<body>
  <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
    <tbody>
      <tr>
        <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
          style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
          <table cellspacing="0" cellpadding="0" border="0"
            style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
            <tbody>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                            <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                              alt="Image"
                              src='https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png'
                              width="434" class="CToWUd a6T" tabindex="0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <!-- <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>-->
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:32px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Welcome to
                                HealthSight Care Tracker!</strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="text-align:center;margin:0px;padding:0px"><span
                                style="font-size:16px; color:rgb(0,0,255);">${fname} ${lname} </span><span> has invited
                                you to join HealthSight Care Tracker.</span></p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><br></span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>

              <tr>
                <td valign="top"
                  style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">

                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              Invitation Link:</p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">Click on the button below to be redirected onto our
                              website
                            </p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><strong><br></strong></span>
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                           <p style="margin:0px;padding:0px">
                             <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                   href=${inviteLink} target="_blank"><strong><span style="font-size:14px">
                                       Invitation Link</span></strong></a></span></strong></a></span><br>
                             </span>
                           </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px;box-sizing:border-box;text-align:center">
                            <img
                              src="https://api.elasticemail.com/userfile/5f8e6df9-a0d4-4390-84f5-7e6efb0f5776/Daco_4844228.png"
                              alt="Image"
                              style="border-width:0px;font-size:12px;border-style:none;max-width:382px;width:100%"
                              width="382" class="CToWUd a6T" tabindex="0">

                          </td>
                        </tr>
                        <tr>
                          <td valign="top"
                            style="padding:20px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:16px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <b><span
                                  style="font-size:18px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Introduction
                                  to HealthSight Care Tracker</span></b></p>
                            <p style="margin:0px;padding:0px"><b><span style="font-size:18px"><br></span></b></p>
                            <p style="text-align:center;margin:0px;padding:0px">
                            </p>
                            <p style="text-align:center;margin:0px;padding:0px">We've attatched a link to an
                              introduction
                              powerpoint. This powerpoint will help introduce you to our system and explains its many
                              functionalities.</p>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top" style="padding:20px;text-align:center">
                            <table style="text-align:center;margin:0 auto" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td class="power_btn" style="border-radius:8px;padding:14px;text-align:center">
                                    <a href="https://1348F.trk.elasticemail.com/tracking/click?d=9AT5DOOSSpB99x-HvYb76FK6EbkjMfGjU-rGEAxQs3_0OKus-a-t0cMhD2vcIivQLijGra4_qia1c-0gQo_5VrO41fwuCLzCCj_-XR2SDjH585VKFMBWhqw_iYpugBFw6ZzyJNnW0050jD5GL19-vCIveCNxnGKyQa7sinvsZ6__vgNa2ZYgcXyZz0g5IKhUf6YuFzo9Ih0oNLrzUIHP3lopzXKx0Fq2oY_AhyAqVlLU0"
                                      style="font-size:14px;color:rgb(255,255,255);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight:normal;text-decoration:none;display:inline-block"
                                      target="_blank">
                                      <span style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                        Power
                                        Point! </span> </a> </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                  <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="font-size:11px;margin:0px;padding:0px">If you no longer wish to receive mail from
                              us, you can&nbsp;
                              <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O"><span
                                  style="font-size:11px"><u>unsubscribe</u></span></a>
                              <br>Raymond Torres, United States
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>

</body>

</html>`
    return template;
  }

  refferalEmailTemplate(referralFname: any, referralLname: any, url: any) {
    let template = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http - equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>EmailTemplate </title>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
    data - name="open_sans" rel="stylesheet" type="text/css">
  <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="ubuntu" rel="stylesheet" type="text/css">
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }

    .abtn {
      font-size: 14px;
      color: #ffffff;
      font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: normal;
      text-decoration: none;
      display: inline-block;
      background-color: #00f;
      border: 1px solid rgb(170, 149, 149);
      padding: 5px 10px;
      text-align: center;
      cursor: pointer;
      border-radius: 6px;
    }

    .abtn:hover {
      background-color: #08328b;
    }

    .bbtn {
      font-size: 14px;
      color: rgb(250, 246, 246);
      font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: normal;
      text-decoration: none;
      display: inline-block;
      background-color: rgb(43, 85, 177);
      border: 1px solid rgb(170, 149, 149);
      padding: 5px 10px;
      text-align: center;
      margin: 10px 30px;
      cursor: pointer;

    }

    .btn:hover {
      background-color: rgb(3, 31, 92);
    }

    .power_btn {
      background-color: #00f;
      transition: all .2s;
    }

    .power_btn:hover {
      background-color: #0000c2;
    }

  </style>
</head>

<body>
  <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
    <tbody>
      <tr>
        <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
          style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
          <table cellspacing="0" cellpadding="0" border="0"
            style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
            <tbody>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                            <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                              alt="Image"
                              src="https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png"
                              width="434" class="CToWUd a6T" tabindex="0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:32px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                Welcome to
                                HealthSight Care Tracker! </strong> </p>
                            <p style="margin:0px;padding:0px">
                              <strong><br></strong></p>
                            <p style="margin:0px;padding:0px"> <strong><br></strong></p>
                            <p style="text-align:center;margin:0px;padding:0px"> <span style="font-size:16px"> You have
                                a
                                new refferal from <span style="color: rgb(0,0,255);"> ${referralFname}
                                  ${referralLname} </span>,
                                please use the link below to access.</span> </p>
                            <p style="margin:0px;padding:0px"> <span style="font-size:14px">
                                <br></span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">

                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              Access Link: </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px"> Click on the button below
                              to login and reply
                            </p>
                            <p style="margin:0px;padding:0px"> <span style="font-size:14px">
                                <strong><br></strong></span>
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                              <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                    href=${url}><strong><span style="font-size:14px">
                                        Log
                                        In</span></strong></a></span></strong></a></span><br>
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px;box-sizing:border-box;text-align:center">
                            <img
                              src="https://api.elasticemail.com/userfile/5f8e6df9-a0d4-4390-84f5-7e6efb0f5776/Daco_4844228.png"
                              alt="Image"
                              style="border-width:0px;font-size:12px;border-style:none;max-width:382px;width:100%"
                              width="382" class="CToWUd a6T" tabindex="0">

                          </td>
                        </tr>
                        <tr>
                          <td valign="top"
                            style="padding:20px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:16px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <b><span
                                  style="font-size:18px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                  Introduction
                                  to HealthSight Care Tracker </span> </b> </p>
                            <p style="margin:0px;padding:0px">
                              <b><span style="font-size:18px">
                                  <br></span></b> </p>
                            <p style="text-align:center;margin:0px;padding:0px">
                            </p>
                            <p style="text-align:center;margin:0px;padding:0px">
                              We've attatched a link to an
                              introduction
                              powerpoint.This powerpoint will
                              help introduce you to our system
                              and explains its many
                              functionalities.</p>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top" style="padding:20px;text-align:center">
                            <table style="text-align:center;margin:0 auto" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td class="power_btn" style="border-radius:8px;padding:14px;text-align:center">
                                    <a href="https://1348F.trk.elasticemail.com/tracking/click?d=9AT5DOOSSpB99x-HvYb76FK6EbkjMfGjU-rGEAxQs3_0OKus-a-t0cMhD2vcIivQLijGra4_qia1c-0gQo_5VrO41fwuCLzCCj_-XR2SDjH585VKFMBWhqw_iYpugBFw6ZzyJNnW0050jD5GL19-vCIveCNxnGKyQa7sinvsZ6__vgNa2ZYgcXyZz0g5IKhUf6YuFzo9Ih0oNLrzUIHP3lopzXKx0Fq2oY_AhyAqVlLU0"
                                      style="font-size:14px;color:rgb(255,255,255);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight:normal;text-decoration:none;display:inline-block"
                                      target="_blank">
                                      <span style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                        Power
                                        Point! </span> </a> </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                  <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="font-size:11px;margin:0px;padding:0px">
                              If
                              you
                              no
                              longer
                              wish
                              to
                              receive
                              mail
                              from
                              us,
                              you
                              can
                              &
                              nbsp;
                              <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                target="_blank" data -
                                saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O">
                                <span style="font-size:11px">
                                  <u>unsubscribe
                                  </u>
                                </span>
                              </a>
                              <br>
                              Raymond
                              Torres,
                              United
                              States
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</body>

</html>
`
    return template
  }

  messageEmailTemplate(msgFname: any, msgLname: any, url: any) {
    let template = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>EmailTemplate</title>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="open_sans" rel="stylesheet" type="text/css">
  <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="ubuntu" rel="stylesheet" type="text/css">
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }
    .abtn {
    font-size: 14px;
    color: #ffffff;
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    background-color: #00f;
    border: 1px solid rgb(170, 149, 149);
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    }

    .abtn:hover {
    background-color: #08328b;
    }

    .bbtn {
    font-size: 14px;
    color: rgb(250, 246, 246);
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    background-color: rgb(43, 85, 177);
    border: 1px solid rgb(170, 149, 149);
    padding: 5px 10px;
    text-align: center;
    margin: 10px 30px;
    cursor: pointer;

    }

    .btn:hover {
    background-color: rgb(3, 31, 92);
    }

    .power_btn {
    background-color: #00f;
    transition: all .2s;
    }

    .power_btn:hover {
    background-color: #0000c2;
    }
  </style>
</head>

<body>
  <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
    <tbody>
      <tr>
        <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
          style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
          <table cellspacing="0" cellpadding="0" border="0"
            style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
            <tbody>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                            <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                              alt="Image"
                              src="https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png"
                              width="434" class="CToWUd a6T" tabindex="0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>

              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:32px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Message from 
                              HealthSight Care Tracker Portal!</strong>
                            </p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="text-align:center;margin:0px;padding:0px"><span style="font-size:16px">You have a
                                new message from <span style="color: rgb(0,0,255);">${msgFname} ${msgLname}</span>,
                                please use the link below to reply.</span></p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><br></span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>

              <tr>
                <td valign="top"
                  style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">

                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              Access Link:</p>
                            <p style="margin:0px;padding:0px"> </p>
                            <p style="margin:0px;padding:0px">Click on the button below to login and reply </p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><strong><br></strong></span>
                            </p>
                            <p style="margin:0px;padding:0px"> </p>
                            <p style="margin:0px;padding:0px"> </p>
                            <p style="margin:0px;padding:0px">
                              <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                    href=${url}><strong><span style="font-size:14px">
                                        Log
                                        In</span></strong></a></span></strong></a></span><br>
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px;box-sizing:border-box;text-align:center">
                            <img
                              src="https://api.elasticemail.com/userfile/5f8e6df9-a0d4-4390-84f5-7e6efb0f5776/Daco_4844228.png"
                              alt="Image"
                              style="border-width:0px;font-size:12px;border-style:none;max-width:382px;width:100%"
                              width="382" class="CToWUd a6T" tabindex="0">

                          </td>
                        </tr>
                        <tr>
                          <td valign="top"
                            style="padding:20px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:16px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <b><span
                                  style="font-size:18px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Introduction
                                  to HealthSight Care Tracker</span></b>
                            </p>
                            <p style="margin:0px;padding:0px"><b><span style="font-size:18px"><br></span></b></p>
                            <p style="text-align:center;margin:0px;padding:0px"> </p>
                            <p style="text-align:center;margin:0px;padding:0px">We've attatched a link to an
                              introduction powerpoint. This powerpoint will help introduce you to our system and
                              explains its many functionalities.</p>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top" style="padding:20px;text-align:center">
                            <table style="text-align:center;margin:0 auto" cellspacing="0" cellpadding="0">
                              <tbody>
                                 <tr>
                                   <td class="power_btn" style="border-radius:8px;padding:14px;text-align:center">
                                     <a href="https://1348F.trk.elasticemail.com/tracking/click?d=9AT5DOOSSpB99x-HvYb76FK6EbkjMfGjU-rGEAxQs3_0OKus-a-t0cMhD2vcIivQLijGra4_qia1c-0gQo_5VrO41fwuCLzCCj_-XR2SDjH585VKFMBWhqw_iYpugBFw6ZzyJNnW0050jD5GL19-vCIveCNxnGKyQa7sinvsZ6__vgNa2ZYgcXyZz0g5IKhUf6YuFzo9Ih0oNLrzUIHP3lopzXKx0Fq2oY_AhyAqVlLU0"
                                       style="font-size:14px;color:rgb(255,255,255);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight:normal;text-decoration:none;display:inline-block"
                                       target="_blank">
                                       <span style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                         Power
                                         Point! </span> </a> </td>
                                 </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                  <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="font-size:11px;margin:0px;padding:0px">If you no longer wish to receive mail from
                              us, you can&nbsp;
                              <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O"><span
                                  style="font-size:11px"><u>unsubscribe</u></span></a>
                              <br>Raymond Torres, United States
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>

</body>

</html>`
    return template
  }

  updateEmailTemplate(patientFullName: any, patientId: any, CurrentUserFullName: any, value: any) {
    let template = `<!DOCTYPE html>
    <html lang="en">
    
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>EmailTemplate</title>
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
        data-name="open_sans" rel="stylesheet" type="text/css">
      <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
        data-name="ubuntu" rel="stylesheet" type="text/css">
      <style>
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
        }
        .abtn {
        font-size: 14px;
        color: #ffffff;
        font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: normal;
        text-decoration: none;
        display: inline-block;
        background-color: #00f;
        border: 1px solid rgb(170, 149, 149);
        padding: 5px 10px;
        text-align: center;
        cursor: pointer;
        border-radius: 6px;
        }
    
        .abtn:hover {
        background-color: #08328b;
        }
    
        .bbtn {
        font-size: 14px;
        color: rgb(250, 246, 246);
        font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: normal;
        text-decoration: none;
        display: inline-block;
        background-color: rgb(43, 85, 177);
        border: 1px solid rgb(170, 149, 149);
        padding: 5px 10px;
        text-align: center;
        margin: 10px 30px;
        cursor: pointer;
    
        }
    
        .btn:hover {
        background-color: rgb(3, 31, 92);
        }
    
        .power_btn {
        background-color: #00f;
        transition: all .2s;
        }
    
        .power_btn:hover {
        background-color: #0000c2;
        }
      </style>
    </head>
    
    <body>
      <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
        <tbody>
          <tr>
            <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
              style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
              <table cellspacing="0" cellpadding="0" border="0"
                style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
                <tbody>
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                      <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                                <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                                  alt="Image"
                                  src="https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png"
                                  width="434" class="CToWUd a6T" tabindex="0">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </td>
                  </tr>
    
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                      <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top"
                                style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                                <p
                                  style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:32px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                  <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Message from 
                                    HealthSight Care Tracker Portal!</strong>
                                </p>
                                <p style="margin:0px;padding:0px"><strong><br></strong></p>
                                <p style="margin:0px;padding:0px"><strong><br></strong></p>
                                <p> <span style="font-size:16px">Hello Providers,</span> </p><br>
                                <p style="text-align:center;margin:0px;padding:0px"><span style="font-size:16px">
                                Your patient, <span style="color: rgb(0,0,255);">${patientFullName}, with Patient ID: ${patientId}</span>,<br>
                                 ${value} has been updated by 
                                <span style="color: rgb(0,0,255);">${CurrentUserFullName}</span>
                            </p>
                                <p style="margin:0px;padding:0px"><span style="font-size:14px"><br></span></p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </td>
                  </tr>
    
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">
    
                      <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top"
                                style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                                <p
                                  style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                  Access Link:</p>
                                <p style="margin:0px;padding:0px"> </p>
                                <p style="margin:0px;padding:0px">Click on the button below to login and reply </p>
                                <p style="margin:0px;padding:0px"><span style="font-size:14px"><strong><br></strong></span>
                                </p>
                                <p style="margin:0px;padding:0px"> </p>
                                <p style="margin:0px;padding:0px"> </p>
                                <p style="margin:0px;padding:0px">
                                  <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                        href="https://healthsighttracker.com/login"><strong><span style="font-size:14px">
                                            Log
                                            In</span></strong></a></span></strong></a></span><br>
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
    
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                      <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top" style="padding:10px">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </td>
                  </tr>
                  <tr>
                  </tr>
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                      <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top" style="padding:10px">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                      <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top" style="padding:20px">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
    
                    </td>
                  </tr>
                  <tr>
                    <td valign="top"
                      style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                      <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                        <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                            <tr>
                              <td valign="top"
                                style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                                <p style="font-size:11px;margin:0px;padding:0px">If you no longer wish to receive mail from
                                  us, you can&nbsp;
                                  <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                    style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                    target="_blank"
                                    data-saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O"><span
                                      style="font-size:11px"><u>unsubscribe</u></span></a>
                                  <br>Raymond Torres, United States
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </section>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    
    </body>
    
    </html>`

    return template
  }
  changepswrdTemplate(newlyEmail: any, newlyPassword: any, url: any) {
    let template = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>EmailTemplate</title>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="open_sans" rel="stylesheet" type="text/css">
  <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="ubuntu" rel="stylesheet" type="text/css">
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }

    .table_res {
      width: 38%;
      text-align: right;
    }

    .table_p_res {
      padding: 18px;
    }

    .font_14_res {
      font-size: 14px;
    }

    .font_16_res {
      font-size: 16px;
    }

    .font_32_res {
      font-size: 32px;
    }

    @media screen and (max-width:500px) {
      .table_res {
        width: 30%;
      }

      .font_14_res {
        font-size: 13px;
      }

      .font_16_res {
        font-size: 15px;
      }

      .font_32_res {
        font-size: 28px;
      }
    }

    @media screen and (max-width:400px) {
      .table_res {
        width: 21%;
      }

      .table_p_res {
        padding: 0;
      }

      .font_14_res {
        font-size: 12px;
      }

      .font_16_res {
        font-size: 14px;
      }

      .font_32_res {
        font-size: 26px;
      }

    }
     .abtn {
     font-size: 14px;
     color: #ffffff;
     font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
     font-weight: normal;
     text-decoration: none;
     display: inline-block;
     background-color: #00f;
     border: 1px solid rgb(170, 149, 149);
     padding: 5px 10px;
     text-align: center;
     cursor: pointer;
     border-radius: 6px;
     }

     .abtn:hover {
     background-color: #08328b;
     }

     .bbtn {
     font-size: 14px;
     color: rgb(250, 246, 246);
     font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
     font-weight: normal;
     text-decoration: none;
     display: inline-block;
     background-color: rgb(43, 85, 177);
     border: 1px solid rgb(170, 149, 149);
     padding: 5px 10px;
     text-align: center;
     margin: 10px 30px;
     cursor: pointer;

     }

     .btn:hover {
     background-color: rgb(3, 31, 92);
     }

     .power_btn {
     background-color: #00f;
     transition: all .2s;
     }

     .power_btn:hover {
     background-color: #0000c2;
     }
  </style>
</head>

<body>
  <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
    <tbody>
      <tr>
        <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
          style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
          <table cellspacing="0" cellpadding="0" border="0"
            style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
            <tbody>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                            <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                              alt="Image"
                              src="https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png"
                              width="434" class="CToWUd a6T" tabindex="0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p class="font_32_res"
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Message from 
                              HealthSight Care Tracker Portal!</strong>
                            </p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="text-align:center;margin:0px;padding:0px"><span class="font_16_res">Your password
                                has been changed successfully.</span></p>
                            <p style="margin:0px;padding:0px"><span class="font_14_res"><br></span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" class="table_p_res"
                            style="text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="text-align:center;margin:0px;padding:0px"><span class="font_14_res">-Login
                                Credentials Here-</span></p>
                            <p style="margin:0px;padding:0px"><span class="font_14_res"><br></span></p>
                            <table style="width:100%">
                              <tbody>
                                <tr>
                                  <p style="text-align: center;">
                                    <strong> <span class="font_14_res" style="color:#0000ff">Email</span> </strong>
                                    <span class="font_14_res" style="font-weight: normal;color: rgb(95, 95, 95);">:
                                      ${newlyEmail}</span></p>
                                  <p style="text-align: center;">
                                    <strong> <span class="font_14_res"
                                        style="text-align: right;white-space: nowrap;color:#0000ff">Password</span>
                                    </strong>
                                    </span>
                                    <span class="font_14_res"
                                      style="white-space: nowrap;font-weight: normal;color: rgb(95, 95, 95);">:
                                      ${newlyPassword}</span>
                                  </p>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              Access Link:</p>
                            <p style="margin:0px;padding:0px"> </p>
                            <p style="margin:0px;padding:0px">Click on the button below to be redirected onto our website
                            </p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><strong><br></strong></span>
                            </p>
                            <p style="margin:0px;padding:0px"> </p>
                            <p style="margin:0px;padding:0px"> </p>
                             <p style="margin:0px;padding:0px">
                               <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                     href=${url}><strong><span style="font-size:14px">
                                         Log
                                         In</span></strong></a></span></strong></a></span><br>
                               </span>
                             </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px;box-sizing:border-box;text-align:center">
                            <img
                              src="https://api.elasticemail.com/userfile/5f8e6df9-a0d4-4390-84f5-7e6efb0f5776/Daco_4844228.png"
                              alt="Image"
                              style="border-width:0px;font-size:12px;border-style:none;max-width:382px;width:100%"
                              width="382" class="CToWUd a6T" tabindex="0">

                          </td>
                        </tr>
                        <tr>
                          <td valign="top"
                            style="padding:20px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p class="font_16_res"
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <b><span
                                  style="font-size:18px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Introduction
                                  to HealthSight Care Tracker</span></b>
                            </p>
                            <p style="margin:0px;padding:0px"><b><span style="font-size:18px"><br></span></b></p>
                            <p style="text-align:center;margin:0px;padding:0px"> </p>
                            <p style="text-align:center;margin:0px;padding:0px">We've attatched a link to an
                              introduction powerpoint. This powerpoint will help introduce you to our system and
                              explains its many functionalities.</p>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top" style="padding:20px;text-align:center">
                            <table style="text-align:center;margin:0 auto" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td class="power_btn" style="border-radius:8px;padding:14px;text-align:center">
                                    <a href="https://1348F.trk.elasticemail.com/tracking/click?d=9AT5DOOSSpB99x-HvYb76FK6EbkjMfGjU-rGEAxQs3_0OKus-a-t0cMhD2vcIivQLijGra4_qia1c-0gQo_5VrO41fwuCLzCCj_-XR2SDjH585VKFMBWhqw_iYpugBFw6ZzyJNnW0050jD5GL19-vCIveCNxnGKyQa7sinvsZ6__vgNa2ZYgcXyZz0g5IKhUf6YuFzo9Ih0oNLrzUIHP3lopzXKx0Fq2oY_AhyAqVlLU0"
                                      style="font-size:14px;color:rgb(255,255,255);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight:normal;text-decoration:none;display:inline-block"
                                      target="_blank">
                                      <span style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                        Power
                                        Point! </span> </a> </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px"> </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                  <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="font-size:11px;margin:0px;padding:0px">If you no longer wish to receive mail from
                              us, you can&nbsp;
                              <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O"><span
                                  style="font-size:11px"><u>unsubscribe</u></span></a>
                              <br>Raymond Torres, United States
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>

</body>

</html>`
    return template
  }


  forgotPasswordTemplate(resetUrl: any) {

    let template = `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>EmailTemplate</title>
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="open_sans" rel="stylesheet" type="text/css">
  <link href="https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i &amp;subset=cyrillic,latin-ext"
    data-name="ubuntu" rel="stylesheet" type="text/css">
  <style>
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    }
    .abtn {
    font-size: 14px;
    color: #ffffff;
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    background-color: #00f;
    border: 1px solid rgb(170, 149, 149);
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    }

    .abtn:hover {
    background-color: #08328b;
    }

    .bbtn {
    font-size: 14px;
    color: rgb(250, 246, 246);
    font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    background-color: rgb(43, 85, 177);
    border: 1px solid rgb(170, 149, 149);
    padding: 5px 10px;
    text-align: center;
    margin: 10px 30px;
    cursor: pointer;

    }

    .btn:hover {
    background-color: rgb(3, 31, 92);
    }

    .power_btn {
    background-color: #00f;
    transition: all .2s;
    }

    .power_btn:hover {
    background-color: #0000c2;
    }
  </style>
</head>

<body>
  <table style="height:100%;width:100%;background-color:rgb(228,230,236)">
    <tbody>
      <tr>
        <td id="m_1431054743978394557m_-4183195512966669731dbody" valign="top"
          style="width:100%;height:100%;padding-top:24px;padding-bottom:24px;background-color:rgb(228,230,236)">
          <table cellspacing="0" cellpadding="0" border="0"
            style="border-style:none;max-width:659px;box-sizing:border-box;width:100%;margin:0px auto">
            <tbody>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px;box-sizing:border-box;text-align:center">
                            <img style="border-width:1px;font-size:12px;border-style:none;max-width:434px;width:80%"
                              alt="Image"
                              src="https://healthsighttracker.com/assets/img/HSCT_Logo Black-1.png"
                              width="434" class="CToWUd a6T" tabindex="0">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>

              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:18px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:32px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <strong style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Message from 
                              HealthSight Care Tracker Portal!</strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="margin:0px;padding:0px"><strong><br></strong></p>
                            <p style="text-align:center; font-size: medium;"><span>A request has been recieved to change
                                the password of
                                your account.
                              </span></p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><br></span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>

              <tr>
                <td valign="top"
                  style="background-color:rgb(244,244,243);box-sizing:border-box;font-size:0px;text-align:center">

                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:48px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="margin:0px;padding:0px;color:rgb(0,0,0);font-size:22px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              Reset Password Link:</p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">Click on the button below to reset your password.
                            </p>
                            <p style="margin:0px;padding:0px"><span style="font-size:14px"><strong><br></strong></span>
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                            </p>
                            <p style="margin:0px;padding:0px">
                              <span color="#5457ff"><span style="font-size:16px"><a class="abtn" style="color: #ffffff";
                                    href=${resetUrl} target="_blank"><strong><span style="font-size:14px">
                                       Reset Password</span></strong></a></span></strong></a></span><br>
                              </span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px;box-sizing:border-box;text-align:center">
                            <img
                              src="https://api.elasticemail.com/userfile/5f8e6df9-a0d4-4390-84f5-7e6efb0f5776/Daco_4844228.png"
                              alt="Image"
                              style="border-width:0px;font-size:12px;border-style:none;max-width:382px;width:100%"
                              width="382" class="CToWUd a6T" tabindex="0">

                          </td>
                        </tr>
                        <tr>
                          <td valign="top"
                            style="padding:20px;text-align:left;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p
                              style="text-align:center;margin:0px;padding:0px;color:rgb(0,0,0);font-size:16px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                              <b><span
                                  style="font-size:18px;font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">Introduction
                                  to HealthSight Care Tracker</span></b></p>
                            <p style="margin:0px;padding:0px"><b><span style="font-size:18px"><br></span></b></p>
                            <p style="text-align:center;margin:0px;padding:0px">
                            </p>
                            <p style="text-align:center;margin:0px;padding:0px">We've attatched a link to an
                              introduction
                              powerpoint. This powerpoint will help introduce you to our system and explains its many
                              functionalities.</p>
                          </td>
                        </tr>
                        <tr>
                          <td valign="top" style="padding:20px;text-align:center">
                            <table style="text-align:center;margin:0 auto" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td class="power_btn" style="border-radius:8px;padding:14px;text-align:center">
                                    <a href="https://1348F.trk.elasticemail.com/tracking/click?d=9AT5DOOSSpB99x-HvYb76FK6EbkjMfGjU-rGEAxQs3_0OKus-a-t0cMhD2vcIivQLijGra4_qia1c-0gQo_5VrO41fwuCLzCCj_-XR2SDjH585VKFMBWhqw_iYpugBFw6ZzyJNnW0050jD5GL19-vCIveCNxnGKyQa7sinvsZ6__vgNa2ZYgcXyZz0g5IKhUf6YuFzo9Ih0oNLrzUIHP3lopzXKx0Fq2oY_AhyAqVlLU0"
                                      style="font-size:14px;color:rgb(255,255,255);font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight:normal;text-decoration:none;display:inline-block"
                                      target="_blank">
                                      <span style="font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif">
                                        Power
                                        Point! </span> </a> </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(255,255,255);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:10px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);box-sizing:border-box;font-size:0px;text-align:center">
                  <section style="max-width:659px;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top" style="padding:20px">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                </td>
              </tr>
              <tr>
                <td valign="top"
                  style="background-color:rgb(228,230,236);text-align:center;box-sizing:border-box;font-size:0px">
                  <section style="max-width:659px;text-align:center;display:inline-block;vertical-align:top;width:100%">
                    <table style="border-collapse:collapse;width:100%" cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr>
                          <td valign="top"
                            style="padding:10px;text-align:center;color:rgb(95,95,95);font-size:12px;font-family:Ubuntu,Arial,Helvetica,sans-serif;word-break:break-word;direction:ltr;box-sizing:border-box">
                            <p style="font-size:11px;margin:0px;padding:0px">If you no longer wish to receive mail from
                              us, you can&nbsp;
                              <a href="https://1348F.trk.elasticemail.com/tracking/unsubscribe?d=xHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0"
                                style="background-color:initial;color:rgb(130,130,130);font-family:Helvetica,Arial,sans-serif;text-decoration:none"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://1348F.trk.elasticemail.com/tracking/unsubscribe?d%3DxHqI_6iSJmz7wXhESriRY8rv4bOuZtgL3e3Y7BHAx9ApYXdL38yxt9i_EtfgBlzgt4PO_0-wiwyG97SdoD0Y4PQdIB35fg_NygQcdxVmKo6v0&amp;source=gmail&amp;ust=1637643642638000&amp;usg=AOvVaw0sBCaGht03_pny85k9UN8O"><span
                                  style="font-size:11px"><u>unsubscribe</u></span></a>
                              <br>Raymond Torres, United States
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>

</body>

</html>`



    return template
  }



}
