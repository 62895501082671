import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
// import { Doctor } from '../../../../models/users/doctor';
import { MainService } from '../../../../services/mainservice/main.service';
import { Admin } from '@app/models/users/admin';

@Injectable()
export class AdminsDetailsResolver implements Resolve<Admin | null> {
    constructor(
        private _MainService: MainService
    ) { }
    resolve(route: ActivatedRouteSnapshot): Promise<Admin> | null {
      debugger
        const id = route.params.id

        if (id && id !== "0") {
            return this._MainService.getAdminByAdminId(id).then((data: any) => {
                return data.data;
            });
        } else {
            return null;
        }

    }
}
