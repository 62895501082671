import { Injectable } from '@angular/core';
import { MainService } from '../../../services/mainservice/main.service';
import { Resolve } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Injectable()

export class FacilityResolver implements Resolve<any | null> {
    constructor(
        private _MainService: MainService,
        private ngxLoader: NgxUiLoaderService
    ) { }

    resolve(): Promise<any> | null {
        this.ngxLoader.start();
        return Promise.all([
            this._MainService.getfacilityList(1, 10).then(data => {
                this.ngxLoader.stop()
                return data.data;
            })
        ])
    }
}
