export class AddAttorneyDetailsWithResponse{
  data:AddEditAttorneyInsurance
  constructor(){
    this.data=new AddEditAttorneyInsurance()
  }
}

export class AddEditAttorneyInsurance {
    name :string = '';
    phone: string = '';
    fax: string = '';
    email: string = '';
    address: string = '';
    insurance: string = '';
  }
  