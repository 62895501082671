<div class="container my-5">
  <div class="bg-white shadow rounded">
    <div class="p-3 border-bottom">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <h1 class="h5 font-weight-bold mb-0">Patient</h1>
        <div class="form-row w-20">
          <div class="col-auto">
            <a class="btn btn-sm btn-outline-primary pull-right">
              <i class="fa fa-plus-square-o" aria-hidden="true"></i>
              Add New Patient
            </a>
          </div>
        </div>
      </div>
      <hr>
      <div class="p-3 position-relative">
        <div class="align-items-center">
          <h3 class="text-center">No Patient</h3>
        </div>
      </div>
    </div>
  </div>
</div>
